import React, { Component } from "react";
import {
  Box,
  Button,
  Checkbox,
  withStyles,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import CfCustomerSettingTabsController, {
  Props,
} from "./CfCustomerSettingTabsController.web";

const styles = (theme: any) => ({
  root: {
    padding: "0 8px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    minHeight: "120px",
  },
  textContainer: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderLeft: "1px solid #ddd",

    paddingLeft: "20px",
    height: "100%",
  },
  textHolder: {
    display: "flex",
    flexDirection: "column",
  },
  checkbox: {
    "& .MuiSvgIcon-root": {

    },
    "& .Mui-checked": {
      backgroundColor: "transparent !important",
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  checkboxHolder: {
    alignSelf: "flex-start",
    height: "100%",
    display: "flex",
  },
  mainHeading: {
    fontSize: "15px",
    margin: "5px 0",
    fontWeight: "bold",
  },
  mainParagraph: {
    fontSize: "14px",
    marginBottom: "12px",
    color: "#000"
  },
  mainParagraphSelected: {
    fontSize: "14px",
    marginBottom: "12px",
    color: "#2A6396",
    fontWeight: "bold"
  },
  radioBtn: {
    "& .Mui-checked": {
      backgroundColor: "transparent !important",
    },
    "& .MuiSvgIcon-root": {
      color: ' rgba(0, 0, 0, 0.54)',
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    ".MuiIconButton-colorSecondary .Mui-checked:hover": {
      backgroundColor: "transparent !important",
    }
  },
  radioBtnSelected: {
    "& .Mui-checked": {
      backgroundColor: "transparent !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#2A6396",
    },
    "&:hover": {
      color: "transparent",
      backgroundColor: "transparent !important",
    },
    ".MuiIconButton-colorSecondary .Mui-checked:hover": {
      backgroundColor: "transparent !important",
    }
  },
  btnBox: {
    display: "flex",
    float: "right",
    marginBlock: "30px",
    gap: "20px",
  },
  saveButton: {
    background: "#2A6396",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#2A6396",
    },
  },
  discardButton: {
    background: "white",
    textTransform: "capitalize",
  },

});

export class CfCustomerSalesInvoicing extends CfCustomerSettingTabsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Box className={classes.root}>
          <div className={classes.container}>
            <div className={classes.checkboxHolder}>
              <Checkbox
                data-test-id="invoice-policy"
                className={classes.checkbox}
                checked={this.state.userSetting.invoice_policy}
                onChange={(event) => this.handleInvoiceCheckboxCheck(event)}
              />
            </div>
            <div className={classes.textContainer}>
              <div className="box">
                <div className={classes.textHolder}>
                  <p className={classes.mainHeading}>Invoicing policy</p>
                  <p className={classes.mainParagraph}>
                    {" "}
                    Quantities to invoice from sales orders{" "}
                  </p>
                  <RadioGroup
                    data-test-id="radio-invoice"
                    value={this.state.userSetting.invoice_policy_setting}
                    onChange={(event) => this.handleInvoiceRadioButton(event)}
                  >
                    <FormControlLabel
                      value="order"
                      control={
                        <Radio
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          size="small"
                          className={this.state.userSetting.invoice_policy_setting === "order" ? classes.radioBtnSelected : classes.radioBtn}
                          disabled={!this.state.userSetting.invoice_policy}
                        />
                      }
                      label={
                        <p className={this.state.userSetting.invoice_policy_setting === "order" ? classes.mainParagraphSelected : classes.mainParagraph}>
                          Invoice what is ordered
                        </p>
                      }
                    />
                    <FormControlLabel
                      value="delivery"
                      control={
                        <Radio size="small"
                          className={this.state.userSetting.invoice_policy_setting === "delivery" ? classes.radioBtnSelected : classes.radioBtn}
                          disabled={!this.state.userSetting.invoice_policy}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                        />
                      }
                      label={
                        <p className={this.state.userSetting.invoice_policy_setting === "delivery" ? classes.mainParagraphSelected : classes.mainParagraph}>
                          Invoice what is delivered
                        </p>
                      }
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <Box className={classes.btnBox}>
          <Button variant="outlined"
            data-test-id="discard-invoice"
            className={classes.discardButton}
            onClick={() => this.handleSettingShippingDiscard()}>
            Discard
          </Button>
          <Button
            data-test-id="SettingInvoiceSubmit"
            onClick={() => {
              this.handleSetting();
            }}
            variant="contained"
            className={classes.saveButton}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  }
}

//@ts-ignore
export default withStyles(styles)(CfCustomerSalesInvoicing);
