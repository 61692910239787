import React, { Component } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  Chip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

type Options = {
  label: string;
  value: string | number;
};
type MyProps = {
  testID: string;
  label: string;
  options: Options[];
  value: any;
  width: any;
  required: boolean;
  classes: any;
  onChangeValue?: (value: boolean) => void;
};

type SelectedOptions = string;

type MyState = {
  selectedOptions: SelectedOptions[];
  open: boolean;
  onChangeValue?: (value: any) => void;
  onRemoveOptions?: (value: boolean) => void;
};

export class CustomMultiSelect extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedOptions: [],
      open: false,
    };
  }

  render() {
    console.log({ selected: this.state.selectedOptions });
    const {
      width = "50%",
      label = "Select",
      options = [],
      required,
      classes,
    } = this.props;
    return (
      <Box sx={{ width: width }} className={classes.selectContainer}>
        <Typography style={{ marginBottom: 6 }}>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </Typography>
        <Box sx={{ position: "relative" }}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <Select
              IconComponent={() =>
                this.state.open ? <ExpandLess /> : <ExpandMore />
              }
              multiple
              onOpen={() => {
                this.setState({ open: true });
              }}
              onClose={() => {
                this.setState({ open: false });
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    marginTop:-7,
                  },
                },
              }}
              value={this.state.selectedOptions}
              onChange={(e: any) => this.handleValueChange(e.target.value)}
              renderValue={(selected: any) => (
                <Box style={{ display: "flex", gap: 6 }}>
                  {selected.map((value: any) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={() => this.onRemoveOptions(value)}
                      deleteIcon={
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#000000",
                          }}
                          onMouseDown={(event: any) => event.stopPropagation()}
                        >
                          X
                        </Box>
                      }
                    />
                  ))}
                </Box>
              )}
              style={{ height: 56 }}
            >
              {options.map((option: any, id: number) => (
                <MenuItem key={option.label + id} value={option.value}>
                  {option.label}
                  {/* {this.state.selectedOptions.includes(option) ? <CheckIcon color="info" /> : null} */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  }

  componentWillReceiveProps(nextProps: any) {
    if (true) {
      this.setState({
        selectedOptions: nextProps.value,
      });
    }
  }

  handleValueChange(value: any) {
    console.log({ value });
    this.setState({
      selectedOptions: value,
    });
    if (this.props.onChangeValue) {
      this.props.onChangeValue(value);
    }
  }

  onRemoveOptions(value: any) {
    const filteredOptions = this.state.selectedOptions.filter(
      (item: any) => item !== value
    );
    console.log({ filteredOptions });
    this.handleValueChange(filteredOptions);
  }
}

const styles = () => ({
  selectContainer: {
    "& .MuiInputBase-root": {
      height: 56,
    },
    "& .MuiSelect-root": {
      border: "unset !important",
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      right: 12,
      pointerEvents: "none",
    },
  },
});

export default withStyles(styles)(CustomMultiSelect);
