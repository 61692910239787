import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react';
// Customizable Area Start
import { ToastContentProps, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { configJSON } from './FilteroptionsController';
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showFilter: boolean;
  showGroup: boolean;
  showFav: boolean;
  showColumn: boolean
  showCurrentSearch: boolean;
  showAddToDashboard: boolean;
  showSettings: boolean;
  columns: string[];
  token: string | null;
  getlistcategorydata: {
    id: number,
    type: string,
    attributes: {
      category: string,
      unspsc_category: string,
      archive: boolean,
      unit_of_measure: []
    }
  }[];
  categoryType: string
  uom_type: string,
  uom: string,
  ratio: string,
  rounding_precision: string,
  uomCategorylist: { [key: string]: string | {} },
  checkboxId: number[],
  page: number,
  per_page: number,
  pricingState: boolean,
  destinationState: boolean,
  descriptionState: boolean,
  shippingProviderstate: boolean,
  deleteId: string,
  getShippingdata: { id: string, type: string, attributes: { shipping_method: string, provider: string, is_published: boolean, website: string, company: string } }[]
  groupbyShippingdata: { shipping_methods: { data: []; }; key: string }[] | null,
  listviewShippingmethod: boolean,
  shippingData: {
    shipping_method: string;
    provider: string;
    company: string;
    margin_on_rate: number;
    free_margin: boolean;
    amount: number;
    delivery_product: string;
    website: string,
    is_published: boolean,
    pricings: { [key: string]: string }[]
    destinations_availabilities_attributes: { [key: string]: string[] }[]
    descriptions_attributes: { [key: string]: string }[]
  }
  shipping_methodError: boolean,
  providerError: boolean,
  companyError: boolean,
  margin_on_rateError: boolean,
  free_marginError: boolean,
  amountError: boolean,
  delivery_productError: boolean,
  websiteError: boolean,
  is_publishedError: boolean,
  currentPagenumber: number;
  rowsPerpage: number;
  row: {
    from: number;
    end: number;
  };
  totalPage: number;
  totalCounts: number;
  dataLength: number;
  statesList:string[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetUoMcategorieslistCallId: string = "";
  apigetfilterUoMcategorieslistCallId: string = "";
  apicreateUoMcategorieslistCallId: string = "";
  apideleteUnitofmeasureCallId: string = "";
  apideleteUoMcategoriesCallId: string = "";
  apicreateUnitofmeasurelistCallId: string = "";
  apigetShippingmethodlistCallId: string = "";
  apigetfilterShippingmethodlistCallId: string = "";
  apideleteShippingmethodlistCallId: string = "";
  apigroupbyShippingmethodlistCallId: string = "";
  apigetsearchUoMcategorieslistCallId: string = "";
  apisearchShippingmethodlistCallId: string = "";
  apicreateShippingmethodlistCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      showFilter: false,
      showColumn: false,
      showGroup: false,
      showFav: false,
      showCurrentSearch: false,
      showAddToDashboard: false,
      showSettings: false,
      token: "",
      columns: ["Customer Name", "Phone", "Email", "Salesperson", "Activities", "City", "State", "Country", "VAT/TAX ID", "Next Partner Review", "Partner Level", "Activation", "Tags", "Current Membership Status"],
      getlistcategorydata: [],
      categoryType: "",
      uom_type: "",
      uom: "",
      ratio: "",
      rounding_precision: "",
      uomCategorylist: {},
      checkboxId: [],
      page: 1,
      per_page: 5,
      pricingState: true,
      destinationState: false,
      descriptionState: false,
      shippingProviderstate: false,
      getShippingdata: [],
      groupbyShippingdata: null,
      deleteId: "",
      listviewShippingmethod: true,
      shippingData: {
        shipping_method: "",
        provider: "",
        company: "",
        margin_on_rate: 0,
        free_margin: true,
        amount: 0,
        delivery_product: "",
        website: "",
        is_published: false,
        pricings: [
          {
            fixed_price: ""
          }
        ],
        destinations_availabilities_attributes: [
          {
            countries: [],
            states: [],
            zip_from: [],
            zip_to: []
          }
        ],
        descriptions_attributes: [
          {
            description: ""
          }
        ],
      },
      shipping_methodError: false,
      providerError: false,
      companyError: false,
      margin_on_rateError: false,
      free_marginError: false,
      amountError: false,
      delivery_productError: false,
      websiteError: false,
      is_publishedError: false,
      currentPagenumber: 1,
      rowsPerpage: 10,
      row: {
        from: 1,
        end: 9,
      },
      totalPage: 0,
      totalCounts: 0,
      dataLength: 0,
      statesList:["UP","AP","TS","MP"]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const getToken = await StorageProvider.get("TOKEN")
    this.setState({
      token: getToken
    })
    await this.getUoMcategorieslistApi(getToken)
    await this.getShippingmethodlistApi(getToken)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.listResponse(apiRequestCallId, responseJson)
        this.filterResponse(apiRequestCallId, responseJson)

        if (apiRequestCallId === this.apicreateUoMcategorieslistCallId) {
          toast.success(responseJson.message)
          this.setState({
            uomCategorylist: responseJson.info.data
          })
        }
        this.CreateunitofMeasure(apiRequestCallId)
        this.deleteCategory(apiRequestCallId, responseJson)
        this.searchResponse(apiRequestCallId, responseJson)
        this.getShippingmethodResponse(apiRequestCallId, responseJson)
        this.getfilterShippingmethodResponse(apiRequestCallId, responseJson)
        this.getdeleteShippingmethodResponse(apiRequestCallId, responseJson)
        this.getgroupbyShippingmethodResponse(apiRequestCallId, responseJson)
        this.getsearchShippingmethodResponse(apiRequestCallId, responseJson)
        this.handleCreateshippingmethodResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  salesnavigatetoCreateCustomer = () => {
    const salesCreatemessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    salesCreatemessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SalesconfigrationCreateUOMcategories'
    );
    salesCreatemessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(salesCreatemessage.id, salesCreatemessage)
  }
  salesnavigatetoGridview = () => {
    const salesmessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    salesmessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SalesconfigrationUOMcategories'
    );
    salesmessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(salesmessage.id, salesmessage)
  }
  navigatetoCreateshippingmethod = () => {
    const salesmessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    salesmessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SalesconfigurationCreateshippingmethod'
    );
    salesmessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(salesmessage.id, salesmessage)
  }
  listResponse = (apiRequestCallId: string, responseJson: { data: { id: number; type: string; attributes: { category: string; unspsc_category: string; archive: boolean; unit_of_measure: []; }; }[]; errors: string; }) => {

    if (apiRequestCallId === this.apigetUoMcategorieslistCallId) {
      this.setState({
        getlistcategorydata: responseJson.data
      })
    }
    else if (responseJson.errors) {
      toast.error(configJSON.errorMsg)
    }
  }
  filterResponse = (apiRequestCallId: string, responseJson: { data: { id: number; type: string; attributes: { category: string; unspsc_category: string; archive: boolean; unit_of_measure: []; }; }[]; errors: string; }) => {
    if (apiRequestCallId === this.apigetfilterUoMcategorieslistCallId) {
      this.setState({
        getlistcategorydata: responseJson.data
      })
    }
    else if (responseJson.errors) {
      toast.error(configJSON.errorMsg)
    }
  }
  searchResponse = (apiRequestCallId: string, responseJson: { data: { id: number; type: string; attributes: { category: string; unspsc_category: string; archive: boolean; unit_of_measure: []; }; }[]; errors: string; }) => {
    if (apiRequestCallId === this.apigetsearchUoMcategorieslistCallId) {
      this.setState({
        getlistcategorydata: responseJson.data
      })
    }
    else if (responseJson.errors) {
      toast.error(configJSON.errorMsg)
    }
  }
  CreateunitofMeasure = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.apicreateUnitofmeasurelistCallId) {
      toast.success(configJSON.successMsg)
    }
  }
  deleteCategory = (apiRequestCallId: string, responseJson: { message: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | ((props: ToastContentProps) => React.ReactNode) | null | undefined; }) => {
    if (apiRequestCallId === this.apideleteUoMcategoriesCallId) {
      toast.success(responseJson.message)
    }
  }
  handleUomType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      uom: e.target.value
    })
  }
  handleRoundingprecisionType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      rounding_precision: e.target.value
    })
  }
  saleshandleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };
  saleshandleGroup = () => {
    this.setState({ showGroup: !this.state.showGroup });
  };
  saleshandleFavorites = () => {
    this.setState({ showFav: !this.state.showFav });
  };
  saleshandleCurrentSearch = () => {
    this.setState({ showCurrentSearch: !this.state.showCurrentSearch });
  };
  saleshandleAddToDashboard = () => {
    this.setState({ showAddToDashboard: !this.state.showAddToDashboard });
  };
  saleshandleSettings = () => {
    this.setState({ showSettings: !this.state.showSettings });
  };
  saleshandleColumn = () => {
    this.setState({ showColumn: !this.state.showColumn });
  };
  handleCategorytype = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      categoryType: e.target.value
    })
  }
  handleRatiotype = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ratio: e.target.value
    })
  }
  handleCheckbox = (id: number) => {
    const temp = [...this.state.checkboxId, id]
    this.setState({
      checkboxId: temp
    })
  }
  handleuom_type = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      uom_type: e.target.value
    })
  }
  handleOpenpricing = () => {
    this.setState({
      pricingState: true,
      destinationState: false,
      descriptionState: false
    })
  }
  handleOpendestination = () => {
    this.setState({
      pricingState: false,
      destinationState: true,
      descriptionState: false
    })
  }
  handleOpendescription = () => {
    this.setState({
      pricingState: false,
      destinationState: false,
      descriptionState: true
    })
  }
  handleOpengroupby = (value: string) => {
    if (value === "yes") {
      this.setState({
        shippingProviderstate: true,
        listviewShippingmethod: false
      })
    } else {
      this.setState({
        shippingProviderstate: false,
        listviewShippingmethod: true
      })
    }

    this.groupbyShippingmethodlistApi(value)
  }
  handleDeleteid = (id: string) => {
    this.setState({
      deleteId: id
    })
  }

  getShippingmethodResponse = (apiRequestCallId: string, responseJson: { data: { id: string, type: string, attributes: { shipping_method: string, provider: string, is_published: boolean, website: string, company: string } }[]; errors: string; }) => {
    if (apiRequestCallId === this.apigetShippingmethodlistCallId) {
      this.setState({
        getShippingdata: responseJson.data
      })
    }
    else if (responseJson.errors) {
      toast.error(configJSON.errorMsg)
    }
  }
  getfilterShippingmethodResponse = (apiRequestCallId: string, responseJson: { data: { id: string, type: string, attributes: { shipping_method: string, provider: string, is_published: boolean, website: string, company: string } }[]; errors: string; }) => {
    if (apiRequestCallId === this.apigetfilterShippingmethodlistCallId) {
      this.setState({
        getShippingdata: responseJson.data
      })
    }
    else if (responseJson.errors) {
      toast.error(configJSON.errorMsg)
    }
  }
  getsearchShippingmethodResponse = (apiRequestCallId: string, responseJson: { data: { id: string, type: string, attributes: { shipping_method: string, provider: string, is_published: boolean, website: string, company: string } }[]; errors: string; }) => {
    if (apiRequestCallId === this.apisearchShippingmethodlistCallId) {
      this.setState({
        getShippingdata: responseJson.data
      })
    }
    else if (responseJson.errors) {
      toast.error(configJSON.errorMsg)
    }
  }
  getdeleteShippingmethodResponse = (apiRequestCallId: string, responseJson: { message: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | ((props: ToastContentProps) => React.ReactNode) | null | undefined; errors: string; }) => {
    if (apiRequestCallId === this.apideleteShippingmethodlistCallId) {
      toast.success(responseJson.message)
    }
    else if (responseJson.errors) {
      toast.error(configJSON.errorMsg)
    }
  }
  getgroupbyShippingmethodResponse = (apiRequestCallId: string, responseJson: S['groupbyShippingdata']) => {
    if (apiRequestCallId === this.apigroupbyShippingmethodlistCallId) {
      if (!responseJson?.hasOwnProperty("errors")) {
        this.setState({
          groupbyShippingdata: responseJson
        })
      } else if (responseJson?.hasOwnProperty("errors")) {
        toast.error(configJSON.invalidToken)
      }
    }
  }

  handleShippingdata = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      shippingData: { ...this.state.shippingData, [e.target.name]: e.target.value }
    })
    this.validation(e)
  }
  handlePricingChange = (event: { target: { name: string; value: string; }; }, index: number) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let updatedPricings = [...prevState.shippingData.pricings]
      updatedPricings[index][name] = value;
      return {
        shippingData: {
          ...prevState.shippingData,
          pricings: updatedPricings,
        },
      };
    });
  };
  handleDestinationcountryChange = (event: React.ChangeEvent<{ name?: string ; value: unknown; }>) => {
    const {
      target: { value },
    } = event;
    const newOptions = typeof value === 'string'
      ? [{ name: value }]
      : (value as string[]).map((name: string) => ({  name }));

    this.setState((prevState) => {
      const updatedData = {
        ...prevState.shippingData,
        destinations_availabilities_attributes: [{
          countries: newOptions.map((option: { name: string; }) => option.name),
          states: [...prevState.shippingData.destinations_availabilities_attributes[0].states],
          zip_from: [...prevState.shippingData.destinations_availabilities_attributes[0].zip_from],
          zip_to: [...prevState.shippingData.destinations_availabilities_attributes[0].zip_to],
        }],
      };

      return {
        shippingData: updatedData,
      };
    });
  };
  handleDestinationstateChange = (event: React.ChangeEvent<{ name?: string ; value: unknown; }>) => {
    const {
      target: { value },
    } = event;
    const newOptions = typeof value === 'string'
      ? [{ name: value }]
      : (value as string[]).map((name: string) => ({  name }));

    this.setState((prevState) => {
      const updatedData = {
        ...prevState.shippingData,
        destinations_availabilities_attributes: [{
          states: newOptions.map((option: { name: string; }) => option.name),
          countries: [...prevState.shippingData.destinations_availabilities_attributes[0].countries],
          zip_from: [...prevState.shippingData.destinations_availabilities_attributes[0].zip_from],
          zip_to: [...prevState.shippingData.destinations_availabilities_attributes[0].zip_to],
        }],
      };

      return {
        shippingData: updatedData,
      };
    });
  };
  handleDestinationzipformChange = (event: React.ChangeEvent<{ name?: string ; value: unknown; }>) => {
    const {
      target: { value },
    } = event;
    const newOptions = typeof value === 'string'
      ? [{ name: value }]
      : (value as string[]).map((name: string) => ({  name }));

    this.setState((prevState) => {
      const updatedData = {
        ...prevState.shippingData,
        destinations_availabilities_attributes: [{
          states: [...prevState.shippingData.destinations_availabilities_attributes[0].states],
          countries: [...prevState.shippingData.destinations_availabilities_attributes[0].countries],
          zip_to: [...prevState.shippingData.destinations_availabilities_attributes[0].zip_to],
          zip_from: newOptions.map((option: { name: string; }) => option.name),
        }],
      };

      return {
        shippingData: updatedData,
      };
    });
  };  handleDestinationziptoChange = (event: React.ChangeEvent<{ name?: string ; value: unknown; }>) => {
    const {
      target: { value },
    } = event;
    const newOptions = typeof value === 'string'
      ? [{ name: value }]
      : (value as string[]).map((name: string) => ({  name }));

    this.setState((prevState) => {
      const updatedData = {
        ...prevState.shippingData,
        destinations_availabilities_attributes: [{
          states: [...prevState.shippingData.destinations_availabilities_attributes[0].states],
          zip_from: [...prevState.shippingData.destinations_availabilities_attributes[0].zip_from],
          countries: [...prevState.shippingData.destinations_availabilities_attributes[0].countries],
          zip_to: newOptions.map((option: { name: string; }) => option.name),
        }],
      };

      return {
        shippingData: updatedData,
      };
    });
  };
  handleChipDelete=()=>{

  }
  handleDescriptionChange = (event: { target: { name: string; value: string; }; }, index: number) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const updatedDescriptions = [...prevState.shippingData.descriptions_attributes];
      updatedDescriptions[index][name] = value;
      return {
        shippingData: {
          ...prevState.shippingData,
          descriptions_attributes: updatedDescriptions,
        },
      };
    });
  };
  handleSelectprovide = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      providerError: !e.target.value ? true : false
    });
  }

  validation = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "shipping_method":
        if (!e.target.value) {
          this.setState({
            shipping_methodError: true
          })
        }
        break;
      case "provider":
        this.handleSelectprovide(e)
        break
      case "margin_on_rate":
        if (!e.target.value) {
          this.setState({
            margin_on_rateError: true
          });
        } else {
          this.setState({
            margin_on_rateError: false
          });
        }
        break

      case "amount":
        if (!e.target.value) {
          this.setState({
            amountError: true
          });
        } else {
          this.setState({
            amountError: false
          });
        }
        break
        case "delivery_product":
          if (!e.target.value) {
            this.setState({
              delivery_productError: true
            });
          } else {
            this.setState({
              delivery_productError: false
            });
          }
          break

      case "website":
        if (!e.target.value) {
          this.setState({
            websiteError: true
          });
        } else {
          this.setState({
            websiteError: false
          });
        }
        break

      default: {
        return false;
      }
    }
  }
  handleChangePageNumber = (pageNo: number) => {
    this.setState({
      currentPagenumber: pageNo + 1,
    });
    if (this.state.currentPagenumber > pageNo) {
      this.setState((previous) => ({
        row: {
          from: previous.row.from - this.state.rowsPerpage,
          end: previous.row.end - this.state.rowsPerpage,
        },
      }));
    } else {
      this.setState((previous) => ({
        row: {
          from: previous.row.from + this.state.rowsPerpage,
          end: previous.row.end + this.state.rowsPerpage,
        },
      }));
    }
  };

  handleChangerowsPerpage = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      rowsPerpage: parseInt(event.target.value as string),
      currentPagenumber: 1,
    });
  };
  handleCreateshippingmethodResponse = (apiRequestCallId: string, responseJson: { [x: string]: string; errors: string; }) => {
    if (apiRequestCallId === this.apicreateShippingmethodlistCallId) {
      if(responseJson.data){
        toast.success(configJSON.createSucessmsg)
      }
      else if (responseJson.errors) {
        toast.error(configJSON.errorMsg)
      }
    }
  }
  getUoMcategorieslistApi = async (token: string | null) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetUoMcategorieslistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getlistUoMcategoriesAPiEndPoint + `${"page"}=${this.state.currentPagenumber}&${"per_page"}=${this.state.rowsPerpage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getfilterUoMcategorieslistApi = async (key: string, value: boolean) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetfilterUoMcategorieslistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getfilterUoMcategoriesAPiEndPoint}${key}=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getsearchUoMcategorieslistApi = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetsearchUoMcategorieslistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getsearchUoMcategoriesAPiEndPoint}${"search"}=${e.target.value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createUoMcategoryApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const data = {
      unspsc_category: this.state.categoryType,
      archive: "true",
      category: this.state.categoryType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apicreateUoMcategorieslistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUoMcategoriesAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createUnitofmeasureApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const data = {
      uom_type: this.state.uom_type,
      uom: this.state.uom,
      ratio: this.state.ratio,
      rounding_precision: this.state.rounding_precision,
      archive: "true",
      uom_category_id: this.state.uomCategorylist.id
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apicreateUnitofmeasurelistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUnitofmeasureAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteUoMcategoriesApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apideleteUoMcategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `configJSON.deleteUoMcategoriesAPiEndPoint${this.state.checkboxId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getShippingmethodlistApi = async (token: string | null) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetShippingmethodlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getlistShippingmethodAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getfilterShippingmethodlistApi = async (value: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetfilterShippingmethodlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getfilterlistShippingmethodAPiEndPoint + `${value}=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSearchShippingmethodlistApi = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apisearchShippingmethodlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getsearchShippingmethodAPiEndPoint + `${"search"}=${e.target.value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteShippingmethodlistApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apideleteShippingmethodlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteShippingmethodAPiEndPoint + `${this.state.deleteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  groupbyShippingmethodlistApi = async (value: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigroupbyShippingmethodlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getgroupbyShippingmethodAPiEndPoint + `${"group_by_provider"}=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createShippingmethod = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const data = {
      data: {
        shipping_method: this.state.shippingData.shipping_method,
        provider: this.state.shippingData.provider,
        company: this.state.shippingData.company,
        margin_on_rate: this.state.shippingData.margin_on_rate,
        free_margin: this.state.shippingData.free_margin,
        amount: this.state.shippingData.amount,
        delivery_product: this.state.shippingData.delivery_product,
        website: this.state.shippingData.website,
        is_published: this.state.shippingData.is_published,
        pricings: [
          {
            fixed_price: this.state.shippingData.pricings[0].fixed_price
          }
        ],
        destinations_availabilities_attributes: [
          {
            countries: this.state.shippingData.destinations_availabilities_attributes[0].countries,
            states: this.state.shippingData.destinations_availabilities_attributes[0].states,
            zip_from: this.state.shippingData.destinations_availabilities_attributes[0].zip_from,
            zip_to: this.state.shippingData.destinations_availabilities_attributes[0].zip_to
          }
        ],
        descriptions_attributes: [
          {
            description: this.state.shippingData.descriptions_attributes[0].description
          }
        ],
      },
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apicreateShippingmethodlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createShippingmethodAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Customizable Area End
}