//@ts-ignore
import { FilterOptions } from "./types";

export const FILTER_FIELDS: FilterOptions[] = [
  { name: "name", label: "Name" },
  { name: "responsible", label: "Responsible" },
  { name: "productCode", label: "Product Code" },
  { name: "salesPrice", label: "Sales Price" },
  { name: "cost", label: "Cost" },
  { name: "category", label: "Category" },
  { name: "type", label: "Type" },
  { name: "qtyOnHand", label: "QTY on Hand" },
  { name: "forecastedQty", label: "Forecasted QTY" },
  { name: "umo", label: "UMO" },
];
