Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.getCustomerlistAPiEndPoint = "/account_block/accounts/get_customers_list?";
exports.GroupbyfilterlistApi = "account_block/accounts//show_customers_with_filter?";
exports.getSearchlistAPiEndPoint = "/account_block/accounts/get_customers_list?search=";
exports.getGroupbylistAPiEndPoint = "/account_block/accounts/customers_group_by?";
exports.exampleAPiMethod = "POST";
exports.getFilterlistAPiEndPoint = "account_block/accounts/get_customers_list?";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcustomersalesreports3";
exports.labelBodyText = "cfcustomersalesreports3 Body";
exports.mainHeading = "List of customers";
exports.Filters = "Filters";
exports.Favorites = "Favorites";
exports.buttonSave = "Save";
exports.invalidToken = "Invalid Token";
exports.Createcustomer = "Create Customer";
exports.Groupby = "Group by";
exports.name = "NAME";
exports.sales = "SALES PERSON";
exports.phone = "PHONE";
exports.email = "EMAIL";
exports.city = "CITY";
exports.company = "COMPANY";
exports.country = "COUNTRY";
exports.companyName = "companyName";
exports.addField= "+ ADD CUSTOM FIELD";
exports.itemsPerpage = "Items per page: ";
exports.pageOne = "09";
exports.pageTwo = "27";
exports.pageThree = "54";
exports.pageFour ="99";
exports.pageFive = "10";
exports.pageSix = "30";
exports.pageSeven = "60";
exports.pageEight = "90";
exports.pageten = "100";
exports.pageNine = "100";
exports.pagination = "1-5 of 20";
exports.services = "Consulting services";
exports.appliances = "Electronics appliances vendor";


exports.btnExampleTitle = "CLICK ME";

exports.errorTitle1 = "Error";
exports.errorTitle = "Error";
exports.something_went_wrong = "Something went wrong !! Try again.."
exports.emailErrorTitle = "Email Error";
exports.errorAllFieldsAreMandatory = "All * fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorMobileNotValid = "Phone not valid.";
exports.errorMobileLengthNotValid = "Minimum length is 10.";
exports.errorPostalCodeNotValid = "Postal Code not valid.";
exports.errorWebsiteNotValid = "Website not valid.";
exports.noteNotValid = "You can enter upto 500 characters.";
exports.errorAddOneContact = "Add atleast one contact";

exports.success = "Success";
exports.customer_created_msg = "Customer created successfully.";
exports.setting_updated_msg = "Setting updated successfully.";

exports.customer = "Customer";
exports.new = "New";
exports.units = "Units";
exports.purchase = "Purchase";
exports.vendor_bills = "Vendor Bills";
exports.invoiced = "Invoiced";
exports.meetings = "Meetings";
exports.activity = "Activity";
exports.customer_type = "Customer Type";
exports.individual = "Individual";
exports.company = "Company";
exports.customer_name = "Customer Name";
exports.job_position = "Job Position";
exports.title = "Title";
exports.mobile_number = "Mobile Number";
exports.alternative_number = "Alternative Number";
exports.email_address = "Email Address";
exports.tags = "Tags";
exports.language = "Language";
exports.address_line1 = "Address (line 1)";
exports.address_line2 = "Address (line 2)";
exports.country = "Country";
exports.state = "State";
exports.city = "City";
exports.postal_code = "Postal Code";
exports.website = "Website";
exports.company_name = "Company Name";
exports.contact_address = "Contact & Address";
exports.sales_purchase = "Sales & Purchase";
exports.accounting = "Accounting";
exports.internal_notes = "Internal notes";
exports.internal_notes1 = "Internal Notes";
exports.add = "ADD";
exports.add_more = "ADD MORE";
exports.phone = "Phone";
exports.sales = "Sales";
exports.sales_person = "Sales Person";
exports.payment_terms = "Payment Terms";
exports.pricelist = "Pricelist";
exports.delivery_method = "Delivery Method";
exports.point_of_sale = "Point of sale";
exports.product_code = "Product code";
exports.payment_method = "Payment Method";
exports.receipt_reminder = "Receipt Reminder";
exports.supplier_currency = "Supplier Currency";
exports.account_details = "Account Details";
exports.bank = "Bank";
exports.account_number = "Account Number";
exports.account_entries = "Account Entries";
exports.account_recievables = "Account Recievables";
exports.account_payable = "Account Payable";
exports.general_information = "General Information";
exports.drop_files = "Drop Files to attach or browse";
exports.internal_notes_placeholder = "This note only for internal purposes...";
exports.max_500 = "Max 500 characters";
exports.max_8 = "max 8 char's";
exports.create_contact = "Create Contact";
exports.contact = "Contact";
exports.other_address = "Other Address";
exports.contact_name = "Contact Name";
exports.discard = "Discard";
exports.save = "Save";
exports.save_next = "Save & Next";

exports.settings = "Settings";
exports.product_catalog = "Product Catalog";
exports.pricing = "Pricing";
exports.shipping = "Shipping";
exports.invoicing = "Invoicing";
exports.unit_of_measure = "Unit of Measure";
exports.measure_text = "Sell and purchase products in different units of measure";
exports.pricelist_name = "Pricelist Name";
exports.pricelists = "Pricelists";
exports.pricelist = "Pricelist";
exports.pricelists_text = "Set multiple prices per product, automated discounts, etc.";
exports.multiple_prices_per_product = "Mutiple prices per product";
exports.advanced_price_rules = "Advanced price rules (discounts, formulas)";
exports.coupons_and_promotions = "Coupons & Promotions";
exports.manage_programs = "Manage promotion & coupon programs";
exports.coupons_programs = "Coupon Programs";
exports.promotion_programs = "Promotion Programs";
exports.daysBefore = "day(s) before";
exports.loadMore = "Load More Records";
exports.userSettingAPiEndPoint = 'bx_block_settings/settings'


// Customizable Area End