// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Chip, ClickAwayListener, FormControl, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import BanksListViewController, { Props } from './BanksListViewController.web'
import '../../settings2/assets/style.css'
export const configJSON = require("../../settings2/src/config");
import { add, filter, gridleftarrow, groupby, search, star } from '../../settings2/src/assets';
import { AppHeader } from '../../../components/src/AppHeader.web';
import Sidebar from '../../../components/src/Sidebar.web';
import { settingsGrey } from '../../itemavailability/src/assets';
export const configJSON1 = require("./config");
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class BanksListView extends BanksListViewController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { showFilter, showGroup, showSettings, showFav, showCurrentSearch, showAddToDashboard } = this.state;
        const { classes }: any = this.props;
        const searchPlaceholder = this.searchPlaceholderFnc() as string;

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.banklistviewMainLayout}>
                <Box className={classes.banklistviewsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.banklistviewcontent}>
                    {" "}
                    <AppHeader pageTitle={configJSON1.contacts} />
                    <Box className={classes.mainBox} style={{backgroundColor:"#ffffff"}}>
                        <Box>
                            <Box className={classes.banklistviewBlock}>
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Typography className={classes.banklistviewHeading}>{configJSON1.banks}</Typography>
                                    </Grid>
                                    <Grid item sm={6} className={classes.banklistviewSearchbox}>
                                        <TextField
                                            className='search-box-width'
                                            id='user-list-search-input'
                                            variant="outlined"
                                            placeholder={`${searchPlaceholder}`}
                                            data-testid="listSearchinput"
                                            value={this.state.searchKeyword}
                                            onChange={(e:any) => this.setState({...this.state,searchKeyword:e.target.value})}
                                        />
                                        <img src={search} className={`${classes.banklistviewImg} ${this.searchIconDisplayFnc()}`} />
                                        
                                        <Box className={this.searchTextItems()} style={{position:"absolute",left:"0px",top:"12px"}}>
                                            <Box sx={{display:"flex"}}>
                                            {
                                                this.state.searchArr.map((data:any,indexx:number)=>{
                                                    return <Box style={{position:"relative",marginLeft:"10px"}}>
                                                    <Chip style={{backgroundColor:"#006395",color:"#ffffff",padding:"0 25px"}} label={this.chipLabels(data.value)} />
                                                    <span className={this.filterIcon(data.type)} ><img src={this.filterImage(data.type)} /></span>
                                                    <span data-testid={`handleRemoves${indexx}`} className='handleRemoves' onClick={()=>this.filterRemove(data.type,data.value)} >×</span>
                                                    </Box>
                                                })
                                            }
                                            </Box>
                                                
                                                
                                        </Box>
                                        
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.banklistviewFilterbox} >
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Box className={classes.banklistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Filters" onClick={this.handleFilter}
                                            ><img src={filter} className={classes.banklistviewFilterImg} />{configJSON.Filters}</Button>
                                            
                                            {
                                                showFilter &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleFilter}
                                                    data-test-id="clickHandleFilter"
                                                >
                                                <Box className={`filterBox ${classes.showfilterBox}`}>
                                                    <List>
                                                        <ListItem button className={`font-14 ${this.selectedListItems(0)}`} data-testid="filter-archive" 
                                                        onClick={()=>this.handleActiveBankFilter(0)}>
                                                            <ListItemText primary="Archived" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                            }
                                            
                                        </Box>
                                        <Box className={classes.banklistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Groupby"
                                                onClick={this.handleGroup}
                                            ><img src={groupby} className={classes.banklistviewFilterImg} />{configJSON.Groupby}</Button>
                                            
                                            {
                                                showGroup &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleGroup}
                                                    data-test-id="clickHandleGroup"
                                                >
                                                <Box className={`filterBox ${classes.groupBox}`}>
                                                    <List>
                                                        <ListItem className={`font-14 ${this.bankGroupListItem('Country')}`} button data-testid="list-salesPerson" onClick={() => { this.handleActiveBankGroup('Country'); }}>
                                                            <ListItemText  primary="Country" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                        </Box>
                                        <Box className={classes.banklistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Favorites" onClick={this.handleBankFavorites} ><img src={star} className={classes.banklistviewFilterImg} />{configJSON.Favorites}</Button>
                                            {
                                                showFav &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleBankFavorites}
                                                    data-test-id="clickHandleCmpFavorites"
                                                ><Box className="filterBox">
                                                    <List >
                                                        <ListItem button data-testid="list-currentSearch" className={`listItem font-14 favorites-list ${this.showCurrentSearchItem()}`} onClick={this.handleBankCurrentSearch}>
                                                            <ListItemText primary={configJSON.saveCurrentSearch} />
                                                            <img src={gridleftarrow} />
                                                        </ListItem>
                                                        <ListItem button className='listItem font-14 favorites-list' data-testid="list-importRecords">
                                                            <ListItemText primary={configJSON.importRecords} />
                                                        </ListItem>
                                                        <ListItem button className={`font-14 favorites-list ${this.showAddToDashboardItem()}`}>
                                                            <ListItemText primary={configJSON.addToDashboard} data-testid="list-addDashboard" onClick={this.handleBankAddToDashboard} />
                                                            <img src={gridleftarrow} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                            {
                                                showCurrentSearch &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleBankCurrentSearch}
                                                    data-test-id="clickHandleCmpCurrentSearch"
                                                >
                                                <Box className={`filterBoxCurrentSearch ${classes.currentSearch}`}>
                                                <List className={classes.bankgridviewlist} >
                                                    <ListItem button className='listItem font-14'>
                                                        <ListItemText primary={configJSON1.banks} />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.bankgridviewButton} font-14`} style={{paddingBottom:0,paddingLeft:"5px"}}>
                                                        <ListItemIcon className={classes.bankgridviewIcon} >
                                                            <Checkbox  defaultChecked />
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.currentSearchText} primary={configJSON.useByDefault} />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.gridViewlistItem} font-14`} style={{paddingTop:0,paddingLeft:"5px",paddingBottom:0}}>
                                                        <ListItemIcon className={classes.bankgridviewIcon}>
                                                            <Checkbox  />
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.currentSearchText} primary={configJSON.shareWithAllUsers} />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.bankgridviewButtonlist} end-center-align font-14`} >
                                                        <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                    </ListItem>
                                                </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            {
                                                showAddToDashboard &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleBankAddToDashboard}
                                                    data-test-id="clickHandleCmpAddToDashboard"
                                                >
                                                <Box className={`filterBoxAddToDashboard ${classes.dashboardBox}`}>
                                                <List className={`font-14 ${classes.bankgridviewlist}`}>
                                                    <ListItem button style={{paddingBottom:0,paddingLeft:"10px"}} >
                                                        <TextField id="users-input" value={configJSON1.banks}/>
                                                        
                                                    </ListItem>
                                                    <ListItem button className={`font-14 ${classes.bankgridviewButtonlist} end-center-align`} style={{paddingTop:0}}  >
                                                    <Button className='saveBtn'>{configJSON.buttonSave}</Button>  
                                                    </ListItem>
                                                </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} className={classes.banklistviewCreatebox} >
                                        <Box className={classes.banklistviewColumnbox}>
                                            <Button className={`filters-left button-icon ${this.checkboxItems()}`} data-testid="list-settings" onClick={this.handleSettings}><img src={settingsGrey} /></Button>
                                            
                                            {
                                                showSettings &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleSettings}
                                                    data-test-id="clickHandleSettings"
                                                >
                                                <Box className={`settingsBox ${classes.settingsBox}`}>
                                                    <List>
                                                        <ListItem button className='listItem font-14'>
                                                            <ListItemText primary="Export" />
                                                        </ListItem>
                                                        <ListItem button className='listItem font-14'>
                                                            <ListItemText primary="Archive" />
                                                        </ListItem>
                                                        <ListItem button className='listItem font-14'>
                                                            <ListItemText primary="Unarchive" />
                                                        </ListItem>
                                                        <ListItem button className='font-14'>
                                                            <ListItemText primary="Delete" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                        </Box>
                                        
                                        <Button className='filters-right' data-testid="CreateBank" onClick={this.navigatetoCreateBank}>{configJSON1.createBank}&nbsp;&nbsp;<img src={add} /></Button>
                                    </Grid>
                                </Grid>
                                <Box className={`${this.loaderBoxFnc()}`}>
                                {
                                this.loaderBoxContentFnc()
                                }
                                </Box>
                                <Box className={`${this.listViewFnc()}`}>
                                <Box className={classes.banklistviewColumnbox} >
                                    
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.banklistviewCheck}><Checkbox 
                                                    checked={this.state.globalCheck}
                                                    onChange={()=>this.handleGlobalCheckBank()} 
                                                    data-test-id="globalCheckboxBank"
                                                    /></TableCell>
                                                    <TableCell className={classes.banklistviewTableheading}>{configJSON1.name}</TableCell>
                                                    <TableCell className={classes.banklistviewTableheading}>{configJSON1.identifierCode}</TableCell>
                                                    <TableCell className={classes.banklistviewTableheading}>{configJSON1.country}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {(!this.state.activeGroup) && this.state.showBankdata.map((user:any) => {
                                                return (
                                                    <>
                                                    <TableBody>
                                                    {
                                                        this.expandTableRow(user,classes)
                                                    }
                                                    </TableBody>
                                                    
                                                    </>  
                                                 )
                                            })} 
                                            {
                                                this.state.activeGroup && this.state.groupbyResponse.map((item:any,index:number)=>{
                                                    return <>
                                                    {item.banks.data.map((user:any,index1:number) =>{
                                                    return <>
                                                    
                                                    <TableBody>
                                                    {
                                                        this.handleGroupHeading(item,index1,index)
                                                    }
                                                    { this.state.active.includes(index) &&
                                                    this.expandTableRow(user,classes)
                                                    }
                                                    
                                                    </TableBody>
                                                    
                                                    </>
                                                    })}
                                                    </>
                                                })
                                            }
                                            
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box className={`${classes.banklistviewItems} ${this.groupView()}`}>
                                    <Grid container spacing={0} >
                                        <Grid item sm={8} className={classes.banklistviewItemsgrid}>
                                            <Typography className={classes.banklistviewItemsText}>{configJSON.itemsPage}</Typography>
                                            <FormControl id="select-form-control">

                                                <Select
                                                    IconComponent={this.expandMoreIcon}
                                                    inputProps={{
                                                        'aria-label': 'custom select',
                                                    }}
                                                    data-testid="select_page"
                                                    id="item-select"
                                                    value={this.state.per_page}
                                                    onChange={(e:any)=>this.handleBankPagination(e.target.value)}
                                                    label="Select"
                                                    disableUnderline
                                                    className='bold-text'
                                                >
                                                    <MenuItem className='bank-menu-item' value={9}>09</MenuItem>
                                                    <MenuItem className='bank-menu-item' value={27}>27</MenuItem>
                                                    <MenuItem className='bank-menu-item' value={54}>54</MenuItem>
                                                    <MenuItem className='bank-menu-item' value={99}>99</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={4} className='end-center-align'>
                                            <Typography className={classes.banklistviewText} >
                                                <span className='bold-text'>
                                                {(this.state.page - 1) * this.state.per_page + 1}
                                                &nbsp;-&nbsp;
                                                {(this.state.page - 1) * this.state.per_page +
                                                    this.state.showBankdata.length}
                                                </span>
                                                <span className={classes.bottompage}>
                                                &nbsp;of &nbsp;
                                                {this.state.totalCounts}
                                                </span>
                                                    
                                            </Typography>
                                            <Button data-testid="listviewBackwardicon" disabled={this.state.page==1} onClick={()=>this.loadMoreBankRecords(0,'')} className='tb-footer-btn'><img src={this.backwardArrow1()} /></Button>
                                            <Button data-testid="listviewBackArrow" disabled={this.state.page==1} onClick={()=>this.state.page>1 && this.loadMoreBankRecords(-1,'')} className='tb-footer-btn'><img src={this.backwardArrow2()} /></Button>
                                            <Button data-testid="listviewForwardArrow" disabled={this.isThisLastBankPage()} onClick={()=>this.loadMoreBankRecords(+1,'')} className='tb-footer-btn'><img src={this.forwardArrow1()} /></Button>
                                            <Button data-testid="listviewForwardIcon" disabled={this.isThisLastBankPage()} onClick={()=>this.loadMoreBankRecords(this.state.totalPage-this.state.page,'')} id='next-end'><img src={this.forwardArrow2()} /></Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                </Box>
                                
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const userlistwebStyle: any = (theme: any) => ({
    banklistviewMainLayout: {
        display: "flex",
    },
    mainBox:{
        border:"1px solid #e3e3e3",
        borderRadius:"5px",
        marginBottom:"10px"
    },
    banklistviewsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    banklistviewcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    banklistviewBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    banklistviewHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    banklistviewImg: {
        position: "absolute",
        left: "15px",
        top: "19px",
        height: "20px"
    },
    banklistviewFilterbox: {
        
    },
    banklistviewFilterImg: {
        paddingRight: "8px"
    },
    banklistviewColumnbox: {
        position: "relative"
    },
    banklistviewColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    banklistviewCheck: {
        width:"92px"
    },
    banklistviewTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    banklistviewTabletext: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    banklistviewItems: {
        padding: "5px 15px"
    },
    banklistviewCreatebox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px"
    },
    banklistviewSearchbox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative"
    },
    banklistviewItemsgrid: {
        display: "flex !important",
        justifyContent: "left",
        paddingTop:"8px"
    },
    banklistviewItemsText: {
        paddingRight: "10px",
        fontSize: "18px",
        marginTop: "15px",
        color:"#47464a"
    },
    banklistviewText: {
        marginRight: "40px"
    },
    bottompage: {
        color:"#47464a"
    },
    displayNone: {
        display:"none !important"
    },
    showfilterBox:{
        width:"120px"
    },
    groupBox:{
        width:"110px"
    },
    settingsBox:{
        top: "53px !important",
        borderRadius:"5px"
    },
    currentSearch:{
        borderRadius: "5px"
    },
    currentSearchText:{
        marginLeft: "-10px"
    },
    dashboardBox:{
        top:"156px !important",
        borderRadius: "5px"
    },
    bankgridviewButtonlist:{
        paddingTop:"0 px"
    }
})
export const BanksListViewStyles = withStyles(userlistwebStyle)(BanksListView)
  // Customizable Area End
