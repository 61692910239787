export const defaultImg = require("../assets/add-photo-alternate.png");
export const logo = require("../assets/LogoFilled.svg");
export const accounting = require("../assets/accounting@2x.png");
export const contacts = require("../assets/Contacts@2x.png");
export const humanResource = require("../assets/human resource@2x.png");
export const inventory = require("../assets/inventory@2x.png");
export const pointofsale = require("../assets/Point of Sale@2x.png");
export const purchase = require("../assets/Purchase@2x.png");
export const sales = require("../assets/sales@2x.png");
export const schedule = require("../assets/schedule (2)@2x.png");
export const settings = require("../assets/setting@2x.png");
export const sign = require("../assets/sign@2x.png");
export const backgroundImg = require("../assets/Home UI Copy.jpg");
export const filterWhite = require("../assets/filterwhite.svg");
export const groupWhite = require("../assets/groupwhite.svg");
export const searchGrey = require("../assets/searchgrey.svg");
export const searchWhite = require("../assets/searchWhite.svg");

