import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Input,
  IconButton,
  InputAdornment,
  withStyles
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { errorIcon, imgAdminSignIn, imgLogoFilled, passwordHide, passwordShow ,eyeOn,eyeOff} from "./assets";
import { COLORS } from "./Components/constants";
import { configJSON } from "./EmailAccountLoginController";
import { ToastContainer } from 'react-toastify'
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area End

// Customizable Area End
  }
   // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container className={classes.loginContainer} disableGutters maxWidth={false} >
          <ToastContainer />
          <style>
            {`
            .MuiIconButton-root:hover {
              background-color: transparent;
            }
          `}
          </style>
          <Box className={classes.loginMainbox}>
            <Box className={classes.loginFirstbox}>
              <Box className={classes.loginImgbox} >
                <img src={imgAdminSignIn} className={classes.loginImgbox} alt={configJSON.signin} />
              </Box>
            </Box>
            <Box className={classes.loginSecondboxMain}>
              <Box className={classes.loginSecondbox} px={6}>
                <Box >
                  <img src={imgLogoFilled} alt={configJSON.signin} />
                </Box>
                <Typography variant="h4" className={classes.loginHeading}>
                  {this.labelTitle2}
                </Typography>
                <Typography variant="h6" className={classes.loginSubheading}>
                  {this.labelTitle3}
                </Typography>
                <Typography className={classes.loginSidetext} variant="subtitle1" component="div">
                  {this.labelMessage}
                </Typography>
                <Box className={classes.loginTextfieldbox} >
                  <Box>
                    <Box className={classes.loginTextfieldblock} >
                      <Typography className={this.state.emailStatus?classes.loginTextfielderror:classes.loginUsertextfield}>
                        {configJSON.placeHolderEmail}<span className={classes.loginErrorstar}>{configJSON.star}</span>
                      </Typography>
                      <Box>
                        <Input
                          className={this.state.emailStatus?classes.emailTextInputerror:classes.emailTextInput}
                          disableUnderline
                          type="text"
                          data-test-id="txtInputEmail"
                          placeholder={this.state.placeHolderEmail}
                          fullWidth={true}
                          value={this.state.email}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setEmail(e.target.value)}
                        />
                      </Box>
                      {this.state.emailStatus &&
                        <Typography className={classes.loginUsertextfielderror}>
                          <img src={errorIcon} alt="error" className={classes.erroriconEmail} /> {configJSON.errorEmailNotValid}
                        </Typography>
                      }
                    </Box>
                  </Box>
                  <Box className={classes.loginPasswordblock} >
                    <Box className={classes.loginPasswordbox}>
                      <Typography className={this.state.passwordStatus?classes.loginTextfielderror:classes.loginUsertextfield}>
                        {configJSON.placeHolderPassword}<span className={classes.loginErrorstar}>*</span>
                      </Typography>
                      <Box>
                        <Input
                          disableUnderline
                          className={this.state.passwordStatus?classes.passwordTextfielderror:classes.passwordTextfield}
                          data-test-id="txtInputPassword"
                          type={this.state.enablePasswordField ? "password" : "text"}
                          placeholder={this.state.placeHolderPassword}
                          fullWidth={true}
                          value={this.state.password}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                data-test-id="hideshowPassword"
                              >
                                {this.state.enablePasswordField ? (
                                  <img className={classes.hidePassword} src={eyeOff} alt="hide" />
                                ) : (
                                  <img className={classes.showPassword} src={eyeOn} alt="show"/>
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {this.state.passwordStatus &&
                          <Typography className={classes.loginUsertextfielderror}>
                            <img className={classes.erroricon} src={errorIcon} alt="error" />
                            {configJSON.errorPasswordNotValid}
                          </Typography>}
                        <Typography
                          data-test-id={"btnForgotPassword"}
                          className={classes.loginForgotpassword}
                          onClick={() => this.goToForgotPassword()}
                        >
                          {configJSON.forgotPassword}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.loginButtonbox} >
                    <Button
                      className={classes.loginButton}
                      data-test-id={"btnEmailLogIn"}
                      onClick={() => this.doEmailLogIn()}
                    >
                      {configJSON.signin}
                    </Button>
                  </Box>
                  <Box className={classes.loginSignintextBox}>
                    <Typography className={classes.loginSigninText}>
                      {configJSON.signupText} <span data-test-id={"btnSocialLogin"} onClick={() => this.goToSocialLogin()} className={classes.loginSigninTextspan}>{configJSON.signup}</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

const loginScreenstyles: {} = () => ({
  loginContainer: {
    width: "100%",
    height: "100%"
  },
  loginMainbox: {
    width: "100%",
    height: "100%",
    padding: "unset",
    display: "flex"
  },
  loginFirstbox: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
    width: "50%",
    backgroundColor: COLORS.themeColor
  },
  hidePassword: {
    position: "relative",
    right: "50%",
  },
  showPassword:{
    position: "relative",
    right: "50%",
  },
  loginImgbox: {
    width: "90%",
    margin: "auto"
  },
  loginImg: {
    width: "100%",
  },
  erroricon: {
    height: "16px",
    paddingRight:"4px"
  },
  erroriconEmail:{
    height: "16px",
  },
  loginSecondboxMain: {
    height: "100%",
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor:"white"
  },
  loginSecondbox: {
    display: "flex",
    flexDirection: "column",
    height: "85%",
    width: "100%",
    justifyContent: "center"
  },
  loginHeading: {
    color: COLORS.themeColor,
    fontWeight: "bold",
    marginBlock: 16,
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  loginSubheading: {
    color: COLORS.themeColor,
    fontWeight: "bold",
    marginBlock: 4,
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  loginSidetext: {
    marginBlock: 4,
    fontsize: "18px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  loginTextfieldbox: {
    width: "100%",
    paddingTop: "20px"
  },
  loginTextfieldblock: {
    width: "100%",
    marginBottom: 12
  },
  loginUsertextfield: {
    marginBottom: 6,
    fontsize: "16px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  loginTextfielderror: {
    marginBottom: 6,
    fontsize: "16px",
    color: "red",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  loginUsertextfielderror: {
    color: "red",
    fontsize: "14px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    paddingTop:"2%"
  },
  loginErrorstar: {
    color: "red",
    paddingRight:"2px"
  },
  loginPasswordblock: {
    paddingBottom: "16px"
  },
  loginPasswordbox: {
    width: "100%",
    marginBottom: 12
  },

  emailTextInput: {
    "& .MuiInputBase-input": {
      borderRadius: 4
    },
  },
  emailTextInputerror: {
    border: "1px solid red !important",
    "& .MuiInputBase-input": {
      borderRadius: 4
    },
  },
  passwordTextfield: {
    border: "1px solid #e3e3e3 !important",
    borderRadius: 4,
    "& .MuiInputBase-input": {
      height: 56,
      border: "unset !important"
    },
  },
  passwordTextfielderror: {
    border: "1px solid red !important",
    borderRadius: 4,
    "& .MuiInputBase-input": {
      height: 56,
      border: "unset !important"
    },
  },
  loginForgotpassword: {
    fontSize: "16px",
    color: COLORS.themeColor,
    marginTop: "5px",
    textAlign: "right",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
  },
  loginButtonbox: {
    padding: "10px 0px",
  },
  loginButton: {
    backgroundColor: COLORS.themeColor,
    color: "#ffffff",
    textTransform: "capitalize",
    fontsize: "16px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    "&:hover": {
      backgroundColor: COLORS.themeColor,
      color: "#ffffff",
      textTransform: "capitalize",
      fontsize: "16px",
      fontFamily: "Inter",
      fontStretch: "normal",
      fontStyle: "normal",
    }
  },
  loginSignintextBox: {
    padding: "10px 0px",
  },
  loginSigninText: {
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    fontsize: "16px",
  },
  loginSigninTextspan: {
    color: COLORS.themeColor,
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    fontsize: "16px",
  }
});

export const EmailAccountLoginStyles = withStyles(loginScreenstyles)(EmailAccountLoginBlock)
// Customizable Area End
