// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem } from '@material-ui/core';
import SalesconfigrationUOMcategoriesController, { Props } from './SalesconfigrationUOMcategoriesController.web'
import { configJSON } from './FilteroptionsController';
import { add, backArrow, dashboard, filter, forwardArrow, groupby, list, more, moveBackwardArrow, moveForwardArrow, search, settings, star, swap } from './assets';
import { AppHeader } from '../../../components/src/AppHeader.web';
import  Sidebar  from '../../../components/src/Sidebar.web';
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class SalesconfigrationShippingmethod extends SalesconfigrationUOMcategoriesController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    groupbyShippingmethod(classes: { [key: string]: string }) {
        return this.state.shippingProviderstate &&
            <>
                {this.state.groupbyShippingdata?.map((value: {
                    shipping_methods: { data: []; }; key: string
                }) => {
                    return (
                        <>
                            <Accordion className={classes.accordion}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    data-testid="groupby-shippingmethod"
                                >
                                    <Typography >{value.key}</Typography>
                                </AccordionSummary>
                                {value.shipping_methods.data?.map((item: { id: string,attributes: { shipping_method: string, provider: string, is_published: boolean, website: string, company: string, archived: boolean } }) => {
                                    return (
                                        <>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Box className={classes.accordionDetailsbox}>
                                                    <Checkbox />
                                                    <Typography className={classes.accordiontext}>{item.attributes.shipping_method}</Typography>
                                                    <Typography className={classes.accordiontext}>{item.attributes.provider}</Typography>
                                                    <Typography className={classes.accordiontext}>{item.attributes.website}</Typography>
                                                    <Typography className={classes.accordiontext}>{item.attributes.company}</Typography>
                                                    <Typography className={classes.accordiontext}>{item.attributes.archived}</Typography>
                                                </Box>
                                            </AccordionDetails>
                                        </>
                                    )
                                })}
                            </Accordion>
                        </>
                    )
                })}
            </>
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { showFilter, showGroup, showFav, showSettings, showCurrentSearch, showAddToDashboard } = this.state;
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.shippingMainLayout}>
                <Box className={classes.shippingsideBar}>
                    <Sidebar />
                </Box>
                <Box className={classes.shippingcontent}>
                    <AppHeader pageTitle={configJSON.pageTitle} />
                    <Box className={classes.shippingMainbox} >
                        <Box>
                            <Box className={classes.shippingBlock}>
                                <Grid container spacing={0} className={classes.mainHeadingcontainer} >
                                    <Grid item sm={6} className={classes.shippingSearchgrid}>
                                        <Typography className={classes.shippingHeading}>{configJSON.shippingHeading}</Typography>
                                    </Grid>
                                    <Grid item sm={6} className={classes.shippingSearchbox}>
                                        <TextField
                                            className={classes.searchbox}
                                            id='search-input'
                                            variant="outlined"
                                            placeholder={configJSON.search}
                                            data-testid="shipping-Search"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.getSearchShippingmethodlistApi(e)}
                                        />
                                        <img src={search} className={classes.shippingImg} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.shippingFilterbox} >
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className={classes.shippingSearchgrid}>
                                        <Box className={classes.shippingColumnbox}>
                                            <Button className={classes.shippingFilters} data-testid="shipping-Filters" onClick={this.saleshandleFilter}
                                            ><img src={filter} className={classes.shippingFilterImg} />{configJSON.Filters}</Button>
                                            {
                                                showFilter &&
                                                <Box className={classes.filterBoxshipping} >
                                                    <List>
                                                        <ListItem button
                                                        >
                                                            <ListItemText primary={configJSON.Archived} data-testid="shipping-archived" onClick={() => { this.getfilterShippingmethodlistApi("archived") }} />
                                                        </ListItem>
                                                        <ListItem button
                                                        >
                                                            <ListItemText primary={configJSON.Published} data-testid="shipping-published" onClick={() => { this.getfilterShippingmethodlistApi("is_published") }} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                        <Box className={classes.shippingColumnbox}>
                                            <Button className={classes.shippingFilters} data-testid="shipping-Groupby"
                                                onClick={this.saleshandleGroup}
                                            ><img src={groupby} className={classes.shippingFilterImg} />{configJSON.Groupby}</Button>
                                            {
                                                showGroup &&
                                                <Box className={classes.filterBoxshipping}>
                                                    <List>
                                                        <ListItem button
                                                        >
                                                            <ListItemText primary={configJSON.Provider} data-testid="shipping-groupbyProvider" onClick={() => { this.handleOpengroupby("yes") }} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                        <Box className={classes.shippingColumnbox}>
                                            <Button className={classes.shippingFilters} data-testid="shipping-Favorites" onClick={this.saleshandleFavorites} ><img src={star} className={classes.shippingFilterImg} />{configJSON.Favorites}</Button>
                                            {
                                                showFav &&
                                                <Box className={classes.filterBoxshipping}>
                                                    <List >
                                                        <ListItem button data-testid="shipping-currentSearch" onClick={this.saleshandleCurrentSearch}>
                                                            <ListItemText primary={configJSON.saveCurrent} />

                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary={configJSON.importRecords} />
                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary={configJSON.dashboard} data-testid="shipping-addDashboard" onClick={this.saleshandleAddToDashboard} />

                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                            {
                                                showCurrentSearch &&
                                                <Box className={classes.filterBoxCurrentSearch}>
                                                    <List className={classes.gridviewlist} >
                                                        <ListItem button >
                                                            <ListItemText primary={configJSON.shippingHeading} />
                                                        </ListItem>
                                                        <ListItem button className={classes.salesButton} >
                                                            <ListItemIcon className={classes.salesIcon} >
                                                                <Checkbox className={classes.salesCheckbox} defaultChecked />
                                                            </ListItemIcon>
                                                            <ListItemText primary={configJSON.default} />
                                                        </ListItem>
                                                        <ListItem button className={classes.gridViewlistItem}>
                                                            <ListItemIcon className={classes.salesIcon}>
                                                                <Checkbox className={classes.salesCheckbox} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={configJSON.share} />
                                                        </ListItem>
                                                        <ListItem button className={classes.salesButtonlist} >
                                                            <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                            {
                                                showAddToDashboard &&
                                                <Box className={classes.filterBoxAddToDashboard}>
                                                    <List className={classes.gridviewlist}>
                                                        <ListItem button className='listItem'>
                                                            <ListItemText primary={configJSON.shippingHeading} />
                                                        </ListItem>
                                                        <ListItem button className={classes.salesButtonlist}   >
                                                            <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} className={classes.shippingCreatebox} >
                                        <Box className={classes.shippingColumnbox}>
                                            <Button className={classes.shippingFilters} data-testid="shipping-settings" onClick={this.saleshandleSettings}><img src={settings} /></Button>
                                            {
                                                showSettings &&
                                                <Box className={classes.filterBoxshipping}>
                                                    <List>
                                                        <ListItem button >
                                                            <ListItemText primary={configJSON.Archived} />
                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary={configJSON.export} />
                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary={configJSON.unarchived} />
                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary={configJSON.Delete} data-testid="shipping-deletemethod" onClick={() => { this.deleteShippingmethodlistApi() }} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                        <Button className={classes.filtersLeftshipping}><img src={list} /></Button>
                                        <Button className={classes.shippingFilters} data-testid="navigatetoGridview" onClick={this.salesnavigatetoGridview}><img src={dashboard} /></Button>
                                        <Button className={classes.filtersrightshipping} data-testid="navigate_createshippingmethod" onClick={this.navigatetoCreateshippingmethod}>{configJSON.createShipping}<img src={add} /></Button>
                                    </Grid>
                                </Grid>
                                <Box className={classes.shippingColumnbox} >
                                    <Box onClick={this.saleshandleColumn} className={classes.shippingColumn} data-testid="shippingColumn" >
                                        <img src={more} />
                                    </Box>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.shippingTableNameHeading}>
                                                    </TableCell>
                                                    <TableCell className={classes.shippingTableNameHeadingdelivery}>{configJSON.deliveryText}</TableCell>
                                                    <TableCell className={classes.shippingTableNameHeading}>{configJSON.Provider}</TableCell>
                                                    <TableCell className={classes.shippingTableNameHeading}>{configJSON.Published}</TableCell>
                                                    <TableCell className={classes.shippingTableNameHeading}>{configJSON.Website}</TableCell>
                                                    <TableCell className={classes.shippingTableNameHeading}>{configJSON.Company}</TableCell>
                                                    <TableCell className={classes.shippingTableNameHeading}>{configJSON.Countries}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {this.state.listviewShippingmethod &&
                                                <>
                                                    {this.state.getShippingdata?.map((value: { id: string, attributes: { shipping_method: string, provider: string, is_published: boolean, website: string, company: string } }) => {
                                                        return (
                                                            <TableBody key={value.id}>
                                                                <TableRow>
                                                                    <TableCell><Checkbox className={classes.shippingCheck} data-testid="shipping-deleteid" onChange={() => { this.handleDeleteid(value.id) }} />
                                                                        <img src={swap} />
                                                                    </TableCell>
                                                                    <TableCell className={classes.shippingTabletext}>{value.attributes.shipping_method}</TableCell>
                                                                    <TableCell className={classes.shippingTabletext}>{value.attributes.provider}</TableCell>
                                                                    <TableCell className={classes.shippingTabletext}>
                                                                        <Checkbox checked={value.attributes.is_published} />
                                                                    </TableCell>
                                                                    <TableCell className={classes.shippingTabletext}>{value.attributes.website}</TableCell>
                                                                    <TableCell className={classes.shippingTabletext}>{value.attributes.company}</TableCell>
                                                                    <TableCell className={classes.shippingTabletext}>{configJSON.Countries}</TableCell>
                                                                </TableRow>
                                                            </TableBody>)
                                                    })}
                                                </>
                                            }
                                        </Table>
                                    </TableContainer>
                                    {this.groupbyShippingmethod(classes)}
                                    <Grid className={classes.shippingPaginationContainer}>
                                    <Box className={classes.paginationBox}>
                        <Box className={classes.itemPerPage}>
                          <span>{configJSON.itemsPage}&nbsp;</span>
                          <Select
                            onChange={this.handleChangerowsPerpage}
                            value={this.state.rowsPerpage}
                          >
                            <MenuItem value={10}>{configJSON.pageOne}</MenuItem>
                            <MenuItem value={30}>{configJSON.salespageTwo}</MenuItem>
                            <MenuItem value={60}>{configJSON.salespageThree}</MenuItem>
                            <MenuItem value={90}>{configJSON.salespageFour}</MenuItem>
                          </Select>
                        </Box>
                        <Box>
                          <span>
                            {this.state.currentPagenumber * this.state.rowsPerpage +
                              1}
                            &nbsp; -&nbsp;
                            {this.state.currentPagenumber * this.state.rowsPerpage +
                              this.state.currentPagenumber}
                            &nbsp;of &nbsp;{this.state.totalCounts}
                          </span>
                          <Button
                            disabled={this.state.currentPagenumber === 0}
                            className={classes.paginationButton}
                            data-testid="moveBackwardarrow"
                            onClick={() =>
                              this.handleChangePageNumber(this.state.currentPagenumber - 2)
                            }
                          >
                            <img
                              src={moveBackwardArrow}
                              className={
                                this.state.currentPagenumber === 0
                                  ? classes.disabledPaginationButton
                                  : classes.enabledPaginationButton
                              }
                            />
                          </Button>
                          <Button
                            disabled={this.state.currentPagenumber === 0}
                            className={classes.paginationButton}
                            data-testid="backArrow"
                            onClick={() =>
                              this.handleChangePageNumber(this.state.currentPagenumber - 2)
                            }
                          >
                            <img
                              src={backArrow}
                              className={
                                this.state.currentPagenumber === 0
                                  ? classes.disabledPaginationButton
                                  : classes.enabledPaginationButton
                              }
                            />
                          </Button>
                          <Button
                            disabled={
                              this.state.currentPagenumber === this.state.totalPage
                            }
                            data-testid="forwardArrow"
                            className={classes.paginationButton}
                            onClick={() =>
                              this.handleChangePageNumber(this.state.currentPagenumber)
                            }
                          >
                            <img
                              src={forwardArrow}
                              className={
                                this.state.currentPagenumber === this.state.totalPage
                                  ? classes.disabledPaginationButton
                                  : classes.enabledPaginationButton
                              }
                            />
                          </Button>
                          <Button
                            disabled={
                              this.state.currentPagenumber === this.state.totalPage
                            }
                            data-testid="moveForwardarrow"
                            className={classes.paginationButton}
                            onClick={() =>
                              this.handleChangePageNumber(this.state.currentPagenumber)
                            }
                          >
                            <img
                              src={moveForwardArrow}
                              className={
                                this.state.currentPagenumber === this.state.totalPage
                                  ? classes.disabledPaginationButton
                                  : classes.enabledPaginationButton
                              }
                            />
                          </Button>
                        </Box>
                      </Box>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const webStyle: {} = (theme: { breakpoints: { down: (arg0: string) => string; }; }) => ({
    shippingMainbox: {
        marginTop: "3%"
    },
    mainHeadingcontainer: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        }
    },
    filterBoxCurrentSearch: {
        position: "absolute",
        zIndex: 2,
        background: "#fff",
        left: "260px",
        top: "53px",
        border: "1px solid #e3e3e3",
    },
    shippingPaginationContainer: {
        marginTop: "2%",
        marginBottom: "2%"
    },
    shippingPagination: {
        position: "relative",
        "&.MuiTablePagination-toolbar": {
            "&..MuiTablePagination-spacer": {
                "&.MuiTablePagination-caption": {
                    position: "absolute !important"
                }
            }
        }
    },
    shippingSearchgrid: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
    },
    searchbox: {
        maxWidth: "100%",
        width: "100%",
    },
    shippingUnitname: {
        backgroundColor: "#f3f3f3",
        color: "#3d3d3d",
        fontSize: "16px ",
        height: "100%",
        width: "max-content",
        margin: "1%",
        padding: "1%",
        paddingLeft: "4%",
        paddingRight: "4%",
        borderRadius: "15px"
    },
    shippingUnitnameActive: {
        backgroundColor: "#185d85",
        color: "white",
        fontSize: "16px ",
        height: "100%",
        width: "max-content",
        margin: "1%",
        padding: "1%",
        paddingLeft: "4%",
        paddingRight: "4%",
        borderRadius: "15px"
    },
    shippingUnitnamebox: {
        display: "flex",
        alignItems: "center",
    },
    filterBoxAddToDashboard: {
        position: "absolute",
        zIndex: 2,
        background: "#fff",
        left: "262px",
        top: "159px",
        border: "1px solid #e3e3e3",
    },
    shippingTableNameHeading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStyle: "normal",
        width: "600px",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    shippingTableNameHeadingdelivery: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStyle: "normal",
        width: "850px",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    shippingFilters: {
        backgroundColor: "#f3f3f3",
        color: "#3d3d3d",
        margin: "15px 10px ",
        textTransform: "capitalize",
        fontSize: "16px ",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#f3f3f3",
            color: "#3d3d3d",
            margin: "15px 10px ",
            textTransform: "capitalize",
            fontSize: "16px ",
            fontWeight: 500,
        }
    },
    filterBoxshipping: {
        position: "absolute",
        zIndex: 2,
        background: "#fff",
        left: "10px",
        top: "53px",
        border: "1px solid #e3e3e3",
    },
    filtersLeftshipping: {
        backgroundColor: "#006395",
        color: "#ffffff",
        margin: "15px 10px",
        "&:hover": {
            backgroundColor: "#006395",
            color: "#ffffff",
            margin: "15px 10px",
        }
    },
    filtersrightshipping: {
        backgroundColor: "#006395",
        color: " #ffffff ",
        margin: "15px 10px",
        fontSize: "16px",
        fontWeight: 500,
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#006395",
            color: " #ffffff ",
            margin: "15px 10px",
            fontSize: "16px",
            fontWeight: 500,
            textTransform: "capitalize",
        }
    },
    shippingMainLayout: {
        display: "flex",
    },
    shippingsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    shippingcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },
    shippingBlock: {
        padding: "15px",
        border: "1px solid #e3e3e3",
    },
    shippingFilterblock: {
        position: "relative"
    },
    shippingHeading: {
        color: "#185d85",
        fontSize: "30px",
        fontWeight: "bold",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    shippingImg: {
        position: "absolute",
        left: "15px",
        top: "17px",
        height: "20px"
    },
    shippingFilterbox: {
        border: "1px solid #e3e3e3"
    },
    shippingFilterImg: {
        paddingRight: "8px"
    },
    shippingColumnbox: {
        position: "relative",
    },
    shippingColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    shippingAddcustomerbox: {
        width: "250px"
    },
    shippingButton: {
        justifyContent: "end"
    },
    shippingIcon: {
        minWidth: "35px"
    },
    shippingCheckbox: {
        paddingLeft: 0
    },
    shippingCheck: {
        color: "#185d85 !important"
    },
    shippingTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    shippingTabletext: {
        fontSize: "16px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    shippingTableavatar: {
        borderRadius: "50%",
        paddingRight: "10px"
    },
    shippingItems: {
        padding: "15px"
    },
    shippingCreatebox: {
        display: "flex !important" as "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px"
    },
    shippingSearchbox: {
        display: "flex !important" as "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative " as "relative"
    },
    shippingItemsgrid: {
        display: "flex !important" as "flex",
        justifyContent: "left",
    },
    shippingItemsText: {
        paddingRight: "10px",
        fontSize: "20px",
        marginTop: "11px"
    },
    shippingText: {
        marginRight: "50px"
    },
    salesButton: {
        paddingBottom: 0
    },
    salesIcon: {
        minWidth: "35px"
    },
    salesCheckbox: {
        paddingLeft: 0
    },
    salesButtonlist: {
        justifyContent: "end"
    },
    accordionDetailsbox: {
        display: "flex",
        justifyContent: "space-around",
        textAlign: "center"
    },
    accordion: {
        margin: "3%"
    },
    accordionDetails: {
        display: "block",
    },
    accordiontext: {
        fontSize: "16px",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
    },
    paginationBox: {
        display: "flex",
        alignItems: "center",
        /* width: "100%", */
        padding: "15px 5px",
        justifyContent: "space-between",
        marginRight: "52px",
        height: "50px",
      },
      itemPerPage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      paginationArrows: {
        width: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      paginationNumber: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
})
export const SalesconfigrationShippingmethodStyles = withStyles(webStyle)(SalesconfigrationShippingmethod)
  // Customizable Area End
