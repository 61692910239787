export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const moveBackwardArrowDisable = require("../assets/moveBackwardDisable.svg");
export const moveBackwardArrowEnable = require("../assets/moveBackwardEnable.svg");

export const backArrowDisable = require("../assets/backArrowDisable.svg");
export const backArrowEnable = require("../assets/backArrowEnable.svg");

export const moveForwardArrowDisable = require("../assets/moveForwardDisable.svg");
export const moveForwardArrowEnable = require("../assets/moveForwardEnable.svg");

export const forwardArrowDisable = require("../assets/forwardArrowDisable.svg");
export const forwardArrowEnable = require("../assets/forwardArrowEnable.svg");


export const listView = require("../assets/listview.svg");
export const favourite = require("../assets/favourite.svg");
export const multipleFile = require("../assets/multiple_file.svg");
export const filter = require("../assets/filter.svg");
export const more = require("../assets/more.svg");
export const checkboxUnchecked = require("../assets/checkboxUnchecked.svg");
export const checkboxChecked = require("../assets/checkboxChecked.svg");
export const settingsGrey = require("../assets/settingsGrey.svg");
export const canban = require("../assets/dashboard.svg");
export const activity = require("../assets/activity.svg");
export const downArrow = require("../assets/downArrow.svg");
export const settings = require("../assets/settings.svg");
export const addBtn = require("../assets/add.svg");
export const check = require("../assets/check.svg");
export const deleteBtn = require("../assets/delete.svg");
export const preview = require("../assets/preview.svg");
export const graph = require("../assets/graph.svg");
export const validationerror = require("../assets/validationerror.svg");
export const add_icon = require("../assets/add_icon.svg");
export const externalLink = require("../assets/external_link.svg");
export const rightArrow = require("../assets/right-arrow.svg");

