Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.mobileNumber="Mobile"
exports.alternateNumber="Alternate Number"
exports.labelHeading="Create Your Account"
exports.labelButtonnext="Next"
exports.labelTitle="Jawal-ERAM"
exports.labelHeading="Create Your Account"
exports.star="*"
exports.country="Country"
exports.none="None"
exports.state="State"
exports.city="City"
exports.postal="Postal code"
exports.pervious="Previous"
exports.signinText="Already have an account ?"
exports.signin="Sign In"
exports.city="City"
exports.address="Address"
exports.labelnewUsertext="Enter fields below to proceed"
exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.contentTypeApiAddDetail = "application/json";
exports.accountsAPiEndPoint =
  "account/accounts";
exports.apiMethodTypeAddDetail = "POST";
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.errorEmailNotValid = "Please enter the valid email";
exports.errorPasswordNotValid = "Please enter the valid password";
exports.errorFirstname = "Please enter the first name";
exports.errorLastname = "Please enter the last name";
exports.errorPhonenumber = "Please enter the valid phone number";
exports.erroraddress = "Please enter the valid address";
exports.errorCity = "Please enter the city";
exports.errorCountry = "Please enter the country";
exports.errorState = "Please enter the state";
exports.errorPostalcode = "Please enter the postal code";
exports.registrationAPiEndPoint ="account_block/accounts";
exports.registartionSucessmsg = "Account Registartion Success";
exports.registartionErrormsg="Account Registartion Failed"
// Customizable Area End
