// Customizable Area Start
import React from 'react';
import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import AddPosProductCategoryController, { Props } from './AddPosProductCategoryController.web'
import '../../settings2/assets/style.css'
import Sidebar from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { validationerror } from '../../cfcustomersalesreports3/src/assets';
export const configJSON = require("../../settings2/src/config");

export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});


// Customizable Area End



export class AddPosProductCategory extends AddPosProductCategoryController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <Box className={classes.addpcMainLayout}>
                <Box className={classes.addpcsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.addpccontent}>
                    {" "}
                    <AppHeader pageTitle="Configuration" />
                    <Box className={classes.parentBox} style={{backgroundColor:"#ffffff"}}>
                    <Box className={classes.addpcBlock}>
                        <Grid container spacing={0} >
                        <Grid item sm={12} className='left-center-align'>
                            <Typography className={classes.addpcHeading}>POS Product Categories</Typography>
                        </Grid>
                        </Grid>
                    </Box>
                <Box style={{padding:"15px"}}>
                                <Grid container spacing={0} >
                                
                                    <Grid item sm={8}>
                                    <Grid item sm={10}>
                                <Box>
                                    <Typography className={`${this.handleCategoryError(1)}`}>Category Name<span className='required'>*</span></Typography>
                                    <TextField data-test-id="categoryInput" 
                                    
                                    className={`form-input ${this.handleCategoryError(2)}`} 
                                    value={this.state.category}
                                    onChange={(e:any) => this.setState({...this.state,category:e.target.value})} 
                                    name="category" type="text" />
                                    <Box className={`flex relative ${this.hideCategoryError()} ${classes.pb10}`} >
                                        <img className='error-text-img' src={validationerror} />
                                    <Typography className='error-text'>Please enter valid category name.</Typography>
                                    </Box>
                                </Box>
                                
                                
                                    </Grid>
                                    
                                    <Grid item sm={10} className=''>
                                    <Typography className={`${this.handleParentCategoryError(1)}`}>Parent Category<span className='required'>*</span></Typography>
                                    <FormControl id="select-form-control" className="width-100">
                                    
                                    <Select
                                        data-test-id="parentCategoryInput"
                                        IconComponent={this.expandMore}
                                        inputProps={{
                                            'aria-label': 'custom select',
                                        }}
                                        value={this.state.parent_category}
                                        displayEmpty
                                        onChange={(e:any) => this.setState({...this.state,parent_category:e.target.value})}
                                        label="Select"
                                        disableUnderline
                                        className={`width-100 ${this.handleParentCategoryError(2)}`}
                                        MenuProps={{
                                            PaperProps: {
                                            style: {
                                                marginTop:52,
                                            },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="x">x</MenuItem>
                                        <MenuItem value="y">y</MenuItem>
                                        <MenuItem value="z">z</MenuItem>
                                        
                                    </Select>
                                    
                                    </FormControl>
                                    <Box className={`flex relative ${this.hideParentCategoryError()}`} >
                                        <img className='error-text-img' src={validationerror} />
                                    <Typography className='error-text'>Please enter valid parent category.</Typography>
                                    </Box>
                                    </Grid>
                                    
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Box className='end-center-align'>
                                        {this.imageUI()}
                                        </Box>
                                    
                                    </Grid>
                                
                                </Grid>
                                </Box>
                
                

                </Box>
                <Box className='end-center-align' style={{margin:"20px 0"}}>
                <Button className={classes.discardBtn} data-test-id="discard-product-category" variant="outlined" onClick={this.discardProductCategory}>{configJSON.discard}</Button>
                <Button data-test-id="save-product-category" variant="contained" onClick={this.addPosProductCategory} style={{backgroundColor:"#026494",color:"#ffffff"}}>{configJSON.save}</Button>
                
                </Box>
                </Box>
                
                
                
            </Box>
            
            </>
            // Customizable Area End
        )

    }
}

// Customizable Area Start
export const userlistwebStyle: any = {
    addpcMainLayout: {
        display: "flex",
    },
    addpcsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    addpccontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    addpcBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    parentBox: {
        border: "1px solid #e3e3e3",
        borderRadius:"5px"
    },
    addpcHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    pb10: {
        paddingBottom:"10px !important"
    },
    discardBtn:{
        marginRight: "15px",
        backgroundColor: "white",
        border: "1px solid #e6e6e6"
    }
    
}
export default withStyles(userlistwebStyle)(AddPosProductCategory)
  // Customizable Area End