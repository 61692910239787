import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ExpandMore } from '@material-ui/icons';
import StorageProvider from "../../../framework/src/StorageProvider";
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { defaultImg, validationerror } from '../../cfcustomersalesreports3/src/assets';
import { City } from 'country-state-city';
export const configJSON = require("./config");


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface Props {
    navigation: any;
    id: string;
    
    
    // Customizable Area Start
    
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    company_name:string;
    address:string;
    house_no:string;
    door_no:string;
    city_name:string;
    country_name:string;
    state_name:string;
    zip:string;
    vat:string;
    company_registry:string;
    currency:string;
    phone:string;
    mobile:string;
    email:string;
    website:string;
    parent_company:string;
    token: string | null;
    image:any;
    value:number;
    saveClick:boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export interface Option {
    value: string;
    label: string;
    
}

export default class AddCompanyController extends BlockComponent<
    Props,
    S,
    SS
> {
    addCompanyApiCallId: any = "";
    stateObj = {
                company_name:'',
                address:'',
                house_no:'',
                door_no:'',
                city_name:'',
                country_name:'',
                state_name:'',
                zip:'',
                vat:'',
                company_registry:'',
                currency:'',
                phone:'',
                mobile:'',
                email:'',
                website:'',
                parent_company:'',
                token: '',
                image:null,
                value:0,
                saveClick:false,
              };
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
          ];
        // Customizable Area Start  
        this.state = this.stateObj;
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        
        
        // Customizable Area End
    }

    

    async componentDidMount() {
      
      const getToken =  await StorageProvider.get("TOKEN")
       this.setState({
         token: getToken
       })
       
     }
    
    
    async receive(from: string, msg1: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === msg1.id) {
          const apiRequestCallId = msg1.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          let responseJson = msg1.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          let errorReponse = msg1.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          console.log({errorReponse});
          runEngine.debugLog("API Message Recived", msg1);
          console.log('before',responseJson);
          
          if (responseJson) {
            if (responseJson.errors) {
              this.showAlert(configJSON.errorTitle, configJSON.something_went_wrong);
            }
            
            if (apiRequestCallId === this.addCompanyApiCallId) {
                
              if(responseJson.data?.id){
                this.showAlert(
                  configJSON.success,
                  configJSON.company_created_msg
                );
                this.setState(this.stateObj);
              }
              
              
            }
          } 
          
        }
        // Customizable Area End
      }

    imageRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    imageUI = () => {
      return <>
      <Box className={` ${(!this.state.image) && 'imageBox'}`}>
      <img src={this.state.image ? URL.createObjectURL(this.state.image) : defaultImg} data-test-id="imageRef" onClick={()=>{this.imageRef.current?.click();}} height={this.state.image?100:50} width={this.state.image?100:50} />
      <input data-test-id="imageFile" accept=".jpg, .jpeg, .png" type='file' className="display-none" ref={this.imageRef} name="image" onChange={(e:any)=>this.setState({...this.state,image: e?.target?.files[0]})} />
      </Box>
      
      </>
    }

    isStringNullOrBlank(str: string) {
    return str === null || str?.length === 0;
    }

    discardCompanyData = () => {
      this.setState(this.stateObj);
    }
    
    expandMore = () => {
      return <ExpandMore/>;
    }

    TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
        return <div hidden={value !== index}>{children}</div>;
    };
    
    
    handleTabChange = (event: any, newValue: number) => {
        this.setState({ ...this.state,value: newValue });
    };

    errorTextClass = (field:any,value:any) => {
    if((this.handleErrorMessage1(field) || this.handleErrorMessage2(field)) && !value){
        return 'error-text1';
    }else{
        return 'main-color';
    }
    }

    errorFocusClass = (field:any,value:any) => {
    if((this.handleErrorMessage1(field) || this.handleErrorMessage2(field)) && !value){
        return 'error-focus';
    }else{
        return '';
    }
    }

    mainCompanyErrorBox = () => {
      return <Box className={`flex error-main-box2 ${!(this.state.saveClick && (!this.checkCompanyValidation())) && 'display-none'}`}>
            <img src={validationerror}/>
                <Typography className='error-main-text'>Some of the fields are incorrect or invalid. Please enter the valid details to continue.</Typography>
            </Box>
    }
    

    addCompanyData = () => {
        this.setState({...this.state,saveClick:false})
      let formdata:any = new FormData();
      this.state.image && formdata.append("data[company_image]", this.state.image);
      formdata.append("data[comapny_name]",this.state.company_name);
      formdata.append("data[company_general_infos_attributes][address]", this.state.address);
      formdata.append("data[company_general_infos_attributes][house_number]", this.state.house_no);
      formdata.append("data[company_general_infos_attributes][door_number]", this.state.door_no);
      formdata.append("data[company_general_infos_attributes][country]", this.state.country_name);
      formdata.append("data[company_general_infos_attributes][state]", this.state.state_name);
      formdata.append("data[company_general_infos_attributes][city]", this.state.city_name);
      formdata.append("data[company_general_infos_attributes][zip]", this.state.zip);
      formdata.append("data[company_general_infos_attributes][vat]", this.state.vat);
      formdata.append("data[company_general_infos_attributes][phone]", this.state.phone);
      formdata.append("data[company_general_infos_attributes][mobile]", this.state.mobile);
      formdata.append("data[company_general_infos_attributes][email]", this.state.email);
      formdata.append("data[company_general_infos_attributes][website]", this.state.website);
      formdata.append("data[company_general_infos_attributes][company_registry]", this.state.company_registry);
      formdata.append("data[company_general_infos_attributes][currency]", this.state.currency);
      formdata.append("data[company_general_infos_attributes][parent_company]", this.state.parent_company);
      
      
      
        const header = {
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.addCompanyApiCallId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'bx_block_admin/companies'
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'POST'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleLabel = (flabel:any) => {
        return <>
        {
          flabel ? this.handleErrorLabel(flabel) : flabel
        }
        </>
      }

    handleErrorLabel = (flabel:any) => {
    return <Box className='flex relative' >
                <img className='error-text-img' src={validationerror} />
            <Typography className='error-text'>{flabel}</Typography>
            </Box>
    }

    handleErrorMessage1 = (type:string,show:boolean=false) => {
        if(this.state.saveClick || show){
          let flabel:any = '';
          switch(type){
            
            case 'address' :  flabel = !this.state.address && 'Please select valid address.'; break;
            case 'country_name' :  flabel = !this.state.country_name && 'Please select valid Country.'; break;
            case 'state_name' :  flabel = !this.state.state_name.trim() && 'Please select valid State.'; break;
            case 'city_name' :  flabel = !this.state.city_name.trim() && 'Please select valid City.'; break;
            case 'zip' :  flabel = !this.state.zip.trim() && 'Please enter valid zip.'; break;
            case 'house_no' :  flabel = !this.state.house_no.trim() && 'Please enter valid house no'; break;
            case 'door_no' :  flabel = !this.state.door_no.trim() && 'Please enter valid door no.'; break;
            case 'vat' :  flabel = !this.state.vat && 'Please enter valid VAT.'; break;
            case 'company_registry' :  flabel = !this.state.company_registry && 'Please enter valid company registry.'; break;
            case 'currency' :  flabel = !this.state.currency && 'Please select valid Currency.'; break;
            
            default :  
            break;
            
          }
          return this.handleLabel(flabel)
        }else{
          return ''
        }
        
      }

      handleErrorMessage2 = (type:string,show:boolean=false) => {
        if(this.state.saveClick || show){
          let flabel:any = '';
          switch(type){
            case 'company_name' :  flabel = !this.state.company_name.trim() && 'Please enter valid company name.'; break;
            case 'email' :  flabel = !this.state.email.trim() && 'Please enter valid email.'; break;
            case 'mobile' :  flabel = !this.state.mobile && 'Please enter valid mobile.'; break;
            case 'phone' :  flabel = !this.state.phone && 'Please enter valid phone.'; break;
            case 'website' :  flabel = !this.state.website.trim() && 'Please enter valid website.'; break;
            case 'parent_company' :  flabel = !this.state.parent_company.trim() && 'Please select valid parent company.'; break;
            case 'email1' :
            flabel = configJSON.errorEmailNotValid; 
            break;
            case '1' :  
            case '2' : 
            flabel = configJSON.errorMobileLengthNotValid; 
            break;
            case '3' : 
            flabel = 'Website not valid'; 
            break;
            
            default :  
            break;
            
          }
          return this.handleLabel(flabel)
        }else{
          return ''
        }
        
      }

    isValidEmail = (Email: string) => {
        let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (Email && reg.test(Email) === false && Email.trim()) {
          
          return false;
        } else {
          
          return true;
        }
    };

    checkMobileLength = (mob: any) => {
        if (mob && mob.length<10) {
          
          return false;
        }
        
        return true;
      }

    websiteValidation = (website:any) => {
    const urlPattern = configJSON.websiteValidation;
    if(urlPattern.test(website) || website == ""){
        return true;
        
    }else{
        return false;
    }
    
    }


    checkOtherFields = () => {
        if((!this.isValidEmail(this.state.email)) || 
        (!this.checkMobileLength(this.state.phone)) || 
        (!this.checkMobileLength(this.state.mobile))){
          return false;
        }
        if(!(this.websiteValidation(this.state.website))){
          return false;
        }
        return true;
      
    }

    handleCountryChange = (event:any) => {
        const country_name = event.target?.value as string;
        this.setState({
          ...this.state,
          country_name,
          state_name: '',
          city_name: '',
        });
      };

      handleRegionChange = (event:any) => {
        const state_name = event.target?.value as string;
        this.setState({
          ...this.state,
          state_name,
          city_name: '',
        });
      };

      handleCityChange = (event:any) => {
        const city_name = event.target?.value as string;
        this.setState({
          ...this.state,
          city_name,
        });
        
      };

      SelectCity = (state_name:any,city_name:any,country_name:any) => {
        return <>
              <Select
                            IconComponent={this.expandMore}
                            data-test-id="cityNameInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            
                            value={city_name}
                            displayEmpty
                            onChange={this.handleCityChange}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorFocusClass('city_name',this.state.city_name)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem value="">Select City</MenuItem>
                            {(state_name ? City.getCitiesOfState(country_name,state_name) : [])?.length>0 && (state_name ? City.getCitiesOfState(country_name,state_name) : []).map((city:any,index:number) => (
                                <MenuItem className='select-menu-item' key={index} value={city.name+'@'+city.latitude+'@'+city.longitude}>
                                {city.name}
                                </MenuItem>
                            ))}
                            
                            
                        </Select>
              </>
      }

      SelectState = (state_name:any,regions:any) => {
        return <>
                <Select
                            IconComponent={this.expandMore}
                            data-test-id="stateNameInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            
                            value={state_name}
                            displayEmpty
                            onChange={this.handleRegionChange}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorFocusClass('state_name',this.state.state_name)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem value="">Select State</MenuItem>
                            {regions?.length>0 && regions.map((region:any) => (
                            <MenuItem  key={region.isoCode} value={region.isoCode}>
                            {region.name}
                            </MenuItem>
                            ))}
                            
                            
                        </Select>
            
              </>
    }

    checkCompanyValidation = () => {
        if (
            this.isStringNullOrBlank(this.state.company_name) ||
            this.isStringNullOrBlank(this.state.email) ||
            this.isStringNullOrBlank(this.state.phone) ||
            this.isStringNullOrBlank(this.state.mobile) ||
            this.isStringNullOrBlank(this.state.website) ||
            this.isStringNullOrBlank(this.state.parent_company) ||
            this.isStringNullOrBlank(this.state.address) ||
            this.isStringNullOrBlank(this.state.house_no) ||
            this.isStringNullOrBlank(this.state.door_no) ||
            this.isStringNullOrBlank(this.state.country_name) ||
            this.isStringNullOrBlank(this.state.state_name) ||
            this.isStringNullOrBlank(this.state.city_name) ||
            this.isStringNullOrBlank(this.state.zip) ||
            this.isStringNullOrBlank(this.state.vat) ||
            this.isStringNullOrBlank(this.state.company_registry) ||
            this.isStringNullOrBlank(this.state.currency)
            ) {
              
              return false;
            }
            else if(!this.checkOtherFields()){
                console.log('2');
                return false;
            }
            return true;
    }

    addCompany = () => {
        this.setState({...this.state,saveClick:true})
    if (!this.checkCompanyValidation()) {
        console.log('1');
          return false;
        }else {
        this.addCompanyData();
        }
    }

    

    

}