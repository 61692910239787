Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ordercreation2";
exports.labelBodyText = "ordercreation2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.btnExampleTitle = "CLICK ME";
exports.ordermanagement ="Order Management"
exports.quotation = "Quotations"
// Customizable Area End