import React from "react";

import {
  Box,
  Button,
  Typography,
  IconButton,
  // Customizable Area Start
  ClickAwayListener,
  Popper,
  MenuList,
  Card,
  MenuItem,
  CardContent,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Select,
  Menu,
  MenuProps,
  TableBody,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { AppHeader } from "../../../components/src/AppHeader.web";
import { createTheme, ThemeProvider, styled ,withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import Sidebar from "../../../components/src/Sidebar.web";
import { FILTER_FIELDS } from "../../../components/src/Utils/constants";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
const Images = require("./assets");

const ButtonComponent = styled("button")({
  cursor: "pointer",
  color: "black",
  backgroundColor: "#F3F3F3",
  fontSize: "12px",
  alignItems: "center",
  border: "none",
  display: "flex",
  borderRadius: "4px",
  padding: "8px 15px",
  marginX: "10px",
  height: "100%",
});

const theme = createTheme({
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
  palette: {primary: {
      contrastText: "#fff",
      main: "#fff",
    },
  },
});




const StyledMenuItem = withStyles(() => ({
  root: {
    paddingBotton: 0,
  },
}))(MenuItem);

const StyledMenu = ((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      horizontal: "right",
      vertical: "bottom",
    }}
    transformOrigin={{
      horizontal: "right",
      vertical: "top",
    }}
    PaperProps={{
      style: {width: 350,},
    }}
    {...props}
  />
));

const tableHeading = [
  "Order ID",
  "Order Date",
  "Customer Name",
  "Sales Person",
  "Company",
  "Order Amount",
  "Invoice Status",
];

// Customizable Area End

import OrderlistingController, {
  Props,
  configJSON,
} from "./OrderlistingController";


export default class Orderlisting extends OrderlistingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleColor=(status:string)=>{
    if(status === 'To Invoice'){
      return "#2C6897"
    }
    else if(status === 'Nothing to invoice'){
      return "#E1AB5F"
    }
    else if (status === 'Fully Invoiced'){
      return "#4CAB8F"
    } 
  }

  expandableTable = (data: any) => {
    return (
      <TableBody>
        {data?.map((row: any, indice: number) => (
          <React.Fragment 
          key={row.key}
          >
            <TableRow 
              style={{ backgroundColor: '#E5E5E5' }}
              >
              <TableCell 
              padding="checkbox">
                <IconButton 
                data-test-id="iconButton" 
                onClick={() => this.toggleIsExpanded(indice)}>
                  {this.state.isExpanded[indice] ? 
                  <KeyboardArrowUpIcon /> : 
                  <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row" style={{fontWeight: 700}}>{row.key} ({row.data?.length})</TableCell>
              <TableCell>
              </TableCell>
              <TableCell>
              </TableCell>
              <TableCell>
              </TableCell>
              <TableCell>
              </TableCell>
              <TableCell>{
                row.data?.map((item : any) => item.total).reduce((prev: number, next:number) => prev + next,0)
              } OMR
              </TableCell>
              <TableCell></TableCell><TableCell></TableCell>
            </TableRow>
            {this.state.isExpanded[indice] &&
              row.data?.map((tablerow: any, index: number) => (
                <TableRow
                  key={index}
                  style={webStyle.tableRow}
                >
                  <TableCell>
                    <Box sx={webStyle.tableBox}>
                      <Checkbox
                        tabIndex={-1}
                        onChange={() => { this.handleCheckBoxSelect(tablerow, index); }}
                        data-test-id="rowCheckbox"
                        checked={tablerow.checked}
                        size="medium"
                        edge="start"
                        className="individual"
                      />
                    </Box>
                  </TableCell>
                  <TableCell>{tablerow.id}
                  </TableCell>
                  <TableCell>{this.handleDate(tablerow.order_date)}
                  </TableCell>
                  <TableCell>{tablerow.customer_name}
                  </TableCell>
                  <TableCell>{tablerow.sales_name}
                  </TableCell>
                  <TableCell>{tablerow.invoice_address}
                  </TableCell>
                  <TableCell>{tablerow.total}
                  </TableCell>
                  <TableCell style={{ width: "fit-content" }}>
                    <Typography style={{ color: this.handleColor(tablerow.invoice_status), fontWeight: 600, fontSize: "16px", whiteSpace: "nowrap" }}>
                      {tablerow.invoice_status}
                    </Typography></TableCell>
                </TableRow> )
              )}
          </React.Fragment>
        ))}
      </TableBody>
    );
  };


  isCurrentPageOne(): boolean {
    if (!this.state.isGroupby) {
      return this.state.currentPage === 1;
    }
    return true;
  }

  isCurrentPageLast(): boolean {
    if (!this.state.responseData.length) {
      return true;
    }
    if (!this.state.isGroupby) {
      return this.state.currentPage === this.state.totalPage;
    }
    return true;
  }

  renderKanbanView = (responseData: any) => {
    return (
      responseData.length ?
        (<Grid container 
          spacing={2} 
          style={{padding:"0 15px 10px 15px"}}
          >
          {responseData.map((tablerow: any) => (
            <Grid item 
              xs={4} 
              key={tablerow.id}
              >
              <Card style={webStyle.productCard}>
                <CardContent>
                  <Grid container lg={12} md={9} sm={6}>
                    <Grid item xl={10} lg={8} md={9} sm={8}><Box>
                        <Typography component="h6" variant="body1" style={webStyle.productDescriptionHeading}>
                          {tablerow.customer_name || ""}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" style={webStyle.productDescription}>
                        {tablerow.quotation_no || ""}
                        </Typography>
                        <Typography variant="body2" style={webStyle.productDescription} color="textSecondary">
                        {this.handleDateAndTime(tablerow.order_date)}
                        </Typography></Box>
                    </Grid>
                    <Grid item xl={2} lg={4} md={3} sm={4}>
                      <Box px={2} 
                        style={webStyle.canbanRightBox as React.CSSProperties}
                      > 
                        <Typography component="h6" variant="body1" style={webStyle.productDescriptionHeading}>
                          {tablerow.total} OMR </Typography>
                        <Typography style={webStyle.greenText as React.CSSProperties} variant="body2">
                          Sales Order
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
          )}</Grid>
          )
        : (
        <Box style={webStyle.emptyData}>
          <Typography>No Data Found</Typography>
        </Box>)
    )
  }

  renderListView = (responseData: any) => {
    return (
      <TableBody>
        {this.state.isLoading
          ?
          <TableRow><TableCell colSpan={10}><Box style={webStyle.loader}><CircularProgress /></Box></TableCell></TableRow>:
          responseData.map((tablerow: any, index: number) => (
            <TableRow key={index} style={webStyle.tableRow} data-test-id="data-row" onClick={() => this.handleRowClick(tablerow.id)}><TableCell>
                <Box sx={webStyle.tableBox}>
                  <Checkbox
                    tabIndex={-1}
                    checked={tablerow.checked}
                    className="individual"
                    size="medium"
                    data-test-id="rowCheckbox"
                    edge="start"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleCheckBoxSelect(tablerow, index);
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell>{tablerow.id}
              </TableCell>
              <TableCell>
                {this.handleDate(tablerow.order_date)}</TableCell>
              <TableCell>{tablerow.customer_name}
              </TableCell>
              <TableCell>{tablerow.sales_name}
              </TableCell>
              <TableCell>{tablerow.invoice_address}
              </TableCell>
              <TableCell>{tablerow.total} OMR
              </TableCell>
              <TableCell 
                style={{width:"fit-content"}}
                >
                <Typography style={{ color: this.handleColor(tablerow.invoice_status) , fontWeight:600 , fontSize:"16px" ,whiteSpace:"nowrap"}}>{tablerow.invoice_status}</Typography>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    )
  }

  renderListAndGroupbyTable = (responseData: any) => {
    return (
      this.state.isLoading ? (
        <TableRow><TableCell colSpan={10}>
            <Box style={webStyle.loader}><CircularProgress /></Box>
          </TableCell></TableRow>
      ) : 
      (this.renderGroupedTable(responseData))
    );
  };

  renderGroupedTable = (responseData: any) => {
    if (this.state.isGroupby) {return this.expandableTable(responseData);} 
    else {return this.renderListView(responseData);}
  };


  renderPagination = () => {
    return (
      <Box sx={webStyle.paginationContainer}>
        <Box sx={webStyle.perPageItem}>
          <Typography>Items per page : &nbsp;</Typography>
          <Select
            value={this.state.rowsPerPage}
            variant="outlined"
            data-test-id="dropdown"
            style={webStyle.navinationDropdown as React.CSSProperties}
            onChange={this.handleChangeRowsPerPage}
            IconComponent={KeyboardArrowDownSharpIcon}
          >
            <MenuItem value={9}>
              09
            </MenuItem>
            <MenuItem
              value={27}
            >
              27
            </MenuItem>
            <MenuItem
              value={54}
            >
              54
            </MenuItem>
            <MenuItem
              value={99}
            >
              99
            </MenuItem>
          </Select>
        </Box>
        <Box style={webStyle.paginationText}>
          <Box style={this.state.isLoading ? webStyle.paginationCountBoxHidden as React.CSSProperties : webStyle.paginationCountBoxShow as React.CSSProperties}>
            <Typography style={webStyle.rowText as React.CSSProperties}>
              {(this.state.currentPage - 1) * this.state.rowsPerPage + 1}
              &nbsp;-&nbsp;
              {(this.state.currentPage - 1) * this.state.rowsPerPage +
                this.state.responseData.length}
            </Typography>
            <Typography>
              &nbsp;of &nbsp;
              {this.state.totalCounts}
            </Typography>
          </Box>
          <Button
            disabled={this.isCurrentPageOne()}
            data-test-id="previous-1"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(0)}
          >
            <img
              src={
                this.isCurrentPageOne()
                  ? Images.moveBackwardArrowDisable
                  : Images.moveBackwardArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageOne()}
            data-test-id="previous-2"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(this.state.currentPage - 2)}
          >
            <img
              src={
                this.isCurrentPageOne()
                  ? Images.backArrowDisable
                  : Images.backArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageLast()}
            data-test-id="next-1"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(this.state.currentPage)}
          >
            <img
              src={
                this.isCurrentPageLast()
                  ? Images.forwardArrowDisable
                  : Images.forwardArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageLast()}
            data-test-id="next-2"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(this.state.totalPage - 1)}
          >
            <img
              src={
                this.isCurrentPageLast()
                  ? Images.moveForwardArrowDisable
                  : Images.moveForwardArrowEnable
              }
            />
          </Button>
        </Box>
      </Box>
    );
  };

  renderLoadMore = () => {
    return (<>
      {this.state.isLoadMore && <Box style={webStyle.loaderContainer} data-test-id="loadmore">
        <TableRow>
          <TableCell colSpan={10}>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "20vh", width: "100%" }}>
              <CircularProgress style={{ color:"#2A6395"}}/>
            </Box>
          </TableCell>
        </TableRow>
      </Box>}
      <Box style={webStyle.loadMoreContainer} data-test-id="loadmore">
        <Button variant="contained" data-test-id="loadMore-btn" style={webStyle.gridviewLoadmoreButton as React.CSSProperties} onClick={this.handleLoadMore}>
          Load More Records
        </Button>
      </Box>
    </>
    )
  }


    isViewActive = (
    isViewTypeChanged: any,anchorElement: any, responseData: any
  ) =>
    isViewTypeChanged ? (
      <Box sx={{ overflow: "auto" }}>
        <TableContainer >
          {responseData.length ? 
          <Table><TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    edge="start"
                    checked={this.state.isAllChecked}
                    data-test-id="allCheckBox"
                    tabIndex={-1}
                    onChange={this.handleAllChecked}
                    disabled={this.state.responseData.length <= 0}
                  />
                </TableCell>
                {tableHeading.map((item, index) => (<TableCell key={index} style={{ textTransform: "uppercase" }}><b style={{ whiteSpace: "nowrap" }}>{item}</b></TableCell>
                ))}
                <TableCell
                  onClick={(event) => 
                    this.toggleColumn(event.currentTarget)}
                  data-test-id="toggleColumn"
                >
                  <img 
                    src={Images.more} 
                    />
                </TableCell>

                <StyledMenu
                  open={Boolean(anchorElement)}
                  anchorEl={anchorElement}
                  onClose={() => this.toggleColumn(null)}
                  data-test-id="closedMenu-2"
                >
                  {FILTER_FIELDS.map((field) => (
                    <React.Fragment key={field.name}>
                      <StyledMenuItem
                        data-test-id="filterMenu"
                        onClick={() => this.handleFilterChange([field.name])}
                      ><ListItemIcon><Checkbox
                            disableTouchRipple
                            edge="start"
                            disableRipple
                            checkedIcon={<img src={Images.checkboxChecked} />}
                            disableFocusRipple
                            icon={<img src={Images.checkboxUnchecked} />}
                          /></ListItemIcon>
                        <ListItemText primary={field.label} />
                      </StyledMenuItem><Divider />
                    </React.Fragment>
                  ))}
                </StyledMenu>
              </TableRow>
            </TableHead>
            {
            this.renderListAndGroupbyTable(responseData)
            }
          </Table>
            : <Box 
            style={webStyle.emptyData}>
              <Typography>No Data Found</Typography>
              </Box>
          }

        </TableContainer>
       {
       !this.state.isGroupby && this.renderPagination()
       }
      </Box>
    ) : 
        (
          <Box>
            {this.renderKanbanView(responseData)}
            {(((this.state.totalCounts - this.state.rowsPerPage) > 0) || !this.state.initialLoadComplete) && this.renderLoadMore()}
          </Box>
        );


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box 
          sx={webStyle.OrderLayout}>
          <Box 
            sx={webStyle.sideBar}>
            <Sidebar />
            <Box sx={webStyle.bodyContent}>
              <AppHeader pageTitle="Order Management" />
              <Card style={webStyle.mainContainer}>
                <CardContent 
                  style={webStyle.listCard}>
                <style>
                  {`
                    .MuiContainer-root-50 {
                      padding-right: 0 !important;
                    }
                  `}
                </style>
                  <Box 
                    style={webStyle.listHeading}>
                    <Typography variant="h6"
                      style={webStyle.topbarTitle as React.CSSProperties}>
                      List of Order</Typography>
                    <Box>
                      <AdvancedSearch
                        classes={{}}
                        navigation={undefined}
                        id={""}
                        querySearch={this.state.query}
                        onChangeSearchField={this.onChangeSearchField}
                        queryItems={this.state.queryItems}
                        handleRemoveTag={this.handleRemoveTag}
                        data-test-id="advanceSearch"
                      />
                    </Box>
                  </Box>
                  <Divider 
                    style={webStyle.divider} />
                  <Box 
                    sx={webStyle.listMainContainer}>
                    <Box 
                      sx={webStyle.tableOption}>
                      <ClickAwayListener
                        onClickAway={this.handleFilterClickAway}
                        data-test-id="clickAway"
                      >
                        <div>
                          <Button
                            onClick={this.handleClickFilter}
                            style={webStyle.buttonComponent}
                            data-test-id="popperClick"
                          >
                            <img 
                              src={Images.filter} /> &nbsp; 
                              Filters
                          </Button>
                          <Popper
                            transition
                            open={Boolean(this.state.anchorElFilter)}
                            anchorEl={this.state.anchorElFilter}
                            style={webStyle.popper}
                            placement="bottom-start"
                          >
                            <MenuList style={{ padding: 0 }}>
                              {this.filterOptions.map((item) => (
                                <div style={webStyle.popperBlock} >
                                  <MenuItem
                                    style={webStyle.popperItem}
                                    key={item.name}
                                    data-value={item.name}
                                    data-test-id="MenuPopper"
                                    onClick={() => { this.handleFilterSelect(item); }}
                                    >{item.name}</MenuItem>
                                </div>
                              ))}</MenuList>
                          </Popper>
                        </div>
                      </ClickAwayListener>
                      <ClickAwayListener
                        onClickAway={this.handleGroupClickAway} data-test-id="clickAway">
                        <div>
                          <Button
                            onClick={this.handleClickGroupBy}
                            data-test-id="popperClick"
                            style={webStyle.buttonComponent}
                          >
                            <img src={Images.multipleFile} /> &nbsp; Group by
                          </Button>
                          <Popper
                            anchorEl={this.state.anchorElGroupBy}
                            transition
                            open={Boolean(this.state.anchorElGroupBy)}
                            style={webStyle.popper}
                            placement="bottom-start"
                          >

                            <MenuList style={{ padding: 0 }}>
                              {this.groupByOptions.map((item) => (
                                <div style={webStyle.popperBlock}>
                                  <MenuItem
                                    onClick={() => { this.handleGroupBySelect(item); }}
                                    style={webStyle.popperItem}
                                    key={item.name}
                                    data-value={item.name}
                                    data-test-id="MenuPopper-group"
                                  >
                                    {item.name}
                                    </MenuItem></div>
                              ))}
                            </MenuList>
                          </Popper>
                        </div>
                      </ClickAwayListener>

                      <div>
                        <Button style={webStyle.buttonComponent}>
                          <img src={Images.favourite} /> &nbsp; Favorites</Button>
                      </div>
                    </Box>

                    <Box 
                      sx={webStyle.tableOption}>
                      {this.state.isViewTypeChanged &&
                        <><ClickAwayListener 
                          data-test-id="clickAway"
                          onClickAway={this.handlePrintClickAway} 
                          >
                          <div>
                            <Button
                              onClick={this.handleClickPrint}
                              style={webStyle.buttonComponentSettings}
                              data-test-id="popperClick"
                            >
                              <img src={Images.print} />
                            </Button>
                            <Popper
                              anchorEl={this.state.anchorElPrint}
                              transition
                              open={Boolean(this.state.anchorElPrint)}
                              placement="bottom-end"
                              style={webStyle.printPopper}
                            >

                              <MenuList style={{ padding: 0 }}>
                                {this.PrintOptions.map((item) => (
                                  <div style={{ borderBottom: "1px solid #F3F3F3" }}>
                                    <MenuItem
                                      key={item.name}
                                      data-test-id="closedMenu-1"
                                      data-value={item.name}
                                      style={webStyle.popperItem}
                                    >{item.name}
                                    </MenuItem></div>
                                ))}
                              </MenuList>
                            </Popper>
                          </div>
                        </ClickAwayListener>

                        <ClickAwayListener 
                          data-test-id="clickAway"
                          onClickAway={this.handleSettingsClickAway} 
                          >
                          <div>
                            <Button
                              onClick={this.handleClickSetting}
                              data-test-id="popperClick"
                              style={
                                (!this.state.isViewTypeChanged && this.state.responseData.length || this.state.isAllChecked || this.state.isGroupby || Object.keys(this.state.selectedItems).length) ?
                                  webStyle.buttonComponentSettings
                                  : { visibility: "hidden" }
                              }
                            ><img src={Images.settings} /></Button>
                            <Popper
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              transition
                              placement="bottom-end"
                              style={webStyle.settingsPopper}
                            >

                              <MenuList style={{ padding: 0 }}>
                                {this.SettingOptions.map((item) => (
                                  <div style={{ borderBottom: "1px solid #F3F3F3" }}>
                                    <MenuItem
                                      style={webStyle.popperItem}
                                      key={item.name}
                                      data-value={item.name}
                                      data-test-id="closedMenu-1"
                                    >{item.name}</MenuItem>
                                  </div>
                                ))}
                              </MenuList>
                            </Popper>
                          </div>
                        </ClickAwayListener></>
                      }

                      <ButtonComponent
                        data-test-id="listView"
                        color="primary"
                        onClick={() => {
                          this.setState({ isViewTypeChanged: true, rowsPerPage: 9 });
                        }}
                        style={this.state.isViewTypeChanged ? webStyle.activeField : {}}
                      >
                        <img
                          alt="listview"
                          style={this.state.isViewTypeChanged
                              ? {}: { filter: "invert(1)" }
                          }
                          src={Images.listView}
                        />
                      </ButtonComponent>
                      <ButtonComponent
                        onClick={() => { 
                          this.setState({ 
                            isViewTypeChanged: false,
                            rowsPerPage: 18, 
                            isLoading: true 
                          }); }}
                        data-test-id="canbanView"
                        style={
                          this.state.isViewTypeChanged 
                          ? {} : webStyle.activeField}
                      >
                        <img
                          style={this.state.isViewTypeChanged ? {} : { filter: "invert(1)" }}
                          src={Images.canban}
                          alt="canbanview"
                        />
                      </ButtonComponent>
                      <ButtonComponent color="primary" style={webStyle.activeField} data-test-id="ordercreation"
                        onClick={
                          () => this.props.navigation.navigate("Ordercreation2")
                        }
                      >
                        <Typography style={{ marginRight: "5px" }}>
                          Create Quotation</Typography>
                        <img src={Images.add_icon} />
                      </ButtonComponent>
                    </Box>
                  </Box>
                  {this.isViewActive(
                    this.state.isViewTypeChanged,this.state.anchorElement,this.state.responseData
                  )}
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  OrderLayout: {
    display: "flex",
    width: "100%",
  },
  bodyContent: {
    flexGrow: 1,
    width: "calc( 100% - 300px)",
    overflow: "auto",
    backgroundColor: "#f3f3f3",
    padding: "0 20px",
  },
  sideBar: {
    minHeight: "100%",
    width: "100%",
    display: "flex",
    transition: "all 0.3s",
  },
  mainWrapper: {
    margin: "5px 0",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    gap: "10px",
  },
  tableBox: {
    alignItems: "center",
    display: "flex",
  },
  paginationContainer: {
    height: "50px",
    alignItems: "center",
    padding: "15px",
    justifyContent: "space-between",
    display: "flex",
  },
  perPageItem: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  navigationArrows: {
    width: "200px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  cursorPointor: {
    cursor: "pointer" as string,
  },
  paginationNumber: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  activeField: {
    backgroundColor: "#006395",
    color: "#fff",
  },
  tableOption: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "space-between",
    gap: "10px",
    margin: "5px 0",
  },
  listMainContainer: {
    height: "50px",
    alignItems: "center",
    display: "flex",
    padding: "15px",
    justifyContent: "space-between",
    gap: "10px",
    margin: "5px 0",
  },
  paginationButton: {
    height: "100%",
  },
  canbanMainContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
    display: "flex",
    height: "50px",
    margin: "5px 0",
    gap: "10px",
  },
  productDescription: {
    textWrap: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "12px",
  },
  mainContainer: {
    boxShadow: "none",
    padding: 0,
    marginBottom: "20px",
    minHeight: "750px",
    border: "1px solid #d9d9d9",
  },
  enabledNaviginationButton: {
    fill: "grey",
    color: "grey",
  },
  listHeading: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    alignItems: "flex-start",
  },
  disabledNaviginationButton: {
    color: "lightgrey",
    fill: "lightgrey",
  },
  popperBlock: {
    borderBottom: "2px solid #F3F3F3",
  },
  searchBar: {
    "& .MuiOutlinedInput-root": {
      "& input": {
        border: "none !important",
      },
      height: "100%",
      "& .MuiOutlinedInput-input": {
        border: "none !important",
      },
      width: "500px",
    },
    minWidth: "250px",
    border: "none !important",
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
    backgroundColor: "#ffffff",
    width: "max-content",
    border: "1px solid #ccc",
    padding: 0,
    borderRadius: 4,
    marginTop: "4px",
  },
  topbarTitle: {
    color: "#006395",
    fontWeight: "bold",
  },
  buttonComponentSettings: {
    height: "100%",
    padding: "8px 8px",
    fontSize: "12px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#e1e1e1",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "black",
  },
  settingsPopper: {
    backgroundColor: "#ffffff",
    marginTop: "4px",
    padding: 0,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    border: "1px solid #ccc",
    borderRadius: 4,
  },
  printPopper: {
    border: "1px solid #ccc",
    borderRadius: 4,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    padding: 0,
    marginTop: "4px",
    backgroundColor: "#ffffff",
  },
  popperItem: {
    borderBottom: "1px solid #F3F3F3 !important",
    fontSize: "small",
  },
  loader: {
    display: 'flex',
    height: "50vh",
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
  },
  buttonComponent: {
    borderRadius: "4px",
    backgroundColor: "#e1e1e1",
    color: "black",
    display: "flex",
    fontSize: "12px",
    alignItems: "center",
    cursor: "pointer",
    height: "100%",
    border: "none",
    padding: "8px 15px",
  },
  navinationDropdown: {
    height: "40px",
    padding: "0px 5px",
    outline: "1px solid #f3f3f3",
    borderRadius: "4px",
    fontWeight: "bold",
  },
  rowText: {
    fontWeight: "bold",
  },
  listCard: {
    padding: 0
  },
  paginationText: {
    height: "40px",
    alignItems: "center",
    display: "flex",
  },
  tableRow: {
    borderBottom: "1px solid #E5E5E5",
    cursor: "pointer"
  },
  paginationCountBoxHidden: {
    flexDirection: "row",
    visibility: "hidden",
    display: "flex",
  },
  paginationCountBoxShow: {
    display: "flex",
    flexDirection: "row",
  },
  emptyData: {
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHeading: { 
    textTransform: "uppercase" 
  },
  divider: {
    backgroundColor: "#E3E3E3",
    marginTop: "20px",
    marginBottom: "20px",
  },
  productCard: {
    border: "1px solid #d9d9d9",
  },
  productDescriptionHeading: {
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    overflow: "hidden",
    width: "150px",
  },
  favoriteBtn: {
    width: "10%",
    position: "relative",
    right: "-50px",
  },
  canbanRightBox:{
    display: "flex",
    gap: "15px",
    flexDirection: "column",
  },
  greenText:{
    fontWeight:"bold",
    color:"#6BD8B5",
    whiteSpace: "nowrap",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
  },
  loadMoreContainer: {
    marginBlock: "3%",
    display: "flex",
    justifyContent: "center",
  },
  gridviewLoadmoreButton: {
    backgroundColor: "#f3f3f3",
    color: "#3d3d3d",
    margin: "15px 10px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "500"
  }
};
// Customizable Area End
