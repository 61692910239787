import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper,Box, Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  favorite,
} from "../assets"; 

const useStyles = makeStyles({

});



const ExpandableTableRow = ({ children, expandData,checkboxSelect,...otherProps }:any) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps} style={{backgroundColor:'#E5E5E5'}}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded &&
        expandData.map((tablerow: any, index: number) => (
          <TableRow key={index}>
            <TableCell>
              <Box display="flex" flexDirection="row">
                <Checkbox color="primary" edge="start"
                  tabIndex={-1}
                  className="individual"
                  checked={tablerow.checked}
                  onChange={()=>checkboxSelect(tablerow,index)}
                  />

                <img src={favorite} />
              </Box>
            </TableCell>
            <TableCell>{tablerow.name}</TableCell>
            <TableCell>{tablerow.responsible}</TableCell>
            <TableCell>{tablerow.productCode}</TableCell>
            <TableCell>{tablerow.salesPrice}</TableCell>
            <TableCell>{tablerow.cost}</TableCell>
            <TableCell>{tablerow.category}</TableCell>
            <TableCell>{tablerow.type}</TableCell>
            <TableCell>{tablerow.qTYOnHand}</TableCell>
            <TableCell>{tablerow.forecastedQTY}</TableCell>
            <TableCell>{tablerow.UMO}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        ))}
    </>
  );
};



export function CollapseTable({ data, checkboxSelect }: { data: any, checkboxSelect :any}) {
  const classes = useStyles();
  
  return (
    <TableBody>
          {data.map((row:any) => (
            <ExpandableTableRow key={row.key} expandData={row?.data} checkboxSelect={checkboxSelect}>
              <TableCell component="th" scope="row">
                {row.key}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell component="th" scope="row">{row.total_sales_price}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </ExpandableTableRow>
          ))}
        </TableBody>
  );
}
