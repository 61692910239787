export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const sales = require("../../cfcustomersalesreports3/assets/sales.svg");
export const meetings = require("../../cfcustomersalesreports3/assets/calendar.svg");
export const invoice = require("../../cfcustomersalesreports3/assets/invoice.svg");
export const activity = require("../../cfcustomersalesreports3/assets/activity.svg");
export const star = require("../../cfcustomersalesreports3/assets/star.svg");
export const dropdown = require("../../cfcustomersalesreports3/assets/dropdown.svg");
export const downarrow = require("../../cfcustomersalesreports3/assets/down-arrow.svg");
export const settings = require("../assets/settings.svg");
export const menu = require("../assets/Menu.svg");
export const forwardarrow = require("../assets/forwardarrow.svg");
export const moveforward = require("../assets/move-forward.svg");
export const movebackward = require("../assets/move-backward.svg");
export const backarrow = require("../assets/back-arrow.svg");
export const paginationarrow = require("../assets/pagination-arrow.svg");
export const griddownarrow = require("../assets/grid-down-arrow.svg");
export const gridleftarrow = require("../assets/grid-left-arrow.svg");
export const envelope = require("../assets/envelope.svg");
export const greenDot = require("../assets/green-dot.svg");
export const kanbanview = require("../assets/kanban-view.svg");
export const listview = require("../assets/list-view.svg");
export const filtericon = require("../assets/filtericon.svg");
export const radioSelected = require("../assets/radio-selected.svg");
export const radioNonSelected = require("../assets/radio-non-selected.svg");
export const add = require("../../cfcustomersalesreports3/assets/add.svg");
export const groupby = require("../../cfcustomersalesreports3/assets/groupby.svg");
export const dashboard = require("../../cfcustomersalesreports3/assets/dashboard.svg");
export const filter = require("../../cfcustomersalesreports3/assets/filter.svg");
export const list = require("../../cfcustomersalesreports3/assets/list.svg");
export const search = require("../../cfcustomersalesreports3/assets/search.svg");
export const more = require("../../cfcustomersalesreports3/assets/more.svg");
export const purchase = require("../../cfcustomersalesreports3/assets/purchase.svg");
export const vendorbills = require("../../cfcustomersalesreports3/assets/vendorbills.svg");
export const upload = require("../../cfcustomersalesreports3/assets/upload.png");
export const addrow = require("../../cfcustomersalesreports3/assets/add-row.svg");
export const delrow = require("../../cfcustomersalesreports3/assets/del-row.svg");
export const external = require("../../cfcustomersalesreports3/assets/external-link.svg");
export const close = require("../../cfcustomersalesreports3/assets/close.svg");
export const userimg = require("../assets/userimg.png");
export const rightArrow = require("../../cfcustomersalesreports3/assets/right-arrow.svg");

export const backArrowEnable = require("../assets/backArrowEnable.svg");
export const forwardArrowDisable = require("../assets/forwardArrowDisable.svg");
export const backArrowDisable = require("../assets/backArrowDisable.svg");
export const forwardArrowEnable = require("../assets/forwardArrowEnable.svg");


export const moveBackwardArrowEnable = require("../assets/moveBackwardEnable.svg");
export const moveForwardArrowDisable = require("../assets/moveForwardDisable.svg");
export const moveBackwardArrowDisable = require("../assets/moveBackwardDisable.svg");
export const moveForwardArrowEnable = require("../assets/moveForwardEnable.svg");


export const validationerror = require("../assets/validationerror.svg");
export const external_link = require("../assets/external-link.svg");

export const listView = require("../assets/listview.svg");
export const canban = require("../assets/dashboard.svg");
export const multipleFile = require("../assets/multiple_file.svg");
export const favourite = require("../assets/favourite.svg");
export const swap = require("../assets/swap.svg");
export const checkboxChecked = require("../assets/checkboxChecked.svg");
export const checkboxUnchecked = require("../assets/checkboxUnchecked.svg");
export const printer = require("../assets/printer.svg");
