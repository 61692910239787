import React, { Component } from "react";
import {
    Container,
    Box,
    CardContent,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Grid,
    TextField,
    FormControl,
    Select,
    FormHelperText,
    FormLabel,
    MenuItem,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Form } from "formik";
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
const {alert} = require("./assets");
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

const mockData = [
    {
        id: "1", title: "XYZ", value: "xyz"
    },
    {
        id: "2", title: "ABC", value: "abc"
    },
    {
        id: "3", title: "PQR", value: "pqr"
    },
];

interface DropDownProps {
    name: string,
    options: { [name: string]: string }[],
    label: string,
    touched: any,
    errors: any,
    placeholderValue: string,
    values: any,
    handleBlur: any,
    isRequired: boolean,
    handleChange: any,
}

interface TextFieldProps {
    name: string,
    label: string,
    type: string,
    isRequired: boolean,
    errors: any,
    values: any,
    handleBlur: any,
    placeholderValue: string,
    handleChange: any,
    inputProps?: any,
    touched: any,
}
// Customizable Area End

import ItemavailabilityController, { Props } from "./ItemavailabilityController";

export default class AccountingTab extends React.Component<any>  {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    errorText = (touched: { [key: string]: boolean }, errors: { [key: string]: string }, name: string) => {
        return touched[name] && Boolean(errors[name]) ? true : false;
    }

    dropDownField = ({ name, options, label, errors, touched, isRequired, handleChange, values, handleBlur, placeholderValue }: DropDownProps) => {
        return (
            <div
                className="form-group form-check"
                style={webStyle.formGroupContainer}
            >
                <Typography
                    style={webStyle.pageHeading as React.CSSProperties}
                >
                    {label}{isRequired && <span style={{ color: "red" }}>*</span>}
                </Typography>
                <div
                    style={webStyle.errorContainer as React.CSSProperties}
                >
                    <FormControl
                        fullWidth
                        variant="outlined"
                        error={this.errorText(touched, errors, name)}
                    >
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                            <Select
                                style={{ color: values?.[name] ? "black" : "grey", width: "90%" }}
                                IconComponent={KeyboardArrowDownSharpIcon}
                                name={name}
                                displayEmpty
                                onChange={handleChange}
                                value={values?.[name]}
                                className={
                                    'form-check-input' +
                                    (errors[name] && touched[name] ? 'is-invalid' : '')
                                }
                                error={touched[name] && Boolean(errors[name])}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            width: "fit-content",
                                            marginTop: 55,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        },
                                    },
                                }}
                                onBlur={handleBlur}
                            >
                                <MenuItem
                                    value=""
                                    disabled
                                    style={{ color: "#E5E5E5", display: "none" }}
                                >{placeholderValue}</MenuItem>
                                {options?.length ?
                                    options.map(item => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.value}
                                            style={webStyle.menuItem as React.CSSProperties}
                                        >
                                            {item.title}
                                        </MenuItem>
                                    )) :
                                    null}
                            </Select>
                        </Box>
                        <div style={webStyle.errorBox}>
                            {(touched[name] && errors[name]) &&
                                <img
                                    src={alert}
                                    style={webStyle.alertIcon as React.CSSProperties}
                                />}
                            <FormHelperText>
                                {touched[name] && errors[name]}
                            </FormHelperText>
                        </div>
                    </FormControl>
                </div>
            </div>
        );
    }

    textFieldFormik = ({ name, label, type, errors, touched, isRequired, handleChange, values, handleBlur, inputProps, placeholderValue }: TextFieldProps) => {
        return (
            <div className="form-group" style={webStyle.formGroupContainer}>
                <Typography style={webStyle.pageHeading as React.CSSProperties}>
                    {label}
                    {isRequired && <span style={{ color: "red" }}>*</span>}
                </Typography>
                <div style={webStyle.errorContainer as React.CSSProperties}>
                    <TextField
                        name={name}
                        type="text"
                        placeholder={placeholderValue}
                        variant="outlined"
                        style={{ width: "90%" }}
                        error={touched[name] && Boolean(errors[name])}
                        value={values?.[name]}
                        onChange={handleChange}
                        inputProps={inputProps}
                        onBlur={handleBlur}
                        className={
                            'form-control' +
                            (errors[name] && touched[name] ? ' is-invalid' : '')
                        }
                    />
                    <div style={webStyle.errorBox}>
                        {(touched[name] && errors[name]) &&
                            <img alt="" src={alert} style={webStyle.alertIcon as React.CSSProperties} />}
                        <FormHelperText
                            style={webStyle.errorTextMsg}
                        >
                            {touched[name] && errors[name]}
                        </FormHelperText>
                    </div>
                </div>
            </div>
        );
    }

    // Customizable Area End


    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <Box sx={webStyle.accountingTabFormContainer}>
                    <Form translate={undefined}>
                        <Box>
                            <Grid container spacing={4} justifyContent="space-between">
                                <Grid item sm={6} md={6} lg={6}>
                                    <FormLabel component="legend" style={{ marginTop: "15px"}}>Receivables</FormLabel>
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    <FormLabel component="legend" style={{ marginTop: "15px"}}>Payables</FormLabel>
                                </Grid>
                            </Grid> 
                        </Box>
                        <Box>
                            <Grid container spacing={4} justifyContent="space-between">
                                <Grid item sm={6} md={6} lg={6}>
                                    {this.dropDownField({
                                        isRequired: true,
                                        name: "income_account",
                                        options: mockData,
                                        placeholderValue: "Select Income Account",
                                        touched: this.props.touched,
                                        errors: this.props.errors,
                                        values: this.props.values,
                                        handleChange: this.props.handleChange,
                                        handleBlur: this.props.handleBlur,
                                        label: "Income Account",
                                    })}
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    {this.dropDownField({
                                        name: "expense_account",
                                        options: mockData,
                                        label: "Expense Account",
                                        touched: this.props.touched,
                                        errors: this.props.errors,
                                        handleBlur: this.props.handleBlur,
                                        handleChange: this.props.handleChange,
                                        isRequired: true,
                                        values: this.props.values,
                                        placeholderValue: "Select Expense Account",
                                    })}
                                </Grid>
                            </Grid> 
                        </Box>
                        <Box>
                            <Grid container spacing={4} justifyContent="space-between">
                                <Grid item sm={6} md={6} lg={6}>
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    {this.dropDownField({
                                        name: "price_difference_account",
                                        errors: this.props.errors,
                                        options: mockData,
                                        placeholderValue: "Select Price Difference Account",
                                        touched: this.props.touched,
                                        values: this.props.values,
                                        label: "Price Difference Account",
                                        handleBlur: this.props.handleBlur,
                                        isRequired: true,
                                        handleChange: this.props.handleChange,
                                    })}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid container spacing={4} justifyContent="space-between">
                                <Grid item sm={6} md={6} lg={6}>
                                    <FormLabel component="legend">Interstat</FormLabel>
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    <FormLabel component="legend">Email Templates</FormLabel>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid container spacing={4} justifyContent="space-between">
                                <Grid item sm={6} md={6} lg={6}>
                                    {this.textFieldFormik({
                                        name: "commodity_code",
                                        label: "Commodity Code",
                                        placeholderValue: "Enter Commodity Code",
                                        type: "text",
                                        errors: this.props.errors,
                                        handleBlur: this.props.handleBlur,
                                        handleChange: this.props.handleChange,
                                        values: this.props.values,
                                        touched: this.props.touched,
                                        isRequired: true,
                                    })}
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    {this.dropDownField({
                                        name: "automatic_email_at_invoice",
                                        placeholderValue: "Select Automatic Email At Invoice",
                                        label: "Automatic Email At Invoice",
                                        errors: this.props.errors,
                                        touched: this.props.touched,
                                        options: mockData,
                                        values: this.props.values,
                                        handleBlur: this.props.handleBlur,
                                        isRequired: true,
                                        handleChange: this.props.handleChange,
                                    })}
                                </Grid>
                            </Grid>  
                        </Box>
                        <Grid container spacing={4} justifyContent="space-between">
                            <Grid item sm={6} md={6} lg={6}>
                                {this.dropDownField({
                                    name: "country_of_origin",
                                    errors: this.props.errors,
                                    placeholderValue: "Select Country of Origin",
                                    touched: this.props.touched,
                                    handleBlur: this.props.handleBlur,
                                    handleChange: this.props.handleChange,
                                    options: mockData,
                                    isRequired: true,
                                    values: this.props.values,
                                    label: "Country of Origin",
                                })}
                            </Grid>
                        </Grid>
                        <Box sx={webStyle.actionBtnStyle}>
                            <Button
                                style={{ backgroundColor: "#fff" }}
                                variant="contained"
                                onClick={() => {
                                    this.props.handleDiscardImage();
                                    this.props.resetForm({ values: this.props.getInitialValues() });
                                }}
                            >Discard</Button>
                            <Button
                                type="submit"
                                data-test-id="saveBtn"
                                onClick={() => {
                                    this.props.handleSave(this.props.values);
                                    this.props.validateInput(this.props.productName);
                                }}
                                style={webStyle.saveBtn}
                            >Save</Button>
                        </Box>   
                    </Form>
                </Box>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    accountingTabFormContainer: {
        display: "block",
        paddingInline: 16,
        paddingBlock: 8,
    },
    alertIcon: {
        width: "10px",
        position: "absolute",
    },
    pageHeading: {
        textWrap: "nowrap !important"
    },
    errorContainer: {
        flex: 1,
        flexDirection: "column",
        display: "flex",
        padding: "10px 0",
    },
    formGroupContainer: {
        alignItems: "baseline",
        width: "100%",
    },
    errorBox: {
        display: 'flex',
        alignItems: 'center',
    },
    menuItem: {
        justifyContent: "space-between",
        flexDirection: "row",
        borderBottom: "1px solid #E3E3E3",
        display: "flex",
    },
    saveBtn: {
        backgroundColor: "rgba(9, 65, 115, 0.77)",
        color: "#ffffff",
    },
    errorTextMsg: {
        marginLeft: "15px",
        color: 'red',
    },
    actionBtnStyle: {
        position: "relative",
        gap: 8,
        left: "25px",
        display: "flex",
        marginBlock: "40px",
        float: "right",
    },
};
// Customizable Area End
