import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";
import { Box, Grid, Typography } from '@material-ui/core';
import { configJSON1 } from './BanksListView.web';
export const configJSON = require("../../settings2/src/config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  
  token:string|null;
  showBankdata:any;
  loading: boolean;
  bankId: string|null;
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BankViewModeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetBanklistCallId!: string;
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),

      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      
      token:"",
      loading:false,
      showBankdata: {},
      bankId: null,
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


    // Customizable Area End
  }
  async receive(from: string, mesgs: Message) {
    // Customizable Area Start
    this.setState({...this.state,loading:false});
    if (getName(MessageEnum.RestAPIResponceMessage) === mesgs.id) {
      const apiRequestCallId = mesgs.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = mesgs.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
 
      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.apigetBanklistCallId) {
          this.apiResponse(responseJson)
        }
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const bankId = window.location.href.split('BankViewMode/')[1]
    const getToken= await StorageProvider.get("TOKEN")
    this.setState({
      token:getToken,
      bankId
    })
   await this.getBanklistApi()
  }

  apiResponse=(responseJson:any)=>{
    if(responseJson.data){
      this.setState({showBankdata:responseJson.data.attributes});
    }else if(responseJson.errors){
    window.alert("Invalid Token")
    }
  }

  navigatetoAddBank = () => {
    const message1: Message = new Message(getName(MessageEnum.NavigationMessage))
    message1.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddBank'
    );
    message1.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message1);

  }

  navigatetoEditBank = () => {
    this.props.navigation.navigate("AddBanks", { id: this.state.bankId })
  }
  
  getBanklistApi = async () => {
    this.setState({...this.state,loading:true});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetBanklistCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.bankApiUrl}/${this.state.bankId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  boxUI = (p1:string,p2:string,classes:any) => {
    return <Grid container className={classes.boxMargin} >
                <Grid item sm={5} >
                <Box>
                    <Typography >{p1}<span className={`required ${p1 === 'Address (line 2)' ? 'display-none' : ''}`}>*</span></Typography>
                </Box>
                </Grid>
                <Grid item sm={7} >
                <Box>
                    <Typography >{p2}</Typography>
                </Box>
                </Grid>
            </Grid>
  }

  // Customizable Area End
}
