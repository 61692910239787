import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  withStyles,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  StepConnector,
  IconButton,
  Input,
  InputAdornment,
  StepIconProps
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imgCustomerSignIn, imgLogoFilled, showPassword,hidePassword, errorIcon } from "./assets";
import { ToastContainer } from "react-toastify";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props, configJSON,
} from "./EmailAccountRegistrationController";

// Customizable Area Start

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17,
  },
  active: {
    "& $line": {
      background: "#0A4D68",
    },
  },
  completed: {
    "& $line": {
      background: "#0A4D68",
    },
  },
  line: {
    border: "1px solid #0A4D68",
    borderRadius: 1,
  },
})(StepConnector);

const webStyle={
  stepIcon: {
    backgroundColor: "#FFF",
    zIndex: 1,
    color: "#B4CDEA",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0A4D68",
  },
  active: {
    background: "#0A4D68",
    color: "#fff",
  },
  completed: {
    background: "#0A4D68",
    color: "#fff",
  },
}

export class ColorlibStepIcon extends React.Component<StepIconProps> {

  render() {

    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      "1": <Box>1</Box>,
      "2": <Box>2</Box>,
    };

    return (
      <div
        data-testid="stepper"
        style={{...webStyle.stepIcon,background: active || completed ? '#0A4D68' : '#FFF', color: active || completed ? '#fff' : '#0A4D68' }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Grid container >
        <ToastContainer />
          <Grid className={classes.logoGrid} item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.logoGridbox}>
              <Box className={classes.logoBox}>
                <img src={imgCustomerSignIn} className={classes.loginImgbox} alt={configJSON.signin} />
              </Box>
            </Box>
          </Grid>
          <Grid
            className={classes.fieldsGrid}
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Box className={classes.fieldsGridbox}>
              <img src={imgLogoFilled} alt={configJSON.signin} />
              <Typography className={classes.accountTitle}>
                {configJSON.labelTitle}
              </Typography>
              <Typography className={classes.accountText}>
                {configJSON.labelHeading}
              </Typography>
              <Typography className={classes.haveAccounttext}>
                {configJSON.labelnewUsertext}
              </Typography>
              <Box>
                <Box className={classes.root}>
                  <Stepper activeStep={this.state.activeStep} connector={<ColorlibConnector />}>
                    {this.state.steps.map((label) => (

                      <Step key={label}>
                        <StepLabel className={classes.steppertext} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Box>
              {this.state.generalState &&
                <>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.labelFirstName}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <TextField
                        className={classes.textField}
                        name="first_name"
                        placeholder={configJSON.labelFirstName}
                        value={this.state.registrationData.first_name}
                        id="outlined-error-helper-text"
                        data-testid="first_name"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                      />
                    </Box>
                    {this.state.first_nameError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.errorFirstname}
                      </Typography>}
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.lastName}<span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <TextField
                        className={classes.textField}
                        name="last_name"
                        placeholder={configJSON.lastName}
                        value={this.state.registrationData.last_name}
                        id="outlined-error-helper-text"
                        data-testid="last_name"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                      />
                    </Box>
                    {this.state.last_nameError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.errorLastname}
                      </Typography>}
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.mobileNumber}<span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <TextField
                        className={classes.textFieldClass}
                        type="text"
                        InputProps={{
                          inputProps: {
                            maxLength: 10,
                          },
                        }}
                        name="full_phone_number"
                        placeholder={configJSON.mobileNumber}
                        value={this.state.registrationData.full_phone_number}
                        id="outlined-error-helper-text"
                        data-testid="full_phone_number"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                      />
                    </Box>
                    {this.state.full_phone_numberError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.errorPhonenumber}
                      </Typography>}
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.alternateNumber}<span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <TextField
                        className={classes.textFieldClass}
                        type="text"
                        InputProps={{
                          inputProps: {
                            maxLength: 10,
                          },
                        }}
                        name="alternate_phone_number"
                        placeholder={configJSON.mobileNumber}
                        value={this.state.registrationData.alternate_phone_number}
                        id="outlined-error-helper-text"
                        data-testid="alternate_phone_number"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                      />
                    </Box>
                    {this.state.alternative_numberError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.errorPhonenumber}
                      </Typography>}
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.labelEmail}<span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <TextField
                        className={classes.textField}
                        name="email"
                        placeholder={configJSON.labelEmail}
                        value={this.state.registrationData.email}
                        id="outlined-error-helper-text"
                        data-testid="email"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                      />
                    </Box>
                    {this.state.emailError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.errorEmailNotValid}
                      </Typography>}
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.labelPassword}<span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <Input
                       placeholder={configJSON.labelEmail}
                       id="outlined-error-helper-text"
                       data-testid="password"
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                        className={classes.passwordTextfield}
                        disableUnderline
                        name="password"
                        value={this.state.registrationData.password}
                        type={this.state.showandhidePassword ? "password" : "text"}
                        fullWidth={true}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              data-test-id="hideshowPassword"
                            >
                              {this.state.showandhidePassword ? (
                                <img className={classes.hidePassword} src={hidePassword} alt="hide" />
                              ) : (
                                <img className={classes.hidePassword} src={showPassword} alt="show" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {this.state.passwordError &&
                        <Typography className={classes.loginUsertextfielderror}>
                          <img className={classes.erroricon} src={errorIcon} alt="error" />
                          {configJSON.errorPasswordNotValid}
                        </Typography>}
                    </Box>
                  </Box>
                  <Button className={classes.createAccountbutton}
                    data-testid="handleNextbutton"
                    onClick={() => { this.handleNext() }}
                  >
                    {configJSON.labelButtonnext}
                  </Button>
                </>
              }
              {this.state.locationState &&
                <>
                  <Box className={classes.textfieldBox} data-testid="locationStatescreen" >
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.address}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <TextField
                        className={classes.textField}
                        name="address"
                        placeholder={configJSON.mobileNumber}
                        value={this.state.registrationData.address}
                        id="outlined-error-helper-text"
                        data-testid="address"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                      />
                    </Box>
                    {this.state.addressError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.erroraddress}
                      </Typography>}
                    <Box className={classes.textfieldBlock}>
                      <TextField
                        className={classes.textField}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.country}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <FormControl
                        fullWidth
                      >
                        <TextField
                          className={classes.textFieldselect}
                          select
                          name="country"
                          value={this.state.registrationData.country}
                          id="outlined-error-helper-text"
                          data-testid="country"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                        >
                          <MenuItem value="">
                            {configJSON.none}
                          </MenuItem>
                          <MenuItem value={configJSON.country}>
                            {configJSON.country}
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    </Box>
                    {this.state.countryError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.errorCountry}
                      </Typography>}
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.state}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <FormControl
                        fullWidth
                      >
                        <TextField
                          className={classes.textFieldselect}
                          select
                          name="state"
                          value={this.state.registrationData.state}
                          id="outlined-error-helper-text"
                          data-testid="state"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                        >
                          <MenuItem value="">
                            {configJSON.none}
                          </MenuItem>
                          <MenuItem value={configJSON.state}>
                            {configJSON.state}
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    </Box>
                    {this.state.stateError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.errorState}
                      </Typography>}
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.city}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <FormControl
                        fullWidth
                      >
                        <TextField
                          className={classes.textFieldselect}
                          select
                          name="city"
                          value={this.state.registrationData.city}
                          id="outlined-error-helper-text"
                          data-testid="city"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                        >
                          <MenuItem value="">
                            {configJSON.none}
                          </MenuItem>
                          <MenuItem value={configJSON.city}>
                            {configJSON.city}
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    </Box>
                    {this.state.cityError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.errorCity}
                      </Typography>}
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Typography className={classes.textfieldTextstyle}>
                      {configJSON.postal}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.textfieldBlock}>
                      <TextField
                        className={classes.textField}
                        name="postal_code"
                        value={this.state.registrationData.postal_code}
                        id="outlined-error-helper-text"
                        data-testid="postal_code"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleRegistrationdata(event) }}
                      />
                    </Box>
                    {this.state.postal_codeError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.errorPostalcode}
                      </Typography>}
                  </Box>
                  <Box className={classes.textfieldBox}>
                    <Button className={classes.createAccountbuttonDisabled}
                      data-testid="handlePerviousbutton"
                      onClick={() => { this.handlePrevious() }}
                    >
                      {configJSON.pervious}
                    </Button>
                    <Button onClick={()=>{this.handleRegistraion()}} data-testid="createAccount" className={classes.createAccountbutton}>
                      {configJSON.btnTextSignUp}
                    </Button>
                  </Box>
                </>
              }
              <Typography className={classes.textfieldTextstyle}>
                {configJSON.signinText}<span className={classes.textFieldSignin}
                  data-testid="navigateTosignin"
                  onClick={() => { this.navigatetoSignin() }}>{configJSON.signin}</span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

const registrationScreenstyles: {} = () => ({
  steppertext: {
    fontSize: "18px",
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: "#0A4D68",
      fontSize: "18px",
    },
    "& .MuiStepLabel-label.MuiStepLabel-completed": {
      color: "#0A4D68",
      fontSize: "18px",
    }
  },
  logoGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "50%",
      alignItems: "center",
    }
  },
  logoGridbox: {
    backgroundColor: "#0A4D68",
    backgroundPosition: "center",
    backgroundRepeat: " no-repeat",
    backgroundSize: "cover",
    position: "fixed",
    display: "flex",
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  logoBox: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  fieldsGrid: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    width: "100%",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
      borderStartStartRadius: "50px",
      borderStartEndRadius: "50px",
      alignItems: "start",
      justifyContent: "center",
      marginTop: "-10%",
      padding: "2%",
      paddingTop: "10%",
    }
  },
  accountTitle: {
    fontSize: "32px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    marginTop: "7%",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#005e95"
  },
  accountText: {
    fontSize: "21px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    marginTop: "7%",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#005e95"
  },
  haveAccounttext: {
    fontSize: "20px",
    marginTop: "2%",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000"
  },
  signIntext: {
    fontSize: "23px",
    color: "#0A4D68",
    paddingLeft: "10px",
    fontFamily: "Inter",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    cursor: "grab"
  },
  textfieldTextstyle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#000",
    paddingTop: "2%",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  textFieldSignin: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#0A4D68",
    paddingTop: "2%",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  loginErrorstar: {
    color: "red"
  },
  textFieldselect: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    width: "100%",
    border: "1px solid lightgray"
  },
  textfieldBlock: {
    position: "relative",
    width: "100%",
  },
  textField: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    width: "100%",
    '& div::after': {
      borderBottom: "2px solid #a6a6a6"
    },
    '& div::before': {
      borderBottom: "2px solid #a6a6a6"
    }
  },
  fieldsGridbox: {
    backgroundColor: "white",
    margin: "5%",
    width: "100%"
  },
  textFieldClass: {
    fontSize: "20px",
    fontWeight: "500",
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    width: "100%",
    '& div::after': {
      borderBottom: "2px solid #a6a6a6"
    },
    '& div::before': {
      borderBottom: "2px solid #a6a6a6"
    },
  },
  textfieldBox: {
    marginTop: "1%"
  },
  createAccountbutton: {
    color: "#fff !important",
    fontSize: "17px",
    marginTop: "3%",
    fontWeight: "600",
    textTransform: "capitalize",
    padding: "15.5px 33.5px",
    borderRadius: "4px",
    backgroundColor: "#0a4d68",
    fontFamily: "Inter",
    fontStretch: "normal",
    lineHeight: "normal",
    "&:hover": {
      color: "#fff !important",
      fontSize: "17px",
      marginTop: "3%",
      fontWeight: "600",
      textTransform: "capitalize",
      padding: "15.5px 33.5px",
      borderRadius: "4px",
      backgroundColor: "#0a4d68",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
    }
  },
  createAccountbuttonDisabled: {
    color: "#000 !important",
    fontSize: "17px",
    marginTop: "3%",
    fontWeight: "600",
    marginRight: "1%",
    textTransform: "capitalize",
    padding: "15.5px 33.5px",
    borderRadius: "4px",
    fontFamily: "Inter",
    fontStretch: "normal",
    lineHeight: "normal",
    border: "1px solid #000"
  },
  stepperConnector: {
    ".MuiStepConnector-lineHorizontal": {
      borderTopWidth: "3px"
    }
  },
  passwordTextfield: {
    border: "1px solid #e3e3e3 !important",
    borderRadius: 4,
    "& .MuiInputBase-input": {
      height: 56,
      border: "unset !important"
    },
  },
  hidePassword: {
    position: "relative",
    right: "50%"
  },
  loginUsertextfielderror: {
    color: "red",
    fontsize: "14px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    paddingTop:"2%"
  },
});

export const EmailAccountRegistrationWeb = withStyles(registrationScreenstyles)(EmailAccountRegistrationBlock)
// Customizable Area End
