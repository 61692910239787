Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfposscreen4";
exports.labelBodyText = "cfposscreen4 Body";

exports.btnExampleTitle = "CLICK ME";
exports.posInterface = "POS Interface";
exports.connectedDevices = "Connected Devices";
exports.taxes = "Taxes";
exports.pricing = "Pricing";
exports.payments = "Payments";
exports.billsReceipts = "Bills & Receipts";
exports.inventory = "Inventory";
exports.accounting = "Accounting";
exports.sales = "Sales";


exports.posHeading="Point of Sale"
exports.posButtonLabel="Create POS"
exports.sessionButtonLabel="New Session"
exports.posOrderHeading="Orders"
exports.posListOrderHeading="List of Orders"
// Customizable Area End