Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypeDelete = "DELETE";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";
exports.mainHeading = "UoM Categories";
exports.Filters = "Filters";
exports.Favorites = "Favorites";
exports.pageTitle = "Configuration";
exports.buttonSave = "Save";
exports.Createcustomer = "Create UoM Categories";
exports.CreateNew = "Create New:";
exports.UoMtext = "Unit of measure Category";
exports.Groupby = "Groupby";
exports.name = "UoM";
exports.tableHeadingtext = "Units of Measure";
exports.Measuretext = "Unit of Measure";
exports.Typetext = "Type";
exports.Ratio = "Ratio";
exports.Rounding = "Rounding";
exports.errorMsg="Invalid token";
exports.successMsg="Unit of Measure is sucessfully created";
exports.lengthPlaceHolder = "Length"
exports.Unit = "UNIT OF MEASURE CATEGORIES";
exports.sales = "SALES PERSON";
exports.addField= "+ ADD CUSTOM FIELD";
exports.itemsPage = "Items per page: ";
exports.pageOne = "10";
exports.salespageTwo = "30";
exports.salespageThree = "60";
exports.salespageFour ="90";
exports.salespageFive = "10";
exports.salespageSix = "30";
exports.salespageSeven = "60";
exports.salespageEight = "90";
exports.salespageNine = "100";
exports.pagination = "1-5 of 20";
exports.btnExampleTitle = "CLICK ME";
exports.discard = "Discard";
exports.save = "Save";
exports.update = "Update";
exports.getlistUoMcategoriesAPiEndPoint = "bx_block_productquickview/uom_categories?";
exports.createUoMcategoriesAPiEndPoint = "bx_block_productquickview/uom_categories";
exports.getfilterUoMcategoriesAPiEndPoint = "bx_block_productquickview/uom_categories?";
exports.createUnitofmeasureAPiEndPoint = "bx_block_productquickview/unit_of_measures";
exports.deleteUoMcategoriesAPiEndPoint = "bx_block_productquickview/uom_categories/delete_uom_records?uom_record_ids=";
exports.deleteUnitofmeasureAPiEndPoint = "bx_block_productquickview/unit_of_measures/15";
exports.shippingHeading = "Shipping Method";
exports.deliveryText = "Delivery Method";
exports.Provider = "Provider";
exports.Published = "Is Published";
exports.Website = "Website";
exports.Company = "Company";
exports.Countries = "Countries";
exports.createShipping = "Create Shipping Method";
exports.search="Search here...."
exports.Archived = "Archived";
exports.export = "Export";
exports.unarchived = "Unarchived";
exports.Delete = "Delete";
exports.saveCurrent = "Save Current Search";
exports.importRecords = "Import Records";
exports.dashboard = "Add to my Dashboard";
exports.share = "Share with all users";
exports.amount = "Amount";
exports.Pricing = "Pricing";
exports.Description = "Description";
exports.destinationText = "Destination Avaliability";
exports.default = "Use by default";
exports.fixedPrice = "Fixed Price";
exports.States ="States";
exports.zipFrom = "Zip From";
exports.zipTo = "Zip To";
exports.delivery = "Filling this form allows to filter delivery carriers according to the delivery address of your customer";
exports.default = "Fixed Price";
exports.deliveryChargestext = "Normal Delivery Charges";
exports.marginonrate = "Margin on rate";
exports.freeFoodtext = "Free if ordered amount is above";
exports.deliveryProduct = "Delivery Product";
exports.getlistShippingmethodAPiEndPoint = "bx_block_settings/shipping_methods";
exports.deleteShippingmethodAPiEndPoint = "bx_block_settings/shipping_methods/";
exports.createShippingmethodAPiEndPoint = "bx_block_settings/shipping_methods";
exports.getfilterlistShippingmethodAPiEndPoint = "bx_block_settings/shipping_methods?"
exports.getgroupbyShippingmethodAPiEndPoint = "bx_block_settings/shipping_methods?"
exports.getsearchUoMcategoriesAPiEndPoint = "uom_categories/search_uom_category?"
exports.getsearchShippingmethodAPiEndPoint = "bx_block_settings/search_by_shipping?"
exports.createSucessmsg = "Shipping method is created sucessfully";
exports.providerErrormsg = "Provider is required";
exports.shippingErrormsg = "Shipping method is required";
exports.marginErrormsg = "Margin on rate is required";
exports.amountErrormsg = "Amount is required";
exports.productErrormsg = "Delivery product is required";
exports.companyErrormsg = "Company is required";
exports.websiteErrormsg = "Website is required";
exports.star = "*";
// Customizable Area End