import React, { Component } from "react";
import {
    Container,
    Box,
    CardContent,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Grid,
    FormControl,
    Select,
    MenuItem,
    TextField,
    FormHelperText,
    Chip,
    Paper
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik, Form } from "formik";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const { externalLink, alert, edit } = require("./assets");
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

const optionsList = [
    {
        id: "1",
        title: "Tax 10%",
    },
    {
        id: "2",
        title: "Tax 20%",
    },
    {
        id: "3",
        title: "Tax 30%",
    },
];


const productTypeList = [
    {
        id: "1",
        title: "Storable product",
        value: "storable_product"
    },
    {
        id: "2",
        title: "Consumable",
        value: "consumable"
    },
    {
        id: "3",
        title: "Service",
        value: "service"
    },
];

const invoicePolicyList = [
    {
        id: "1",
        title: "Ordered Quantities",
        value: "ordered_quantities"
    },
    {
        id: "2",
        title: "Delivered Quantities",
        value: "delivered_quantities"
    },
];

interface DropDownProps {
    name: string,
    options: { [name: string]: string }[],
    label: string,
    errors: any,
    touched: any,
    isRequired: boolean,
    values: any,
    handleBlur: any,
    handleChange: any,
    isEditable: boolean,
    isSearchable: boolean,
    isExpandable: boolean,
    placeholderValue: string,
}

interface ChipDropDownProps {
    name: string,
    options: { [name: string]: string }[],
    label: string,
    errors: any,
    touched: any,
    isRequired: boolean,
    values: any,
    handleBlur: any,
    handleChange: any,
    setFieldValue: any,
    placeholderValue: string,
}


interface TextFieldProps {
    name: string,
    label: string,
    errors: any,
    touched: any,
    isRequired: boolean
    values: any
    handleBlur: any
    handleChange: any
    inputProps?: any
    placeholderValue: string
}

interface GeneralInfoTabProps {
    uomCategoriesData: any;
    ProductCategoriesData: any;
    getInitialValues: () => {
        canbesold: boolean,
        canbepurchased: boolean,
        product_type: string,
        invoice_policy: string,
        unit_of_measure: string,
        purchased_uom: string,
        product_code: string,
        sales_price: number,
        customer_tax: string,
        product_category: string,
        internal_notes: string,
        cost: number,
    };
    validationSchema: any;
    extractUomValues: {
        id: string;
        title: string;
    }[];
    extractProductCategoryValues: {
        id: string;
        title: string;
    }[];
    handleSave: () => Promise<void>;
    validateInput: any;
    productName: string;

}


// Customizable Area End

import ItemavailabilityController, { Props } from "./ItemavailabilityController";

export default class GeneralInfoTab extends React.Component<any> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    errorText = (touched: { [key: string]: boolean }, errors: { [key: string]: string }, name: string) => {
        return touched[name] && Boolean(errors[name]) ? true : false;
    }

    formikDropDownField = ({ name, options, label, errors, touched, isRequired, handleChange, values, handleBlur, isEditable, isSearchable, isExpandable, placeholderValue }: DropDownProps) => {
        return (
            <div className="form-group form-check" style={webStyle.formGroupContainer}>
                <Typography style={webStyle.pageHeading as React.CSSProperties}>
                    {label}
                    {isRequired && <span style={{ color: "red" }}>*</span>}
                </Typography>
                <div style={webStyle.errorContainer as React.CSSProperties}>
                    <FormControl fullWidth variant="outlined" error={this.errorText(touched, errors, name)}>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                            <Select
                                style={{ width: "90%", color: values?.[name] ? "black" : "grey" }}
                                name={name}
                                IconComponent={KeyboardArrowDownSharpIcon}
                                value={values?.[name] || 0}
                                defaultValue={0}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                    'form-check-input' +
                                    (errors[name] && touched[name] ? ' is-invalid' : '')
                                }
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            marginTop: 55,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            width: "fit-content"
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={0} disabled style={{ display: "none", color: "#E5E5E5" }}>
                                    {placeholderValue}
                                </MenuItem>
                                {options?.length ? options.map(item => (
                                    <MenuItem key={item.id} value={item.value} style={webStyle.menuItem as React.CSSProperties}>{item.title}{isEditable && <img src={edit} style={webStyle.editImg as React.CSSProperties} />}</MenuItem>
                                )) : null}
                                {isSearchable && <MenuItem><Button>Search</Button></MenuItem>}
                            </Select>
                            {isExpandable && <img src={externalLink} style={{ width: "25px", paddingLeft: "10px" }} />}
                        </Box>
                        <div style={webStyle.errorBox}>
                            {(touched[name] && errors[name]) && <img src={alert} style={webStyle.alertIcon as React.CSSProperties} />}
                            <FormHelperText>{touched[name] && errors[name]}</FormHelperText>
                        </div>
                    </FormControl>
                </div>
            </div>
        );
    }

    formikTextField = ({ name, label, errors, touched, isRequired, handleChange, values, handleBlur, inputProps, placeholderValue }: TextFieldProps) => {
        return (
            <div className="form-group" style={webStyle.formGroupContainer}>
                <Typography style={webStyle.pageHeading as React.CSSProperties}>
                    {label}
                    {isRequired && <span style={{ color: "red" }}>*</span>}
                </Typography>
                <div style={webStyle.errorContainer as React.CSSProperties}>
                    <TextField
                        variant="outlined"
                        name={name}
                        type="text"
                        className={
                            'form-control' +
                            (errors[name] && touched[name] ? ' is-invalid' : '')
                        }
                        placeholder={placeholderValue}
                        error={touched[name] && Boolean(errors[name])}
                        value={values?.[name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: "90%" }}
                        inputProps={inputProps}
                    />
                    <div style={webStyle.errorBox}>
                        {(touched[name] && errors[name]) && <img src={alert} style={webStyle.alertIcon as React.CSSProperties} />}
                        <FormHelperText style={webStyle.errorTextMsg}>{touched[name] && errors[name]}</FormHelperText>
                    </div>
                </div>
            </div>
        );
    }

    formikChipDropDownField = ({ name, options, label, errors, touched, isRequired, handleChange, values, handleBlur, setFieldValue, placeholderValue }: ChipDropDownProps) => {
        const hasValue = values?.[name] && values?.[name].length > 0;
        return (
            <div className="form-group form-check" style={webStyle.formGroupContainer}>
                <Typography style={webStyle.pageHeading as React.CSSProperties}>
                    {label}
                    {isRequired && <span style={{ color: "red" }}>*</span>}
                </Typography>
                <div style={webStyle.errorContainer as React.CSSProperties}>
                    <Autocomplete
                        data-test-id={"autocompleteChip"}
                        style={{ width: "90%" }}
                        disableClearable
                        multiple
                        value={values?.[name]}
                        id="tags-outlined"
                        options={options}
                        renderOption={(option) => (
                            <Typography style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>{option.title}</Typography>
                        )}
                        popupIcon={<ExpandMoreIcon />}
                        filterSelectedOptions
                        getOptionLabel={(option) => option.title}
                        PaperComponent={({ children }) => (
                            <Paper style={{ boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) ", marginTop: 2, }}>{children}</Paper>)}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (<Chip
                                    deleteIcon={<CloseIcon style={{ color: "black", height: "17px" }} />}
                                    label={option.title}
                                    {...getTagProps({ index })}/>
                            ))
                        }
                        onChange={(_, newValue) => { 
                            setFieldValue(name, newValue); 
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                className={
                                    'form-check-input' + (errors[name] && touched[name] ? ' is-invalid' : '')
                                }
                                error={touched[name]
                                    && Boolean(errors[name])}
                                placeholder={hasValue ? "" : placeholderValue}
                                onBlur={handleBlur}
                                name={name}
                            />
                        )}
                    />

                    <div style={webStyle.errorBox}>
                        {(touched[name] && errors[name]) && <img src={alert} style={webStyle.alertIcon as React.CSSProperties} />}
                        <FormHelperText style={webStyle.errorTextMsg}>{touched[name] && errors[name]}</FormHelperText>
                    </div>
                </div>
            </div>
        );
    }

    // Customizable Area End


    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <Box sx={webStyle.generalInfoFormContainer}>
                    <Form translate={undefined}>

                        <style>
                            {`
                                            #tags-outlined-popup {
                                                padding:0 !important;
                                            }
                                            li[role="option"] {
                                                border-bottom: 1px solid #E3E3E3;
                                            }
                                            ul[role="listbox"] {
                                                padding-top:0 !important;
                                                padding-bottom:0 !important;
                                            }
                                        `}
                        </style>
                        <Grid container spacing={4} justifyContent="space-between" >
                            <Grid item sm={6} md={6} lg={6}>
                                {this.formikDropDownField({
                                    label: "Product Type",
                                    options: productTypeList,
                                    name: "product_type",
                                    errors: this.props.errors,
                                    touched: this.props.touched,
                                    isRequired: true,
                                    handleChange: this.props.handleChange,
                                    values: this.props.values,
                                    handleBlur: this.props.handleBlur,
                                    isEditable: false,
                                    isSearchable: false,
                                    isExpandable: false,
                                    placeholderValue: "Select Product Type"
                                })}
                            </Grid>
                            <Grid item sm={6} md={6} lg={6}>
                                {this.formikTextField({
                                    name: "sales_price",
                                    label: "Sales Price",
                                    touched: this.props.touched,
                                    errors: this.props.errors,
                                    handleChange: this.props.handleChange,
                                    handleBlur: this.props.handleBlur,
                                    values: this.props.values,
                                    isRequired: true,
                                    placeholderValue: "Enter Sales Price"
                                })}
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} justifyContent="space-between">
                            <Grid item sm={6} md={6} lg={6}>
                                {this.formikDropDownField({
                                    label: "Invoicing Policy",
                                    options: invoicePolicyList,
                                    name: "invoice_policy",
                                    errors: this.props.errors,
                                    touched: this.props.touched,
                                    isRequired: true,
                                    handleChange: this.props.handleChange,
                                    values: this.props.values,
                                    handleBlur: this.props.handleBlur,
                                    isEditable: false,
                                    isSearchable: false,
                                    isExpandable: false,
                                    placeholderValue: "Select Invoicing Policy"
                                })}
                            </Grid>
                            <Grid item sm={6} md={6} lg={6}>
                                {this.formikChipDropDownField({
                                    label: "Customer Taxes",
                                    options: optionsList,
                                    name: "customer_tax",
                                    errors: this.props.errors,
                                    touched: this.props.touched,
                                    isRequired: true,
                                    handleChange: this.props.handleChange,
                                    values: this.props.values,
                                    handleBlur: this.props.handleBlur,
                                    setFieldValue: this.props.setFieldValue,
                                    placeholderValue: "Select Customer Taxes"
                                })}
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} justifyContent="space-between">
                            <Grid item sm={6} md={6} lg={6} >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {this.formikDropDownField({
                                        label: "Unit of Measure",
                                        options: this.props.extractUomValues(this.props.uomCategoriesData),
                                        name: "unit_of_measure",
                                        errors: this.props.errors,
                                        touched: this.props.touched,
                                        isRequired: true,
                                        handleChange: this.props.handleChange,
                                        values: this.props.values,
                                        handleBlur: this.props.handleBlur,
                                        isEditable: true,
                                        isSearchable: true,
                                        isExpandable: false,
                                        placeholderValue: "Select Unit of Measure"
                                    })}
                                </div>
                                <Typography style={webStyle.fieldBottomTextBlue as React.CSSProperties}>Create UoM Category</Typography>
                            </Grid>
                            <Grid item sm={6} md={6} lg={6}>
                                {this.formikTextField({
                                    errors: this.props.errors,
                                    touched: this.props.touched,
                                    label: "Cost",
                                    name: "cost",
                                    values: this.props.values,
                                    isRequired: true,
                                    handleChange: this.props.handleChange,
                                    handleBlur: this.props.handleBlur,
                                    placeholderValue: "Enter Cost"
                                })}
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} justifyContent="space-between">
                            <Grid item sm={6} md={6} lg={6}>
                                <Box style={{ display: "flex", gap: "5px" }}>
                                    {this.formikDropDownField({
                                        label: "Purchase UoM",
                                        options: this.props.extractUomValues(this.props.uomCategoriesData),
                                        name: "purchased_uom",
                                        errors: this.props.errors,
                                        touched: this.props.touched,
                                        isRequired: true,
                                        handleChange: this.props.handleChange,
                                        values: this.props.values,
                                        handleBlur: this.props.handleBlur,
                                        isEditable: false,
                                        isSearchable: false,
                                        isExpandable: true,
                                        placeholderValue: "Select Purchase UoM"
                                    })}
                                </Box>
                            </Grid>
                            <Grid item sm={6} md={6} lg={6}>
                                <Box style={{ display: "flex", gap: "5px" }}>
                                    {this.formikDropDownField({
                                        label: "Product Category",
                                        options: this.props.extractProductCategoryValues(this.props.ProductCategoriesData),
                                        name: "product_category",
                                        errors: this.props.errors,
                                        touched: this.props.touched,
                                        isRequired: true,
                                        handleChange: this.props.handleChange,
                                        values: this.props.values,
                                        handleBlur: this.props.handleBlur,
                                        isEditable: true,
                                        isSearchable: true,
                                        isExpandable: true,
                                        placeholderValue: "Select Product Category"
                                    })}
                                </Box>
                                <Typography style={webStyle.fieldBottomTextBlue as React.CSSProperties}>Create Product Category</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item sm={6} md={6} lg={6}>
                                <div className="form-group" style={webStyle.formGroupContainer}>
                                    <Typography style={webStyle.pageHeading as React.CSSProperties}>
                                        {"Product Code"}
                                        {<span style={{ color: "red" }}>*</span>}
                                    </Typography>
                                    <div style={webStyle.errorContainer as React.CSSProperties}>
                                        <TextField
                                            data-test-id="product_code"
                                            variant="outlined"
                                            name={"product_code"}
                                            type="text"
                                            className={
                                                'form-control' +
                                                (this.props.errors["product_code"] && this.props.touched["product_code"] ? ' is-invalid' : '')
                                            }
                                            error={this.props.touched["product_code"] && Boolean(this.props.errors["product_code"])}
                                            value={this.props.values?.["product_code"]}
                                            placeholder={"Enter Product Code"}
                                            onChange={(e) => {
                                                const filteredInput = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                                                e.target.value = filteredInput;
                                                this.props.handleChange(e);
                                            }}
                                            onBlur={this.props.handleBlur}
                                            style={{ width: "90%" }}
                                        // inputProps={{
                                        //     minLength: 3,
                                        //     maxLength: 46,
                                        // }}
                                        />
                                        <div style={webStyle.errorBox}>
                                            {(this.props.touched["product_code"] && this.props.errors["product_code"]) && <img src={alert} style={webStyle.alertIcon as React.CSSProperties} />}
                                            <FormHelperText style={webStyle.errorTextMsg}>{this.props.touched["product_code"] && this.props.errors["product_code"]}</FormHelperText>
                                        </div>
                                    </div>
                                </div>
                                <Typography style={webStyle.fieldBottomTextGrey as React.CSSProperties}>min 3 and max 46 characters*</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item sm={12} md={12} lg={12}>
                                <div className="form-group" style={webStyle.formGroupContainer}>
                                    <Typography style={webStyle.pageHeading as React.CSSProperties}>{"Internal Notes"}</Typography>
                                    <div style={webStyle.errorContainer as React.CSSProperties}>
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            name={"internal_notes"}
                                            className={
                                                'form-control' +
                                                (this.props.errors["internal_notes"] && this.props.touched["internal_notes"] ? ' is-invalid' : '')
                                            }
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <Typography style={{ color: "black" }}>EN</Typography>
                                                </InputAdornment>,
                                            }}
                                            inputProps={{
                                                maxLength: 265,
                                            }}
                                            style={{ width: "95%" }}
                                            value={this.props.values?.["internal_notes"]}
                                            placeholder={"This is for Internal Purposes"}
                                            onChange={this.props.handleChange}
                                        />
                                    </div>
                                </div>
                                <Typography style={webStyle.NotesBottomTextGrey as React.CSSProperties}>max 265 characters*</Typography>
                            </Grid>
                        </Grid>
                        <Box sx={webStyle.actionBtnStyle}>
                            <Button
                                variant="contained" style={{ backgroundColor: "#fff" }}
                                onClick={() => {this.props.handleDiscardImage();
                                    this.props.resetForm({ values: this.props.getInitialValues() });
                                }}                                
                            >
                                Discard
                            </Button>
                            <Button
                                type="submit"
                                data-test-id="saveBtn"
                                onClick={() => { 
                                    this.props.validateInput(this.props.productName);
                                    this.props.handleSave(this.props.values);
                                }}
                                style={webStyle.saveBtn}
                            >Save</Button></Box>
                    </Form></Box>
            </ThemeProvider>

        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    generalInfoFormContainer: {
        display: "block",
        paddingBlock: 8,
        paddingInline: 16,
    },
    pageHeading: {
        textWrap: "nowrap !important"
    },
    formGroupContainer: {
        width: "100%",
        alignItems: "baseline",
    },
    errorContainer: {
        display: "flex",
        flexDirection: "column",
        padding: "10px 0",
        flex: 1
    },
    inputField: {
        width: "60%",
        padding: "10px",
        outline: "none",
        border: '2px solid lightgrey',
        borderRadius: '4px',
    },
    errorBox: {
        display: 'flex',
        alignItems: 'center'
    },
    imageStyle: {
        width: "25px",
        paddingTop: "15px"
    },
    alertIcon: {
        width: "10px",
        position: "absolute"
    },
    errorTextMsg: {
        color: 'red',
        marginLeft: "15px"
    },
    actionBtnStyle: {
        display: "flex",
        position: "relative",
        left: "25px",
        gap: 8,
        float: "right",
        marginBlock: "40px",
    },
    saveBtn: {
        backgroundColor: "rgba(9, 65, 115, 0.77)",
        color: "#ffffff"
    },
    actionButtonBox: {
        position: "absolute",
        right: "25px",
        paddingTop: "30px",
    },
    fieldBottomTextBlue: {
        textAlign: "right",
        color: "#2A6395",
        fontWeight: "bold",
        paddingRight: "10%"
    },
    fieldBottomTextGrey: {
        textAlign: "right",
        color: "grey",
        fontSize: "12px",
        paddingRight: "10%"
    },
    NotesBottomTextGrey: {
        textAlign: "right",
        color: "grey",
        fontSize: "12px",
        paddingRight: "5%"
    },
    menuItem: {
        borderBottom: "1px solid #E3E3E3",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"

    },
    editImg: {
        position: "absolute",
        right: "20px",
        top: "10px"
    }
};
// Customizable Area End