export const imgPasswordVisible = require("../assets/eye.svg");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgAdminSignIn = require("../assets/admin_sign_in.svg");
export const imgCustomerSignIn = require("../assets/customer_sign_in.svg");
export const imgLogo = require("../assets/Logo.svg");
export const imgLogoFilled = require("../assets/LogoFilled.svg");
export const errorIcon = require("../assets/Error.svg");
export const hidePassword = require("../assets/eye-invisible-filled copy.svg");
export const passwordHide = require("../assets/password-hide.svg");
export const passwordShow = require("../assets/password-show.svg");
export const eyeOn = require("../assets/eye_on.svg");
export const eyeOff = require("../assets/eye_off.svg");