import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Avatar,
  MenuItem,
  Button,
} from "@material-ui/core";
import { search, call, chat, notification, activity } from "./assets";
// import { ToastContainer } from 'react-toastify'

type DropDownProps = {
  children: JSX.Element | JSX.Element[];
};

type TitleProps={
  pageTitle : string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  })
);

const DropDown = (props: DropDownProps) => {
  const { children } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        disableTouchRipple
        disableFocusRipple
        disableRipple
        onClick={() => handleToggle()}
        style={webStyle.dropDownButton as React.CSSProperties}
      >
        {children}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              width: "237px",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={(event) => event.key === "Tab" && handleToggle()}
                >
                  <MenuItem onClick={handleToggle}>Profile</MenuItem>
                  <MenuItem onClick={handleToggle}>My account</MenuItem>
                  <MenuItem onClick={handleToggle}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export function AppHeader(props:TitleProps) {
  const {pageTitle}=props;
  const classes = useStyles();

  return (
    <Box sx={webStyle.topHeader}>
      {/* <ToastContainer/> */}
      <Typography variant="h5">
        <b>{pageTitle}</b>
      </Typography>
      <Box sx={webStyle.rightHeader}>
        <Box sx={webStyle.headerItem}>
          <img src={search} alt="search" />
          <img src={call} alt="call" />
          <img src={chat} alt="chat" />
          <img src={notification} alt="notification" />
          <img src={activity} alt="activity" />
        </Box>

        <DropDown>
          <Box sx={webStyle.headerOptions}>
            <Avatar>S</Avatar>
            <Box sx={webStyle.typoBox}>
              <Typography
                variant="h6"
                style={webStyle.headerOptionText as React.CSSProperties}
              >
                San Francisco
              </Typography>
              <Typography
                variant="body2"
                style={webStyle.headerOptionText as React.CSSProperties}
              >
                Jawal ERAM - COMEX
              </Typography>
            </Box>
            <Box>
              <ExpandMoreIcon />
            </Box>
          </Box>
        </DropDown>

        <DropDown>
          <Box sx={webStyle.headerOptions}>
            <Avatar>M</Avatar>
            <Box sx={webStyle.typoBox}>
              <Typography
                variant="h6"
                style={webStyle.headerOptionText as React.CSSProperties}
              >
                Mitchell Admin
              </Typography>
              <Typography
                variant="body2"
                style={webStyle.headerOptionText as React.CSSProperties}
              >
                mitchell987@gmail.com
              </Typography>
            </Box>
            <Box>
              <ExpandMoreIcon />
            </Box>
          </Box>
        </DropDown>
      </Box>
    </Box>
  );
}

const webStyle = {
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    width: "100%",
    paddingBottom: "12px",
  },
  rightHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  headerItem: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  headerOptions: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    border: "1px solid #dfdfdf",
    borderRadius: "5px",
    padding: "3px 10px",
    backgroundColor: "white",
  },
  typoBox: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  headerOptionText: {
    textTransform: "none",
  },
  dropDownButton:{
    backgroundColor:"transparent",
    "&:hover" :{
      background: "transparent",
      color: "white",
    }
  }
};
