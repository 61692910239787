import React, { Component } from "react";
import {
    Container,
    Box,
    CardContent,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Grid,
    FormControl,
    Select,
    MenuItem,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Divider,
    FormHelperText
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Form } from "formik";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
const { externalLink, alert, edit } = require("./assets");

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        },
        secondary:{
            main: "#2A6395",
        }
    }
});

const productTypeList = [
    {
        id: "1", title: "Storable product", value: "storable_product"
    },
    {
        id: "2", title: "Consumable", value: "consumable"
    },
    {
        id: "3", title: "Service", value: "service"
    },
];

interface DropDownProps {
    name: string,
    options: { [name: string]: string }[],
    label: string,
    errors: any,
    touched: any,
    values: any,
    handleChange: any,
    handleBlur: any,
    isRequired: boolean,
    isEditable: boolean,
    isExpandable: boolean,
    isSearchable: boolean,
    defaultValue: string
}

interface TextFieldProps {
    name: string,
    label: string,
    type:string,
    placeholderValue: string
    errors: any,
    touched: any,
    values: any,
    handleBlur: any,
    handleChange: any,
    inputProps?: any,
    isRequired: boolean,
}

// Customizable Area End

import ItemavailabilityController, { Props } from "./ItemavailabilityController";

export default class InventoryTab extends React.Component<any>  {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    
    errorText = (touched: { [key: string]: boolean }, errors: { [key: string]: string }, name: string) => {
        return touched[name] && Boolean(errors[name]) ? true : false;
    }

    dropDownField = ({ name, options, label, errors, touched, isRequired, handleChange, values, handleBlur, isEditable, isSearchable, isExpandable, defaultValue }: DropDownProps) => {
        return (
            <div className="form-group form-check" 
                style={webStyle.formGroupContainer}>
                <Typography style={webStyle.pageHeading as React.CSSProperties}>
                    {label}
                    {isRequired && <span style={{ color: "red" }}>*</span>}</Typography>
                <div 
                    style={webStyle.errorContainer as React.CSSProperties}>
                    <FormControl 
                        variant="outlined" 
                        error={this.errorText(touched, errors, name)}
                        fullWidth 
                        >
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                            <Select
                                style={{ color: values?.[name] ? "black" : "grey", width: "90%" }}
                                name={name}
                                displayEmpty
                                IconComponent={KeyboardArrowDownSharpIcon}
                                error={touched[name] && Boolean(errors[name])}
                                value={values?.[name]}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={
                                    'form-check-input' +
                                    (errors[name] && touched[name] ? 'is-invalid' : '')
                                } 
                                MenuProps={{  
                                    PaperProps: {
                                        style: {
                                            width: "fit-content",
                                            marginTop: 55,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        },
                                    },
                                }}
                            >
                                <MenuItem 
                                    value="created user" 
                                    disabled 
                                    style={{ color: "#E5E5E5", display: "none" }}
                                >{defaultValue}</MenuItem>
                                {options?.length ?
                                    options.map(item => (
                                        <MenuItem
                                            key={item.id}
                                            value={item.value}
                                            style={webStyle.menuItem as React.CSSProperties}
                                        >
                                            {item.title}{isEditable && <img src={edit} style={webStyle.editImg as React.CSSProperties} />}
                                        </MenuItem>
                                    )) :
                                    null}
                                {isSearchable &&
                                    <MenuItem>
                                        <Button>Search</Button>
                                    </MenuItem>
                                }
                            </Select>
                            {isExpandable &&
                                <img
                                    src={externalLink}
                                    style={{ width: "25px", paddingLeft: "10px" }}
                                />
                            }
                        </Box>
                        <div 
                        style={webStyle.errorBox}
                        >
                            {(touched[name] 
                            && errors[name]) 
                            &&
                                <img
                                    style={webStyle.alertIcon as React.CSSProperties}
                                    src={alert}/>}
                            <FormHelperText>
                            {touched[name] && errors[name]}
                            </FormHelperText>
                        </div>

                    </FormControl>
                </div></div>
        );
    }

    textFieldFormik = 
    ({ name, label, type, errors, touched, isRequired, handleChange, values, handleBlur, inputProps, placeholderValue }: TextFieldProps) => 
    {
        return (
            <div className="form-group" style={webStyle.formGroupContainer}>
                <Typography style={webStyle.pageHeading as React.CSSProperties}>
                    {label}
                    {isRequired && <span style={{ color: "red" }}>*</span>}
                </Typography>
                <div style={webStyle.errorContainer as React.CSSProperties}>
                    <TextField
                        type="text"
                        name={name}
                        variant="outlined"
                        placeholder={placeholderValue}
                        className={
                            'form-control' + (errors[name] && touched[name] ? ' is-invalid' : '')
                        }
                        style={{ width: "90%" }}
                        error={touched[name] && Boolean(errors[name])}
                        value={values?.[name]}
                        inputProps={inputProps}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <div style={webStyle.errorBox}>
                        {(touched[name] && errors[name]) && 
                        <img src={alert} style={webStyle.alertIcon as React.CSSProperties} />}
                        <FormHelperText
                            style={webStyle.errorTextMsg}
                        >
                            {touched[name] && errors[name]}
                        </FormHelperText>
                    </div>
                </div>
            </div>
        );
    }


    // Customizable Area End


    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                            <Box sx={webStyle.InventoryFormContainer}>
                            <style>
                                 {`
                                   .radioBtn:hover {
                                        background-color: rgba(0, 42, 87, 0.04) !important;
                                    }
                                    .radioBtn:focus {
                                        background-color: rgba(0, 42, 87, 0.04) !important;
                                    }
                                `}
                            </style>

                                <Form translate={undefined}> 
                                    <FormLabel component="legend" style={{ marginTop: "15px", marginBottom: "15px" }}>Logistics</FormLabel>      
                                    <Grid container spacing={4} justifyContent="space-between">
                                        <Grid item sm={6} md={6} lg={6}>
                                            {this.dropDownField({
                                                name: "responsible",
                                                defaultValue: "created user",
                                                options: productTypeList,
                                                errors:this.props.errors,
                                                touched: this.props.touched,
                                                handleChange: this.props.handleChange,
                                                values: this.props.values,
                                                handleBlur: this.props.handleBlur,
                                                isRequired: true,
                                                isEditable: false,
                                                isSearchable: false,
                                                isExpandable: false,
                                                label: "Responsible",
                                            })}
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6}>
                                            {this.textFieldFormik({
                                                label: "Barcode",
                                                placeholderValue: "Enter Barcode",
                                                type:"text",
                                                touched: this.props.touched,
                                                errors: this.props.errors,
                                                name: "barcode",
                                                handleChange: this.props.handleChange,
                                                handleBlur: this.props.handleBlur,
                                                values: this.props.values,
                                                isRequired: false,
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} justifyContent="space-between" style={{position: "relative"}}>
                                        <Grid item sm={6} md={6} lg={6}>
                                            {this.textFieldFormik({
                                                label: "Weight",
                                                placeholderValue: "Enter Weight",
                                                name: "weight",
                                                handleBlur: this.props.handleBlur,
                                                handleChange: this.props.handleChange,
                                                type: "number",
                                                touched: this.props.touched,
                                                errors: this.props.errors,
                                                values: this.props.values,
                                                isRequired: true,
                                            })}
                                        </Grid>
                                         <Grid item sm={6} md={6} lg={6} style={{
                                            position: "absolute",
                                            width: "100%",
                                            left: "50%"
                                        }}>
                                            <FormLabel component="legend" style={{ marginBottom: "15px" }}>Traceability</FormLabel>
                                            <Box style={{ display: "flex" }} className={
                                                'form-control' +
                                                (this.props.errors["trackingValue"] && this.props.touched["trackingValue"] ? ' is-invalid' : '')
                                            }>
                                                <Typography style={webStyle.tracking as React.CSSProperties}>
                                                    {"Tracking"}
                                                    {<span style={{ color: "red" }}>*</span>}
                                                </Typography>
                                                <Divider variant="middle" orientation="vertical" flexItem />
                                            <Box>
                                                <RadioGroup data-test-id="radioBtn" aria-label="tracking" name="trackingValue" value={this.props.values.trackingValue} onChange={(_, newValue) => { this.props.setFieldValue("trackingValue", newValue); }}>
                                                    <FormControlLabel value="By Unique Serial Number"
                                                        control={
                                                            <Radio
                                                                className="radioBtn"
                                                                disableRipple
                                                                disableFocusRipple
                                                                disableTouchRipple
                                                                color="secondary"
                                                                size='small'
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                style={this.props.values.trackingValue === "By Unique Serial Number" ? webStyle.radioChecked : webStyle.radioUnchecked}>
                                                                By Unique Serial Number
                                                            </Typography>
                                                        }
                                                        />
                                                    <FormControlLabel value="By Lots"
                                                        control={
                                                            <Radio
                                                                className="radioBtn"
                                                                disableRipple
                                                                disableFocusRipple
                                                                disableTouchRipple
                                                                color="secondary"
                                                                size='small'
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                style={this.props.values.trackingValue === "By Lots" ? webStyle.radioChecked : webStyle.radioUnchecked}>
                                                                By Lots
                                                            </Typography>
                                                        }
                                                        />
                                                    <FormControlLabel value="No Tracking"
                                                        control={
                                                            <Radio
                                                                className="radioBtn"
                                                                disableRipple
                                                                disableFocusRipple
                                                                disableTouchRipple
                                                                color="secondary"
                                                                size='small' 
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                style={this.props.values.trackingValue === "No Tracking" ? webStyle.radioChecked : webStyle.radioUnchecked}>
                                                                No Tracking
                                                            </Typography>
                                                        }
                                                        />
                                                </RadioGroup>
                                                    <div style={webStyle.errorBox}>
                                                        {(this.props.touched["trackingValue"] && this.props.errors["trackingValue"]) && <img src={alert} style={webStyle.alertIcon as React.CSSProperties} />}
                                                        <FormHelperText style={webStyle.errorTextMsg}>{this.props.touched["trackingValue"] && this.props.errors["trackingValue"]}</FormHelperText>
                                                    </div>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={4} >
                                        <Grid item sm={6} md={6} lg={6} >
                                            {this.textFieldFormik({
                                                name: "volume",
                                                placeholderValue: "Enter Volume",
                                                handleChange: this.props.handleChange,
                                                handleBlur: this.props.handleBlur,
                                                errors: this.props.errors,
                                                type: "number",
                                                touched: this.props.touched,
                                                values: this.props.values,
                                                isRequired: true,
                                                label: "Volume",
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} >
                                        <Grid item sm={6} md={6} lg={6}>
                                                {this.textFieldFormik({
                                                    label: "Manuf. Lead Time",
                                                    placeholderValue: "Enter Manuf. Lead Time",
                                                    handleBlur: this.props.handleBlur,
                                                    handleChange: this.props.handleChange,
                                                    touched: this.props.touched,
                                                    type: "number",
                                                    errors: this.props.errors,
                                                    values: this.props.values,
                                                    isRequired: true,
                                                    name: "manuf_lead_time",
                                                })}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} >
                                        <Grid item sm={6} md={6} lg={6}>   
                                            {this.textFieldFormik({
                                                name: "customer_lead_time",
                                                placeholderValue: "Enter Customer Lead Time",
                                                handleBlur: this.props.handleBlur,
                                                handleChange: this.props.handleChange,
                                                label: "Customer Lead Time",
                                                type: "number",
                                                touched: this.props.touched,  
                                                values: this.props.values,
                                                errors: this.props.errors,
                                                isRequired: true,
                                            })}    
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4}>
                                        <Grid item sm={6} md={6} lg={6}>
                                            {this.textFieldFormik({
                                                name: "hs_code",
                                                placeholderValue: "Enter HS Code",
                                                touched: this.props.touched,
                                                values: this.props.values,
                                                errors: this.props.errors,
                                                label: "HS Code",
                                                type: "text",
                                                handleChange: this.props.handleChange,
                                                handleBlur: this.props.handleBlur,
                                                isRequired: false,
                                            })}     
                                        </Grid>
                                    </Grid>
                                    <FormLabel component="legend" style={{ marginTop: "10px", marginBottom: "15px" }}>Description</FormLabel>
                                    <Grid container spacing={4}>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <div 
                                                style={webStyle.formGroupContainer}
                                                className="form-group" 
                                            >
                                                <Typography
                                                    style={webStyle.pageHeading as React.CSSProperties}>
                                                    {"Description for Receipts"}
                                                </Typography>
                                                <div style={webStyle.errorContainer as React.CSSProperties}>
                                                    <TextField
                                                        placeholder={"This note added to receipt orders (e.g. where to store the products in the warehouse)."}
                                                        variant="outlined"
                                                        name={"description_for_receipts"}
                                                        type="text"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                                <Typography style={{color:"black"}}>EN</Typography>
                                                                        </InputAdornment>,
                                                        }}
                                                        className={
                                                            'form-control' +
                                                            (this.props.errors["description_for_receipts"] && this.props.touched["description_for_receipts"] ? ' is-invalid' : '')
                                                        }
                                                        inputProps={{
                                                            maxLength: 265,
                                                        }}
                                                        style={{ width: "100%" }}
                                                        value={this.props.values?.["description_for_receipts"]}
                                                        onChange={this.props.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <Typography style={webStyle.notesBottomTextGrey as React.CSSProperties}>max 265 characters*</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4}>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <div className="form-group" 
                                            style={webStyle.formGroupContainer}>
                                                <Typography 
                                                    style={webStyle.pageHeading as React.CSSProperties}>{"Description for Delivery Orders"}
                                                </Typography>
                                                <div 
                                                    style={webStyle.errorContainer as React.CSSProperties}>
                                                    <TextField
                                                        variant="outlined"
                                                        className={
                                                            'form-control' +
                                                            (this.props.errors["description_for_delivery_orders"] && this.props.touched["description_for_delivery_orders"] ? ' is-invalid' : '')
                                                        }
                                                        type="text"
                                                        name={"description_for_delivery_orders"}
                                                        onChange={this.props.handleChange}
                                                        inputProps={{
                                                            maxLength: 265,
                                                        }}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                <Typography style={{ color: "black" }}>EN</Typography>
                                                            </InputAdornment>,
                                                        }}
                                                        style={{ width: "100%" }}
                                                        placeholder={"This note added to delivery orders."}
                                                        value={this.props.values?.["description_for_delivery_orders"]}
                                                    />
                                                </div>
                                            </div>
                                            <Typography style={webStyle.notesBottomTextGrey as React.CSSProperties}>max 265 characters*</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4}>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <div 
                                                className="form-group" 
                                                style={webStyle.formGroupContainer}>
                                                <Typography
                                                    style={webStyle.pageHeading as React.CSSProperties}>
                                                    {"Description for Internal Transfers"}
                                                </Typography>
                                                <div style={webStyle.errorContainer as React.CSSProperties}>
                                                    <TextField
                                                        name={"description_for_internal_transfers"}
                                                        type="text"
                                                        value={this.props.values?.["description_for_internal_transfers"]}
                                                        className={
                                                            'form-control' +
                                                            (this.props.errors["description_for_internal_transfers"] && this.props.touched["description_for_internal_transfers"] ? ' is-invalid' : '')
                                                        }
                                                        variant="outlined"
                                                        placeholder={"This note added to internal transfer orders (e.g where to pick the products in warehouse)."}
                                                        style={{ width: "100%" }}
                                                        onChange={this.props.handleChange}
                                                        inputProps={{maxLength: 265,
                                                        }}
                                                        InputProps={{endAdornment: <InputAdornment position="end">
                                                                <Typography 
                                                                    style={{ color: "black" }}>EN</Typography></InputAdornment>,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <Typography style={webStyle.notesBottomTextGrey as React.CSSProperties}>max 265 characters*</Typography>
                                        </Grid>
                                    </Grid>
                                    <Box sx={webStyle.actionBtnStyle}>
                                        <Button style={{ backgroundColor: "#fff" }}
                                            variant="contained"
                                            onClick={() => {
                                                this.props.handleDiscardImage();
                                                this.props.resetForm({ values: this.props.getInitialValues() });
                                            }}>Discard</Button>
                                        <Button
                                            style={webStyle.saveBtn}
                                            data-test-id="saveBtn"
                                            onClick={
                                                () => { 
                                                    this.props.validateInput(this.props.productName);
                                                    this.props.handleSave(this.props.values);
                                                }
                                            }
                                            type="submit"
                                        >Save</Button>
                                    </Box>
                                </Form>
                            </Box>  
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    tracking: {
        marginTop: "4px",
        textWrap: "nowrap !important",
    },
    InventoryFormContainer: {
        paddingBlock: 8,
        paddingInline: 16,
        display: "block",
    },
    formGroupContainer: {
        width: "100%",
        alignItems: "baseline",
    },
    errorContainer: {
        padding: "10px 0",
        flexDirection: "column",
        flex: 1,
        display: "flex",
    },
    pageHeading:{
        textWrap: "nowrap !important"
    },
    inputField: {
        padding: "10px",
        borderRadius: '4px',
        width: "60%",
        border: '2px solid lightgrey',
        outline: "none",
    },
    errorBox: {
        alignItems: 'center',
        display: 'flex',
    },
    alertIcon: {
        position: "absolute",
        width: "10px",
    },
    errorTextMsg: {
        color: 'red',
        marginLeft: "15px"
    },
    actionBtnStyle: {
        display: "flex",
        position: "relative",
        left: "25px",
        gap: 8,
        float: "right",
        marginBlock: "40px",
    },
    imageStyle: {
        width: "25px",
        paddingTop: "15px"
    },
    actionButtonBox: {
        position: "absolute",
        right: "25px",
        paddingTop: "30px",
    },
    saveBtn: {
        color: "#ffffff",
        backgroundColor: "rgba(9, 65, 115, 0.77)",
    },
    fieldBottomTextGrey: {
        paddingRight: "10%",
        color: "grey",
        fontSize: "12px",
        textAlign: "right",
    },
    fieldBottomTextBlue: {
        paddingRight: "10%",
        color: "#2A6395",
        fontWeight: "bold",
        textAlign: "right",
    },
    menuItem: {
        borderBottom: "1px solid #E3E3E3",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    editImg: {
        top: "10px",
        right: "20px",
        position: "absolute",
    },
    notesBottomTextGrey: {
        textAlign: "right",
        color: "grey",
        fontSize: "12px",
    },
    radioUnchecked:{
        color:"#000",
    },
    radioChecked: {
        color: "#2A6395",
        fontWeight:600
    },
};
// Customizable Area End
