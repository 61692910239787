Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timezones = [
  '(UTC-12:00) International Date Line West',
  '(UTC-11:00) Coordinated Universal Time-11',
  '(UTC-10:00) Aleutian Islands',
  '(UTC-10:00) Hawaii',
  '(UTC-09:30) Marquesas Islands',
  '(UTC-09:00) Alaska',
  '(UTC-09:00) Coordinated Universal Time-09',
  '(UTC-08:00) Baja California',
  '(UTC-08:00) Coordinated Universal Time-08',
  '(UTC-08:00) Pacific Time (US & Canada)',
  '(UTC-07:00) Arizona',
  '(UTC-07:00) La Paz, Mazatlan',
  '(UTC-07:00) Mountain Time (US & Canada)',
  '(UTC-07:00) Yukon',
  '(UTC-06:00) Central America',
  '(UTC-06:00) Central Time (US & Canada)',
  '(UTC-06:00) Easter Island',
  '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
  '(UTC-06:00) Saskatchewan',
  '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
  '(UTC-05:00) Chetumal',
  '(UTC-05:00) Eastern Time (US & Canada)',
  '(UTC-05:00) Haiti',
  '(UTC-05:00) Havana',
  '(UTC-05:00) Indiana (East)',
  '(UTC-05:00) Turks and Caicos',
  '(UTC-04:00) Asuncion',
  '(UTC-04:00) Atlantic Time (Canada)',
  '(UTC-04:00) Caracas',
  '(UTC-04:00) Cuiaba',
  '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  '(UTC-04:00) Santiago',
  '(UTC-03:30) Newfoundland',
  '(UTC-03:00) Araguaina',
  '(UTC-03:00) Brasilia',
  '(UTC-03:00) Cayenne, Fortaleza',
  '(UTC-03:00) City of Buenos Aires',
  '(UTC-03:00) Greenland',
  '(UTC-03:00) Montevideo',
  '(UTC-03:00) Punta Arenas',
  '(UTC-03:00) Saint Pierre and Miquelon',
  '(UTC-03:00) Salvador',
  '(UTC-02:00) Coordinated Universal Time-02',
  '(UTC-01:00) Azores',
  '(UTC-01:00) Cabo Verde Is.',
  '(UTC) Coordinated Universal Time',
  '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
  '(UTC+00:00) Monrovia, Reykjavik',
  '(UTC+00:00) Sao Tome',
  '(UTC+01:00) Casablanca',
  '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  '(UTC+01:00) West Central Africa',
  '(UTC+02:00) Athens, Bucharest',
  '(UTC+02:00) Beirut',
  '(UTC+02:00) Cairo',
  '(UTC+02:00) Chisinau',
  '(UTC+02:00) Damascus',
  '(UTC+02:00) Gaza, Hebron',
  '(UTC+02:00) Harare, Pretoria',
  '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  '(UTC+02:00) Jerusalem',
  '(UTC+02:00) Juba',
  '(UTC+02:00) Kaliningrad',
  '(UTC+02:00) Khartoum',
  '(UTC+02:00) Tripoli',
  '(UTC+02:00) Windhoek',
  '(UTC+03:00) Amman',
  '(UTC+03:00) Baghdad',
  '(UTC+03:00) Istanbul',
  '(UTC+03:00) Kuwait, Riyadh',
  '(UTC+03:00) Minsk',
  '(UTC+03:00) Moscow, St. Petersburg',
  '(UTC+03:00) Nairobi',
  '(UTC+03:00) Volgograd',
  '(UTC+03:30) Tehran',
  '(UTC+04:00) Abu Dhabi, Muscat',
  '(UTC+04:00) Astrakhan, Ulyanovsk',
  '(UTC+04:00) Baku',
  '(UTC+04:00) Izhevsk, Samara',
  '(UTC+04:00) Port Louis',
  '(UTC+04:00) Saratov',
  '(UTC+04:00) Tbilisi',
  '(UTC+04:00) Yerevan',
  '(UTC+04:30) Kabul',
  '(UTC+05:00) Ashgabat, Tashkent',
  '(UTC+05:00) Ekaterinburg',
  '(UTC+05:00) Islamabad, Karachi',
  '(UTC+05:00) Qyzylorda',
  '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  '(UTC+05:30) Sri Jayawardenepura',
  '(UTC+05:45) Kathmandu',
  '(UTC+06:00) Astana',
  '(UTC+06:00) Dhaka',
  '(UTC+06:00) Omsk',
  '(UTC+06:30) Yangon (Rangoon)',
  '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  '(UTC+07:00) Barnaul, Gorno-Altaysk',
  '(UTC+07:00) Hovd',
  '(UTC+07:00) Krasnoyarsk',
  '(UTC+07:00) Novosibirsk',
  '(UTC+07:00) Tomsk',
  '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  '(UTC+08:00) Irkutsk',
  '(UTC+08:00) Kuala Lumpur, Singapore',
  '(UTC+08:00) Perth',
  '(UTC+08:00) Taipei',
  '(UTC+08:00) Ulaanbaatar',
  '(UTC+08:45) Eucla',
  '(UTC+09:00) Chita',
  '(UTC+09:00) Osaka, Sapporo, Tokyo',
  '(UTC+09:00) Pyongyang',
  '(UTC+09:00) Seoul',
  '(UTC+09:00) Yakutsk',
  '(UTC+09:30) Adelaide',
  '(UTC+09:30) Darwin',
  '(UTC+10:00) Brisbane',
  '(UTC+10:00) Canberra, Melbourne, Sydney',
  '(UTC+10:00) Guam, Port Moresby',
  '(UTC+10:00) Hobart',
  '(UTC+10:00) Vladivostok',
  '(UTC+10:30) Lord Howe Island',
  '(UTC+11:00) Bougainville Island',
  '(UTC+11:00) Chokurdakh',
  '(UTC+11:00) Magadan',
  '(UTC+11:00) Norfolk Island',
  '(UTC+11:00) Sakhalin',
  '(UTC+11:00) Solomon Is., New Caledonia',
  '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
  '(UTC+12:00) Auckland, Wellington',
  '(UTC+12:00) Coordinated Universal Time+12',
  '(UTC+12:00) Fiji',
  '(UTC+12:45) Chatham Islands',
  '(UTC+13:00) Coordinated Universal Time+13',
  "(UTC+13:00) Nuku'alofa",
  '(UTC+13:00) Samoa',
  '(UTC+14:00) Kiritimati Island'
];
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.mainHeading = "Users";
exports.access_rights = "Access Rights";
exports.Filters = "Filters";
exports.Favorites = "Favorites";
exports.buttonSave = "Save";
exports.Createusers = "Create Users";
exports.CreateAccessRights = "Create Access Rights";
exports.create_access_rights = "Create access rights";
exports.user_created_msg = "User created successfully.";
exports.company_created_msg = "Company created successfully."
exports.Groupby = "Group by";
exports.name = "NAME";
exports.sales = "SALES PERSON";
exports.phone = "PHONE";
exports.email = "EMAIL";
exports.city = "CITY";
exports.country = "COUNTRY";
exports.company = "COMPANY";
exports.addField= "+ ADD CUSTOM FIELD";
exports.itemsPage = "Items per page: ";
exports.pageOne = "01";
exports.pageTwo = "02";
exports.pageThree = "03";
exports.pageFour ="04";
exports.pageFive = "05";
exports.pageSix = "06";
exports.pageSeven = "07";
exports.pageEight = "08";
exports.pageNine = "09";
exports.pageTen = "10";
exports.pageTwenty = "20";
exports.pagination = "1-5 of 20";
exports.services = "Consulting services";
exports.appliances = "Electronics appliances vendor";

exports.btnExampleTitle = "CLICK ME";

exports.settings = "Settings";
exports.users = "Users";
exports.new = "New";
exports.name = "Name";
exports.email_address = "Email Address";
exports.access_rights = "Access Rights";
exports.preferences = "Preferences";

exports.archive = "Archive";
exports.duplicate = "Duplicate";
exports.delete = "Delete";
exports.changePassword = "Change Password";
exports.relatedPartner = "Related Partner";
exports.multiCompanies = "Multi Companies";
exports.defaultCompany = "Default Company";
exports.userTypes = "User Types";
exports.ecommerceUser = "E-commerce User";
exports.internalUser = "Internal User";
exports.localization = "Localization";
exports.language = "Language";
exports.timezone = "Timezone";
exports.notification = "Notification";
exports.handle_by_emails = "Handle By Emails";
exports.handle_by_customer = "Handle By Customers";
exports.discard = "Discard";
exports.save = "Save";
exports.userlogin = "User login";
exports.allowedCompanies = "Allowed Companies";
exports.sales = "Sales";
exports.purchase = "Purchase";
exports.accounting = "Accounting";
exports.humanResources = "Human Resources";
exports.settings = "Settings";
exports.pointOfSale = "Point Of Sale";
exports.inventory = "Inventory";
exports.contacts = "Contacts";
exports.sign = "Sign";
exports.approval = "Approval";
exports.tag = "Tag";
exports.getUserlistAPiEndPoint = "";
exports.global = "Global";
exports.full_access = "Full Access";
exports.read_access = "Read Access";
exports.write_access = "Write Access";
exports.archived = "Archived";
exports.group = "Group";
exports.model = "Model";

exports.errorTitle1 = "Error";
exports.errorTitle = "Error";
exports.something_went_wrong = "Something went wrong !! Try again.."
exports.emailErrorTitle = "Email Error";
exports.errorEmailNotValid = "Email not valid.";
exports.errorMobileLengthNotValid = "Minimum length is 10.";
exports.errorAllFieldsAreMandatory = "All * fields are mandatory.";
exports.success = "Success";
exports.access_right_created_msg = "Access Right created successfully.";
exports.Createposproductcatgeory = "Create POS Product Category";

exports.unit_of_measure = "Unit of Measure";
exports.measure_text = "Sell and purchase products in different units of measure";
exports.pricelist_name = "Pricelist Name";
exports.pricelists = "Pricelists";
exports.pricelist = "Pricelist";
exports.pricelists_text = "Set multiple prices per product, automated discounts, etc.";
exports.multiple_prices_per_product = "Mutiple prices per product";
exports.advanced_price_rules = "Advanced price rules (discounts, formulas)";
exports.coupons_and_promotions = "Coupons & Promotions";
exports.manage_programs = "Manage promotion & coupon programs";
exports.coupons_programs = "Coupon Programs";
exports.promotion_programs = "Promotion Programs";
exports.configuration = "Configuration"
exports.shipping_methods = "Shipping Method"
exports.pricing = "Pricing"
exports.destination_availability = "Destination Availability"
exports.description = "Description"
exports.websiteValidation = /^(https?:\/\/)?(www\.)?[a-z\d]+([-.][a-z\d]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/i

exports.companies = "Companies"
exports.createCompanies = "Create Companies"
exports.parentCompany = "Parent Company"
exports.saveCurrentSearch = "Save Current Search"
exports.importRecords = "Import Records"
exports.addToDashboard = "Add to my Dashboard"
exports.useByDefault = "Use by default"
exports.shareWithAllUsers = "Share with all users"
exports.previewInternalReport = "Preview Internal Report"
exports.previewExternalReport = "Preview External Report"
exports.reportLayoutPreview = "Report Layout Preview"
exports.export = "Export"
exports.companyName= "COMPANY NAME"
exports.partner = "PARTNER"
exports.email = "EMAIL"
exports.phone = "PHONE"

// Customizable Area End