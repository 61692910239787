// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Chip, ClickAwayListener, FormControl, Grid, List, ListItem, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import UomCategorieslistviewController, { Props } from './UomCategorieslistviewController.web'

import '../../../settings2/assets/style.css'
export const configJSON = require("../../../settings2/src/config");
import { add, backArrowEnable, backarrow, filter, filtericon, forwardArrowDisable, forwardarrow, moveBackwardArrowEnable, moveForwardArrowDisable, movebackward, moveforward, search, star } from '../../../settings2/src/assets';
import { AppHeader } from '../../../../components/src/AppHeader.web';
import Sidebar from '../../../../components/src/Sidebar.web';
import { groupWhite } from '../../../../components/src/assets';
import { settingsGrey } from '../../../itemavailability/src/assets';
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class UomCategorieslistview extends UomCategorieslistviewController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { showFilter, showSettingsUOMcat } = this.state;
        const { classes }: any = this.props;
        const searchPlaceholder = this.searchPlaceholderFnUOMcat() as string;

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.uomlistviewMainLayout}>
                <Box className={classes.uomlistviewsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.uomlistviewcontent}>
                    {" "}
                    <AppHeader pageTitle="Configuration" />
                    <Box className={classes.mainBox} style={{backgroundColor:"#ffffff"}}>
                        <Box>
                            <Box className={classes.uomlistviewBlock}>
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Typography className={classes.uomlistviewHeading}>UoM Categories</Typography>
                                    </Grid>
                                    <Grid item sm={6} className={classes.uomlistviewSearchbox}>
                                        <TextField
                                            className='search-box-width'
                                            id='user-list-search-input'
                                            variant="outlined"
                                            placeholder={`${searchPlaceholder}`}
                                            data-testid="listSearchinput"
                                            value={this.state.searchKeyword}
                                            onChange={(e:any) => this.setState({...this.state,searchKeyword:e.target.value})}
                                        />
                                        <img src={search} className={`${classes.uomlistviewImg} ${this.searchIconDisplayFnUOMcat()}`} />
                                        
                                        <Box className={`${this.checkGroupStatus(this.state.searchArray.length)}`} sx={{position:"absolute",left:"0px",top:"12px"}}>
                                            <Box sx={{display:"flex"}}>
                                            {
                                                this.state.searchArray.map((data:any,indexx:number)=>{
                                                    return <Box style={{position:"relative",marginLeft:"10px"}}>
                                                    <Chip style={{backgroundColor:"#006395",color:"#ffffff",padding:"0 25px"}} label={this.chipLabelUOMcat(data.value)} />
                                                    <span className={data.type=='filter'?'handleFilterIcon':'handleGroupIcon'} ><img src={data.type=='filter'?filtericon:groupWhite} /></span>
                                                    <span data-testid={`handleRemoves${indexx}`} className='handleRemoves' onClick={()=>data.type=='filter'?this.removeActiveFilterUOMcat(data.value):this.handleActiveGroupUOMcat('')} >×</span>
                                                    </Box>
                                                })
                                            }
                                            </Box>
                                                
                                                
                                        </Box>
                                        
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.uomlistviewFilterbox} >
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Box className={classes.uomlistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Filters" onClick={this.handleFilter}
                                            ><img src={filter} className={classes.uomlistviewFilterImg} />{configJSON.Filters}</Button>
                                            
                                            {
                                                showFilter &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleFilter}
                                                    data-test-id="clickHandleFilter"
                                                >
                                                <Box className={`filterBox ${classes.showfilterBox}`}>
                                                    <List>
                                                        <ListItem button className={`font-14 ${this.selectedListItem(0)}`} data-testid="filter-archive" 
                                                        onClick={()=>this.handleActiveFilterUOMcat(0)}>
                                                            <ListItemText primary="Archive" />
                                                        </ListItem>
                                                        
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                            }
                                            
                                        </Box>
                                        
                                        <Box className={classes.uomlistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Favorites" onClick={this.handleFavorites} ><img src={star} className={classes.uomlistviewFilterImg} />{configJSON.Favorites}</Button>
                                            
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} className={classes.uomlistviewCreatebox} >
                                        <Box className={classes.uomlistviewColumnbox}>
                                            <Button className={`filters-left ${classes.settingsBtn} ${this.checkGroupStatus(this.state.checkedArr.length)}`} data-testid="list-settings" onClick={this.handleSettings}><img src={settingsGrey} /></Button>
                                            
                                            {
                                                showSettingsUOMcat &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleSettings}
                                                    data-test-id="clickHandleSettingsUOMcat"
                                                >
                                                <Box className={`settingsBox ${classes.settingsBox}`}>
                                                    <List>
                                                        <ListItem className='listItem font-14' button>
                                                            <ListItemText primary="Export" />
                                                        </ListItem>
                                                        <ListItem className='listItem font-14' button>
                                                            <ListItemText primary="Delete" />
                                                        </ListItem>
                                                        <ListItem className='font-14' button>
                                                            <ListItemText primary="Archive" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                        </Box>
                                        
                                        <Button className='filters-right' data-testid="Createcustomer" onClick={this.navigatetoCreateUOMcategories}>Create UoM Categories&nbsp;&nbsp;<img src={add} /></Button>
                                    </Grid>
                                </Grid>
                                <Box className={`${this.loaderBoxFnUOMcat()}`}>
                                {
                                this.loaderBoxContentFnUOMcat()
                                }
                                </Box>
                                <Box className={`${this.listViewFnUOMcat()}`}>
                                <Box className={classes.uomlistviewColumnbox} >
                                    
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.uomlistviewCheck}><Checkbox 
                                                    checked={this.state.globalCheck}
                                                    onChange={()=>this.handleGlobalCheckUOM()} 
                                                    data-test-id="globalCheckboxUOM"
                                                    /></TableCell>
                                                    <TableCell className={`${classes.uomlistviewTableheading} ${classes.tableheading2}`}>UNIT OF MEASURE CATEGORIES</TableCell>
                                                    <TableCell className={`${classes.uomlistviewTableheading} ${classes.uomTc}`}>UoM</TableCell>
                                                    
                                                </TableRow>
                                            </TableHead>
                                            {(!this.state.activeGroup) && this.state.showUOMcatdata?.map((user:any) => {
                                                return (
                                                    <>
                                                    <TableBody>
                                                    {
                                                        this.expandTableRow(user,classes)
                                                    }
                                                    </TableBody>
                                                    
                                                    </>  
                                                 )
                                            })} 
                                            
                                            
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box className={`${classes.uomlistviewItems} ${this.checkActiveGroupStatus()}`}>
                                    <Grid container spacing={0} >
                                        <Grid item sm={8} className={classes.uomlistviewItemsgrid}>
                                            <Typography className={classes.uomlistviewItemsText}>{configJSON.itemsPage}</Typography>
                                            <FormControl id="select-form-control">

                                                <Select
                                                    IconComponent={this.expandMore}
                                                    inputProps={{
                                                        'aria-label': 'custom select',
                                                    }}
                                                    data-testid="select_page"
                                                    id="item-select"
                                                    value={this.state.per_page}
                                                    onChange={(e:any)=>this.handlePaginationUOMcat(e.target.value)}
                                                    label="Select"
                                                    disableUnderline
                                                    className='bold-text'
                                                >
                                                    <MenuItem className='uom-menu-item' value={9}>09</MenuItem>
                                                    <MenuItem className='uom-menu-item' value={27}>27</MenuItem>
                                                    <MenuItem className='uom-menu-item' value={54}>54</MenuItem>
                                                    <MenuItem className='uom-menu-item' value={99}>99</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={4} className='end-center-align'>
                                            <Typography className={classes.uomlistviewText} >
                                                <span className='bold-text'>
                                                {(this.state.page - 1)*this.state.per_page+1}
                                                &nbsp;-&nbsp;
                                                {(this.state.page - 1)*this.state.per_page +
                                                    this.state.showUOMcatdata.length}
                                                </span>
                                                <span className={classes.bottompage}>
                                                &nbsp;of &nbsp;
                                                {this.state.totalCounts}
                                                </span>
                                                    
                                            </Typography>
                                            <Button data-testid="listviewBackwardicon" disabled={this.state.page==1} onClick={()=>this.loadMoreRecordsUOMcat(0,'')} className='tb-footer-btn'><img src={this.state.page==1 ? movebackward : moveBackwardArrowEnable} /></Button>
                                            <Button data-testid="listviewBackArrow" disabled={this.state.page==1} onClick={()=>this.state.page>1 && this.loadMoreRecordsUOMcat(-1,'')} className='tb-footer-btn'><img src={this.state.page==1 ? backarrow : backArrowEnable} /></Button>
                                            <Button data-testid="listviewForwardArrow" disabled={this.isThisLastPageUOMcat()} onClick={()=>this.loadMoreRecordsUOMcat(+1,'')} className='tb-footer-btn'><img src={this.isThisLastPageUOMcat()? forwardArrowDisable:forwardarrow} /></Button>
                                            <Button data-testid="listviewForwardIcon" disabled={this.isThisLastPageUOMcat()} onClick={()=>this.loadMoreRecordsUOMcat(this.state.totalPage-this.state.page,'')} id='next-end'><img src={this.isThisLastPageUOMcat()? moveForwardArrowDisable:moveforward} /></Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                </Box>
                                
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const userlistwebStyle: any = (theme: any) => ({
    uomlistviewMainLayout: {
        display: "flex",
    },
    mainBox:{
        border:"1px solid #e3e3e3",
        borderRadius:"5px",
        marginBottom:"10px"
    },
    uomlistviewsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    uomlistviewcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    uomlistviewBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    uomlistviewHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    uomlistviewImg: {
        position: "absolute",
        left: "15px",
        top: "19px",
        height: "20px"
    },
    uomlistviewFilterbox: {
        
    },
    uomlistviewFilterImg: {
        paddingRight: "8px"
    },
    uomlistviewColumnbox: {
        position: "relative"
    },
    uomlistviewColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    uomlistviewCheck: {
        width:"92px"
    },
    uomlistviewTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    uomlistviewTabletext: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    uomlistviewItems: {
        padding: "5px 15px"
    },
    uomlistviewCreatebox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px"
    },
    uomlistviewSearchbox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative"
    },
    uomlistviewItemsgrid: {
        display: "flex !important",
        justifyContent: "left",
        paddingTop:"8px"
    },
    uomlistviewItemsText: {
        paddingRight: "10px",
        fontSize: "18px",
        marginTop: "15px",
        color:"#47464a"
    },
    uomlistviewText: {
        marginRight: "40px"
    },
    bottompage: {
        color:"#47464a"
    },
    displayNone: {
        display:"none !important"
    },
    showfilterBox:{
        width:"120px"
    },
    uomTc:{marginLeft:"5px"},
    uomChip:{
        marginLeft:"5px",
        backgroundColor: "#fff !important",
        color: "#006395 !important",
        border: "3px solid #c3c3c3 !important"
    },
    uomChip2:{
        marginLeft:"5px",
        backgroundColor:"#f3f3f3 !important",
        color:"black !important"
    },
    tableheading2:{
        maxWidth:"80px"
    },
    settingsBtn:{
        padding: "10px 0!important",
        minWidth: "45px!important"
    },
    settingsBox:{
        top:"55px !important",
        borderRadius: "5px !important"
    }
})
export const UomCategorieslistviewStyles = withStyles(userlistwebStyle)(UomCategorieslistview)
  // Customizable Area End
