// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Grid, Radio, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import CfcustomersalesconfigurationController, { Props } from './CfcustomersalesconfigurationController.web'
import '../assets/style.css'
import { rightArrow, search } from './assets';
import Sidebar from '../../../components/src/Sidebar.web';
import { configJSON } from './Cfcustomersalesreports3Controller';
import { AppHeader } from '../../../components/src/AppHeader.web';




// Customizable Area End



export default class Cfcustomersalesconfiguration extends CfcustomersalesconfigurationController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { value } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <Box className="gridMainLayout">
            <Box className="gridsideBar">
            <Sidebar />
            </Box>
            
            <Box className="gridcontent" >
            <AppHeader pageTitle="Configuration" />
            
            
            <Box className='sales-config-parent-div'>
                <Box className='sales-config-title-div'>
                <Grid container spacing={0} >
                <Grid item sm={6}  className='left-center-align'>
                <Typography 
                className='sales-config-title'
                >
                    {configJSON.settings}
                </Typography>
                </Grid>
                <Grid item sm={6} className='end-center-align relative' >
                
                </Grid>
                </Grid>
                </Box>
                <Box className='sales-config-tab-div'>
                
                <Box style={{background:"#ffffff"}}>
                <div>
                <Tabs value={value} onChange={this.handleTabChange}>
                <Tab label={configJSON.product_catalog} />
                <Tab label={configJSON.pricing} />
                <Tab label={configJSON.shipping} />
                <Tab label={configJSON.invoicing} />
                
                </Tabs>
                <this.TabPanel value={value} index={0}>
                
                <Box className='sales-config-tab-0 pt-20'>
                <Box className='sales-config-measure-checkbox'>
                <Checkbox data-test-id="unitOfMeasureInput" onChange={()=>this.setState({...this.state,userSetting:{...this.state.userSetting,unit_of_measure:!this.state.userSetting?.unit_of_measure}})} checked={this.state.userSetting?.unit_of_measure} />
                </Box>
                <Box>
                <Typography className='sales-config-heading-color'>{configJSON.unit_of_measure}</Typography>
                <Typography className='sales-config-heading-font measure_txt'>{configJSON.measure_text}</Typography>
                <Box className='flex'>
                <img src={rightArrow} className='mr-10'/>
                <Typography className="grid2-arrow-text" >{configJSON.unit_of_measure}</Typography>
                </Box>
                
                </Box>
                </Box>
        
                </this.TabPanel>
                <this.TabPanel value={value} index={1}>
                <Box>
                <Grid className='pt-20' container spacing={0} >
                <Grid item sm={7}>
                <Box>
                    <Typography className='pricelist-input-text'>{configJSON.pricelist_name}<span className='required'>*</span></Typography>
                    <TextField data-test-id="pricelistInput" 
                    style={{width:"85%"}}
                    className="form-input input-field" 
                    value={this.state.userSetting?.pricelist_name}
                    onChange={(e:any) => this.setState({...this.state,userSetting:{...this.state.userSetting,pricelist_name:e.target.value}})} 
                    name="pname" type="text" />
                </Box>
                <Box className='sales-config-pricelist'>
                <Box className='sales-config-pricelist-checkbox' >
                <Checkbox data-test-id="checkbox3"
                onChange={()=>this.setState({...this.state,userSetting:{...this.state.userSetting,price_list:!this.state.userSetting?.price_list}})} 
                checked={this.state.userSetting?.price_list} />
                </Box>
                <Box className={`${!this.state.userSetting?.price_list && 'pointer-events-none'}`}>
                <Typography className='sales-config-heading-color'>{configJSON.pricelists}</Typography>
                <Typography className='sales-config-heading-font measure_txt'>{configJSON.pricelists_text}</Typography>
                <Box className='left-center-align'>
                    <Radio data-test-id="radiobtn1"
                        className='individualRadio'
                        checked={this.state.userSetting?.price_list_setting === 'a'}
                        onChange={()=>this.setState({...this.state,userSetting:{...this.state.userSetting,price_list_setting:'a'}})}
                        value="a"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <Typography className={`${this.state.userSetting?.price_list_setting === 'a' ? 'radio-selected-text' : 'radio-not-selected-text'}`}>{configJSON.multiple_prices_per_product}</Typography>
                    </Box>
                    <Box className='left-center-align' style={{marginLeft:"-10px"}}>
                    <Radio data-test-id="radiobtn2"
                        value="b"
                        checked={this.state.userSetting?.price_list_setting === 'b'}
                        onChange={()=>this.setState({...this.state,userSetting:{...this.state.userSetting,price_list_setting:'b'}})}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }}
                    />
                    <Typography className={`${this.state.userSetting?.price_list_setting === 'b' ? 'radio-selected-text' : 'radio-not-selected-text'}`}>{configJSON.advanced_price_rules}</Typography>
                    </Box>
                <Box style={{display:"flex"}}>
                <img src={rightArrow} className='mr-10'/>
                <Typography className="grid2-arrow-text" >{configJSON.pricelist}</Typography>
                </Box>
                
                </Box>
                </Box>
                </Grid>
                <Grid item sm={5} className='no-center-align' >
                <Box className='pricelist-grid2-parent-div'>
                <Box className='pricelist-grid2-checkbox'>
                <Checkbox data-test-id="checkbox2" checked={this.state.userSetting?.coupons_and_promotions} onChange={()=>this.setState({...this.state,userSetting:{...this.state.userSetting,coupons_and_promotions:!this.state.userSetting?.coupons_and_promotions}})} />
                </Box>
                <Box>
                <Typography className='sales-config-heading-color'>{configJSON.coupons_and_promotions}</Typography>
                <Typography className='sales-config-heading-font measure_txt'>{configJSON.manage_programs}</Typography>
                
                <Box className='grid2-arrow-div'>
                <img src={rightArrow} className='mr-10'/>
                <Typography className="grid2-arrow-text" >{configJSON.promotion_programs}</Typography>
                </Box>
                <Box className='grid2-arrow-div'>
                <img src={rightArrow} className='mr-10'/>
                <Typography className="grid2-arrow-text" >{configJSON.coupons_programs}</Typography>
                </Box>
                
                </Box>
                </Box>
                </Grid>
                </Grid>
                </Box>
                </this.TabPanel>
                <this.TabPanel value={value} index={2}>
                Content 3
                </this.TabPanel>
                <this.TabPanel value={value} index={3}>
                Content 4
                </this.TabPanel>
                
                </div>

                </Box>
                </Box>
                
                <div>
        
        
      </div>
                
            </Box>
            <Box className='end-center-align' style={{margin:"20px 0"}}>
                <Button data-test-id="discard-btn" variant="outlined" onClick={this.handleDiscard} className="sales-config-discard-btn">{configJSON.discard}</Button>
                <Button data-test-id="save-next" onClick={this.handleSetting} variant="contained" style={{backgroundColor:"#026494",color:"#ffffff"}}>{configJSON.save}</Button>
                
                </Box>
            </Box>
            </Box>
            </>
            // Customizable Area End
        )

    }
}