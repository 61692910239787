export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const sales = require("../assets/sales.svg");
export const meetings = require("../assets/calendar.svg");
export const invoice = require("../assets/invoice.svg");
export const activity = require("../assets/activity.svg");
export const star = require("../assets/star.svg");
export const dropdown = require("../assets/dropdown.svg");
export const downarrow = require("../assets/down-arrow.svg");
export const settings = require("../assets/settings.svg");
export const add = require("../assets/add.svg");
export const groupby = require("../assets/groupby.svg");
export const dashboard = require("../assets/dashboard.svg");
export const filter = require("../assets/filter.svg");
export const list = require("../assets/list.svg");
export const listBlack = require("../assets/list view copy.svg");
export const search = require("../assets/search.svg");
export const more = require("../assets/more.svg");
export const purchase = require("../assets/purchase.svg");
export const vendorbills = require("../assets/vendorbills.svg");
export const upload = require("../assets/upload.png");
export const defaultImg = require("../assets/add-photo.svg");
export const addrow = require("../assets/add-row.svg");
export const delrow = require("../assets/del-row.svg");
export const external = require("../assets/external-link.svg");
export const close = require("../assets/close.svg");
export const img = require("../assets/image_Bitmap.png");
export const rightArrow = require("../assets/right-arrow.svg");
export const backwardIconDisable = require("../assets/move_backward.svg");
export const backwardArrowDisable = require("../assets/backArrowDisable.svg");
export const backwardIconEnable = require("../assets/moveBackwardEnable.svg");
export const backwardArrowEnable = require("../assets/backArrowEnable.svg");
export const forwardArrowDisable = require("../assets/forwardArrowDisable.svg");
export const forwardArrowEnable  = require("../assets/forward_arrow.svg");
export const forwardIconDisable = require("../assets/moveForwardDisable.svg");
export const forwardIconEnable  = require("../assets/move_forward.svg");
export const whiteGridicon = require("../assets/dashboard Copy@2x.png");
export const whitefiltericon = require("../assets/filterwhite.svg");
export const whitegroupbyicon = require("../assets/groupwhite.svg");


export const backwardIcon = require("../assets/move_backward.svg");
export const forwardArrow = require("../assets/forward_arrow.svg");
export const forwardIcon = require("../assets/move_forward.svg");
export const validationerror = require("../assets/validationerror.svg");

