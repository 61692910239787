import React from "react";
// Customizable Area Start
import {
  Container,
  Chip,
  TextField
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { searchGrey, groupWhite, filterWhite } from "./assets"
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.root as React.CSSProperties}>
          {/* Customizable Area Start */}
          <style>
            {`
              .MuiOutlinedInput-root-63 {
                align-items: flex-start;
              }
              .searchField{
                width: 478px
              }
            `}
          </style>
          {!this.props.queryItems.length && <img src={searchGrey} style={webStyle.searchIcon as React.CSSProperties} />}
          <TextField
            variant="outlined"
            data-test-id="searhBar"
            placeholder={!this.props.queryItems.length ? "Search here..." : ""}
            fullWidth
            className="searchField"
            disabled={this.props.queryItems.length ? true : false}
            value={this.props.querySearch}
            onChange={(e) => this.props.onChangeSearchField(e)}
            inputProps={{
              style: {
                paddingLeft: 10
              }
            }}
            InputProps={{
              startAdornment: (
                <div style={{ ...webStyle.chipsContainer as React.CSSProperties, minWidth: this.props.queryItems.length ? "100%" : '' }}>
                  {this.props.queryItems.map((item: { id: string; tag: string; key: string; type: string; }) => (
                    <Chip
                      key={item.key}
                      data-test-id="filterChip"
                      className="MuiChip-root"
                      label={item.tag}
                      onDelete={() => this.props.handleRemoveTag(item)}
                      variant="default"
                      style={webStyle.tagChip}
                      deleteIcon={<ClearIcon style={webStyle.icon} />}
                      icon={item.type === "groupby" ? <img src={groupWhite} style={webStyle.chipIcon} /> : <img src={filterWhite} style={webStyle.chipIcon} />}
                      />
                  ))}
                </div>
              ),
            }}
          />
          {/* Customizable End Start */}
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: 500,
    "& > * + *": {
    },
    position: "relative"
  },
  icon: {
    color: "#fff",
    width: "15px",
    "& :hover": {
      color: "#fff",
    },
  },
  chipIcon: {
    marginRight: "0.5px",
    color: "white",
    paddingLeft: "8px",
    width: "15px",
  },
  searchIcon: {
    position: "absolute",
    top: "20px",
    left: "35px",
  },
  tagChip: {
    background: "#2A6395 !important",
    color: "#FFF !important",
    borderColor: "#FFF !important",
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gridGap: "8px",
    padding: "8px",
    alignSelf:"center"
  }
}

// Customizable Area End
