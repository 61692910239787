import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  ClickAwayListener,
  Popper,
  MenuList,
  Card,
  MenuItem,
  CardContent,
  Divider,
  withStyles
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { AppHeader } from "../../../components/src/AppHeader.web";
import { createTheme, ThemeProvider, styled ,Theme} from "@material-ui/core/styles";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import Sidebar from "../../../components/src/Sidebar.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DoneIcon from '@material-ui/icons/Done';
import { Styles } from "@material-ui/core/styles/withStyles";
const Images = require("./assets");

const ButtonComponent = styled("button")({
  cursor: "pointer",
  color: "black",
  backgroundColor: "#F3F3F3",
  fontSize: "12px",
  alignItems: "center",
  border: "none",
  display: "flex",
  borderRadius: "4px",
  padding: "8px 15px",
  marginX: "10px",
  height: "100%",
});

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import PosOrderListingController, {
  Props,
  configJSON,
} from "./PosOrderListingController";

export default class PosOrderListing extends PosOrderListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box 
        className={this.props.classes.POSLayout}>
          <Box 
          className={this.props.classes.sideBar}>
            <Sidebar />
            <Box className={this.props.classes.bodyContent}>
              <AppHeader pageTitle={configJSON.posOrderHeading} />
              <Card className={this.props.classes.mainContainer}>
                <CardContent 
                className={this.props.classes.listCard}>
                <style>
                  {`
                    .MuiContainer-root-50 {
                      padding-right: 0 !important;
                    }
                    .MuiPaper-root.MuiMenu-paper.MuiPopover-paper > ul > li {
                      border-bottom:0px !important;
                    }
                  `}
                </style>
                  <Box 
                  className={this.props.classes.listHeading}>
                    <Typography variant="h6"
                    className={this.props.classes.topbarTitle}>
                      {configJSON.posListOrderHeading}</Typography>
                    <Box>
                      <AdvancedSearch
                        classes={{}}
                        navigation={undefined}
                        id={""}
                        querySearch={this.state.query}
                        onChangeSearchField={this.onChangeSearchField}
                        queryItems={this.state.queryItems}
                        handleRemoveTag={this.handleRemoveTag}
                        data-test-id="advanceSearch"
                      />
                    </Box>
                  </Box>
                  <Divider 
                  className={this.props.classes.divider} />
                  <Box 
                  className={this.props.classes.listMainContainer}>
                    <Box 
                    className={this.props.classes.tableOption}
                      >
                      <ClickAwayListener
                        onClickAway={this.handleFilterClickAway}
                        data-test-id="clickAway"
                      >
                        <div>
                          <Button
                            onClick={this.handleClickFilter}
                            className={this.props.classes.buttonComponent}
                            data-test-id="popperClick"
                          >
                            <img
                              src={Images.filter} /> &nbsp;
                            Filters
                          </Button>
                          <Popper
                            transition
                            open={Boolean(this.state.anchorElFilter)}
                            anchorEl={this.state.anchorElFilter}
                            className={this.props.classes.popper}
                            placement="bottom-start"
                          >
                            <MenuList style={{ padding: 0 }}>
                              {this.filterOptions.map((item) => (
                                <div className={this.props.classes.popperBlock}>
                                  <MenuItem
                                    className={this.props.classes.popperItem}
                                    key={item.name}
                                    data-value={item.name}
                                    data-test-id="MenuPopper"
                                    onClick={() => { this.handleFilterSelect(item); }}
                                  >
                                    <Typography className={this.getClassName(this.state.queryItems.some((element) => element.tag === item.name), this.props.classes.selectedQueryItem , this.props.classes.unselectedQueryItem)}>
                                        {item.name}
                                      </Typography>
                                    </MenuItem>
                                </div>
                              ))}
                              <MenuItem
                                className={this.props.classes.popperItem}
                                data-test-id="orderDateFilter"
                                onClick={(event: React.MouseEvent<HTMLLIElement>) => this.handleClickFilterOrder(event)}
                              >
                                <Typography className={this.getClassName(this.state.filterOrderDateAnchor !==null , this.props.classes.selectedQueryItem , this.props.classes.unselectedQueryItem)}>
                                  Order Date
                                </Typography>
                                <ArrowDropDownIcon style={this.getStyle(this.state.filterOrderDateAnchor !==null , {color:"#2A6395"} ,{color: "#000" })}/>
                              </MenuItem>
                              <Popper
                                transition
                                open={Boolean(this.state.filterOrderDateAnchor)}
                                anchorEl={this.state.filterOrderDateAnchor}
                                className={this.props.classes.popper}
                                placement="right-start"
                              >
                                <MenuList style={{ padding: 0 }}>
                                {this.state.isQuarterSelected && this.state.filterMonthOption.map((item) => (
                                    <div className={this.props.classes.popperBlock}>
                                      <MenuItem
                                        className={this.props.classes.popperItem}
                                        key={item}
                                        data-value={item}
                                        data-test-id="monthSelected"
                                        onClick={() => { this.handleSelectedMonth(item); }}
                                      >{item}
                                      { this.monthCheck(this.state.filterSelectedMonth,item) && <DoneIcon style={{color:"#2A6395"}}/>}
                                      </MenuItem>
                                    </div>
                                  ))}
                                  {this.state.isYearSelected && this.filterQuarterOptions.map((item) => (
                                    <div className={this.props.classes.popperBlock}>
                                      <MenuItem
                                        className={this.props.classes.popperItem}
                                        key={item.name}
                                        data-value={item.name}
                                        data-test-id="quarterSelection"
                                        onClick={() => { this.handleSelectedQuarter(item); }}
                                      >{item.name}
                                          {this.renderCheck(this.state.filterSelectedQuarter === item.selKey,<DoneIcon style={{color:"#2A6395"}}/>)}
                                      </MenuItem>
                                    </div>
                                  ))}
                                  {this.filterOrderYearOptions.map((item) => (
                                    <div className={this.props.classes.popperBlock} >
                                      <MenuItem
                                        className={this.props.classes.popperItem}
                                        key={item}
                                        data-value={item}
                                        data-test-id="yearSelection"
                                        onClick={() => { this.handleSelectedYear(item); }}
                                      >{item}
                                        {this.renderCheck(this.state.filterSelectedYear === item,<DoneIcon style={{color:"#2A6395"}}/>)}
                                      </MenuItem>
                                    </div>
                                  ))}</MenuList>
                              </Popper>
                            </MenuList>
                          </Popper>
                        </div>
                      </ClickAwayListener>
                      <ClickAwayListener
                        onClickAway={this.handleGroupClickAway} data-test-id="clickAway">
                        <div>
                          <Button
                            onClick={this.handleClickGroupBy}
                            data-test-id="popperClick"
                            className={this.props.classes.buttonComponent}
                          >
                            <img src={Images.multipleFile} /> &nbsp; Group by
                          </Button>
                          <Popper
                            anchorEl={this.state.anchorElGroupBy}
                            transition
                            open={Boolean(this.state.anchorElGroupBy)}
                            className={this.props.classes.popper}
                            placement="bottom-start"
                          >

                            <MenuList style={{ padding: 0 }}>
                              {this.groupByOptions.map((item) => (
                                <div className={this.props.classes.popperBlock}>
                                  <MenuItem
                                    onClick={() => { this.handleGroupBySelect(item); }}
                                    className={this.props.classes.popperItem}
                                    key={item.name}
                                    data-value={item.name}
                                    data-test-id="MenuPopper-group"
                                  >
                                    <Typography className={this.getClassName(this.state.queryItems.some((element) => element.tag === item.name) , this.props.classes.selectedQueryItem , this.props.classes.unselectedQueryItem)}>
                                      {item.name}
                                    </Typography>
                                    </MenuItem></div>
                              ))}
                              <MenuItem
                                className={this.props.classes.popperItem}
                                data-test-id="order-date-groupby"
                                onClick={(event: React.MouseEvent<HTMLLIElement>) => this.handleClickGroupbyOrder(event)}
                              >
                                <Typography className={this.getClassName(this.state.groupbyOrderDateAnchor !==null || this.state.groupbySelectedQuery !== "" , this.props.classes.selectedQueryItem , this.props.classes.unselectedQueryItem)}>
                                  Order Date
                                </Typography>
                                <ArrowDropDownIcon style={ this.getStyle(this.state.groupbyOrderDateAnchor !==null || this.state.groupbySelectedQuery !== ""  , {color:"#2A6395"} ,{color: "#000" })}/>
                              </MenuItem>
                              <Popper
                                transition
                                open={Boolean(this.state.groupbyOrderDateAnchor)}
                                anchorEl={this.state.groupbyOrderDateAnchor}
                                className={this.props.classes.popper}
                                placement="right-start"
                              >
                                <MenuList style={{ padding: 0 }}>
                                  {this.groupbyFormatOptions.map((item) => (
                                    <div className={this.props.classes.popperBlock}>
                                      <MenuItem
                                        className={this.props.classes.popperItem}
                                        key={item.name}
                                        data-value={item.name}
                                        data-test-id="order-date-groupby-format"
                                        onClick={() => { this.handleGroupbyOrderDate(item); }}
                                      >
                                        <Typography className={this.getClassName(this.state.groupbySelectedQuery === item.selKey , this.props.classes.selectedQueryItem , this.props.classes.unselectedQueryItem)} >
                                           {item.name}
                                        </Typography>
                                        {this.renderCheck(this.state.groupbySelectedQuery === item.selKey,<DoneIcon style={{color:"#2A6395"}}/>)}
                                      </MenuItem>
                                    </div>
                                  ))}</MenuList>
                              </Popper>
                            </MenuList>
                          </Popper>
                        </div>
                      </ClickAwayListener>

                      <div>
                        <Button className={this.props.classes.buttonComponent}>
                          <img src={Images.favourite} /> &nbsp; Favorites</Button>
                      </div>
                    </Box>

                    <Box 
                    className={this.props.classes.tableOption}>
                      {this.state.isViewTypeChanged &&

                        <ClickAwayListener 
                          data-test-id="clickAway"
                          onClickAway={this.handleSettingsClickAway} 
                          >
                          <div>
                            <Button
                              onClick={this.handleClickSetting}
                              data-test-id="popperClick"
                              className={(!this.state.isViewTypeChanged && this.state.responseData.length || this.state.isAllChecked || this.state.isGroupby || Object.keys(this.state.selectedItems).length) ? this.props.classes.buttonComponentSettings : this.props.classes.buttonComponentSettingsHidden}
                            ><img src={Images.settings} /></Button>
                            <Popper
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              transition
                              placement="bottom-end"
                              className={this.props.classes.settingsPopper}
                            >

                              <MenuList style={{ padding: 0 }}>
                                {this.SettingOptions.map((item) => (
                                  <div style={{ borderBottom: "1px solid #F3F3F3" }}>
                                    <MenuItem
                                      className={this.props.classes.popperItem}
                                      key={item.name}
                                      data-value={item.name}
                                      data-test-id="closedMenu-1"
                                    >
                                        {item.name}
                                      </MenuItem>
                                  </div>
                                ))}
                              </MenuList>
                            </Popper>
                          </div>
                        </ClickAwayListener>
                      }

                      <ButtonComponent
                        data-test-id="listView"
                        color="primary"
                        onClick={this.handleListViewClick}
                        className={this.getClassName( this.state.isViewTypeChanged , this.props.classes.activeField , "")}
                      >
                        <img
                          alt="listview"
                          style={this.state.isViewTypeChanged
                              ? {}: { filter: "invert(1)" }
                          }
                          src={Images.listView}
                        />
                      </ButtonComponent>
                      <ButtonComponent
                        onClick={this.handleCanbanViewClick}
                        data-test-id="canbanView"
                        className={this.getClassName( this.state.isViewTypeChanged , "" , this.props.classes.activeField )}
                      >
                        <img
                          style={this.state.isViewTypeChanged ? {} : { filter: "invert(1)" }}
                          src={Images.canban}
                          alt="canbanview"
                        />
                      </ButtonComponent>
                    </Box>
                  </Box>
                  {this.isViewActive(
                    this.state.isViewTypeChanged,this.state.responseData
                  )}
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const userlistwebStyle = (theme: Theme) => ({
  POSLayout: {
    display: "flex",
    width: "100%",
  },
  bodyContent: {
    flexGrow: 1,
    width: "calc( 100% - 300px)",
    overflow: "auto",
    backgroundColor: "#f3f3f3",
    padding: "0 20px",
  },
  sideBar: {
    minHeight: "100%",
    width: "100%",
    display: "flex",
    transition: "all 0.3s",
  },
  mainWrapper: {
    margin: "5px 0",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    gap: "10px",
  },
  tableBox: {
    alignItems: "center",
    display: "flex",
  },
  paginationContainer: {
    height: "50px",
    alignItems: "center",
    padding: "15px",
    justifyContent: "space-between",
    display: "flex",
  },
  perPageItem: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  navigationArrows: {
    width: "200px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  cursorPointor: {
    cursor: "pointer" as string,
  },
  paginationNumber: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  activeField: {
    backgroundColor: "#006395",
    color: "#fff",
  },
  tableOption: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "space-between",
    gap: "10px",
    margin: "5px 0",
  },
  listMainContainer: {
    height: "50px",
    alignItems: "center",
    display: "flex",
    padding: "15px",
    justifyContent: "space-between",
    gap: "10px",
    margin: "5px 0",
  },
  paginationButton: {
    height: "100%",
  },
  canbanMainContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
    display: "flex",
    height: "50px",
    margin: "5px 0",
    gap: "10px",
  },
  productDescription: {
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "12px",
    fontWeight:500,
  },
  productTitleHeading:{
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "10px",
    fontWeight:600,
  },
  mainContainer: {
    boxShadow: "none",
    padding: 0,
    marginBottom: "20px",
    minHeight: "750px",
    border: "1px solid #d9d9d9",
  },
  enabledNaviginationButton: {
    fill: "grey",
    color: "grey",
  },
  listHeading: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    alignItems: "flex-start",
  },
  disabledNaviginationButton: {
    color: "lightgrey",
    fill: "lightgrey",
  },
  popperBlock: {
    borderBottom: "2px solid #F3F3F3",
  },
  searchBar: {
    "& .MuiOutlinedInput-root": {
      "& input": {
        border: "none !important",
      },
      height: "100%",
      "& .MuiOutlinedInput-input": {
        border: "none !important",
      },
      width: "500px",
    },
    minWidth: "250px",
    border: "none !important",
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
    backgroundColor: "#ffffff",
    width: "max-content",
    border: "1px solid #ccc",
    padding: 0,
    borderRadius: 4,
    marginTop: "4px",
  },
  topbarTitle: {
    color: "#006395",
    fontWeight: 600,
  },
  buttonComponentSettings: {
    height: "100%",
    padding: "8px 8px",
    fontSize: "12px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#e1e1e1",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "black",
  },
  settingsPopper: {
    backgroundColor: "#ffffff",
    marginTop: "4px",
    padding: 0,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    border: "1px solid #ccc",
    borderRadius: 4,
  },
  printPopper: {
    border: "1px solid #ccc",
    borderRadius: 4,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    padding: 0,
    marginTop: "4px",
    backgroundColor: "#ffffff",
  },
  popperItem: {
    borderBottom: "1px solid #F3F3F3 !important",
    fontSize: "small",
  },
  loader: {
    display: 'flex',
    height: "50vh",
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
  },
  buttonComponent: {
    borderRadius: "4px",
    backgroundColor: "#e1e1e1",
    color: "black",
    display: "flex",
    fontSize: "12px",
    alignItems: "center",
    cursor: "pointer",
    height: "100%",
    border: "none",
    padding: "8px 15px",
  },
  navinationDropdown: {
    height: "40px",
    padding: "0px 5px",
    outline: "1px solid #f3f3f3",
    borderRadius: "4px",
    fontWeight: 600,
  },
  rowText: {
    fontWeight: 600,
  },
  listCard: {
    padding: 0
  },
  paginationText: {
    height: "40px",
    alignItems: "center",
    display: "flex",
  },
  tableRow: {
    borderBottom: "1px solid #E5E5E5",
    cursor: "pointer"
  },
  paginationCountBoxHidden: {
    flexDirection: "row",
    visibility: "hidden",
    display: "flex",
  },
  paginationCountBoxShow: {
    display: "flex",
    flexDirection: "row",
  },
  emptyData: {
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHeading: { 
    textTransform: "uppercase" 
  },
  divider: {
    backgroundColor: "#E3E3E3",
    marginTop: "20px",
    marginBottom: "20px",
  },
  productCard: {
    border: "1px solid #d9d9d9",
  },
  productDescriptionHeading: {
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    overflow: "hidden",
    fontSize:"14px"
  },
  canbanRightBox:{
    display: "flex",
    gap: "17px",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  layoutBox:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height:" 100%",
  },
  greenText:{
    fontWeight:600,
    color:"#6BD8B5",
    whiteSpace: "nowrap",
  },
  moreBtn:{
    minWidth:0
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
  },
  loadMoreContainer: {
    marginBlock: "3%",
    display: "flex",
    justifyContent: "center",
  },
  gridviewLoadmoreButton: {
    backgroundColor: "#f3f3f3",
    color: "#3d3d3d",
    margin: "15px 10px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "500"
  },
  selectedQueryItem:{
    color: "#2A6395", 
    fontSize: "12px", 
    fontWeight: 500 
  },
  unselectedQueryItem:{
    fontSize: "12px",
    fontWeight: 500 
  },
  buttonComponentSettingsHidden:{
    visibility: "hidden"
  }
})

const styles2 = userlistwebStyle as Styles<Theme, Props>

export const PosOrderListingStyles = withStyles(styles2)(PosOrderListing)
// Customizable Area End

