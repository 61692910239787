import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  querySearch:string;
  queryItems: { id: string, tag: string, key: string, type: string }[];
  onChangeSearchField: (e: any) => void;
  handleRemoveTag: (item: {
     id: string;
     tag: string;
     key: string;
     type: string;
   }) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navigatetoSales = () => {
    const salesMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    salesMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleslistview'
    );
    salesMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(salesMessage.id, salesMessage)
  }
  navigatetoPointofsale = () => {
    const PointofsaleMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    PointofsaleMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleskanbanview'
    );
    PointofsaleMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(PointofsaleMessage.id, PointofsaleMessage)
  }
  navigatetoPurchase = () => {
    const PurchaseMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    PurchaseMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleskanbanview'
    );
    PurchaseMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(PurchaseMessage.id, PurchaseMessage)
  }
  navigatetoInventory = () => {
    const InventoryMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    InventoryMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleskanbanview'
    );
    InventoryMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(InventoryMessage.id, InventoryMessage)
  }
  navigatetoAccounting = () => {
    const AccountingMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    AccountingMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleskanbanview'
    );
    AccountingMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(AccountingMessage.id, AccountingMessage)
  }
  navigatetoContacts = () => {
    const ContactsMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    ContactsMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleskanbanview'
    );
    ContactsMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(ContactsMessage.id, ContactsMessage)
  }
  navigatetoHumanresource = () => {
    const ResrouceMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    ResrouceMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleskanbanview'
    );
    ResrouceMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(ResrouceMessage.id, ResrouceMessage)
  }
  navigatetoSign = () => {
    const SigninMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    SigninMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleskanbanview'
    );
    SigninMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(SigninMessage.id, SigninMessage)
  }
  navigatetoCalender= () => {
    const CalenderMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    CalenderMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleskanbanview'
    );
    CalenderMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(CalenderMessage.id, CalenderMessage)
  }
  navigatetoSetting= () => {
    const SettingMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    SettingMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Cfcustomersaleskanbanview'
    );
    SettingMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(SettingMessage.id, SettingMessage)
  }
  // Customizable Area End
}
