import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { validationerror } from "./assets";
import React from 'react';
export type token = string | null;
import { getStorageData } from "../../../framework/src/Utilities";
import { Box, Typography } from '@material-ui/core';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token:token,
  tabValue:number,
  saveClick:boolean,
  programName: string,
  authorizedEmployee:boolean,
  posInterface: {
    productConfigurator: boolean,
    customerNotes:boolean,
    floorsAndTables:boolean,
    internalNotes:boolean,
    categoryPictures:boolean,
    restrictProductCategories:boolean,
    startCategory:boolean,
    largeScrollbars:boolean,
    openProductInfo:boolean,
    limitedPartnersLoading:boolean,
  },
  connectedDevices: {
    iotBox: boolean,
    customerDisplay: boolean,
    orderPrinter: boolean,
    directDevices: boolean,
    barcodeNomenclature: string,
  },
  taxes: {
    fiscalPositionPerOrder: boolean,
    fiscalPosition: boolean,
  },
  pricing:{
    default_pricelist:string,
    advanced_pricelists:boolean,
    tax_excluded_price:boolean,
    tax_included_price:boolean,
    global_discounts:boolean,
    discount_product: string,
    discount_percentage: string,
    gift_card: boolean,
    manual_discounts: boolean,
    loyalty_program:string,
    coupons_and_promotions:boolean,
    price_control:boolean,
  },
  payment: {
    set_maximum_diffrence: boolean,
    tips: boolean,
    payment_methods: string[],
  },
  bill_and_receipt: {
    header_and_footer: boolean,
    automatic_receipt_printing: boolean,
    bill_printing: boolean,
    bill_spitting: boolean,
    invoice_journal: string
  },
  inventory: {
    limited_products_loading: boolean,
    ship_later: boolean,
    operation_type: string
  },
  accounting: {
    sales_journal: string,
  },
  sale: {
    sales_team: string,
    down_payment_product: string
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PosCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPOSMethodAPI: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.RestAPIRequestMessage), 
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token:"",
      tabValue:0,
      saveClick: false,
      programName: "",
      authorizedEmployee:false,
      posInterface: {
        productConfigurator: false,
        customerNotes:false,
        floorsAndTables:false,
        internalNotes:false,
        categoryPictures:false,
        restrictProductCategories:false,
        startCategory:false,
        largeScrollbars:false,
        openProductInfo:false,
        limitedPartnersLoading:false,
      },
      connectedDevices: {
        iotBox: false,
        customerDisplay: false,
        orderPrinter: false,
        directDevices: false,
        barcodeNomenclature: "",
      },
      taxes: {
        fiscalPositionPerOrder: false,
        fiscalPosition: false,
      },
      pricing:{
        default_pricelist:"",
        advanced_pricelists:false,
        tax_excluded_price:false,
        tax_included_price:false,
        global_discounts:false,
        discount_product: "",
        discount_percentage: "",
        gift_card: false,
        manual_discounts: false,
        loyalty_program:"",
        coupons_and_promotions:false,
        price_control:false,
      },
      payment: {
        set_maximum_diffrence: false,
        tips: false,
        payment_methods: []
      },
      bill_and_receipt: {
        header_and_footer: false,
        automatic_receipt_printing: false,
        bill_printing: false,
        bill_spitting: false,
        invoice_journal: ""
      },
      inventory: {
        limited_products_loading: false,
        ship_later: false,
        operation_type: ""
      },
      accounting: {
        sales_journal: "",
      },
      sale: {
        sales_team: "",
        down_payment_product: ""
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
      runEngine.debugLog(
        "API Message Recived", message
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginStyles")
    }

  }

  receiveApi = (
    apiRequestCallId: string,
    responseJson: {
      data: object[];
      meta: { total_pages: number; total_counts: number };
    }
  ) => {
    if (apiRequestCallId === this.createPOSMethodAPI) {
      this.handleGetSingleOrderID();
    }
  };

  handleGetSingleOrderID = async() => {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
  }

  handleCheckboxChangePosInterface = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      posInterface: {
        ...prevState.posInterface,
        [name]: checked,
      }
    }),);
  }

  handleCheckboxChangeConnectedDevices = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      connectedDevices: {
        ...prevState.connectedDevices,
        [name]: checked,
      }
    }));
  }

  handleCheckboxChangeTaxes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      taxes: {
        ...prevState.taxes,
        [name]: checked,
      }
    }),);
  }

  handleCheckboxChangePricing = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      pricing: {
        ...prevState.pricing,
        [name]: checked,
      }
    }),);
  }

  handleCheckboxChangePayments = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      payment: {
        ...prevState.payment,
        [name]: checked,
      }
    }));
  }

  handleCheckboxChangeBills = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      bill_and_receipt: {
        ...prevState.bill_and_receipt,
        [name]: checked,
      }
    }));
  }

  handleCheckboxChangeInventory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      inventory: {
        ...prevState.inventory,
        [name]: checked,
      }
    }));
  }

  handleSelectChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      connectedDevices: {
        ...prevState.connectedDevices,
        [(name as string)]: value
      }
    }));
  }
  
  handleSelectChangePricing = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      pricing: {
        ...prevState.pricing,
        [(name as string)]: value
      }
    }));
  }

  handleSelectChangeBills = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      bill_and_receipt: {
        ...prevState.bill_and_receipt,
        [(name as string)]: value
      }
    }));
  }
        
  handleSelectChangeAccounting = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      accounting: {
        ...prevState.accounting,
        [(name as string)]: value
      }
    }));
  }

  handleSelectChangeInventory = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      inventory: {
        ...prevState.inventory,
        [(name as string)]: value
      }
    }));
  }

  handleSelectChangeSales = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      sale: {
        ...prevState.sale,
        [(name as string)]: value
      }
    }));
  }
  
  handleAuthorizedEmployeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    this.setState({
      authorizedEmployee: checked,
    });
  }

  handleProgramNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({
      programName: value,
    });
  }

  handleAutocompleteChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    this.setState({
      payment: {
        ...this.state.payment,
        payment_methods: newValue
      }
    });
  }

  handleCheckFields=()=>{
    if (
      this.isStringNullOrBlank(
        this.state.programName
      )
      ) { return false;}
    return true;
  }

  showErrorMessage = (state: string, statMsg: string) =>
  {
    return !state && statMsg;
  }

  handleLabel = (slabel: boolean | string) => {
    return <>
      { slabel ? this.handleErrorLabel(slabel) : slabel }
    </>
  }

  isStringNullOrBlank(testStr: string) {
    return testStr === null || testStr === '' || /^\s*$/.test(testStr);
  }

  handleErrorLabel = (slabel: boolean | string) => {
    return <Box className='flex relative' >
      <img
        className='error-text-img'
        src={validationerror}
      />
      <Typography
        className='error-text'>
        {slabel}
      </Typography>
    </Box>
  }

  handleErrorMessage = (type: string, show: boolean = false) => {
    if (this.state.saveClick || show) {
      let slabel: boolean | string = '';
      if (type === 'programName') {
        slabel = this.showErrorMessage(this.state.programName, 'Please enter valid program name.');
      }
      return this.handleLabel(slabel)
    }
    else { return ''}
  }

  errorTextClass = (field: string, value: string | boolean) => {
    if (this.handleErrorMessage(field) && !value) { return 'error-text1';}
    else { return 'main-color';}
  }
  
  TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
    return <div hidden={value !== index}>{children}</div>;
  };

  errorFocusClass = (field: string, value: string | boolean) => {
    if (this.handleErrorMessage(field) && !value) { return 'error-focus'; }
    else { return '';}
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabValue: newValue
    });
  };

  handleTaxExcludedPriceChange = () => {
    this.setState(prevState => ({
      pricing: {
        ...prevState.pricing,
        tax_excluded_price: true,
        tax_included_price: false,
      }
    }));
  };

  handleTaxIncludedPriceChange = () => {
    this.setState(prevState => ({
      pricing: {
        ...prevState.pricing,
        tax_excluded_price: false,
        tax_included_price: true,
      }
    }));
  };

  handleResetOrder = () => {
    this.setState({
      programName: "",
      authorizedEmployee:false,
      
      connectedDevices: {
        directDevices: false,
        iotBox: false,
        orderPrinter: false,
        barcodeNomenclature: "",
        customerDisplay: false,
      },
      posInterface: {
        limitedPartnersLoading:false,
        largeScrollbars:false,
        customerNotes:false,
        floorsAndTables:false,
        internalNotes:false,
        categoryPictures:false,
        restrictProductCategories:false,
        startCategory:false,
        productConfigurator: false,
        openProductInfo:false,
      },
      taxes: {
        fiscalPosition: false,
        fiscalPositionPerOrder: false,
      },
      payment: {
        payment_methods: [],
        set_maximum_diffrence: false,
        tips: false,
      },
      bill_and_receipt: {
        bill_spitting: false,
        automatic_receipt_printing: false,
        bill_printing: false,
        header_and_footer: false,
        invoice_journal: ""
      },
      sale: {
        down_payment_product: "",
        sales_team: "",
      },
      pricing:{
        loyalty_program:"",
        advanced_pricelists:false,
        tax_excluded_price:false,
        tax_included_price:false,
        gift_card: false,
        global_discounts:false,
        default_pricelist:"",
        price_control:false,
        discount_product: "",
        discount_percentage: "",
        manual_discounts: false,
        coupons_and_promotions:false,
      },
      accounting: {
        sales_journal: "",
      },
      inventory: {
        operation_type: "",
        limited_products_loading: false,
        ship_later: false,
      },
    })
  }
  
  handleSave = () => {

    const token = this.state.token;

    const posData = {
      "data": {
        "program_name": this.state.programName,
        "company": "customer2",
        "active": true,
        "authorized_employees": this.state.authorizedEmployee,
        "pos_interface_attributes": {
          "product_configurator": this.state.posInterface.productConfigurator,
          "customer_notes": this.state.posInterface.customerNotes,
          "floors_and_tables": this.state.posInterface.floorsAndTables,
          "internal_notes": this.state.posInterface.internalNotes,
          "category_pictures": this.state.posInterface.categoryPictures,
          "restrict_product_categories": this.state.posInterface.restrictProductCategories,
          "start_category": this.state.posInterface.startCategory,
          "large_scrollbars": this.state.posInterface.largeScrollbars,
          "open_product_info": this.state.posInterface.openProductInfo,
          "limited_partners_loading": this.state.posInterface.limitedPartnersLoading
        },
        "connected_device_attributes": {
          "iot_box": this.state.connectedDevices.iotBox,
          "direct_devices": this.state.connectedDevices.customerDisplay,
          "customer_display": this.state.connectedDevices.orderPrinter,
          "order_printer": this.state.connectedDevices.directDevices,
          "barcode_nomenclature": this.state.connectedDevices.barcodeNomenclature
        },
        "tax_attributes": {
          "fiscal_position_per_order": this.state.taxes.fiscalPositionPerOrder,
          "fiscal_position": this.state.taxes.fiscalPosition
        },
        "pos_pricing_attributes": {
          "advanced_pricelists": this.state.pricing.advanced_pricelists,
          "tax_excluded_price": this.state.pricing.tax_excluded_price,
          "tax_included_price": this.state.pricing.tax_included_price,
          "global_discounts": this.state.pricing.global_discounts,
          "gift_card": this.state.pricing.gift_card,
          "manual_discounts": this.state.pricing.manual_discounts,
          "coupons_and_promotions": this.state.pricing.coupons_and_promotions,
          "price_control": this.state.pricing.price_control,
          "default_pricelist": this.state.pricing.default_pricelist,
          "discount_product": this.state.pricing.discount_product,
          "discount_percentage": this.state.pricing.discount_percentage,
          "loyalty_program": this.state.pricing.loyalty_program
        },
        "pos_payment_attributes": {
          "set_maximum_diffrence": this.state.payment.set_maximum_diffrence,
          "tips": this.state.payment.tips,
          "payment_methods": this.state.payment.payment_methods,
        },
        "bill_and_receipt_attributes": {
          "header_and_footer": this.state.bill_and_receipt.header_and_footer,
          "automatic_receipt_printing": this.state.bill_and_receipt.automatic_receipt_printing,
          "bill_printing": this.state.bill_and_receipt.bill_printing,
          "bill_spitting": this.state.bill_and_receipt.bill_spitting,
          "invoice_journal": this.state.bill_and_receipt.invoice_journal
        },
        "pos_inventory_attributes": {
          "limited_products_loading": this.state.inventory.limited_products_loading,
          "ship_later": this.state.inventory.ship_later,
          "operation_type": this.state.inventory.operation_type,
        },
        "pos_accounting_attributes": {
          "sales_journal": this.state.accounting.sales_journal,
        },
        "pos_sale_attributes": {
          "sales_team": this.state.sale.sales_team,
          "down_payment_product": this.state.sale.down_payment_product,
        }
      }
    }

    this.setState({
      saveClick: true
    })

    if (this.handleCheckFields()) {
      let endPoint = `/bx_block_point_of_sale/point_of_sales`
      let method = "POST"
      this.createPOSMethodAPI = this.apiCallHandle(
        token,
        endPoint,
        method,
        posData
      );
    }
  };



  apiCallHandle = (token: token, endPoint: string, method: string, body?: object) => {
    const header = {
      "Content-Type": "application/json", token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
