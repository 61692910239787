import React from 'react';
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { paginationarrow } from '../../../settings2/src/assets';
import StorageProvider from "../../../../framework/src/StorageProvider";
import { Box, Checkbox, Chip, CircularProgress, TableCell, TableRow, Typography } from '@material-ui/core';
import { swap } from './../assets';
export const configJSON = require("../../../settings2/src/config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  
  showUOMcatdata:any[];
  groupbyResponse:any[];
  searchValue: string;
  groupValue: string;
  groupByvalue: string;
  loading: boolean;
  searchKeyword: string;
  totalCounts:number;
  totalPage:number;
  activeGroup: string; 
  activeFilter: number;
  activeFilterArr: any[];
  filterLabel: string;
  globalCheck:boolean;
  checkedArr:any[];
  active:any[];
  showFilter: boolean;
  showGroup: boolean;
  showFav: boolean;
  showColumn: boolean
  showCurrentSearch: boolean;
  showAddToDashboard: boolean;
  showSettingsUOMcat: boolean;
  searchArray: any[];
  defaultView:boolean;
  loadMoreRecords:boolean;
  page: number;
  per_page: number;
  name:string;
  model:string;
  group:string;
  open: boolean;
  token:string|null;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UomCategorieslistviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetUOMlistCallId!: string;
  apigetUOMGroupbylistCallId!: string;
  apigetUOMFilterlistCallId!: string;


  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),

      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      
      token:"",
      filterLabel:"",
      loading:false,
      searchKeyword: '',
      totalCounts: 0,
      totalPage:0,
      globalCheck:false,
      checkedArr:[],
      active:[],
      activeGroup: '',
      activeFilter: -1,
      activeFilterArr: [],
      showFilter: false,
      showColumn: false,
      showGroup: false,
      showFav: false,
      showCurrentSearch: false,
      showAddToDashboard: false,
      showSettingsUOMcat: false,
      showUOMcatdata: [],
      groupbyResponse:[],
      searchValue: "",
      groupValue: "",
      groupByvalue: "",
      searchArray:[],
      defaultView:true,
      loadMoreRecords:false,
      page:1,
      per_page:9,
      name:'',
      model:'',
      group:'',
      open: false,
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({...this.state,loading:false});
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
 
      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.apigetUOMlistCallId) {
          this.apiResponseUOM(responseJson)
        }
        if (apiRequestCallId === this.apigetUOMFilterlistCallId) {
          this.apiResponseUOM(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  selectedListItem = (value:any) => {
    if(this.state.activeFilterArr.includes(value)){
      return 'selected-listItem'
    }else{
      return ''
    }
  }

  searchPlaceholderFnUOMcat = () => {
    return !(this.state.searchArray.length) ? 'Search here...' : '';
  }

  searchIconDisplayFnUOMcat = () => {
    return this.state.activeFilterArr.length && this.state.activeGroup ? 'display-none' : ''
  }

  checkGroupStatus = (length:any) => {
    return (!length) ? 'display-none' : ''
  }
  checkActiveGroupStatus = () => {
    return this.state.activeGroup ? 'display-none' : ''
  }

  loaderBoxFnUOMcat = () => {
    if(this.state.loading || this.state.showUOMcatdata.length==0){
      return 'loaderBox'
    }else{
      return 'display-none'
    }
  }

  loaderBoxContentFnUOMcat = () => {
    if(this.state.loading){
      return <CircularProgress />
    }else if(this.state.showUOMcatdata.length==0){
      return <Typography className='bold-text'>No Records Found!</Typography>
    }else{
      return <></>
    }
  }

  listViewFnUOMcat = () => {
    if(!(this.state.defaultView && this.state.showUOMcatdata.length>0 && (!this.state.loading))){ 
      return 'display-none'
    }else{
      return ''
    }
  }

  handleGlobalCheckUOM = () => {
    let arr:any = [];
    this.state.showUOMcatdata?.forEach((user:any) => {
      arr.push(user.id)
    })
    this.setState({...this.state,checkedArr:!this.state.globalCheck?arr:[],globalCheck:!this.state.globalCheck})
  }

  handleUserCheckboxUOM = (id:any) => {
    if(this.state.checkedArr.includes(id)){
      this.setState({...this.state,globalCheck:false,checkedArr:this.state.checkedArr.filter((i)=> i !== id)})
    }else{
      this.setState({...this.state,globalCheck:(this.state.showUOMcatdata.length==(this.state.checkedArr.length+1))?true:false,checkedArr:[...this.state.checkedArr,id]})
    }
  }
  
  removeActiveFilterUOMcat = (value:any) => {
    const filteredNumbers = this.state.activeFilterArr.filter(number => number !== value);
    const sarr = this.state.searchArray.filter(i=>i.value!=value);
    this.setState({...this.state,searchArray:sarr,activeFilterArr:filteredNumbers});
  }

  async componentDidMount() {
    const getToken= await StorageProvider.get("TOKEN")
    this.setState({
      token:getToken
    })
   await this.getUOMlistApi(this.state.per_page,this.state.page)
  }

  async componentDidUpdate(prevProps: {}, prevState: S) {
    
    if ((prevState.searchKeyword !== this.state.searchKeyword)) {
      if(this.state.searchKeyword.length>=3){
        await this.getUOMlistApi(this.state.per_page,1);
      }
      if(this.state.searchKeyword.length==0){
        await this.getUOMlistApi(this.state.per_page,1);
      }
    }
    if ((prevState.activeFilterArr.length !== this.state.activeFilterArr.length)) {
      await this.getUOMFilterlistApi('','');
    }
    
    if ((prevState.page !== this.state.page) || (prevState.per_page !== this.state.per_page)) {
      this.state.token && await this.getUOMlistApi(this.state.per_page,this.state.page)
    }
    
    
  }

  chipLabelUOMcat = (value:any) => {
    return value==0 ? 'Archive' : value;
  }


  handleActiveFilterUOMcat = (value:any) => {
    !(this.state.searchArray.filter((i)=>i.value==value).length) && this.setState({...this.state,searchArray:[...this.state.searchArray,{type:'filter',value:value}],activeFilter:value,activeFilterArr:[...this.state.activeFilterArr,value],showFilter:false});
  }

  handleActiveGroupUOMcat = (value:any) => {
    if(!value){
      const sarr = this.state.searchArray.filter(i=>i.type!='group');
      this.setState({...this.state,searchArray:sarr,activeGroup:'',showGroup:false})
    }
    else{
      !(this.state.searchArray.filter((i)=>i.value==value).length) && this.setState({...this.state,searchArray:[...this.state.searchArray,{type:'group',value:value}],activeGroup:value,showGroup:false});
    }
    
  }

  loadMoreRecordsUOMcat = async(action:number,type:string='',lmore:boolean=false) => {
    this.setState({
      page:action==0?1:this.state.page+action,
      loadMoreRecords:lmore
    })
    
  }

  handlePaginationUOMcat = async(action:number) => {
    this.setState({
      per_page:action,page:1
    })
    
  }

  isThisLastPageUOMcat(): boolean {
    if (!this.state.showUOMcatdata.length) {
      return true;
    }
    if (!this.state.activeGroup) {
      return this.state.page === this.state.totalPage;
    }
    return true;
  }
 
  
  navigatetoCreateUOMcategories = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddUomCategories'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);

  }

  handleFilter = () => {
    this.setState({ ...this.state, showFilter: !this.state.showFilter });
  };
  handleFavorites = () => {
    this.setState({ ...this.state, showFav: !this.state.showFav });
  };
  handleGroup = () => {
    this.setState({ ...this.state, showGroup: !this.state.showGroup });
  };
  
  
  handleSettings = () => {
    this.setState({ ...this.state, showSettingsUOMcat: !this.state.showSettingsUOMcat });
  };
  
  apiResponseUOM=(responseJson:any)=>{
    if(responseJson.data){
      (this.state.loadMoreRecords && this.state.showUOMcatdata?.length>0) ? this.setState({loadMoreRecords:false,showUOMcatdata:[...this.state.showUOMcatdata,...responseJson.data],totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages}) : this.setState({loadMoreRecords:false,showUOMcatdata:responseJson.data,totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages});
    }else if(responseJson.errors){
    window.alert("Invalid Token")
    }
  }
  
  

  getUOMlistApi = async (per_page:number,page1:number) => {
    this.setState({...this.state,loading:true,globalCheck:false,checkedArr:[],page:page1});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetUOMlistCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productquickview/uom_categories?per_page=${per_page}&page=${page1}&search=${this.state.searchKeyword}${this.state.activeFilterArr.includes(0)?'&filter_archive=true':''}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  

  getUOMFilterlistApi = async (key:string,value:string) => {
     
    this.setState({...this.state,loading:true,globalCheck:false,checkedArr:[],page:1,searchKeyword:''});
    const url_params = `?per_page=${this.state.per_page}&page=1${this.state.activeFilterArr.includes(0)?'&filter_archive=true':''}${this.state.activeGroup == 'Parent Category' ? '&parent_category=parent_category' : ''}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };
    this.apigetUOMFilterlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productquickview/uom_categories${url_params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  expandMore = () => {
    return <img className='expandMoreImg' height={7} src={paginationarrow}/>;
  }

  

  expandTableRow = (user:any,classes:any) => {
    return <TableRow>
                <TableCell style={{width:"92px",display:"flex"}}>
                  <Checkbox 
                  checked={this.state.checkedArr.includes(user?.id) ? true : false} 
                  className={classes.userlistviewCheck} 
                  onChange={()=>this.handleUserCheckboxUOM(user?.id)}
                  data-test-id="usercheckboxUOM" 
                  />
                  <img src={swap} />
                  </TableCell>
                <TableCell className={classes.userlistviewTabletext}>{user.attributes?.category}</TableCell>
                <TableCell className={classes.userlistviewTabletext}>
                  <Box sx={{display:"flex"}}>
                  {
                    user.attributes?.unit_of_measures.map((u:any)=><Chip className={u.uom_type == "Reference Unit of Measure for this category" ? classes.uomChip : classes.uomChip2} label={u.uom} />)
                  }
                  </Box>
                
                </TableCell>
            </TableRow>
  }

  // Customizable Area End
}
