export const star = require("../assets/star.svg");
export const downarrow = require("../assets/down-arrow.svg");
export const settings = require("../assets/settings.svg");
export const add = require("../assets/add.svg");
export const groupby = require("../assets/groupby.svg");
export const dashboard = require("../assets/dashboard.svg");
export const filter = require("../assets/filter.svg");
export const list = require("../assets/list.svg");
export const search = require("../assets/search.svg");
export const more = require("../assets/more.svg");
export const addrow = require("../assets/add-row.svg");
export const delrow = require("../assets/del-row.svg");
export const swap = require("../assets/swap.svg");
export const moveBackwardArrow = require("../assets/move_backward.svg");
export const backArrow = require("../assets/back_arrow.svg");
export const forwardArrow = require("../assets/forward_arrow.svg");
export const moveForwardArrow = require("../assets/move_forward.svg");
export const externalLink = require("../assets/external-link.svg");
export const dropdowm = require("../assets/dropdown.svg");
export const errorIcon = require("../assets/Error.svg");





