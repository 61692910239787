// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Radio,
  Tab,
  Tabs,
  TextField,
  OutlinedInput,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CfCustomerSettingTabsController, {
  Props,
} from "./CfCustomerSettingTabsController.web";
import CfCustomerSalesProductCatalog from "./CfCustomerSalesProductCatalog.web";
import CfCustomerSalesPricing from "./CfCustomerSalesPricing.web";
import CfCustomerSalesShipping from "./CfCustomerSalesShipping.web";
import CfCustomerSalesInvoicing from "./CfCustomerSalesInvoicing.web";
import  Sidebar  from "../../../../components/src/Sidebar.web";

import { AppHeader } from "../../../../components/src/AppHeader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { TabPanel } from "@material-ui/lab";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  palette: {
    primary: {
      main: "#2A6396",
    },
  },
  overrides: {
    MuiTab: {
      root: {
        "&.Mui-selected": {
          fontWeight:"600 !important" // Override with your desired color
        },
      },
    },
  },
});
// Customizable Area End

export default class CfCustomerSettingTabs extends CfCustomerSettingTabsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { settingTabs } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.MainLayout}>
          <Box sx={webStyle.sideBar}>
            <Sidebar />
          </Box>
          <Box sx={webStyle.content}>
            <AppHeader pageTitle="Configuration" />
            <Box>
              <Box style={webStyle.outerTabBox}>
                <Box style={webStyle.innertabBox}>
                  <Grid container alignItems="center">
                    <Grid md={6} >
                      <Typography style={webStyle.menuHeading} variant="body1">
                        Settings
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <div style={webStyle.tabContainer}>
                  <Tabs
                    indicatorColor="primary"
                    data-test-id="setting-tabs"
                    textColor="primary"
                    value={settingTabs}
                    onChange={(_, value) => this.handlerTabs(value)}
                  >
                    <Tab label="Product Catalog" />
                    <Tab label="Pricing" />
                    <Tab label="Shipping" />
                    <Tab label="Invoicing" />
                  </Tabs>
                  <div style={webStyle.tabBody}>
                    {settingTabs === 0 && (
                      <CfCustomerSalesProductCatalog
                      id={""}
                      navigation={undefined}
                    />
                    )}
                    {settingTabs === 1 && (
                      <CfCustomerSalesPricing
                      id={""}
                      navigation={undefined}
                    />
                    )}
                    {settingTabs === 2 && (
                      <CfCustomerSalesShipping navigation={undefined} id={""} />
                    )}
                    {settingTabs === 3 && (
                      <CfCustomerSalesInvoicing
                        id={""}
                        navigation={undefined}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  MainLayout: {
    display: "flex",
  },
  sideBar: {
    display: "flex",
    minHeight: "100%",
    flexGrow: 1,
    transition: "all 0.3s",
  },
  content: {
    flexGrow: 9,
    padding: "0 20px",
  },
  settingLayout: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
  },
  mainContainer: {
    width: "100%",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  btnBox: {
    display: "flex",
    justifyContent: "end",
    marginBlock: "30px",
    gap: "20px",
  },
  activeButton: {
    color: "#1976d2",
    fontWeight: 600,
  },
  inActiveButton: {
    color: "#000",
  },
  outerTabBox: {
    border: "1px solid #e3e3e3",
    borderRadius: "4px",
    backgroundColor: "white"
  },
  innertabBox: {
    padding: "6px 15px",
    borderBottom: " 1px solid #ddd",
  },
  menuHeading: {
    color: "#2A6396",
    fontWeight: 600,
    padding:"10px 0",
  },
  searchbar: {
    "& input": {
      border: "none !important",
    },
  },
  tabContainer: {
    padding: "10px 8px",
  },
  tabBody: {
    marginTop: "10px",
  },
};
// Customizable Area End
