// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, FormControl, Grid, List, ListItem, ListItemText, MenuItem, Radio, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import SettingsAddUserController, { Props } from './SettingsAddUserController.web'
import '../assets/style.css'
import { close, envelope, menu, radioNonSelected, radioSelected, settings } from './assets';
import Modal from '@material-ui/core/Modal';
export const configJSON = require("./config");
import Sidebar from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';


// Customizable Area End


export default class SettingsAddUser extends SettingsAddUserController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { userType,notificationType,value,open } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            
            <Box className="gridMainLayout">
            <Box className="gridsideBar">
            <Sidebar />
            </Box>
            
            <Box className="gridcontent" >
            <AppHeader pageTitle={configJSON.settings} />
            <Box >
                <Box className='child-div-1'>
                <Grid container spacing={0} >
                <Grid item sm={10}  className='grid-item-1'>
                <Grid item sm={10}  >
                <Box>
                <Typography className="customer bold-text" >{configJSON.users} <span className='new-normal-text'>/ {configJSON.new}</span></Typography>
                </Box>
                </Grid>
                <Grid item sm={2}  className='end-center-align'>
                
                <Box className='grid-box-1'>
                <img src={settings} data-test-id="toggleSettings" onClick={this.handleSettings} />
                <div ref={(div) => (this.divRef = div)}>
                {
                    this.state.showSettings && 

                    <Box className="filterBox-create-user">
                    <List>
                        <ListItem button className='listItem' data-test-id="archive" >
                            <ListItemText primary={configJSON.archive} />
                        </ListItem>
                        <ListItem button className='listItem' data-test-id="duplicate" >
                            <ListItemText primary={configJSON.duplicate} />
                        </ListItem>
                        <ListItem button className='listItem' data-test-id="delete" >
                            <ListItemText primary={configJSON.delete} />
                        </ListItem>
                        <ListItem onClick={()=>{this.handleOpen();}} button data-test-id="changePassword" >
                            <ListItemText primary={configJSON.changePassword} />
                        </ListItem>
                    </List>
                    </Box>
                    
                }
                </div>
                </Box>
                </Grid>
                
                
                
                </Grid>
                <Grid item sm={2} className='relative grid-item-2' >
                <Box className='center-align customer-heading-right' >
                <Box className='flex'>
                <img className='' src={menu}/> 
                <Box className='pl-5'>
                <Typography className='pl-5 bold-text'>345</Typography>
                <Typography className='pl-5' style={{fontSize:"13px"}}>{configJSON.access_rights}</Typography>
                </Box>
                </Box>
                </Box>
                </Grid>
                </Grid>
                </Box>
                
                <Box className="form-div form-div-border bottomBorder">
                
                <Grid container  >
                
                <Grid item sm={12} >
                    <Box>
                        <Typography className='main-color'>{configJSON.name}<span className='required'>*</span></Typography>
                        <TextField data-test-id="nameInput" className="form-input create-user-input" 
                        placeholder='E.g. Laurie Poiret'
                        value={this.state.user_name}
                        onChange={(e:any) => this.setState({...this.state,user_name:e.target.value})} 
                        name="user_name" type="text" />
                    </Box>
                </Grid>
            
                <Grid container spacing={2}  >
                
                    <Grid item sm={6} >
                    <Box>
                        <Typography className='main-color'>{configJSON.email_address}<span className='required'>*</span></Typography>
                        <TextField data-test-id="emailInput" className="form-input create-user-input" 
                        placeholder='E.g. Laurie.poiret@gmail.com'
                        value={this.state.email}
                        onBlur={(e:any) => this.isValidEmail(e.target.value,'email1')}
                        onChange={(e:any) => this.setState({...this.state,email:e.target.value})} 
                        name="user_email" type="email" />
                    </Box>
                    </Grid>
                    <Grid item sm={6} >
                    <Box>
                        <Typography className='main-color'>{configJSON.relatedPartner}<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            data-test-id="relatedPartnerInput"
                            IconComponent={this.expandMore}
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.relatedPartner}
                            displayEmpty
                            onChange={(e:any) => this.setState({...this.state,relatedPartner:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${!this.state.relatedPartner && 'select-default-color'}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                        >
                            <MenuItem value="">Select Related Partner</MenuItem>
                            <MenuItem value="xyz">XYZ</MenuItem>
                            
                        </Select>
                        </FormControl>
                    </Box>
                    </Grid>
                
                
                </Grid>
                
                </Grid>
                </Box>
                <Box className='tab-parent-div leftRightBorder' >
                <div >
                <Tabs value={value} onChange={this.handleTabChange}>
                <Tab label={configJSON.access_rights} />
                <Tab label={configJSON.preferences} />
                </Tabs>
                <this.TabPanel value={value} index={0}>
                <Box className="form-div">
                <Grid container spacing={2} >
                <Grid item sm={6}>
                    <Typography className='mb-10 bold-text'>{configJSON.multiCompanies}</Typography>
                <Grid item sm={12} >
                    <Box>
                    {
                        this.customSelect(this.companies_options,this.state.allowedCompanies,'allowedCompanies',configJSON.allowedCompanies)
                    }
                    </Box>
                </Grid>
                
                </Grid>
                <Grid item sm={6} className='pt-48'>
                <Grid item sm={12} >
                    <Box>
                        <Typography className='main-color'>{configJSON.defaultCompany}</Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="defaultCompanyInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.defaultCompany}
                            displayEmpty
                            onChange={(e:any) => this.setState({...this.state,defaultCompany:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${!this.state.defaultCompany && 'select-default-color'}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem value="">Default Company</MenuItem>
                            <MenuItem value="xyz">XYZ</MenuItem>
                            
                        </Select>
                        </FormControl>
                    </Box>
                </Grid>
                </Grid>
                </Grid>
                <Grid container spacing={2} className='flex'>
                
                <Grid item sm={6}  >
                <Grid item sm={12} >
                    <Typography className='mb-10 bold-text'>
                    {configJSON.userTypes}
                    </Typography>
                    </Grid>
                    <Grid item sm={12} style={{paddingTop:"17px"}}>
                    <Box className='flex'>
                    <Typography className='main-color'>{configJSON.userTypes}</Typography>
                        <Box style={{marginLeft:"35px"}} className={`left-center-align ${userType === 'internal_user' && 'customer-checked'}`}>
                        <img data-testid="internalUserRadio" className='mr-5' onClick={()=>this.handleUserType({target: { value: "internal_user" }})} src={userType === 'internal_user' ? radioSelected : radioNonSelected} />
                        <Typography>{configJSON.internalUser}</Typography>
                        </Box>
                        <Box className={`left-center-align ${userType === 'e_commerce_user' && 'customer-checked'}`} style={{marginLeft:"20px"}}>
                        <img data-testid="eCommerceRadio" className='mr-5' onClick={()=>this.handleUserType({target: { value: "e_commerce_user" }})} src={userType === 'internal_user' ?  radioNonSelected : radioSelected} />
                        <Typography>{configJSON.ecommerceUser}</Typography>
                        </Box>
                    </Box>
                    </Grid>
                    
                </Grid>
                <Grid item sm={6} >
                    <Box >
                        
                    {
                        this.customSelect(this.tag_options,this.state.tag,'tag',configJSON.tag)
                    }
                       
                    </Box>
                </Grid>
                
                </Grid>
                <Grid container spacing={2} >
                <Grid item sm={6}>
                    
                <Grid item sm={12} >
                    <Box className='custom-div'>
                    {
                        this.customSelect(this.sales_options,this.state.sales,'sales',configJSON.sales)
                    }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='custom-div'>
                    {
                        this.customSelect(this.purchase_options,this.state.purchase,'purchase',configJSON.purchase)
                    }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='custom-div'>
                    {
                        this.customSelect(this.accounting_options,this.state.accounting,'accounting',configJSON.accounting)
                    }
                    </Box>
                </Grid>
                
                <Grid item sm={12} >
                    <Box className='custom-div'>
                    {
                        this.customSelect(this.human_resources_options,this.state.humanResources,'humanResources','Human Resources')
                    }
                    </Box>
                </Grid>

                <Grid item sm={12} >
                    <Box className='custom-div'>
                    {
                        this.customSelect(this.settings_options,this.state.settings,'settings',configJSON.settings)
                    }
                    </Box>
                </Grid>
                
                </Grid>
                <Grid item sm={6}>
                <Grid item sm={12} >
                    <Box className='custom-div'>
                        
                    {
                        this.customSelect(this.point_of_sale_options,this.state.pointOfSale,'pointOfSale',configJSON.pointOfSale)
                    }
                       
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='custom-div'>
                        
                    {
                        this.customSelect(this.inventory_options,this.state.inventory,'inventory',configJSON.inventory)
                    }
                    
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='custom-div'>
                        
                    {
                        this.customSelect(this.contacts_options,this.state.contacts,'contacts',configJSON.contacts)
                    }
                        
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='custom-div'>
                        
                    {
                        this.customSelect(this.sign_options,this.state.sign,'sign',configJSON.sign)
                    }
                       
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box className='custom-div'>
                        
                    {
                        this.customSelect(this.approval_options,this.state.approval,'approval',configJSON.approval)
                    }
                       
                    </Box>
                </Grid>
                </Grid>
                </Grid>
                
                </Box>
                </this.TabPanel>
                <this.TabPanel value={value} index={1}>
                <Box className="form-div">
                <Grid container spacing={2} >
                <Grid item sm={6}>
                    <Typography className='mb-10 bold-text'>{configJSON.localization}</Typography>
                <Grid item sm={12} >
                    <Box>
                        <Typography className='main-color'>{configJSON.language}</Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            data-test-id="languageInput"
                            IconComponent={this.expandMore}
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.language}
                            displayEmpty
                            onChange={(e:any) => this.setState({...this.state,language:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${!this.state.language && 'select-default-color'}`}
                            
                        >
                            <MenuItem value="">Language</MenuItem>
                            <MenuItem value="xyz">XYZ</MenuItem>
                            
                        </Select>
                        </FormControl>
                    </Box>
                </Grid>
                
                </Grid>
                <Grid item sm={6} className='pt-42'>
                <Grid item sm={12} >
                    <Box>
                        <Typography className='main-color'>{configJSON.timezone}</Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="timezoneInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            value={this.state.timezone}
                            displayEmpty
                            onChange={(e:any) => this.setState({...this.state,timezone:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${!this.state.timezone && 'select-default-color'}`}
                            
                        >
                            <MenuItem value="">Timezone</MenuItem>
                            {
                                this.state.timezones.length>0 && this.state.timezones.map((timezone:string,index:number)=>{
                                   return <MenuItem key={index} value={timezone}>{timezone}</MenuItem>
                                })
                            }
                            
                        </Select>
                        </FormControl>
                    </Box>
                </Grid>
                </Grid>
                </Grid>
                <Grid container spacing={2} >
                <Grid item sm={6} className='grid-padding-right' >
                    
                <Grid item sm={12} >
                    <Typography className='mb-10 bold-text'>
                    {configJSON.notification}
                    </Typography>
                    </Grid>
                <Grid item sm={12} className='flex' >
                    <Grid item sm={4}>
                    <Typography className='main-color'>{configJSON.notification}</Typography>
                    </Grid>
                    <Grid item sm={8}>
                    <Box>
                        
                        <Box className={`left-center-align ${notificationType === 'handle_by_emails' && 'customer-checked'}`}>
                        <img data-testid="emailHandling" className='mr-10' onClick={()=>this.handleNotificationType({target: { value: "handle_by_emails" }})} src={notificationType === 'handle_by_emails' ? radioSelected : radioNonSelected} />
                        
                        <Typography>{configJSON.handle_by_emails}</Typography>
                        </Box>
                        <Box className={`left-center-align ${notificationType === 'handle_by_customer' && 'customer-checked'}`} style={{marginTop:"15px"}}>
                        <img data-testid="customerHandling" className='mr-10' onClick={()=>this.handleNotificationType({target: { value: "handle_by_customer" }})} src={notificationType === 'handle_by_customer' ? radioSelected : radioNonSelected} />
                        
                        <Typography>{configJSON.handle_by_customer}</Typography>
                        </Box>
                    </Box>
                    </Grid>
                    
                </Grid>
                </Grid>
                <Grid item sm={6} >
                
                </Grid>
                </Grid>
                </Box>
                </this.TabPanel>
                
                
                </div>

                </Box>
                
                <Box className='end-center-align form-btn-box'>
                <Button variant="outlined" data-test-id="discard-button" className='discard-btn' onClick={this.discardUserData}>{configJSON.discard}</Button>
                <Button variant="contained" data-test-id="save-button" className='save-btn' onClick={this.addUser}
                   >{configJSON.save}</Button>
                
                </Box>
                <div>
        
        
      </div>
                
            </Box>
            <Modal
            open={open}
            onClose={this.handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            >
            <Box className="modalBox-access-right">
                <Box className="relative">
                <Typography variant="h6" id="modal-title" className='bold-text' gutterBottom>
                {configJSON.changePassword}
                </Typography>
                <img onClick={this.handleClose} data-test-id="modal-close" className='modal-close' src={close} />

                </Box>
                
                <Typography variant="body1" id="modal-description" gutterBottom>
                
                    <Grid container spacing={1} >
                        <Grid item sm={12} >
                        <Box>
                        <Box className='leftTitle'><Typography>{configJSON.userlogin}</Typography></Box>
                        <Box className='contact-form-input-1'>
                        <TextField className="form-input create-user-input" 
                        placeholder='E.g. Laurie Poiret'
                        data-test-id="userLoginInput"
                        value={this.state.user_login}
                        onChange={(e:any) => this.setState({...this.state,user_login:e.target.value})} 
                        name="user_login" type="text" />
                        </Box>
                        </Box>
                        </Grid>
                        <Grid item sm={12} >
                        <Box>
                        <Box className='leftTitle'><Typography>{configJSON.new} {configJSON.changePassword.split(' ')[1]}</Typography></Box>
                        <Box className='contact-form-input-1'>
                        <TextField className="form-input create-user-input" 
                        data-test-id="newPasswordInput"
                        value={this.state.new_password}
                        onChange={(e:any) => this.setState({...this.state,new_password:e.target.value})} 
                        name="new_password" type="text" />
                        </Box>
                        </Box>
                        </Grid>
                    
                    </Grid>
                </Typography>
                {
                    this.state.errorAlert && <Typography>All * fields are required</Typography>
                }
                <Box className="modal-cancel"  >
                
                    <Button onClick={this.handleClose} data-test-id="discard-modal" variant="outlined" className='mr-15'>{configJSON.discard}</Button>
                    
                    <Button variant="contained" className='create-btn' data-test-id="change-password" onClick={this.handleChangePassword} style={{float:"right"}}>{configJSON.changePassword}</Button>
                    
                    
                </Box>
                
                
            </Box>
            </Modal>
            </Box>
            </Box>
            </>
            
            // Customizable Area End
        )

    }
}