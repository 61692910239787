// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Chip, ClickAwayListener, FormControl, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import SettingsUserKanbanViewController, { Props } from './SettingsUserKanbanViewController.web'

import '../assets/style.css'
export const configJSON = require("./config");
import { add, dashboard, envelope, filter, filtericon, greenDot, gridleftarrow, groupby, kanbanview, list, listview, search, settings, star, userimg } from './assets';
import { AppHeader } from '../../../components/src/AppHeader.web';
import Sidebar from '../../../components/src/Sidebar.web';
import { groupWhite } from '../../../components/src/assets';
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class SettingsUserListView extends SettingsUserKanbanViewController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { showFilter, showGroup, showFav, showSettings, showCurrentSearch, showAddToDashboard } = this.state;
        const { classes }: any = this.props;
        const searchPlaceholder = this.searchPlaceholderFunc() as string;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.userlistviewMainLayout}>
                <Box className={`${classes.userlistviewsideBar} ${this.state.defaultView && classes.sidebarMarginRight}`}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.userlistviewcontent}>
                    {" "}
                    <AppHeader pageTitle="Settings" />
                    <Box style={{backgroundColor:"#ffffff"}}>
                        <Box>
                            <Box className={classes.userlistviewBlock}>
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Typography className={classes.userlistviewHeading}>{configJSON.mainHeading}</Typography>
                                    </Grid>
                                    <Grid item sm={6} className={classes.userlistviewSearchbox}>
                                        <TextField
                                            className='search-box-width'
                                            id='user-list-search-input'
                                            variant="outlined"
                                            placeholder={`${searchPlaceholder}`}
                                            data-testid="listSearchinput"
                                            value={this.state.searchKeyword}
                                            onChange={(e:any) => this.setState({...this.state,searchKeyword:e.target.value})}
                                        />
                                        <img src={search} className={`${classes.userlistviewImg} ${(this.searchIconDisplayFunc()) && 'display-none'}`} />
                                        
                                        
                                        <Box className={`${!this.state.activeGroup && 'display-none'}`} style={{position:"absolute",left:"15px",top:"12px"}}>
                                            <Box style={{position:"relative"}}>
                                            <Chip style={{backgroundColor:"#006395",color:"#ffffff",padding:"0 25px"}} label="User Type" />
                                            <span className='handleGroupIcon' ><img src={groupWhite} /></span>
                                            <span data-testid="handleRemoves1" className='handleRemoves' onClick={()=>this.handleActiveGroup('')} >×</span>
                                            </Box>
                                            
                                        </Box>
                                        
                                        <Box className={`${this.filterUIfunc()}`}>
                                        {
                                            this.state.activeFilterArr.map((value:any,index:number)=>{
                                            const flabel = this.handleFilterLabel(value);
                                            return  <Box className='mr-5' key={index}>
                                                        <Box className='relative'>
                                                        <Chip style={{backgroundColor:"#006395",color:"#ffffff",padding:"0 25px"}} label={flabel} />
                                                        <span className='handleFilterIcon' ><img src={filtericon} /></span>
                                                        <span className='handleRemoves' data-testid="handleRemoves2"  onClick={()=>this.removeActiveFilter(value)} >×</span>
                                                        </Box>
                                                        
                                                    </Box>
                                            })
                                        }
                                        </Box>
                                        
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.userlistviewFilterbox} >
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Box className={classes.userlistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Filters" onClick={this.handleFilter}
                                            ><img src={filter} className={classes.userlistviewFilterImg} />{configJSON.Filters}</Button>
                                            
                                            {
                                                showFilter &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleFilter}
                                                    data-test-id="clickHandleFilter"
                                                >
                                                <Box className="filterBox">
                                                    <List>
                                                        <ListItem button className={`listItem font-14 ${this.selectedListItem(0)}`} data-testid="filter-internal" 
                                                        onClick={()=>this.handleActiveFilter(0)}>
                                                            <ListItemText primary="Internal Users" />
                                                        </ListItem>
                                                        <ListItem button className={`listItem font-14 ${this.selectedListItem(1)}`} data-testid="filter-ecommerce" 
                                                        onClick={()=>this.handleActiveFilter(1)}
                                                        >
                                                            <ListItemText primary="E-Commerce Users" />
                                                        </ListItem>
                                                        <ListItem button className={`listItem font-14 ${this.selectedListItem(2)}`} data-testid="filter-inactive" 
                                                        onClick={()=>this.handleActiveFilter(2)}>
                                                            <ListItemText primary="Inactive Users" />
                                                        </ListItem>
                                                        <ListItem button className={`listItem font-14 ${this.selectedListItem(3)}`} data-testid="filter-two-factor-enable" 
                                                        onClick={()=>this.handleActiveFilter(3)}>
                                                            <ListItemText primary="Two-Factor Authentication Enabled" />
                                                        </ListItem>
                                                        <ListItem button className={`font-14 ${this.selectedListItem(4)}`} data-testid="filter-two-factor-disable" 
                                                        onClick={()=>this.handleActiveFilter(4)}>
                                                            <ListItemText primary="Two-Factor Authentication Disabled" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                            }
                                            
                                        </Box>
                                        <Box className={classes.userlistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Groupby"
                                                onClick={this.handleGroup}
                                            ><img src={groupby} className={classes.userlistviewFilterImg} />{configJSON.Groupby}</Button>
                                            
                                            {
                                                showGroup &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleGroup}
                                                    data-test-id="clickHandleGroup"
                                                >
                                                <Box className="filterBox">
                                                    <List>
                                                        <ListItem className={`font-14 ${this.selectedGroupListItem('User Type')}`} button data-testid="list-salesPerson" onClick={() => { this.handleActiveGroup('User Type'); }}>
                                                            <ListItemText  primary="User Type" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                        </Box>
                                        <Box className={classes.userlistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Favorites" onClick={this.handleFavorites} ><img src={star} className={classes.userlistviewFilterImg} />{configJSON.Favorites}</Button>
                                            
                                            {
                                                showFav &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleFavorites}
                                                    data-test-id="clickHandleFavorites"
                                                ><Box className="filterBox">
                                                    <List >
                                                        <ListItem button data-testid="list-currentSearch" className='listItem font-14 favorites-list' onClick={this.handleCurrentSearch}>
                                                            <ListItemText primary="Save Current Search" />
                                                            <img src={gridleftarrow} />
                                                        </ListItem>
                                                        <ListItem button className='listItem font-14 favorites-list' data-testid="list-importRecords">
                                                            <ListItemText primary="Import Records" />
                                                        </ListItem>
                                                        <ListItem button className='font-14 favorites-list'>
                                                            <ListItemText primary="Add to my Dashboard" data-testid="list-addDashboard" onClick={this.handleAddToDashboard} />
                                                            <img src={gridleftarrow} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                            {
                                                showCurrentSearch &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleCurrentSearch}
                                                    data-test-id="clickHandleFavorites"
                                                >
                                                <Box className="filterBoxCurrentSearch">
                                                <List className={classes.gridviewlist} >
                                                    <ListItem button className='listItem font-14'>
                                                        <ListItemText primary="Users" />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.gridviewButton} font-14`} style={{paddingBottom:0}}>
                                                        <ListItemIcon className={classes.gridviewIcon} >
                                                            <Checkbox className={classes.gridviewCheckbox} defaultChecked />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Use by default" />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.gridViewlistItem} font-14`} style={{paddingTop:0}}>
                                                        <ListItemIcon className={classes.gridviewIcon}>
                                                            <Checkbox className={classes.gridviewCheckbox} />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Share with all users" />
                                                    </ListItem>
                                                    <ListItem button className={`${classes.gridviewButtonlist} end-center-align font-14`} >
                                                        <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                    </ListItem>
                                                </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            {
                                                showAddToDashboard &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleAddToDashboard}
                                                    data-test-id="clickHandleFavorites"
                                                >
                                                <Box className="filterBoxAddToDashboard">
                                                <List className={`font-14 ${classes.gridviewlist}`}>
                                                    <ListItem button style={{paddingBottom:0}} >
                                                        <TextField id="users-input" value="Users"/>
                                                        
                                                    </ListItem>
                                                    <ListItem button className={`font-14 ${classes.gridviewButtonlist} end-center-align`} style={{paddingTop:0}}  >
                                                    <Button className='saveBtn'>{configJSON.buttonSave}</Button>  
                                                    </ListItem>
                                                </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} className={classes.userlistviewCreatebox} >
                                        <Box className={classes.userlistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-settings" onClick={this.handleSettings}><img src={settings} /></Button>
                                            
                                            {
                                                showSettings &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleSettings}
                                                    data-test-id="clickHandleSettings"
                                                >
                                                <Box className="settingsBox">
                                                    <List>
                                                        <ListItem button className='listItem font-14'>
                                                            <ListItemText primary="Export" />
                                                        </ListItem>
                                                        <ListItem button className='listItem font-14'>
                                                            <ListItemText primary="Archive" />
                                                        </ListItem>
                                                        <ListItem button className='listItem font-14'>
                                                            <ListItemText primary="Unarchive" />
                                                        </ListItem>
                                                        <ListItem button className='font-14'>
                                                            <ListItemText primary="Delete" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                </ClickAwayListener>
                                                
                                            }
                                            
                                        </Box>
                                        <Button className={this.state.defaultView ? `filters-left-active` : `filters-left`} data-testid="navigatetoListview" onClick={this.navigatetoListview}><img src={!this.state.defaultView ? listview : list}/></Button>
                                        <Button className={!this.state.defaultView ? `filters-left-active` : `filters-left`} data-testid="navigatetoGridview" onClick={this.navigatetoGridview}><img src={!this.state.defaultView ? kanbanview : dashboard}  /></Button>
                                        <Button className='filters-right' data-testid="Createcustomer" onClick={this.navigatetoCreateCustomer}>{configJSON.Createusers}<img src={add} /></Button>
                                    </Grid>
                                </Grid>

                                <Box className={`${this.loaderBoxFunc()}`}>
                                {
                                this.loaderBoxContentFunc()
                                }
                                </Box>
                                
                                <Box className={`${this.listViewFunc()}`}>
                                <Box className={classes.userlistviewColumnbox} >
                                    
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                    <Checkbox checked={this.state.globalCheck} 
                                                    onChange={()=>this.handleGlobalCheck()} 
                                                    className={classes.userlistviewCheck}
                                                    data-test-id="globalCheckbox" />
                                                    </TableCell>
                                                    <TableCell className={classes.userlistviewTableheading}>NAME</TableCell>
                                                    <TableCell className={classes.userlistviewTableheading}>LOGIN</TableCell>
                                                    <TableCell className={classes.userlistviewTableheading}>LANGUAGE</TableCell>
                                                    <TableCell className={classes.userlistviewTableheading}>LATEST AUTHENTICATION</TableCell>
                                                    <TableCell className={classes.userlistviewTableheading}>COMPANY</TableCell>
                                                    
                                                </TableRow>
                                            </TableHead>
                                            {
                                                this.state.activeGroup && ['internal_user','e_commerce_user'].map((utype:any,index:number)=>{
                                                    return <>
                                                    {this.state.showCustomerdata.filter((i:any)=>i?.attributes?.access_right?.user_types == utype).map((user:any,index1:number) =>{
                                                    return <>
                                                    
                                                    <TableBody>
                                                    {
                                                        this.handleGroupIndexZeroFunc(utype,index1,index)
                                                    }
                                                    { this.state.active.includes(index) &&
                                                    this.expandTableRow(user,classes)
                                                    }
                                                    </TableBody>
                                                    
                                                  </>
                                                  })}
                                                    </>
                                                })
                                            }
                                            {(!this.state.activeGroup) && this.state.showCustomerdata?.map((user:any) => {
                                                return (
                                                    <>
                                                    
                                                        <TableBody>
                                                        {
                                                            this.expandTableRow(user,classes)
                                                        }
                                                        </TableBody>
                                                        
                                                    </>  
                                                 )
                                            })} 
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box className={`${classes.userlistviewItems} ${this.state.activeGroup && 'display-none'}`}>
                                    <Grid container spacing={0} >
                                        <Grid item sm={8} className={classes.userlistviewItemsgrid}>
                                            <Typography className={classes.userlistviewItemsText}>{configJSON.itemsPage}</Typography>
                                            <FormControl id="select-form-control">

                                                <Select
                                                    IconComponent={this.expandMore}
                                                    inputProps={{
                                                        'aria-label': 'custom select',
                                                    }}
                                                    data-testid="select_page"
                                                    id="item-select"
                                                    value={this.state.per_page}
                                                    onChange={(e:any)=>this.handlePagination(e.target.value)}
                                                    label="Select"
                                                    disableUnderline
                                                    className='bold-text'
                                                >
                                                    
                                                    <MenuItem value={9}>09</MenuItem>
                                                    <MenuItem value={27}>27</MenuItem>
                                                    <MenuItem value={54}>54</MenuItem>
                                                    <MenuItem value={99}>99</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={4} className='end-center-align'>
                                            <Typography className={classes.userlistviewText} >
                                                <span className='bold-text'>
                                                {(this.state.page - 1) * this.state.per_page + 1}
                                                &nbsp;-&nbsp;
                                                {(this.state.page - 1) * this.state.per_page +
                                                    this.state.showCustomerdata.length}
                                                </span>
                                                <span className={classes.bottompage}>
                                                    &nbsp;of &nbsp;
                                                {this.state.totalCounts}
                                                </span>
                                            </Typography>
                                            <Button data-testid="listviewBackwardicon" disabled={this.state.page==1} onClick={()=>this.loadMoreRecords(0,'')} className='tb-footer-btn'><img src={this.movebackward()} /></Button>
                                            <Button data-testid="listviewBackArrow" disabled={this.state.page==1} onClick={()=>this.state.page>1 && this.loadMoreRecords(-1,'')} className='tb-footer-btn'><img src={this.backarrow()} /></Button>
                                            <Button data-testid="listviewForwardArrow" disabled={this.isPageLast()} onClick={()=>this.loadMoreRecords(+1,'')} className='tb-footer-btn'><img src={this.forwardarrow()} /></Button>
                                            <Button data-testid="listviewForwardIcon" disabled={this.isPageLast()} onClick={()=>this.loadMoreRecords(this.state.totalPage-this.state.page,'')} id='next-end'><img src={this.moveforward()} /></Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                </Box>
                                
                                <Box className={`${this.kanbanView()}`}>
                                <Box className={classes.userlistgridviewbox} >
                                    <Grid container spacing={3} >
                                        {
                                            this.state.showCustomerdata?.map((user:any) => {
                                                return <>
                                                    <Grid item lg={6}  >
                                                        <Box className={classes.userlistgridviewFilterbox}  >
                                                            <Grid container spacing={0} className={classes.userlistgridviewbox}  >
                                                                <Grid item lg={3} >
                                                                    <img src={userimg} height={100} width={100} />
                                                                </Grid>
                                                                <Grid item lg={9} className={classes.userlistgridviewlistimg}  >
                                                                    {
                                                                        user.attributes?.latest_authentication && 
                                                                        <img src={greenDot} className={classes.userlistgridListimg} />
                                                                    }
                                                                    
                                                                    {
                                                                        user.attributes?.preference?.language && 
                                                                        <Chip className={classes.userlistgridListimg2} label={user.attributes?.preference?.language} />
                                                                    }
                                                                    
                                                                    <Typography className={classes.userlistgridListtext} >{user.attributes?.first_name}</Typography>
                                                                    <Box className='flex'>
                                                                    <img src={envelope} />
                                                                    <Typography className={classes.userlistgridListsubtext} style={{marginLeft:"5px"}} >{user.attributes?.email}</Typography>
                                                                    </Box>
                                                                    
                                                                </Grid>
                                                            </Grid>
                                                            
                                                        </Box>
                                                        
                                                    </Grid>
                                                    
                                                </>
                                            })
                                        }
                                    </Grid>
                                </Box>
                                
                                    <Box className={`${classes.userlistgridviewbox} ${this.hideLoadMore()}`} style={{textAlign:"center"}} >
                                    <Button style={{backgroundColor:"rgb(243, 243, 243)"}} data-testid="kanbanLoadMore" onClick={()=>this.loadMoreRecords(+1,'',true)}>Load More Records</Button>
                                    </Box>
                                
                                
                                </Box>
                                
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const userlistwebStyle: any = (theme: any) => ({
    userlistviewMainLayout: {
        display: "flex",
    },
    userlistviewsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
        
    },
    sidebarMarginRight:{
        marginRight:"-40px"
    },
    userlistviewcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    userlistviewBlock: {
        padding: "15px",
        border: "1px solid #e3e3e3"
    },
    userlistviewHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    userlistviewImg: {
        position: "absolute",
        left: "15px",
        top: "19px",
        height: "20px"
    },
    userlistviewFilterbox: {
        border: "1px solid #e3e3e3"
    },
    userlistviewFilterImg: {
        paddingRight: "8px"
    },
    userlistviewColumnbox: {
        position: "relative"
    },
    userlistviewColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    userlistviewCheck: {
        
    },
    bottompage: {
        color:"#47464a"
    },
    userlistviewTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    userlistviewTabletext: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    userlistviewItems: {
        padding: "15px"
    },
    userlistviewCreatebox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px"
    },
    userlistviewSearchbox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative"
    },
    userlistviewItemsgrid: {
        display: "flex !important",
        justifyContent: "left",
    },
    userlistviewItemsText: {
        paddingRight: "10px",
        marginTop: "18px",
        color:"#47464a"
    },
    userlistviewText: {
        marginRight: "50px"
    },
    userlistgridviewbox: {
        padding: "15px"
    },
    userlistgridviewFilterbox: {
        border: "1px solid #e3e3e3"
    },
    userlistgridviewlistimg: {
        padding: "10px 10px 10px 0"
    },
    userlistgridListimg: {
        margin: "-5px 5px 0 0",
        float: "right"
    },
    userlistgridListimg2: {
        margin: "15px -20px 0 0",
        float: "right"
    },
    userlistgridListtext: {
        fontSize: "20px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
    },
    userlistgridListsubtext: {
        fontSize: "16px",
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
})
export const SettingsUserListViewStyles = withStyles(userlistwebStyle)(SettingsUserListView)
  // Customizable Area End
