import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  TextField,
  Select,
  MenuItem,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Popper,
  ClickAwayListener,
  MenuList,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Dialog,
  DialogContent,
  DialogActions,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, makeStyles, withStyles } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
const {activity ,addBtn,deleteBtn,graph,preview ,check ,settings,print } = require("./assets");
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done'; 
import clsx from "clsx";

const theme = createTheme({
  typography: {
    h6: {fontWeight: 500,},
    subtitle1: {margin: "20px 0px",},
  },
  palette: {
    primary: {contrastText: "#fff",main: "#2A6395",},
  },
});


const tableHeading = ["Product Name","Description of Product","Qty.","In Units","Price(IN UNITS)","Tax","Discount","Subtotal",];

const steps = [
  'Quotation', 
  'Quotation Sent', 
  'Order Sent', 
  'Done'
];

const ColorlibConnector = withStyles({
  active: {
    "& $line": {background: "#2A6395",},
  },
  completed: {
    "& $line": {background: "#2A6395",},
  },
  alternativeLabel: {top: 11,},
  line: {
    borderRadius: 1,
    border: 0,
    height: 2,
    backgroundColor: "#eaeaf0",
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  completed: {
    background: "#2A6395",
  },
  root: {
    zIndex: 1,
    color: "#fff",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#ccc",
    borderRadius: "10%",
    width: 25,
    height: 25,
    justifyContent: "center",
  },
  active: {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    background: "#2A6395",
  },
  iconActive :{
    color: "#2A6395",
    background: "transparent",
  },
  iconCompleted: {
    color: "#2A6395",
    background: "none",
  },
  icon:{
    color:"grey",
    padding:0,
  },
});

function ColorlibStepIcon(props:any) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <div className={clsx(classes.root, {[classes.active]: active,[classes.completed]: completed,})}>1</div>,
    2: <div className={clsx(classes.root, {[classes.active]: active,[classes.completed]: completed,})}>2</div>,
    3: <div className={clsx(classes.root, {[classes.active]: active,[classes.completed]: completed,})}>3</div>,
    4: <DoneIcon className={clsx(classes.icon,{[classes.iconActive]: active,[classes.iconCompleted]: completed,})} />
  };

  return (
    <>{icons[String(props.icon)]}</>
  );
}

// Customizable Area End

import Ordercreation2Controller, {
  Props,
  configJSON,
} from "./Ordercreation2Controller";

export default class OrdercreationView extends Ordercreation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider 
      theme={theme}>
        <Box 
          style={webStyle.gridMainLayout}>
          <Box 
            style={webStyle.gridsideBar}>
            <Sidebar /></Box>
          <Box 
            className="gridcontent" 
            style={webStyle.gridcontent}>
            <AppHeader 
              pageTitle={configJSON.ordermanagement} />

            <Box display={"flex"} flexDirection={"row-reverse"} style={{margin:"1px"}}>
              <Stepper activeStep={this.state.activeStep} connector={<ColorlibConnector />} style={{width:"50%",background:"transparent"}}>
                {steps.map((label) => (<Step key={label}><StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel></Step>))}
              </Stepper>
            </Box>

            <Box className="parent-div" >
              <style>
                {`
                  .MuiSelect-selectMenu{
                    border: 1px solid #e3e3e3;
                  }
                  .MuiInputBase-root{
                    border-radius: 5px;
                  }
                  .MuiInput-underline:after {
                    border-bottom: 0px !important;
                  }
                  .MuiInputAdornment-positionEnd{
                    padding : 10px;
                  }
                  .MuiInputBase-input{
                    border: 0px !important;
                  }
                  .MuiChip-root{
                    color:#000 !important;
                    background-color:#e0e0e0 !important;
                  }
                  .MuiFormControl-fullWidth{
                    width: 90%;
                  }
                  .MuiIconButton-root:hover{
                    background-color: transparent !important;
                  }
                  .MuiChip-deleteIcon{
                    color:#000 !important;
                  }
                  .MuiPaper-elevation1 {
                    box-shadow: none;
                  }
                  .productnameCell {
                      & .MuiInputBase-root {
                        outline: none;
                      }
                  }
                  .MuiGrid-spacing-xs-3{
                    width: calc(100% + 50px);
                  }
                  .MuiStepLabel-label.MuiStepLabel-completed{
                    color:#2A6395;
                  }
                  .MuiStepLabel-label.MuiStepLabel-active{
                    color:#2A6395;
                  }
                  .MuiSelect-select.MuiSelect-select{
                    padding-right : 40px;
                  }
                  .MuiStepper-root{
                    padding: 24px 0;
                  }
                  .MuiTableCell-alignLeft{
                    padding:8px;
                  }
                  .tablecontainer::-webkit-scrollbar {
                      height:5px;
                      width:10px;
                    }

                    .tablecontainer::-webkit-scrollbar-thumb {
                      background: lightgrey;
                      border-radius:10px;
                    }

                    .tablecontainer::-webkit-scrollbar-thumb:hover {
                      background: grey;
                    }
                  }
                `}
              </style>
              <Grid container spacing={0} style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                <Grid item sm={3} lg={12} md={12}>
                  <Box style={{ paddingRight: "25px", height: "50px", alignItems: "center" }} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}  >
                    <Box sx={{ width: "40%" }}  display={"flex"} flexDirection={"row"}  justifyContent={"space-between"} >
                      <Typography className="customer bold-text" >{configJSON.quotation} / <span style={{ color: "grey" }}>{this.state.quotation_number}</span></Typography>
                      <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <ClickAwayListener 
                          onClickAway={this.handlePrintClickAway} 
                          data-test-id="printClickAway"
                        >
                          <div>
                            <Button
                              style={webStyle.buttonComponentSettings}
                              data-test-id="printPopper"
                              onClick={this.handleClickPrint}
                            ><img src={print} /></Button>
                            <Popper
                              transition
                              style={webStyle.printPopper}
                              placement="bottom-end"
                              open={Boolean(this.state.anchorElPrint)}
                              anchorEl={this.state.anchorElPrint}
                            >

                              <MenuList style={{ padding: 0 }}>
                                {this.PrintOptions.map((item) => (
                                  <div 
                                    style={{ borderBottom: "1px solid #F3F3F3" }}>
                                    <MenuItem
                                      style={webStyle.popperItem}
                                      data-test-id="closedMenu-1"
                                      data-value={item.name}
                                      key={item.name}
                                    >{item.name}
                                    </MenuItem></div>
                                ))}
                              </MenuList></Popper></div>
                        </ClickAwayListener>

                        <ClickAwayListener 
                          onClickAway={this.handleSettingsClickAway} 
                          data-test-id="settingClickAway"
                          >
                          <div>
                            <Button
                              data-test-id="settingPopper"
                              style={webStyle.buttonComponentSettings}
                              onClick={this.handleClickSetting}
                            ><img src={settings} /></Button>
                            <Popper
                              style={webStyle.settingsPopper}
                              open={Boolean(this.state.anchorElSetting)}
                              transition
                              placement="bottom-end"
                              anchorEl={this.state.anchorElSetting}
                            >

                              <MenuList style={{ padding: 0 }}>
                                {this.SettingOptions.map((item) => ( <div style={{ borderBottom: "1px solid #F3F3F3" }}>
                                    <MenuItem
                                      data-test-id="closedMenu-1"
                                      key={item.name}
                                      data-value={item.name}
                                      style={webStyle.popperItem}
                                    >{item.name}</MenuItem>
                                  </div>
                                ))}
                              </MenuList>
                            </Popper>
                          </div>
                        </ClickAwayListener>
                      </Box>
                    </Box>
                    
                    <Box sx={webStyle.activityIconWrapper}>
                      <img alt={"icon"} src={activity} />
                      <Typography>Activity</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Divider style={webStyle.divider} />
                  <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} paddingX={"15px"} >
                  <Typography className='bold-text' style={{ color: "#2A6395", fontSize: "20px" }}>
                    {this.state.quotation_number}
                    </Typography>
                    <Box display={"flex"} flexDirection={"row"}>
                    <Typography className='bold-text' style={{ color: "#2A6395", fontSize: "18px", whiteSpace: "nowrap",marginInlineEnd: "10px"}}>
                        Customer Preview
                      </Typography>
                      <img src={preview} alt="preview"/>
                    </Box>
                  </Box>
                  <Box style={{padding: "0px !important", width: "100%" }}>
                    <Box className="form-div" style={{width: "95%"}}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Customer Code</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.customer_code}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Customer Name</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.customer_name}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  {/* <Typography variant="body2">Address</Typography> */}
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.invoice_address}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Order Date</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.order_date}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Invoice Address</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.invoice_address}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Delivery Address</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.delivery_address}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Payment Terms</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.payment_terms}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Status of Order</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.status_of_order}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Total no. of Items</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.total_items}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Branch Name</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.branch_name}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Sales Person</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{ marginRight: "30px" }} />
                                <Grid item xs={3}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>{this.state.sales_person}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <Typography variant="body2">Pricelist</Typography>
                                </Grid>
                                  <Divider orientation="vertical" flexItem style={{marginRight:"30px"}}/>
                                <Grid item xs={3}>
                                  <Typography style={{fontWeight:600}} variant="body2">{this.state.pricelist}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Box>
                    <Box>
                      <Typography 
                        style={{fontSize:"18px" , fontWeight:600 , marginTop:"2%" , marginBottom: "2%", marginLeft: "1%"}}>Order Lines</Typography>
                      <TableContainer 
                        component={Paper} className="tableContainer">
                        <Table aria-label="caption table">
                          <TableHead><TableRow>
                              {tableHeading.map((item, index) => (
                                <TableCell key={index} style={{ textTransform: "uppercase" }}><b style={{ whiteSpace: "nowrap" }}>{item}</b></TableCell>
                              ))}
                              <TableCell align="left">
                                <Button data-test-id="addRowBtn" onClick={this.handleAddRow}><img src={addBtn} alt="addBtn"/></Button>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.tableData.map((rowData, rowIndex) => (
                              <TableRow key={rowIndex}>
                                <TableCell align="left" className="productnameCell">
                                  <Select
                                    displayEmpty
                                    labelId="productname"
                                    value={rowData.product_name}
                                    data-test-id="productname"
                                    fullWidth
                                    onChange={(e) => this.handleProductSelect(rowIndex, e.target.value)}
                                    IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                                    className={`width-100 ${!rowData.product_name && 'select-default-color'}`}
                                    renderValue={() => rowData.product_name || "Select Product Name"}
                                  >
                                    <MenuItem disabled value="" style={{ display: 'none', color: "grey" }}>Select Product Name</MenuItem>
                                    {this.state.productDataList.map((product, index) => (
                                      <MenuItem key={index} value={product.name}>{product.name}</MenuItem>
                                    ))}
                                  </Select>
                                </TableCell>
                                <TableCell align="left">
                                  {rowData.product_description}
                                </TableCell>
                                <TableCell align="left" >
                                  <Box 
                                    style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography>
                                      {this.state.quantity}
                                    </Typography>
                                    <ClickAwayListener
                                      data-test-id="availableClickAway"
                                      onClickAway={this.handleAvailabilityClickAway}
                                    >
                                      <div>
                                        <Button
                                          onClick={this.handleClickAvailability}
                                          data-test-id="availablePopper"
                                          style={webStyle.graphpopper}
                                        >
                                          <img src={graph} alt="graph"/>
                                        </Button>
                                        <Popper
                                          anchorEl={this.state.anchorAvailability}
                                          transition
                                          open={Boolean(this.state.anchorAvailability)}
                                          placement="right-end"
                                          style={webStyle.availabilityPopper}
                                        >

                                          <MenuList style={{ padding: 0 }}>
                                            <div style={{ borderBottom: "1px solid #F3F3F3" }}>
                                              <MenuItem
                                                data-test-id="closedMenu-1"
                                                style={webStyle.popperItemBlue}
                                              >
                                                <Typography style={{ color: "#fff" }}>Availability</Typography>
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => this.handleQty(rowData.forecastedQty)}
                                                style={webStyle.popperItem}
                                                data-test-id="forecastedQTY"
                                              >
                                                <Grid container>
                                                  <Grid item lg={8} md={8}>
                                                    <Typography 
                                                      style={{ color: "#2A6395", fontSize: "12px" }}
                                                      >Forecasted Stock
                                                      </Typography>
                                                    <Typography 
                                                    style={{ color: "grey", fontSize: "10px" }}
                                                    >on 02/01/2023
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item lg={4} md={4}>
                                                    <Typography 
                                                      style={{ color: "#2A6395", fontWeight: 600, fontSize: "12px" }}>
                                                        {rowData.forecastedQty} Units
                                                      </Typography>
                                                  </Grid>
                                                </Grid>
                                              </MenuItem>
                                              <MenuItem
                                                data-test-id="qTYOnHand"
                                                style={webStyle.popperItem}
                                                onClick={()=>this.handleQty(rowData.onHandQty)}
                                              >
                                                <Grid container>
                                                  <Grid item lg={8} md={8}>
                                                    <Typography 
                                                      style={{ color: "#2A6395", fontSize: "12px" }}>
                                                        Available
                                                      </Typography>
                                                    <Typography 
                                                    style={{ color: "grey", fontSize: "10px" }}>
                                                      All planned operation included
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item lg={4} md={4}>
                                                    <Typography 
                                                    style={{ color: "#2A6395", fontWeight: 600, fontSize: "12px" }}>
                                                      {rowData.onHandQty} Units
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </MenuItem>
                                            </div>
                                          </MenuList>
                                        </Popper>
                                      </div>
                                    </ClickAwayListener>
                                  </Box>
                                </TableCell>
                                <TableCell align="left">
                                  {rowData.qty}
                                </TableCell>
                                <TableCell align="left">
                                  {rowData.price_in_units}
                                </TableCell>
                                <TableCell align="left">
                                  {"tax"}
                                </TableCell>
                                <TableCell align="left">
                                  {rowData.discount}
                                </TableCell>
                                <TableCell align="left">
                                  {rowData.sub_total}
                                </TableCell>
                                <TableCell align="left">
                                  <Button data-test-id="deleteRowBtn" onClick={() => this.handleDeleteRow(rowIndex, rowData.quotation_id,rowData.id)}><img src={deleteBtn} alt="deleteBtn"/></Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>

                    <Divider style={{padding:"2px" , marginTop:'3%' , marginBottom:'1%'}}/>

                    <Box display={"flex"} flexDirection={"row"} style={{float:"right",marginTop:"10px" , marginBottom:"10px" ,marginRight:"10px"}} >
                      <Typography style={{marginRight:"10px" , color:"#2A6395" , fontWeight:600}}>
                        Add Shipping
                      </Typography>
                      <Typography style={{ marginRight: "10px", color: "#2A6395", fontWeight: 600 }}>
                        Coupon
                      </Typography>
                      <Typography style={{ marginRight: "10px", color: "#2A6395", fontWeight: 600 }}>
                        Promotion
                      </Typography>
                    </Box>

                  <Box style={{  marginLeft: "1%", marginTop: "2%"}}>
                    <Grid container 
                      justifyContent="space-between" 
                      alignItems="center"
                      >
                      <Grid item sm={2} md={2} lg={2}>
                        <Typography>
                          Terms and Conditions:
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                        <TextField
                          name="terms_and_conditions"
                          data-test-id="terms_and_conditions"
                          type="text"
                          style={{ width: "90%", border: "1px solid #e3e3e3" }}
                          value={this.state.terms_and_conditions}
                          placeholder="Enter Terms and Conditions"
                          onChange={(e: any) => this.handleValueChange(e, "terms_and_conditions")}
                        />
                      </Grid>
                      <Grid item sm={2} md={2} lg={2}>
                        <Box display={"flex"} alignItems={"center"} flexDirection={"row"} >
                          <Typography>Margin : </Typography><Typography style={webStyle.boldFont}>{this.state.margin}</Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={2} md={2} lg={2}>
                        <Box display={"flex"} alignItems={"center"} flexDirection={"row"} >
                          <Typography>Total : </Typography><Typography style={webStyle.boldFont}>{this.state.total}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={webStyle.btnBar}>
                    <Box>
                      <Button 
                      variant="contained" 
                      style={webStyle.discardBtn} 
                      data-test-id="discardBtn"
                      onClick={this.handleDiscard}>
                        Cancel
                      </Button>
                    </Box>
                    <Box sx={webStyle.sentBtn}>
                      <Button 
                        variant="contained" 
                        style={webStyle.discardBtn} 
                        onClick={this.handleDiscard}
                        data-test-id="discardBtn" >
                          Confirm
                      </Button>
                      <Button
                        type="submit"
                        onClick={this.handleSave}
                        style={webStyle.saveBtn}
                        data-test-id="saveBtn"
                      >
                        Send By Email
                      </Button>
                    </Box>

                  </Box>
                  </Box>
              </Grid>
            </Box>
            <Box sx={webStyle.actionBtnStyle}>
              <Button 
              onClick={this.handleDiscard}
              variant="contained" 
              data-test-id="discardBtn" 
              style={webStyle.discardBtn} 
              >Discard
              </Button>
              <Button
                type="submit"
                onClick={this.handleSave}
                style={webStyle.saveBtn}
                data-test-id="saveBtn"
              >
                Save
              </Button>
            </Box>
          </Box>
          <Dialog
            data-test-id="deleteDialog"
            maxWidth={"sm"}
            onClose={this.handleDialogCancel}
            open={this.state.deleteDialog}
          >
            <DialogContent><Typography>Are you sure you want to delete ?</Typography> </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                data-test-id="dialogCancelBtn"
                onClick={this.handleDialogCancel}
              >Cancel</Button>
              <Button
                data-test-id="dialogDeleteBtn"
                variant="contained"
                color="primary"
                onClick={this.onDeleteOrderLines}
              >Delete</Button>
            </DialogActions></Dialog>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    background: "#fff",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    display: "flex",
    alignItems: "center",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "100px",
  },
  gridMainLayout: {
    display: "flex",
  },
  gridsideBar: {
    display: "flex",
    minHheight: "100%",
    transition: "all 0.3s",
  },
  buttonStyle: {
    border: "none",
    height: "45px",
    backgroundColor: "rgb(98, 0, 238)",
    marginTop: "40px",
    width: "100%",
  }, 
  actionBtnStyle: {
    marginBlock: "40px",
    gap: 8,
    float: "right",
    display: "flex",
  },
  gridcontent: {
    flexGrow: 9,
    padding: "0 20px",
    width: "77%",
  },
  discardBtn: {
    backgroundColor: "white",
  },
  saveBtn: {
    color: "#ffffff",
    backgroundColor: "rgba(9, 65, 115, 0.77)",
  },
  buttonComponentSettings: {
    fontSize: "12px",
    padding: "8px 8px",
    backgroundColor: "#e1e1e1",
    borderRadius: "4px",
    height: "100%",
    marginRight:"20px",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    color: "black",
    display: "flex",
  },
  divider: {
    backgroundColor: "#E3E3E3",
    marginTop: "20px",
    marginBottom: "20px",
  },
  popperItem: {
    fontSize: "small",
    borderBottom: "1px solid #F3F3F3 !important",
  },
  availabilityPopper: {
    borderRadius: 4,
    padding: 0,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    backgroundColor: "#ffffff",
    marginTop: "4px",
    border: "1px solid #ccc",
  },
  productCardBox: {
    gap: 40,
    display: "flex",
  },
  popperItemBlue:{
    fontSize: "18px",
    background:"#2A6395",
    borderBottom: "1px solid #F3F3F3 !important",
  },
  graphpopper: {
    margin: "10",
    minWidth: "30px",
    height: "30px",
  },
  activityIconWrapper: {
    gap: 8,
    display: "flex",
    alignItems: "center",
  },
  boldFont:{
    marginLeft:"5%",
    fontSize: "22px",
    fontWeight: 500,
  },
  printPopper: {
    zIndex: theme.zIndex.tooltip,
    borderRadius: 4,
    padding: 0,
    width: "fit-content",
    backgroundColor: "#ffffff",
    marginTop: "4px",
    border: "1px solid #ccc",
  },
  settingsPopper: {
    padding: 0,
    backgroundColor: "#ffffff",
    marginTop: "4px",
    borderRadius: 4,
    width: "fit-content",
    border: "1px solid #ccc",
    zIndex: theme.zIndex.tooltip,
  },
  sentBtn: {
    gap:10,
    display: "flex",
  },
  btnBar: {
    justifyContent: "space-between",
    margin: "20px",
    display: "flex",
    flexDirection: "row",
  },
};
// Customizable Area End
