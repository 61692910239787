import React, { Component } from "react";
import { Box, Button, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CfCustomerSettingTabsController, {
  Props,
} from "./CfCustomerSettingTabsController.web";
import { rightArrow, search } from '../assets';

const styles = (theme: any) => ({
root: {
    padding: "0 8px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    minHeight: "120px",
  },
  textContainer: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderLeft: "1px solid #ddd",

    paddingLeft: "20px",
    height: "100%",
  },
  textHolder: {
    display: "flex",
    flexDirection: "column",
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    "& .MuiSvgIcon-root": {

    },
    "& .Mui-checked": {
      backgroundColor: "transparent !important",
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  checkboxHolder: {
    alignSelf: "flex-start",
    height: "100%",
    display: "flex",
  },
  mainHeading: {
    fontSize: "15px",
    margin: "5px 0",
    fontWeight: "bold",
  },
  mainPara: {
    fontSize: "14px",
  },
  btnBox: {
    display: "flex",
    float: "right",
    marginBlock: "30px",
    gap: "20px",
  },
  saveButton: {
    background: "#2A6396",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#2A6396",
    },
  },
  discardButton: {
    background: "white",
    textTransform: "capitalize",
  },
  arrowIcon: {
    color: "#2A6396",
    transform: "rotate(45deg)",
    verticalAlign: "middle",
  },
  ".MuiTab-textColorPrimary.Mui-selected": {
    fontWeight: "bolder",
  },
  shippingBtn: {
    "& .Mui-checked": {
      backgroundColor: "transparent !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#000",
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    ".MuiIconButton-colorSecondary .Mui-checked:hover": {
      backgroundColor: "transparent !important",
    }
  },
});

export class CfCustomerSalesShipping extends CfCustomerSettingTabsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <>
        <Box className={classes.root}>
          <div className={classes.container}>
            <div className={classes.checkboxHolder}>
              <Checkbox
                disableFocusRipple
                disableTouchRipple
                disableRipple
                data-test-id="delivery-method"
                className={classes.checkbox}
                checked={this.state.userSetting.delivery_methods}
                onChange={(e) => this.handleDeliveryCheckboxCheck(e)}
              />
            </div>
            <div className={classes.textContainer}>
              <p className={classes.mainHeading}>Delivery Methods</p>
              <p className={classes.mainPara}>
                Compute shipping costs on orders
              </p>
              <div className={classes.boxContainer}>
                <Button
                  className={classes.shippingBtn}
                  variant="text"
                  color="primary"
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  startIcon={
                    <img src={rightArrow} className='mr-10' />
                  }
                >
                  Shipping Methods
                </Button>
              </div>
            </div>
          </div>
        </Box>
        <Box className={classes.btnBox}>
          <Button variant="contained" 
           data-test-id="discard-shipping"
           className={classes.discardButton} 
           onClick={()=>this.handleSettingShippingDiscard()}>
            Discard
          </Button>
          <Button
            data-test-id="SettingShippingSubmit"
            onClick={() => {
              this.handleSetting();
            }}
            variant="contained"
            className={classes.saveButton}
          >
            Save
          </Button>
        </Box>
      </>
    );
  }
}
//@ts-ignore
export default withStyles(styles)(CfCustomerSalesShipping);
