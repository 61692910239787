Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Please enter the valid email";
exports.errorPasswordNotValid = "Please enter the valid Password";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.labelTitle2 = "Jawal-ERAM";
exports.labelTitle3 = "Let's Get Started.";
exports.labelMessage = "Enter the fields below to proceed.";
exports.apiLoginendPoint = "bx_block_login/login";
exports.loginSucessmsg = "Login Success";
exports.loginErrormsg="Login Failed"
exports.signin="Sign In"
exports.forgotPassword="Forget Password ?"
exports.star="*"
exports.signupText=" Don't have an account ?"
exports.signup="Sign Up"
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End