// Customizable Area Start
import React from 'react';
import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core';
import AddBankController, { Props } from './AddBankController.web'
export const configJSON = require("./../../settings2/src/config.js");
export const configJSON1 = require("./config");
import Sidebar from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { Country, State } from "country-state-city";
import { settingsGrey } from '../../itemavailability/src/assets';


// Customizable Area End


export class AddBank extends AddBankController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        const countries = Country.getAllCountries();
        const regions = this.state.countryName ? State.getStatesOfCountry(this.state.countryName) : [];
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            
            <Box className="gridMainLayout">
            <Box className="gridsideBar">
            <Sidebar />
            </Box>
            
            <Box className="gridcontent" >
            <AppHeader pageTitle={configJSON1.contacts} />
            <Box >
                <Box className='child-div-1'>
                <Grid container spacing={0} >
                <Grid item sm={12}  className='grid-item-1'>
                <Grid item sm={12}  className="flex">
                <Grid item sm={10} className='left-center-align' >
                
                <Box>
                <Typography className="companiesHeading bold-text" >{configJSON1.banks} <span className='new-normal-text'>/ {configJSON.new}</span></Typography>
                </Box>
                
                </Grid>
                <Grid item sm={2}  >
                <Box className={classes.showSettingsBox}>
                <Box sx={{position:"relative"}}>
                <Button className={`filters-left button-icon`} data-testid="list-settings" 
                onClick={this.handleSettings}><img src={settingsGrey} /></Button>
                
                {
                    this.state.showSettings &&
                    this.showSettingsUI(classes)
                }

                </Box>
                
                </Box>
                </Grid>
                    
                </Grid>
                </Grid>
                
                </Grid>
                </Box>
                
                <Box className="form-div form-div-border" sx={{paddingTop:0,paddingBottom:0}}>
                {
                    this.mainBankErrorBox(classes)
                }
                
                </Box>
                
                <Box className='tab-parent-div leftRightBorder' sx={{padding:"0 8px 8px 8px"}} >
                <div >
                <Box className="form-div">
                <Grid container spacing={2} >
                <Grid item sm={6} >
                    
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorText('bank_name',this.state.bank_name)}`}>Bank Name<span className='required'>*</span></Typography>
                    <TextField 
                        className={`${this.errorFocus('bank_name',this.state.bank_name)} form-input create-user-input`}
                        placeholder='Enter Bank Name'
                        data-test-id="bankNameInput"
                        value={this.state.bank_name}
                        onChange={(e:any) => this.setState({...this.state,bank_name:e.target.value})} 
                        name="bank_name" type="text" />
                        {
                            this.handleErrorMessage2('bank_name')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box sx={{position:"relative"}}>
                        <Typography className={`${this.errorText('identifier_code',this.state.identifier_code)}`}>Bank Identifier Code<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="identifierCodeInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            
                            value={this.state.identifier_code}
                            displayEmpty
                            onChange={(e:any) => this.setState({...this.state,identifier_code:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.checkDefaultColor(this.state.identifier_code)} ${this.errorFocus('identifier_code',this.state.identifier_code)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem disabled value="" style={{ display: 'none', color: "grey" }}>Select Bank Identifier Code</MenuItem>
                            <MenuItem value="abc123">abc123</MenuItem>
                            
                        </Select>
                        </FormControl>
                        
                        {
                            this.handleErrorMessage2('identifier_code')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box sx={{position:"relative"}}>
                        <Typography className={`${this.errorText('abm_code',this.state.abm_code)}`}>ABM Code<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="abmCodeInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            
                            value={this.state.abm_code}
                            displayEmpty
                            onChange={(e:any) => this.setState({...this.state,abm_code:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.checkDefaultColor(this.state.abm_code)} ${this.errorFocus('abm_code',this.state.abm_code)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem disabled value="" style={{ display: 'none', color: "grey" }}>Select ABM Code</MenuItem>
                            <MenuItem value="xyz123">xyz123</MenuItem>
                            
                        </Select>
                        </FormControl>
                        
                        {
                            this.handleErrorMessage2('abm_code')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorText('phone_number',this.state.phone_number)}`}>Phone Number<span className='required'>*</span></Typography>
                    <TextField className={`${this.errorFocus('phone_number',this.state.phone_number)} form-input create-user-input`}
                        placeholder='Enter Phone Number'
                        data-test-id="phoneNumberInput"
                        value={this.state.phone_number}
                        onBlur={(e:any) => this.checkMobileLength(e.target.value)}
                        onChange={(e:any) => this.setState({...this.state,phone_number:e.target.value})} 
                        name="phone_number" type="number" />
                        {
                          this.handleErrorMessage2('phone_number') || ((this.checkMobileLength(this.state.phone_number)==="min" && this.handleErrorMessage2('1', true) ||
                          this.checkMobileLength(this.state.phone_number)==="max" && this.handleErrorMessage2('2', true)))
                        }
                    </Box>
                </Grid>
                
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorText('email_address',this.state.email_address)}`}>Email Address<span className='required'>*</span></Typography>
                    <TextField className={`${this.errorFocus('email_address',this.state.email_address)} form-input create-user-input`} 
                        placeholder='Enter Email Address'
                        data-test-id="emailAddressInput"
                        value={this.state.email_address}
                        onBlur={(e:any) => this.isValidEmail(e.target.value)}
                        onChange={(e:any) => this.setState({...this.state,email_address:e.target.value})} 
                        name="email_address" type="text" />
                        {
                            this.handleErrorMessage2('email_address')
                        }
                        {
                            (!this.isValidEmail(this.state.email_address)) && this.handleErrorMessage2('email1',true)
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorText('vat',this.state.vat)}`}>VAT<span className='required'>*</span></Typography>
                        <TextField className={`${this.errorFocus('vat',this.state.vat)} form-input create-user-input`}
                        data-test-id="vatInput"
                        placeholder='Enter VAT'
                        type="text"
                        name="vat"
                        value={this.state.vat}
                        onChange={(e:any) => this.setState({...this.state,vat:e.target.value})} 
                        />
                        {
                            this.handleErrorMessage1('vat')
                        }
                    </Box>
                </Grid>
                
                </Grid>
                <Grid item sm={6} >
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorText('address',this.state.address)}`}>Address (line 1)<span className='required'>*</span></Typography>
                    <TextField 
                        className={`${this.errorFocus('address',this.state.address)} form-input create-user-input`}
                        placeholder='Enter Address (line 1)'
                        data-test-id="addressInput"
                        value={this.state.address}
                        onChange={(e:any) => this.setState({...this.state,address:e.target.value})} 
                        name="address" type="text" />
                        {
                            this.handleErrorMessage1('address')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                    <Typography >Address (line 2)</Typography>
                    <TextField 
                        className={`form-input create-user-input`}
                        placeholder='Enter Address (line 2)'
                        data-test-id="address2Input"
                        value={this.state.address2}
                        onChange={(e:any) => this.setState({...this.state,address2:e.target.value})} 
                        name="address2" type="text" />
                        
                    </Box>
                </Grid>
                
                <Grid item sm={12} >
                    <Box sx={{marginLeft:"8px"}}>
                        <Typography className={`${this.errorText('countryName',this.state.countryName)}`}>Country<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="countryNameInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            
                            value={this.state.countryName}
                            displayEmpty
                            onChange={this.handleCountry}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.checkDefaultColor(this.state.countryName)} ${this.errorFocus('countryName',this.state.countryName)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem disabled value="" style={{ display: 'none', color: "grey" }}>Select Country</MenuItem>
                            {countries.map((country:any) => (
                            <MenuItem key={country.isoCode} value={country.isoCode}>
                                {country.name}
                            </MenuItem>
                            ))}
                            
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleErrorMessage1('countryName')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorText('stateName',this.state.stateName)}`}>State<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                        {
                            this.SelectStateUI(this.state.stateName,regions)
                        }
                        
                        </FormControl>
                        {
                            this.handleErrorMessage1('stateName')
                        }
                        
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box sx={{marginRight:"8px"}}>
                        <Typography className={`${this.errorText('cityName',this.state.cityName)}`}>City<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                        {
                            this.SelectCityUI(this.state.stateName,this.state.cityName,this.state.countryName)
                        }
                        
                        </FormControl>
                        {
                            this.handleErrorMessage1('cityName')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorText('zip',this.state.zip)}`}>Postal Code<span className='required'>*</span></Typography>
                        <TextField className={`${this.errorFocus('zip',this.state.zip)} form-input create-user-input`}
                        placeholder='Enter Postal Code'
                        data-test-id="zipInput"
                        value={this.state.zip}
                        onChange={(e:any) => this.setState({...this.state,zip:e.target.value})} 
                        name="zip" type="text" />
                        {
                            this.handleErrorMessage1('zip')
                        }
                    </Box>
                </Grid>
                
                </Grid>
                </Grid>
                
                
                </Box>
                </div>

                </Box>
                
                <Box className='end-center-align form-btn-box'>
                <Button variant="outlined" data-test-id="discard-button" className='discard-btn' onClick={this.discardBankData}>{configJSON.discard}</Button>
                <Button variant="contained" data-test-id="save-button" className='save-btn' onClick={this.addBank}
                   >{configJSON.save}</Button>
                
                </Box>
                
            </Box>
            
            </Box>
            </Box>
            </>
            
            // Customizable Area End
        )

    }
}

// Customizable Area Start
export const addBankwebStyle: any = {
    showSettingsBox: {
        float:"right",
        paddingRight:"10px"
    },
    showSettingsUI: {
        top:"54px !important",
        borderRadius:"5px"
    },
    errorMainbox: {
        backgroundColor: "#fadee2",
        padding: "20px",
        margin: "10px 0px 0px 0px",
        borderRadius: "5px"
    }
    
}
export default withStyles(addBankwebStyle)(AddBank)
  // Customizable Area End