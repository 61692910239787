// Customizable Area Start
import React from 'react';
import { Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, styled, withStyles } from '@material-ui/core';
import SalesconfigrationUOMcategoriesController, { Props } from './SalesconfigrationUOMcategoriesController.web'
import { configJSON } from './FilteritemsController';
import { addrow, delrow } from './assets';
import { AppHeader } from '../../../components/src/AppHeader.web';
import Sidebar from '../../../components/src/Sidebar.web';
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});

const ExampleTextField = styled(TextField)({
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none"
        }
    }
});
// Customizable Area End

export default class SalesconfigrationCreateUOMcategories extends SalesconfigrationUOMcategoriesController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.categorycreateMainLayout}>
                <Box className={classes.categorycreatesideBar}>
                    <Sidebar />
                </Box>
                <Box className={classes.categorycontent}>
                    <AppHeader pageTitle={configJSON.pageTitle} />
                    <Box>
                        <Box className={classes.categorycreateMainbox}>
                            <Box className={classes.categoryBlock}>
                                <Grid container spacing={0} >
                                    <Grid item sm={12} className={classes.categorySearchgrid}>
                                        <Typography className={classes.categoryHeading}>{configJSON.CreateNew}{configJSON.UoMtext}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.categoryFilterbox} >
                                <Grid container  >
                                    <Grid item sm={12} className={classes.categorySearchbox}>
                                        <Typography className={classes.categorySubheading}>{configJSON.UoMtext}</Typography>
                                        <TextField
                                            className={classes.searchbox}
                                            variant="outlined"
                                            placeholder={configJSON.lengthPlaceHolder}
                                            data-testid="category-type"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleCategorytype(e) }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box className={classes.categoryColumnbox} >
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.categoryTableheading}>{configJSON.Measuretext}</TableCell>
                                                    <TableCell className={classes.categoryTableNameHeading}>{configJSON.Typetext}</TableCell>
                                                    <TableCell className={classes.categoryTableNameHeading}>{configJSON.Ratio}</TableCell>
                                                    <TableCell className={classes.categoryTableNameHeading}>{configJSON.Rounding}</TableCell>
                                                    <TableCell ><img src={addrow} alt="add" /></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.categoryTabletext}>
                                                        <ExampleTextField placeholder={this.state.uom_type} data-testid="category-uomType" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{this.handleuom_type(e)}} />
                                                    </TableCell>
                                                    <TableCell className={classes.categoryTabletext}>
                                                        <ExampleTextField placeholder={this.state.uom} data-testid="category-UoM" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleUomType(e) }} />
                                                    </TableCell>
                                                    <TableCell className={classes.categoryTabletext}>
                                                        <ExampleTextField placeholder={this.state.ratio} data-testid="category-ration" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleRatiotype(e) }} />
                                                    </TableCell>
                                                    <TableCell className={classes.categoryTabletext}>
                                                        <ExampleTextField placeholder={this.state.rounding_precision} data-testid="category-rounding" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleRoundingprecisionType(e) }} />
                                                    </TableCell>
                                                    <TableCell className={classes.categoryTabletext}><img src={delrow} alt="delete" /></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box className={classes.categoryItems}>
                                    <Grid container >
                                        <Grid item sm={12} className={classes.buttonGrid}>
                                            <Button className={classes.discardButton} onClick={this.salesnavigatetoGridview}>{configJSON.discard}</Button>
                                            <Button className={classes.saveButton} data-testid="save-categorytype" onClick={() => { this.createUoMcategoryApi() }}>{configJSON.save}</Button>
                                            <Button className={classes.saveButton} data-testid="update-categorytype" onClick={() => { this.createUnitofmeasureApi() }}>{configJSON.update}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export const webStyle: any = (theme: any) => ({
    categorycreateMainbox: {
        marginTop: "3%"
    },
    categorySearchgrid: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
    },
    categorycreateMainLayout: {
        display: "flex",
    },
    categorycreatesideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    categorycontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    categoryBlock: {
        padding: "15px",
        border: "1px solid #e3e3e3",
    },
    categoryHeading: {
        color: "#185d85",
        fontSize: "24px",
        fontWeight: "bold",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    categorySubheading: {
        color: "#000000",
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: "Inter",
    },
    categoryImg: {
        position: "absolute",
        left: "15px",
        top: "17px",
        height: "20px"
    },
    categoryFilterbox: {
        border: "1px solid #e3e3e3"
    },
    categoryColumnbox: {
        position: "relative"
    },
    categoryColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    categoryCheck: {
        color: "#185d85 !important"
    },
    categoryTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    categoryTabletext: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    categoryItems: {
        padding: "15px"
    },
    categorySearchbox: {
        marginLeft: "2%",
        marginTop: "2%",
        position: "relative"
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
    },
    discardButton: {
        backgroundColor: "#f3f3f3",
        color: "#000000",
        margin: "15px 10px",
        fontSize: "16px",
        fontWeight: "500",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#f3f3f3",
            color: "#000000",
            margin: "15px 10px",
            fontSize: "16px",
            fontWeight: "500",
            textTransform: "capitalize",
        }
    },
    saveButton: {
        backgroundColor: "#006395",
        color: " #ffffff ",
        margin: "15px 10px",
        fontSize: "16px",
        fontWeight: "500",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#006395",
            color: " #ffffff ",
            margin: "15px 10px",
            fontSize: "16px",
            fontWeight: "500",
            textTransform: "capitalize",
        }
    },
})
export const SalesconfigrationCreateUOMcategoriesStyles = withStyles(webStyle)(SalesconfigrationCreateUOMcategories)
  // Customizable Area End
