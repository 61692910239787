export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const validationerror = require("../assets/validationerror.svg");

export const moveBackwardArrowDisable = require("../assets/moveBackwardDisable.svg");
export const moveBackwardArrowEnable = require("../assets/moveBackwardEnable.svg");

export const backArrowDisable = require("../assets/backArrowDisable.svg");
export const backArrowEnable = require("../assets/backArrowEnable.svg");

export const moveForwardArrowDisable = require("../assets/moveForwardDisable.svg");
export const moveForwardArrowEnable = require("../assets/moveForwardEnable.svg");

export const forwardArrowDisable = require("../assets/forwardArrowDisable.svg");
export const forwardArrowEnable = require("../assets/forwardArrowEnable.svg");


export const favourite = require("../assets/favourite.svg");
export const multipleFile = require("../assets/multiple_file.svg");
export const filter = require("../assets/filter.svg");
export const settingsGrey = require("../assets/settingsGrey.svg");
export const settings = require("../assets/settings.svg");
export const add_icon = require("../assets/add_icon.svg");
export const check = require("../assets/check.svg");
export const cross = require("../assets/cross.svg");
