// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  createTheme,
  withStyles,
  Checkbox,
  MenuItem,
  FormControl,
  Button,
  TextareaAutosize,
  Select,
  Chip,
  OutlinedInput} from "@material-ui/core";
import SalesconfigrationUOMcategoriesController, {
  Props
} from "./SalesconfigrationUOMcategoriesController.web";
import { configJSON } from "./FilteroptionsController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import Sidebar from "../../../components/src/Sidebar.web";
import { errorIcon, externalLink } from "./assets";
import { Country } from 'country-state-city';
export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffcc00",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

export default class SalesconfigrationCreateshippingmethod extends SalesconfigrationUOMcategoriesController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const countries = Country.getAllCountries();
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={classes.shippingCreateMainLayout}>
        <Box className={classes.shippingCreatesideBar}>
          <Sidebar />
        </Box>
        <Box className={classes.shippingCreatecontent}>
          <AppHeader pageTitle={configJSON.pageTitle} />
          <Box className={classes.shippingCreateMainbox}>
            <Box>
              <Box className={classes.shippingBlock}>
                <Grid
                  container
                  spacing={0}
                  className={classes.mainHeadingcontainer}
                >
                  <Grid item sm={6} className={classes.shippingSearchgrid}>
                    <Typography className={classes.shippingHeading}>
                      {configJSON.shippingHeading}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className={classes.mainHeadingcontainer}
                >
                  <Grid
                    item
                    lg={2}
                    md={2}
                    className={classes.maintextfieldbox}
                  >
                    <Typography className={classes.shippingMaintextstyle}>
                      {configJSON.shippingHeading}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    md={10}
                    className={classes.maintextfieldbox}
                  >
                    <Box className={classes.shipping_methodBox}>
                      <TextField
                        name="shipping_method"
                        className={classes.shippingMaintextfield}
                        placeholder={configJSON.deliveryChargestext}
                        value={this.state.shippingData.shipping_method}
                        id="outlined-error-helper-text"
                        data-testid="shipping_method"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleShippingdata(event) }} />
                      <img className={classes.shipping_methodImg} src={externalLink} alt="" />
                    </Box>
                    {this.state.shipping_methodError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.shippingErrormsg}
                      </Typography>}
                  </Grid>
                  <Grid item sm={6} className={classes.shippingTextfiledgrid}>
                    <Typography className={classes.shippingtext}>
                      {configJSON.Provider}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <FormControl
                      fullWidth
                    >
                      <TextField
                        name="provider"
                        className={classes.shippingtextfield}
                        select
                        value={this.state.shippingData.provider}
                        data-testid="provider"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleShippingdata(event) }}
                      >
                        <MenuItem value="">
                          {configJSON.Provider}
                        </MenuItem>
                        <MenuItem value={configJSON.Provider}>
                          {configJSON.Provider}
                        </MenuItem>
                      </TextField>
                    </FormControl>
                    {this.state.providerError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.providerErrormsg}
                      </Typography>}
                    <Typography className={classes.shippingtext}>
                      {configJSON.Company}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.companyBox}>
                      <FormControl
                        fullWidth
                      >
                        <TextField
                          name="company"
                          data-testid="company"
                          className={classes.shippingtextfield}
                          select
                          value={this.state.shippingData.company}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleShippingdata(event) }}
                        >
                          <MenuItem value="">
                            {configJSON.Company}
                          </MenuItem>
                          <MenuItem value={configJSON.Company}>
                            {configJSON.Company}
                          </MenuItem>
                        </TextField>
                      </FormControl>
                      <img className={classes.companyExternalImg} src={externalLink} alt="" />
                    </Box>
                    {this.state.companyError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.companyErrormsg}
                      </Typography>}
                    <Typography className={classes.shippingtext}>
                      {configJSON.marginonrate}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box className={classes.companyBox} >
                      <TextField
                        name="margin_on_rate"
                        data-testid="margin-onrate"
                        value={this.state.shippingData.margin_on_rate}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleShippingdata(event) }}
                        className={classes.shippingtextfield}
                      />
                      <img className={classes.marginExternalImg} src={externalLink} alt="" />
                    </Box>
                    {this.state.margin_on_rateError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.marginErrormsg}
                      </Typography>}
                    <Typography className={classes.shippingtext}>
                      {configJSON.freeFoodtext}{" "}
                      <span>
                        <Checkbox name="free_margin" checked={this.state.shippingData.free_margin}
                         />
                      </span>
                    </Typography>
                    <Typography className={classes.shippingtext}>
                      {configJSON.amount}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>

                    <TextField name="amount" value={this.state.shippingData.amount}
                      data-testid="amount"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleShippingdata(event) }}
                      className={classes.shippingtextfield}
                    />
                    {this.state.amountError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.amountErrormsg}
                      </Typography>}

                  </Grid>
                  <Grid item sm={6} className={classes.shippingTextfiledgrid}>
                    <Typography className={classes.shippingtext}>
                      {configJSON.deliveryProduct}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <Box>
                      <FormControl
                        fullWidth
                      >
                        <TextField
                          name="delivery_product"
                          data-testid="delivery_product"
                          className={classes.shippingtextfield}
                          select
                          value={this.state.shippingData.delivery_product}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleShippingdata(event) }}
                        >
                          <MenuItem value="">
                            {configJSON.deliveryProduct}
                          </MenuItem>
                          <MenuItem value={configJSON.deliveryProduct}>
                            {configJSON.deliveryProduct}
                          </MenuItem>
                        </TextField>
                      </FormControl>
                      <img className={classes.deliveryExternalImg} src={externalLink} alt="" />
                    </Box>
                    {this.state.delivery_productError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.productErrormsg}
                      </Typography>}
                    <Typography className={classes.shippingtext}>
                      {configJSON.Website}
                      <span className={classes.loginErrorstar}>{configJSON.star}</span>
                    </Typography>
                    <FormControl
                      fullWidth
                    >
                      <TextField
                        name="website"
                        className={classes.shippingtextfield}
                        data-testid="website"
                        select
                        value={this.state.shippingData.website}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleShippingdata(event) }}
                      >
                        <MenuItem value="">
                          {configJSON.Website}
                        </MenuItem>
                        <MenuItem value={configJSON.Website}>
                          {configJSON.Website}
                        </MenuItem>
                      </TextField>
                    </FormControl>
                    {this.state.websiteError &&
                      <Typography className={classes.loginUsertextfielderror}>
                        <img className={classes.erroricon} src={errorIcon} alt="error" />
                        {configJSON.websiteErrormsg}
                      </Typography>}
                  </Grid>
                </Grid>
                <Grid container className={classes.addFliedsContainer}>
                  <Grid
                    item
                    className={classes.addFliedsgrid}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Typography
                      data-testid="pricingState"
                      className={
                        this.state.pricingState
                          ? classes.shippingActivetext
                          : classes.shippingtext
                      }
                      onClick={this.handleOpenpricing}
                    >
                      {configJSON.Pricing}
                    </Typography>
                    <Typography
                      data-testid="destinationState"
                      className={
                        this.state.destinationState
                          ? classes.shippingActivetext
                          : classes.shippingtext
                      }
                      onClick={this.handleOpendestination}
                    >
                      {configJSON.destinationText}
                    </Typography>
                    <Typography
                      data-testid="descriptionState"
                      className={
                        this.state.descriptionState
                          ? classes.shippingActivetext
                          : classes.shippingtext
                      }
                      onClick={this.handleOpendescription}
                    >
                      {configJSON.Description}
                    </Typography>
                  </Grid>
                </Grid>
                {this.state.pricingState && (
                  <Grid container className={classes.shippingDetailsgrid}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography className={classes.shippingMaintext}>
                        {configJSON.fixedPrice}
                        <span className={classes.loginErrorstar}>{configJSON.star}</span>
                      </Typography>
                      {this.state.shippingData.pricings.map((pricing, index) => (
                        <TextField
                          key={index}
                          type="text"
                          data-testid="fixed_price"
                          value={pricing.fixed_price}
                          onChange={(e) => this.handlePricingChange(e, index)}
                          name="fixed_price"
                          className={classes.shippingMaintextfield}
                        />
                      ))}
                    </Grid>
                  </Grid>
                )}
                {this.state.destinationState && (
                  <Grid container className={classes.shippingDetailsgrid}>
                    <Typography className={classes.shippingDeliverytext}>
                      {configJSON.delivery}
                    </Typography>
                    {this.state.shippingData.destinations_availabilities_attributes.map((destination, index) => (
                      <>
                        <Grid item lg={6} md={6} sm={12} xs={12}
                          key={index}
                          className={classes.destinationGrid}
                        >
                          <Typography className={classes.shippingtext}>
                            {configJSON.Countries}
                            <span className={classes.loginErrorstar}>{configJSON.star}</span>
                          </Typography>
                          <FormControl
                            fullWidth
                          >
                            <Select
                             displayEmpty
                             name="countries"
                            className={classes.selectTextfield}
                            data-testid="destination_countries"
                             inputProps={{ 'aria-label': 'Without label' }}
                             multiple
                             value={this.state.shippingData.destinations_availabilities_attributes[0].countries}
                             onChange={(e) => this.handleDestinationcountryChange(e)}
                             input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                             renderValue={() => (
                               <Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
                                 {this.state.shippingData.destinations_availabilities_attributes[0].countries.map((country) => (
                                   <Chip key={country} label={country} onDelete={this.handleChipDelete} />
                                 ))}
                               </Box>
                             )}
                            >
                              {countries.map((country) => (
                                <MenuItem className='select-menu-item' key={country.isoCode} value={country.name}>
                                  {country.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Typography className={classes.shippingtext}>
                            {configJSON.States}
                            <span className={classes.loginErrorstar}>{configJSON.star}</span>
                          </Typography>
                          <FormControl
                            fullWidth
                          >
                            <Select
                              data-testid="destination_states"
                              className={classes.selectTextfield}
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip"
                             name="states"
                              multiple
                              value={this.state.shippingData.destinations_availabilities_attributes[0].states}
                              onChange={(e) => this.handleDestinationstateChange(e)}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={() => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
                                  {this.state.shippingData.destinations_availabilities_attributes[0].states.map((states) => (
                                    <Chip key={states} label={states} onDelete={this.handleChipDelete} />
                                  ))}
                                </Box>
                              )}
                            >
                              {this.state.statesList.map((states) => (
                                <MenuItem className='select-menu-item' key={states} value={states}>
                                  {states}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Typography className={classes.shippingtext}>
                            {configJSON.zipFrom}
                            <span className={classes.loginErrorstar}>{configJSON.star}</span>
                          </Typography>
                          <FormControl
                            fullWidth
                          >
                            <Select
                              name="zip_from"
                              data-testid="destination_zipfrom"
                              className={classes.selectTextfield}
                              id="demo-multiple-chip"
                              multiple
                              value={this.state.shippingData.destinations_availabilities_attributes[0].zip_from}
                              onChange={(e) => this.handleDestinationzipformChange(e)}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={() => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
                                  {this.state.shippingData.destinations_availabilities_attributes[0].zip_from.map((zip_from) => (
                                    <Chip key={zip_from} label={zip_from}  onDelete={this.handleChipDelete} />
                                  ))}
                                </Box>
                              )}
                            >
                               {this.state.statesList.map((states) => (
                                <MenuItem className='select-menu-item' key={states} value={states}>
                                  {states}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.ziptoGrid}>
                          <Box className={classes.ziptoBox}>
                            <Typography className={classes.shippingtext}>
                              {configJSON.zipTo}
                              <span className={classes.loginErrorstar}>{configJSON.star}</span>
                            </Typography>
                            <FormControl
                              fullWidth
                             
                            >
                              <Select
                                name="zip_to"
                                data-testid="destination_zipto"
                                className={classes.selectTextfield}
                                id="demo-multiple-chip"
                                multiple
                                value={this.state.shippingData.destinations_availabilities_attributes[0].zip_to}
                                onChange={(e) => this.handleDestinationziptoChange(e)}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={() => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
                                    {this.state.shippingData.destinations_availabilities_attributes[0].zip_to.map((zip_to) => (
                                      <Chip key={zip_to} label={zip_to} onDelete={this.handleChipDelete} />
                                    ))}
                                  </Box>
                                )}
                              >
                                 {this.state.statesList.map((states) => (
                                <MenuItem className='select-menu-item' key={states} value={states}>
                                  {states}
                                </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                )}
                {this.state.descriptionState && (
                  <Grid container className={classes.shippingDetailsgrid}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.shippingMaintext}>
                        {configJSON.Description}
                        <span className={classes.loginErrorstar}>{configJSON.star}</span>
                      </Typography>
                      {this.state.shippingData.descriptions_attributes.map((description, index) => (
                        <TextareaAutosize
                          key={index}
                          name="description"
                          data-testid="description"
                          value={description.description}
                          onChange={(e) => this.handleDescriptionChange(e, index)}
                          className={classes.shippingtextarea}
                          minRows={7}
                        />
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Box>
            <Grid container>
              <Grid item sm={12} className={classes.shippingButtonGrid}>
                <Button
                  className={classes.shippingDiscardbutton}
                  onClick={this.salesnavigatetoGridview}
                >
                  {configJSON.discard}
                </Button>
                <Button
                  data-testid="createShippingbutton"
                  onClick={() => { this.createShippingmethod() }}
                  className={classes.shippingSavebutton}>
                  {configJSON.save}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const webStyle: {} = () => ({
  shippingCreateMainbox: {
    marginTop: "3%"
  },
  shippingSearchgrid: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center"
  },
  shippingTextfiledgrid: {
    display: "block",
    justifyContent: "space-between",
    padding: "25px !important",
    position: "relative"
  },
  maintextfieldbox: {
    marginTop: "3%",
    width: "100%"
  },
  shippingCreateMainLayout: {
    display: "flex"
  },
  shippingCreatesideBar: {
    display: "flex",
    minHeight: "100%",
    flexGrow: 1,
    transition: "all 0.3s"
  },
  shippingCreatecontent: {
    flexGrow: 9,
    padding: "0 20px"
  },
  shippingBlock: {
    padding: "3%",
    border: "1px solid #e3e3e3",
    backgroundColor: "white"
  },
  shippingtext: {
    color: "#000000",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  shippingHeading: {
    color: "#185d85",
    fontSize: "30px",
    fontWeight: "bold",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },

  shippingtextfield: {
    fontSize: "20px",
    fontWeight: 500,
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    borderRadius: "5px",
    width: "100%",
    border: "1px solid #a6a6a6",
    "& div::after": {
      borderBottom: "1px solid #a6a6a6",
      borderRadius: "5px"
    },
    "& div::before": {
      borderBottom: "1px solid #a6a6a6",
      borderRadius: "5px"
    }
  },
  shippingFilterbox: {
    border: "1px solid #e3e3e3"
  },
  shippingColumnbox: {
    position: "relative"
  },
  shippingColumn: {
    position: "absolute",
    right: "15px",
    top: "28px"
  },
  shippingMaintext: {
    padding: "1%",
    color: "#000000",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStretch: "normal",
    display: "flex",
    alignSelf: "center"
  },
  shippingMaintextstyle:{
    padding: "1%",
    paddingTop:"5%",
    color: "#000000",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStretch: "normal",
    display: "flex",
    alignSelf: "center"
  },
  shippingMaintextfield: {
    fontSize: "16px",
    fontWeight: 500,
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    borderRadius: "5px",
    width: "97%",
    border: "1px solid #a6a6a6",
    "& div::after": {
      borderBottom: "1px solid #a6a6a6",
      borderRadius: "5px"
    },
    "& div::before": {
      borderBottom: "1px solid #a6a6a6",
      borderRadius: "5px"
    }
  },
  shippingCheck: {
    color: "#185d85 !important"
  },
  addFliedsContainer: {
    borderBottom: "2px solid #a6a6a6",
    width: "100%",
    marginTop: "2%"
  },
  shippingtextarea: {
    width: "100%"
  },
  addFliedsgrid: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    marginTop: "2%"
  },
  shippingActivetext: {
    color: "#006395",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    borderBottom: "2px solid #006395"
  },
  shippingButtonGrid: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center"
  },
  shippingDiscardbutton: {
    backgroundColor: "#f3f3f3",
    color: "#000000",
    margin: "15px 10px",
    fontSize: "16px",
    fontWeight: "500",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#000000",
      margin: "15px 10px",
      fontSize: "16px",
      fontWeight: "500",
      textTransform: "capitalize"
    }
  },
  shippingDetailsgrid: {
    marginTop: "1%"
  },
  shippingSavebutton: {
    backgroundColor: "#006395",
    color: " #ffffff ",
    margin: "15px 10px",
    fontSize: "16px",
    fontWeight: "500",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#006395",
      color: " #ffffff ",
      margin: "15px 10px",
      fontSize: "16px",
      fontWeight: "500",
      textTransform: "capitalize"
    }
  },
  shipping_methodBox: {
    width: "100%",
    position: "relative"
  },
  shipping_methodImg: {
    position: "absolute",
    top: "25px",
    right: "4%"
  },
  loginErrorstar: {
    color: "red"
  },
  companyBox: {
    display: "flex",
    position: "relative"
  },
  companyExternalImg: {
    position: 'absolute',
    left: "103%",
    top: "30%"
  },
  marginExternalImg: {
    position: 'absolute',
    left: "93%",
    top: "30%"
  },
  deliveryExternalImg: {
    position: 'absolute',
    left: "98%",
    top: "15%"
  },
  zipSelectbox: {
    display: "flex",
  },
  destinationGrid: {
    paddingRight: "2%"
  },
  ziptoBox: {
    position: "relative",
    top: "66%"
  },
  shippingDeliverytext:{
    color: "#000000",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    paddingBottom:"2%"
  },
  selectTextfield:{
 height:"60px !important",
fontSize: "20px",
fontWeight: 500,
color: " #000",
fontFamily: "Inter",
fontStretch: "normal",
fontStyle: "normal",
lineHeight: "normal",
letterSpacing: "normal",
borderRadius: "5px",
width: "100%",
border: "1px solid #a6a6a6",
"& div::after": {
  borderBottom: "1px solid #a6a6a6",
  borderRadius: "5px"
},
"& div::before": {
  borderBottom: "1px solid #a6a6a6",
  borderRadius: "5px"
}
  },
  erroricon: {
    height: "16px",
    paddingRight:"4px"
  },
  loginUsertextfielderror: {
    color: "red",
    fontsize: "14px",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    paddingTop:"2%"
  },
});
export const SalesconfigrationCreateshippingmethodStyles = withStyles(webStyle)(
  SalesconfigrationCreateshippingmethod
);
// Customizable Area End
