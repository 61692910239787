export const home = require("../src/assets/home.png");
export const sales = require("../src/assets/sales.png");
export const inventory = require("../src/assets/inventory.png");
export const terminal = require("../src/assets/pos-terminal.png");
export const purcharge = require("../src/assets/purcharge.png");
export const setting = require("../src/assets/setting.png");
export const accounting = require("../src/assets/accounting.png");
export const contacts = require("../src/assets/Contacts.png");
export const homeBlue = require("../src/assets/homeBlue.svg");
export const salesBlue = require("../src/assets/salesBlue.svg");
export const inventoryBlue = require("../src/assets/inventoryBlue.svg");
export const terminalBlue = require("../src/assets/posTerminalBlue.svg");
export const purchaseBlue = require("../src/assets/purchaseBlue.svg");
export const settingBlue = require("../src/assets/settingBlue.svg");
export const accountingBlue = require("../src/assets/accountingBlue.svg");
export const contactsBlue = require("../src/assets/ContactsBlue.svg");
export const logout = require("../src/assets/logout.svg");
export const hamburger = require("../src/assets/hamburger.svg");

export const logo =require("../src/assets/Logo.svg");

export const filter = require("../src/assets/filter.svg");
export const group = require("../src/assets/group.svg");
export const filterWhite = require("../src/assets/filterwhite.svg");
export const groupWhite = require("../src/assets/groupwhite.svg");
export const searchWhite = require("../src/assets/searchWhite.svg");
export const favorite = require("../src/assets/favorite.svg");


export const search = require("../src/assets/search.svg");
export const call = require("../src/assets/call.svg");
export const chat = require("../src/assets/chatting.svg");
export const notification = require("../src/assets/notifications.svg");
export const activity = require("../src/assets/activity.svg");

export const whitesearch = require("../src/assets/search@2x.png");
export const whitecall = require("../src/assets/call@2x.png");
export const whitechat = require("../src/assets/chatting@2x.png");
export const whitenotification = require("../src/assets/notifications@2x.png");
export const whiteactivity = require("../src/assets/activity@2x.png");
export const whiteexpand = require("../src/assets/Down_Arrow_3_ 2 Copy 3@2x.png");

export const searchGrey = require("../src/assets/searchgrey.svg");


