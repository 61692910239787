import React from "react";
import { Box, Button, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CfCustomerSettingTabsController, {
  Props,
} from "./CfCustomerSettingTabsController.web";
import { rightArrow } from '../assets';
export const configJSON = require("../config");

const catalogStyles = (theme: any) => ({
catalogroot: {
    padding: "0 8px",
  },
  catalogcontainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "120px",
  },
  catalogtextContainer: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderLeft: "1px solid #ddd",

    paddingLeft: "20px",
    height: "100%",
  },
  catalogtextHolder: {
    display: "flex",
    flexDirection: "column",
  },
  catalogboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  catalogcheckbox: {
    "& .MuiSvgIcon-root": {
      
    },
    "&:hover": {
      
      backgroundColor: "transparent !important",
    },
  },
  catalogcheckboxHolder: {
    alignSelf: "flex-start",
    height: "100%",
    display: "flex",
  },
  catalogmainHeading: {
    fontSize: "15px",
    margin: "5px 0",
    fontWeight: "bold",
  },
  catalogmainPara: {
    fontSize: "14px",
  },
  catalogbtnBox: {
    display: "flex",
    float: "right",
    marginBlock: "30px",
    gap: "20px",
  },
  catalogsaveButton: {
    background: "#2A6396",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#2A6396",
    },
  },
  catalogdiscardButton: {
    background: "white",
    textTransform: "capitalize",
  },
  catalogarrowIcon: {
    color: "#2A6396",
    transform: "rotate(45deg)",
    verticalAlign: "middle",
  },
  ".MuiTab-textColorPrimary.Mui-selected": {
    fontWeight: "bolder",
  },
  catalogshippingBtn: {
    "& .MuiButton-label": {
      "& .MuiButton-startIcon": {
        paddingTop: "1px",
        marginRight: "2px"
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    ".MuiButton-root:hover":{
      backgroundColor: "transparent",
    }
  },
});

export class CfCustomerSalesProductCatalog extends CfCustomerSettingTabsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <>
        <Box className={classes.catalogroot}>
          <div className={classes.catalogcontainer}>
            <div className={classes.catalogcheckboxHolder}>
              <Checkbox
                disableFocusRipple
                disableTouchRipple
                disableRipple
                data-test-id="product-catalog-method"
                className={classes.catalogcheckbox}
                checked={this.state.userSetting.unit_of_measure}
                onChange={(e) => this.handleUnitOfMeasureCheckboxCheck(e)}
              />
            </div>
            <div className={classes.catalogtextContainer}>
              <p className={classes.catalogmainHeading}>{configJSON.unit_of_measure}</p>
              <p className={classes.catalogmainPara}>
                {configJSON.measure_text}
              </p>
              <div className={classes.catalogboxContainer}>
                <Button
                  className={classes.catalogshippingBtn}
                  variant="text"
                  color="primary"
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  startIcon={
                    <img src={rightArrow} className='mr-10' />
                  }
                >
                  {configJSON.unit_of_measure}
                </Button>
              </div>
            </div>
          </div>
        </Box>
        <Box className={classes.catalogbtnBox}>
          <Button  
           data-test-id="discard-product-catalog"
           variant="contained"
           className={classes.catalogdiscardButton} 
           onClick={()=>this.handleProductCatalogDiscard()}>
            Discard
          </Button>
          <Button
            data-test-id="SettingProductCatalogSubmit"
            onClick={() => {
              this.handleSetting();
            }}
            variant="contained"
            className={classes.catalogsaveButton}
          >
            Save
          </Button>
        </Box>
      </>
    );
  }
}
//@ts-ignore
export default withStyles(catalogStyles)(CfCustomerSalesProductCatalog);
