import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";
export const configJSON = require("./config");

interface TabPanelProps {
children?: React.ReactNode;
index: number;
value: number;
}

export interface Props {
    navigation: any;
    id: string;
    
    
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    value: number;
    userSetting:any;
    edit:boolean;
    token:string|null;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CfcustomersalesconfigurationController extends BlockComponent<
    Props,
    S,
    SS
> {
    updateSettingApiCallId: any = "";
    userSettingApiCallId: any = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
          
        this.state = {
            // Customizable Area Start
            token:'',
            value: 0,
            edit:false,
            userSetting:{
                unit_of_measure: false,
                pricelist_name: '',
                price_list: false,
                price_list_setting: '',
                coupons_and_promotions: false
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        
        // Customizable Area End
    }

    async componentDidMount(){
      const geTokenValue =  await StorageProvider.get("TOKEN");
       this.setState({token: geTokenValue})
        this.getUserSettingApi()
    }

    componentDidUpdate(prevProps: {}, prevState: S) {
      
      if (prevState.userSetting?.price_list !== this.state.userSetting?.price_list) {
         this.setState({...this.state,userSetting:{...this.state.userSetting,price_list_setting:(!this.state.userSetting?.price_list)?"":(this.state.userSetting?.price_list_setting ? this.state.userSetting?.price_list_setting:"a")}})
      }
    }

    
    TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
    return <div hidden={value !== index}>{children}</div>;
    };

    
    getUserSettingApi = () => {
        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token":this.state.token
          };
      
          this.userSettingApiCallId = requestMessage.messageId;
      
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_settings/settings`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          runEngine.debugLog("API Message Recived", message);
          if (responseJson.errors) {
            this.showAlert(configJSON.errorTitle, configJSON.something_went_wrong);
          }
          if(responseJson.data?.id){
            this.setUserSettingId(responseJson.data?.id);
          }
        
          if ((apiRequestCallId === this.updateSettingApiCallId) && responseJson.data?.id) {
              this.updateSettingResponse()
          }
          if ((apiRequestCallId === this.userSettingApiCallId) && responseJson.data?.id) {
              this.apiResponse(responseJson);
          }
           
          
        }
        // Customizable Area End
      }

      updateSettingResponse = () => {
        this.setState({...this.state,edit:true});
              
        this.showAlert(
          configJSON.success,
          configJSON.setting_updated_msg
        );
      }


      setUserSettingId=(id:any)=>{
        typeof window !== 'undefined' && localStorage.setItem('user_setting_id',id);
      }

      
      apiResponse=(responseJson:any)=>{
        this.setState({
            ...this.state,
            edit:true,
            userSetting:{
                unit_of_measure: responseJson.data.attributes.unit_of_measure,
                pricelist_name: responseJson.data.attributes.pricelist_name,
                price_list: responseJson.data.attributes.price_list,
                price_list_setting: responseJson.data.attributes.price_list_setting,
                coupons_and_promotions: responseJson.data.attributes.coupons_and_promotions,
            }
            
          })
        
      }
    
    handleDiscard = () => {
      this.state.edit ? this.getUserSettingApi() : this.setState({...this.state,userSetting:{unit_of_measure: false,pricelist_name: '',price_list: false,price_list_setting: '',coupons_and_promotions: false}});
    }
    
    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ ...this.state,value: newValue });
    };

    
    
    handleSetting = () => {
      let formdata:any = new FormData();
      formdata.append("[data][unit_of_measure]", this.state.userSetting.unit_of_measure);
      formdata.append("[data][pricelist_name]", this.state.userSetting.pricelist_name);
      formdata.append("[data][price_list]", this.state.userSetting.price_list);
      formdata.append("[data][price_list_setting]", this.state.userSetting.price_list_setting);
      formdata.append("[data][coupons_and_promotions]", this.state.userSetting.coupons_and_promotions);
      
      const header = {
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      const user_setting_id = typeof window !== 'undefined' ? localStorage.getItem('user_setting_id') : null;
      this.updateSettingApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.edit ? `bx_block_settings/settings/${user_setting_id}` : 'bx_block_settings/settings/'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.edit ? 'PUT' : 'POST'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;      
    }
    

    

}