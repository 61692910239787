import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { imgPasswordInVisible, imgPasswordVisible, validationerror } from "./assets";
export type token = string | null;
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorElement: HTMLElement | null;
  anchorEl: HTMLElement | null;
  selectedFilter: string;
  isChecked: boolean;
  isAllChecked: boolean;
  token: token;
  responseData:any;
  rowsPerPage: number;
  currentPage: number;
  row: {
    from: number;
    end: number;
  };
  totalCounts: number;
  totalPage: number;
  query: string;
  dataLength: number;
  anchorElGroupBy: HTMLElement | null;
  anchorElFilter: HTMLElement | null;
  params: { [name: string]: boolean | string };
  selectedItems: {[name: string]: boolean;};
  isLoading: boolean;
  isGroupby: boolean;
  isExpanded: boolean[];
  queryItems: { id: string, tag: string, key: string, type: string }[];
  tableData:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface Option {
  value: string;
  label: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createShippingMethodAPI: string = "";
  getShippingMethodAPI: string = "";
  getUomCategories: string = "";
  getSingleShippingMethodAPI: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorElement: null,
      anchorEl: null,
      selectedFilter: "",
      isChecked: false,
      isAllChecked: false,
      token: "",
      responseData:[],
      rowsPerPage: 9,
      currentPage: 1,
      row: {
        from: 1,
        end: 9,
      },
      totalCounts: 0,
      totalPage: 0,
      query: "",
      dataLength: 0,
      anchorElGroupBy: null,
      anchorElFilter: null,
      params: {},
      selectedItems: {},
      isLoading: false,
      isGroupby: false,
      isExpanded:[],
      queryItems: [],
      tableData:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {this.receiveApi(apiRequestCallId, responseJson);}
      runEngine.debugLog("API Message Recived", message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginStyles")
    }
    else {
      this.fetchShippingData();
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const token = this.state.token;

    if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.query !== this.state.query) {
      let searchString = ""
      const { currentPage, rowsPerPage } = this.state;
      if (this.state.query.length >= 3) {
          console.log(searchString)
         searchString = `&search=${this.state.query}` }
      const params = `per_page=${rowsPerPage}&page=${currentPage}${searchString}`
      this.getShippingMethodAPI = this.handleApiCall(token, `bx_block_settings/shipping_methods?${params}`, "GET");
    }

    else if (prevState.queryItems !== this.state.queryItems ||
      (prevState.currentPage !== this.state.currentPage ||
        prevState.rowsPerPage !== this.state.rowsPerPage)
    ) {
        this.fetchShippingData()
    }
  }

  filterOptions = [
    { name: "Archived", key: "archived" },
  ];

  groupByOptions = [
    { name: "Provider", key: "group_by_provider" },
  ];

  SettingOptions = [
    { name: "Export" },
    { name: "Archive" },
    { name: "Unarchive" },
    { name: "Delete" },
  ];


  receiveApi = (
    apiRequestCallId: string,
    responseJson: {
      data: object[];
      meta: { total_pages: number; total_counts: number };
    }
  ) => {
    if (apiRequestCallId === this.getShippingMethodAPI) {
      this.handleGetItemResponse(
        responseJson.data,
        responseJson.meta.total_pages,
        responseJson.meta.total_counts
      );
    }
  };

  handleGetItemResponse = (
    data: object[],
    totalPage: number,
    totalCounts: number
  ) => {
    const tableInfo = this.state.isGroupby ? this.getCollapseTableKey(data) :this.getTableKey(data);    
    this.setState({ responseData: tableInfo, totalPage, totalCounts, isLoading: false });
  };


  getTableKey = (data: any) => {
    const requiredData: any = [];
    data.map((item: any) => {
      const attributes = item.attributes;
      const type = item.type;
      const id = item.id;
      const shippingInfo: any = {
        id,
        type,
        checked: false,
        shipping_method: attributes?.shipping_method || "",
        provider: attributes?.provider,
        company: attributes?.company,
        margin_on_rate: attributes?.margin_on_rate || "",
        free_margin: attributes?.free_margin || 0,
        amount: attributes?.amount || "",
        delivery_product: attributes?.delivery_product || "",
        website: attributes?.website || "",
        is_published: attributes?.is_published || "",
        archived: attributes?.archived || 0,
        pricings: attributes?.pricings || 0,
        destinations_availabilities: attributes?.destinations_availabilities || "",
        descriptions: attributes?.descriptions || 0,
      };
      requiredData.push(shippingInfo);
    })
    return requiredData;
  }

  getCollapseTableKey = (data: any) => {
    this.setState({
      isLoading: true,
    });
    const requiredData = data.map((item: any, index: any) => {
      let payload = {
        "key": item.key,
        data: [],
      };
      payload.data = item.shipping_methods.data.map((method: any) => {
        method.attributes.checked = false;
        return method.attributes;
      });      
      return payload;
    });
    return requiredData;
  };

  handleFilterClickAway = () => {
    this.setState({ anchorElFilter: null });
  };
  
  handleSettingsClickAway = () => {
    this.setState({ anchorEl: null });
  };

  handleGroupClickAway = () => {
    this.setState({ anchorElGroupBy: null });
  };

  handleClickGroupBy = (e: any) => {
    this.setState({
      anchorElGroupBy: this.state.anchorElGroupBy ? null : e.currentTarget,
    });
  };

  handleClickFilter = (e: any) => {
    this.setState({anchorElFilter: this.state.anchorElFilter ? null : e.currentTarget,});
  };

  handleClickSetting = (e: any) => {
    this.setState({anchorEl: this.state.anchorEl ? null : e.currentTarget,});
  };

  handleGroupBySelect = (item: { name: string; key: string }) => {
    this.setState((prevState) => {
      const updatedQueryItems = [...prevState.queryItems];
      const existingIndex = updatedQueryItems.findIndex(
        (queryItem) => queryItem.type === "groupby"
      );

      if (existingIndex !== -1) {
        updatedQueryItems[existingIndex] = {id: item.key,tag: item.name,key: item.key,type: "groupby", };
      } else {
        updatedQueryItems.push({id: item.key,tag: item.name,key: item.key,type: "groupby",});
      }

      return { queryItems: updatedQueryItems };
    });
    this.setState({
      isGroupby: true,
      anchorElGroupBy: null,
      isAllChecked: false,
      selectedItems: {}
    })
  };

  handleFilterAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const filterData = this.state.responseData as any[]
    const checkBoxData = filterData.map((item: any) => {
      item.checked = !this.state.isAllChecked;
      if (item.checked) {updatedSelectedItem[item.id] = item.checked;} 
      else {
        if (updatedSelectedItem.hasOwnProperty(item.id)) {delete updatedSelectedItem[item.id];}
        this.handleSettingsClickAway();
      }
      return item;
    });
    this.setState((prev) => {
      return {
        responseData: checkBoxData,
        isAllChecked: !prev.isAllChecked,
        selectedItems: updatedSelectedItem,
      };
    });
    this.setState({
      isLoading: false,
    })
  }

  handleGroupbyAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const groupbyData = this.state.responseData as any[]
    const checkBoxData = groupbyData.map((element: any) => {
      let updatedElement = element
      updatedElement.data = element.data.map((item: any) => {
        item.checked = !this.state.isAllChecked;
        if (item.checked) {updatedSelectedItem[item.id] = item.checked;} 
        else {
          if (updatedSelectedItem.hasOwnProperty(item.id)) { delete updatedSelectedItem[item.id];}
          this.handleSettingsClickAway();
        }
        return item;
      })
      return updatedElement
    }
    );
    this.setState((prev) => {
      return {
        responseData: checkBoxData,
        isAllChecked: !prev.isAllChecked,
        selectedItems: updatedSelectedItem,
        isLoading: false,
      };
    });
    this.forceUpdate();
  }


  handleFilterSelect = (item: { name: string; key: string }) => {
    const itemExists = this.state.queryItems.some(queryItem => queryItem.id === item.key);

    if (!itemExists) {
      this.setState(prevState => ({
        queryItems: [
          ...prevState.queryItems,
          { id: item.key, tag: item.name, key: item.key, type: "filter" }
        ],
        anchorElFilter: null,
        isAllChecked: false,
        selectedItems: {}
      }));
    } else {
      this.setState({
        anchorElFilter: null, isAllChecked: false, selectedItems: {}});
    }
  };


  handleAllChecked = () => {
    let updatedSelectedItem = Object.assign({}, this.state.selectedItems);
    if (!this.state.isGroupby) {this.handleFilterAllChecked(updatedSelectedItem)} 
    else {this.handleGroupbyAllChecked(updatedSelectedItem)}
  };

  handleChangePage = (pageNo: number) => {
    this.setState({
      currentPage: pageNo + 1,
      isLoading: true,
    });
    if (this.state.currentPage > pageNo) {
      this.setState((prev) => ({
        row: {from: prev.row.from - this.state.rowsPerPage,end: prev.row.end - this.state.rowsPerPage,},
      }));
    } else {
      this.setState((prev) => ({
        row: {from: prev.row.from + this.state.rowsPerPage,end: prev.row.end + this.state.rowsPerPage,},
      }));
    }
    this.setState({isAllChecked: false,})
  };

  handleChangeRowsPerPage = (event:any) => {
    this.setState({currentPage: 1,rowsPerPage: parseInt(event.target.value as string),});
  };

  toggleColumn = (anchorElement: HTMLElement | null) => {
    this.setState({ anchorElement });
  };

  toggleIsExpanded = (rowKey: number) => {
    const expandableRows=this.state.isExpanded
    expandableRows[rowKey] = !expandableRows[rowKey]
    this.setState({isExpanded:expandableRows})
  };

  handleRemoveTag = (item: { id: string; tag: string; key: string; type: string; }) => {
    const updatedQueryItems = this.state.queryItems.filter((queryItem) => queryItem.key !== item.key);
    if (item.type ==="groupby"){
      this.setState({ 
        isGroupby: false ,
        queryItems: updatedQueryItems , 
        query: "",
        currentPage: 1,
        isAllChecked:false,
        selectedItems:{}
      });
    }
    else{
      this.setState({
        queryItems: updatedQueryItems,
        query: "",
        currentPage: 1,
        selectedItems: {}
      });
    }
  }

  onChangeSearchField = (e: any) => {
    const { value } = e.target;
    this.setState({
      query: value,
      currentPage: 1,
      isLoading: true
    });
    return {};
  }

  handleRowClick = (shipId: string) => {
    this.props.navigation.navigate("SalesconfigurationCreateshippingmethod1", { id: shipId });
  }


  fetchShippingData = async () => {
    this.setState({
      isLoading: true,
    })
    const token = this.state.token;
    const { currentPage, rowsPerPage } = this.state;
    let params = {} as any
    this.state.queryItems.forEach((item: any) => {
      if (item.hasOwnProperty("key")) {params[item.key] = true}
    })

    const str = Object.keys(params).map(function (key) {
        return key + "=" + params[key];
      }).join("&");
    this.getShippingMethodAPI = this.handleApiCall(
      token,
      this.state.isGroupby ? `bx_block_settings/shipping_methods?${str}` : `bx_block_settings/shipping_methods?page=${currentPage}&per_page=${rowsPerPage}&${str}`,
      "GET"
    );
  };


  handleCheckBoxSelectGroupby = (item: any, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: any) => {
    if (item.checked) {updatedSelectedItem[item.id] = true;} 
    else {if (updatedSelectedItem.hasOwnProperty(item.id)) {delete updatedSelectedItem[item.id];
      if (!item.checked) {
        this.handleSettingsClickAway();
      }
    }
  
  }

    checkBoxData.map((element:any,index:number)=>{
      element.data.map((ele:any,ind:any)=>{
        if(ele.id==item.id){
          element=item
        }
      })

    })
    return checkBoxData.every((element: any) => {
      return element.data.every((item: any) => item.checked) === true
    })
  }

  handleCheckBoxSelectFilter = (item: any, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: any) => {
    if (item.checked) {updatedSelectedItem[item.id] = true;} 
    else if (updatedSelectedItem.hasOwnProperty(item.id)) { delete updatedSelectedItem[item.id];}
    checkBoxData[index] = item;
    return checkBoxData.every((item: any) => item.checked);
  }


  handleCheckBoxSelect = (item: any, index: number) => {
    const updatedSelectedItem = { ...this.state.selectedItems };
    const checkBoxData = [...this.state.responseData] as any
    let isAllChecked = false
    item.checked = !item.checked;
    if (this.state.isGroupby) {
      isAllChecked = this.handleCheckBoxSelectGroupby(item, index, updatedSelectedItem, checkBoxData);
    } else {
      isAllChecked = this.handleCheckBoxSelectFilter(item, index, updatedSelectedItem, checkBoxData);
    }

    if (!item.checked){
      this.handleSettingsClickAway();
    }

    this.setState({
      responseData: checkBoxData,
      selectedItems: updatedSelectedItem,
      isAllChecked: isAllChecked,
    });
    this.setState({ isLoading: false,})
  };

  handleSingleDigit = (num: number) => {
    let convertedNum = "";
    if (num < 10) convertedNum = '0' + num;
    else convertedNum = num.toString();
    return convertedNum;
  }


  handleApiCall = (token: token, endPoint: string, method: string, body?: object) => {
    const header = {
      token: token, 
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
