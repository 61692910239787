// Customizable Area Start
import React from 'react';
import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core';
import '../../settings2/assets/style.css'
import Sidebar from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { addrow, validationerror, delrow } from '../../cfcustomersalesreports3/src/assets';
import AddPosProductCategoryController, {Props} from './AddPosProductCategoryController.web';
export const configJSON = require("../../settings2/src/config");
export const configJSON1 = require("./config.js");


// Customizable Area End


export class AddUomCategories extends AddPosProductCategoryController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes }:any = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <Box className={classes.adduomMainLayout}>
                <Box className={classes.adduomsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.adduomcontent}>
                    {" "}
                    <AppHeader pageTitle="Configuration" />
                    <Box className={classes.parentBox} style={{backgroundColor:"#ffffff"}}>
                    <Box className={classes.adduomBlock}>
                        <Grid container spacing={0} >
                        <Grid item sm={12} className='left-center-align'>
                            <Typography className={classes.adduomHeading}>{configJSON1.addUomHeading}</Typography>
                        </Grid>
                        </Grid>
                    </Box>
                <Box style={{padding:"15px 0"}}>
                    <Grid container spacing={0} >
                    
                        <Grid item sm={12}>
                        <Grid item sm={6}>
                        <Box sx={{padding:"0 15px"}}>
                            <Typography className={`${this.handleUomCategoryError(1)}`}>{configJSON1.uomCategory}<span className='required'>*</span></Typography>
                            <Box className='relative'>
                            <TextField data-test-id="uomCategoryInput" 
                            placeholder='Enter UoM Category'
                            className={`form-input width-100 ${this.handleUomCategoryError(2)}`} 
                            value={this.state.uomCategory}
                            onChange={(e:any) => this.setState({...this.state,uomCategory:e.target.value})} 
                            name="category" type="text" />
                            <Typography className={`main-color bold-text ${classes.languageText}`}>{configJSON1.en}</Typography>
                            <Box className={`flex relative ${this.hideUomCategoryError()}`} >
                            <img className='error-text-img' src={validationerror} />
                            <Typography className='error-text'>{configJSON1.categoryMessage}</Typography>
                            </Box>
                            </Box>
                        </Box>
                        </Grid>
                        <Grid item sm={6}>

                        </Grid>
                        
                        
                        </Grid>
                        
                    
                    </Grid>
                    <Grid container spacing={0} >
                    <Grid item sm={12} className='account_details' >
                    <Typography className='main-color bold-text'>{configJSON1.addUomTh1}</Typography>
                    </Grid>
                    <Grid item sm={12} className='bank-grid' >
                    <Grid item sm={3}  >
                        <Typography className='main-color bold-text'>{configJSON1.addUomTh2}</Typography>
                    </Grid>
                    <Grid item sm={4}  >
                        <Typography className='main-color bold-text'>{configJSON1.addUomTh3}</Typography>
                    </Grid>
                    <Grid item sm={2}  >
                        <Typography className='main-color bold-text'>{configJSON1.addUomTh4}</Typography>
                    </Grid>
                    <Grid item sm={2}  >
                        <Typography className='main-color bold-text'>{configJSON1.addUomTh5}</Typography>
                    </Grid>
                    <Grid item sm={1} className='end-center-align'>
                        <img 
                        onClick={this.addAnotherColumn} 
                        src={addrow}/>
                    </Grid>
                    </Grid>
                    <Grid item sm={12} style={{padding:0}}  >
                    <hr className='grid-hr'/>
                    </Grid>
                    {
                        this.state.measure_units.map((detail:any,index:number)=>{
                        return <>
                                <Grid item sm={12} className='account-grid'  >
                                <Grid item sm={3}  className='pl-5 left-center-align'>
                                <FormControl id="select-form-control" className="width-100 account-tab-select">
                                <Select
                                    IconComponent={this.expandMore}
                                    data-test-id="uomInput"
                                    inputProps={{
                                        'aria-label': 'custom select',
                                    }}
                                    value={detail.uom}
                                    onChange={(e:any)=>this.handleMeasureUnitsDetails(e,index,'uom')}
                                    label="Select"
                                    disableUnderline
                                    className="width-100"
                                    MenuProps={{
                                        PaperProps: {
                                        style: {
                                            marginTop:52,
                                        },
                                        },
                                    }}
                                >
                                    {
                                        this.uomOption()
                                    }
                                    
                                </Select>
                                </FormControl>
                                </Grid>
                                <Grid item sm={4} className='left-center-align' >
                                <FormControl id="select-form-control" className="width-100 account-tab-select">
                                <Select
                                    IconComponent={this.expandMore}
                                    data-test-id="uomTypeInput"
                                    inputProps={{
                                        'aria-label': 'custom select',
                                    }}
                                    value={detail.uom_type}
                                    onChange={(e:any)=>this.handleMeasureUnitsDetails(e,index,'uom_type')}
                                    label="Select"
                                    disableUnderline
                                    className="width-100 uom-type-select"
                                    MenuProps={{
                                        PaperProps: {
                                        style: {
                                            marginTop:52,
                                        },
                                        },
                                    }}
                                >
                                    <MenuItem value="Reference Unit of Measure for this category">Reference Unit of Measure for this category</MenuItem>
                                    <MenuItem value="Smaller than the reference Unit of Measure for this category">Smaller than the reference Unit of Measure for this category</MenuItem>
                                    <MenuItem value="Bigger than the reference Unit of Measure for this category">Bigger than the reference Unit of Measure for this category</MenuItem>
                                    
                                </Select>
                                </FormControl>
                                </Grid>
                                <Grid item sm={2} className='left-center-align' >
                                
                                {
                                    this.getRatioColumn(detail.uom_type,detail.ratio,index,classes,'1.00000','ratio')
                                }
                                </Grid>
                                <Grid item sm={2} className='left-center-align' >
                                {
                                    this.getRatioColumn(detail.uom_type,detail.rounding_precision,index,classes,'0.01000','rounding_precision')
                                }
                                </Grid>
                                <Grid item sm={1} className='end-center-align delrow'>
                                <img 
                                onClick={()=>this.removeColumn(index)} 
                                src={delrow}/>
                                </Grid>
                                </Grid>
                                <Grid item sm={12} style={{padding:0}}  >
                                <hr className='grid-hr'/>
                                </Grid>
                                </>
                                })
                    }
    
                    </Grid>
                    </Box>
                </Box>
                <Box className='end-center-align' style={{margin:"20px 0"}}>
                <Button className={classes.discardBtn} data-test-id="discard-uom-category" variant="outlined" onClick={this.discardUomCategory}>{configJSON.discard}</Button>
                <Button data-test-id="save-uom-category" variant="contained" onClick={this.addUomCategories} style={{backgroundColor:"#026494",color:"#ffffff"}}>{configJSON.save}</Button>
                
                </Box>
                </Box>
            </Box>
            
            </>
            // Customizable Area End
        )

    }
}

// Customizable Area Start
export const createuomwebStyle: any = {
    adduomMainLayout: {
        display: "flex",
    },
    adduomsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    adduomcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    adduomBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    parentBox: {
        border: "1px solid #e3e3e3",
        borderRadius:"5px"
    },
    adduomHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    pb10: {
        paddingBottom:"10px !important"
    },
    discardBtn:{
        marginRight: "15px",
        backgroundColor: "white",
        border: "1px solid #e6e6e6"
    },
    languageText:{
        position:"absolute",
        right:20,
        top:20
    },
    ratioStyle:{
    paddingRight: "30px",
    marginLeft: "-5px",
    },
    
    roundingStyle:{
    paddingRight: "30px",
    marginLeft: "-10px",
    }
    
}
export default withStyles(createuomwebStyle)(AddUomCategories)
  // Customizable Area End