Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfbankcashrecitevoucher4";
exports.labelBodyText = "cfbankcashrecitevoucher4 Body";

exports.contacts = "Contacts"
exports.banks = "Banks"
exports.new = "New"
exports.save = "Save"
exports.create = "Create"
exports.edit = "Edit"
exports.discard = "Discard"
exports.createBank = "Create Bank"
exports.name= "Name"
exports.identifierCode = "BANK IDENTIFIER CODE"
exports.country = "COUNTRY"
exports.bankApiUrl = "bx_block_cfbankcashrecitevoucher4/banks"
exports.addBankApiUrl = "bx_block_cfbankcashrecitevoucher4/banks"
exports.bank_created_msg = "Bank created successfully.";
exports.bank_updated_msg = "Bank updated successfully.";

exports.btnExampleTitle = "CLICK ME";

exports.mainHeading = "Users";
exports.access_rights = "Access Rights";
exports.Filters = "Filters";
exports.Favorites = "Favorites";
exports.buttonSave = "Save";
exports.Createusers = "Create Users";
exports.CreateAccessRights = "Create Access Rights";
exports.create_access_rights = "Create access rights";
exports.user_created_msg = "User created successfully.";
exports.company_created_msg = "Company created successfully."
exports.Groupby = "Group by";
exports.name = "NAME";
exports.sales = "SALES PERSON";
exports.phone = "PHONE";
exports.email = "EMAIL";
exports.city = "CITY";
exports.country = "COUNTRY";
exports.company = "COMPANY";
exports.addField= "+ ADD CUSTOM FIELD";
exports.itemsPage = "Items per page: ";
exports.pageOne = "01";
exports.pageTwo = "02";
exports.pageThree = "03";
exports.pageFour ="04";
exports.pageFive = "05";
exports.pageSix = "06";
exports.pageSeven = "07";
exports.pageEight = "08";
exports.pageNine = "09";
exports.pageTen = "10";
exports.pageTwenty = "20";
exports.pagination = "1-5 of 20";
exports.services = "Consulting services";
exports.appliances = "Electronics appliances vendor";

exports.btnExampleTitle = "CLICK ME";

exports.settings = "Settings";
exports.users = "Users";
exports.new = "New";
exports.name = "Name";
exports.email_address = "Email Address";
exports.access_rights = "Access Rights";
exports.preferences = "Preferences";

exports.archive = "Archive";
exports.duplicate = "Duplicate";
exports.delete = "Delete";
exports.changePassword = "Change Password";
exports.relatedPartner = "Related Partner";
exports.multiCompanies = "Multi Companies";
exports.defaultCompany = "Default Company";
exports.userTypes = "User Types";
exports.ecommerceUser = "E-commerce User";
exports.internalUser = "Internal User";
exports.localization = "Localization";
exports.language = "Language";
exports.timezone = "Timezone";
exports.notification = "Notification";
exports.handle_by_emails = "Handle By Emails";
exports.handle_by_customer = "Handle By Customers";
exports.discard = "Discard";
exports.save = "Save";
exports.userlogin = "User login";
exports.allowedCompanies = "Allowed Companies";
exports.sales = "Sales";
exports.purchase = "Purchase";
exports.accounting = "Accounting";
exports.humanResources = "Human Resources";
exports.settings = "Settings";
exports.pointOfSale = "Point Of Sale";
exports.inventory = "Inventory";
exports.contacts = "Contacts";
exports.sign = "Sign";
exports.approval = "Approval";
exports.tag = "Tag";
exports.getUserlistAPiEndPoint = "";
exports.global = "Global";
exports.full_access = "Full Access";
exports.read_access = "Read Access";
exports.write_access = "Write Access";
exports.archived = "Archived";
exports.group = "Group";
exports.model = "Model";

exports.errorTitle1 = "Error";
exports.errorTitle = "Error";
exports.something_went_wrong = "Something went wrong !! Try again.."
exports.emailErrorTitle = "Email Error";
exports.errorEmailNotValid = "Email not valid.";
exports.errorMobileLengthNotValidMin = "Please enter a phone number with at least 10 digits.";
exports.errorMobileLengthNotValidMax = "Please enter a phone number with a maximum of 15 digits";
exports.errorAllFieldsAreMandatory = "All * fields are mandatory.";
exports.success = "Success";
exports.access_right_created_msg = "Access Right created successfully.";
exports.Createposproductcatgeory = "Create POS Product Category";

exports.unit_of_measure = "Unit of Measure";
exports.measure_text = "Sell and purchase products in different units of measure";
exports.pricelist_name = "Pricelist Name";
exports.pricelists = "Pricelists";
exports.pricelist = "Pricelist";
exports.pricelists_text = "Set multiple prices per product, automated discounts, etc.";
exports.multiple_prices_per_product = "Mutiple prices per product";
exports.advanced_price_rules = "Advanced price rules (discounts, formulas)";
exports.coupons_and_promotions = "Coupons & Promotions";
exports.manage_programs = "Manage promotion & coupon programs";
exports.coupons_programs = "Coupon Programs";
exports.promotion_programs = "Promotion Programs";
exports.configuration = "Configuration"
exports.shipping_methods = "Shipping Method"
exports.pricing = "Pricing"
exports.destination_availability = "Destination Availability"
exports.description = "Description"
exports.websiteValidation = /^(https?:\/\/)?(www\.)?[a-z\d]+([-.][a-z\d]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/i

exports.companies = "Companies"
exports.createCompanies = "Create Companies"
exports.parentCompany = "Parent Company"
exports.saveCurrentSearch = "Save Current Search"
exports.importRecords = "Import Records"
exports.addToDashboard = "Add to my Dashboard"
exports.useByDefault = "Use by default"
exports.shareWithAllUsers = "Share with all users"
exports.previewInternalReport = "Preview Internal Report"
exports.previewExternalReport = "Preview External Report"
exports.reportLayoutPreview = "Report Layout Preview"
exports.export = "Export"
exports.companyName= "COMPANY NAME"
exports.partner = "PARTNER"
exports.email = "EMAIL"
exports.phone = "PHONE"
exports.bankAccountHeading="Bank Accounts"
// Customizable Area End