import React from "react";

import {
    Box,
    Button,
    Typography,
    InputAdornment,
    // Customizable Area Start
    Grid,
    TextField,
    FormControl,
    Tabs,
    Tab,
    Select,
    MenuItem,
    Checkbox,
    Divider,
    Paper,
    Chip
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import CustomMultiSelect from "../../../components/src/CustomMultiSelect.web";
const { external_link, checkboxChecked, checkboxUnchecked } = require("./assets");
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import { Country } from "country-state-city";


const theme = createTheme({
    palette: {
        primary: {
            main: "#e3e3e3",
            contrastText: "#000",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import ShippingMethodCreationController, {
    Props,
    configJSON,
} from "./ShippingMethodCreationController.web"

export default class ShippingMethodCreation extends ShippingMethodCreationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box style={webStyle.gridMainLayout}>
                    <Box style={webStyle.gridsideBar}>
                        <Sidebar />
                    </Box>
                    <Box className="gridcontent" style={webStyle.gridcontent}>
                        <AppHeader pageTitle={configJSON.configuration} />
                        <Box className="parent-div" >
                            <style>
                                {`
                                .MuiInput-underline:after {
                                    border-bottom: 0px !important;
                                },
                                .MuiSelect-selectMenu{
                                    border: 1px solid #e3e3e3;
                                }
                                .MuiInputBase-root{
                                    border-radius: 5px;
                                }
                       
                                .MuiInputAdornment-positionEnd{
                                    padding : 10px;
                                }
                                .MuiFormControl-fullWidth{
                                    width: 90%;
                                }
                                .MuiChip-root{
                                    background-color:#e3e3e3 !important;
                                    color:#000 !important;
                                }
                                .MuiChip-deleteIcon{
                                    color:#000 !important;
                                }
                                .MuiIconButton-root:hover{
                                    background-color: transparent !important;
                                }
                                #tags-outlined-popup {
                                    padding:0 !important;
                                }
                                li[role="option"] {
                                    border-bottom: 1px solid #E3E3E3;
                                }
                                ul[role="listbox"] {
                                    padding-top:0 !important;
                                    padding-bottom:0 !important;
                                }
                                .MuiSelect-icon{
                                    color:black !important;
                                }
                                .MuiFormControl-root{
                                    margin-top: 10px !important;
                                    margin-bottom: 10px !important;
                                }
                                .MuiAutocomplete-inputRoot{
                                    margin-top: 0px !important;
                                }
                                .form-marginonrate .MuiInputBase-root {
                                    border: 1px solid #e3e3e3;
                                    border-radius: 5px;
                                }

                                .form-marginonrate .MuiInputBase-input {
                                    border: 0px !important;
                                }

                                .form-methodName .MuiInputBase-root {
                                    border: 1px solid #e3e3e3;
                                    border-radius: 5px;
                                }

                                .form-methodName .MuiInputBase-input {
                                    border: 0px !important;
                                }

                                .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
                                    padding:4px;
                                }
                                
                            `}
                            </style>
                            <Grid container spacing={0} style={{ display: "flex", flexDirection: "column" ,marginTop:"25px" }}>
                                <Grid item sm={3} className='left-center-align'>
                                    <Typography className="customer bold-text" >{configJSON.shipping_methods}</Typography>
                                </Grid>
                                <Divider style={webStyle.divider}/>
                                <Grid item sm={12} className='relative' >
                                    {
                                        this.mainErrorBox()
                                    }
                                </Grid>

                                <Box className="form-div">
                                    <Grid container spacing={4}>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Typography className={`${this.errorTextClass('methodName', this.state.shippingMethod.methodName)}`} style={{ whiteSpace: "nowrap", alignSelf: "center", marginRight: "25px", }}>Shipping Method<span className='required'>*</span></Typography>
                                                <Box sx={{width:"100%"}}>
                                                <TextField
                                                    data-test-id="methodName"
                                                    name="methodName"
                                                    placeholder="Enter Shipping Method Name"
                                                    fullWidth
                                                    className={`form-methodName ${this.errorFocusClass('methodName', this.state.shippingMethod.methodName)}`}
                                                    value={this.state.shippingMethod.methodName}
                                                    onChange={(e: any) => this.handleValueChange(e, "methodName")}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end"><Typography style={{color:"black"}}>EN</Typography></InputAdornment>,
                                                    }}
                                                    style={{width:"100%"}}
                                                />
                                                {
                                                    this.handleErrorMessage('methodName')
                                                }
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} justifyContent="space-between" >
                                        <Grid item sm={6} md={6} lg={6}>
                                            <Typography className={`${this.errorTextClass('provider', this.isValueOrPlaceholder(this.state.shippingMethod.provider, "Select Provider"))}`}>Provider<span className='required'>*</span></Typography>
                                            <FormControl id="select-form-control" style={{ width: "90%" }}>
                                                <Select
                                                        inputProps={{
                                                            'aria-label': 'custom select',
                                                        }}
                                                        label="Select"
                                                        displayEmpty
                                                        disableUnderline
                                                        className={`width-100 ${!this.state.shippingMethod.provider && 'select-default-color'} ${this.errorFocusClass('provider', this.state.shippingMethod.provider)}`}
                                                        data-test-id="provider"
                                                        renderValue={() => this.state.shippingMethod.provider || "Select Provider"}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    marginTop: 55,
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                    width: "fit-content"
                                                                },
                                                            },
                                                        }}
                                                        value={this.state.shippingMethod.provider}
                                                        onChange={(e: any) => this.handleValueChange(e, "provider")}
                                                        IconComponent={ExpandMoreIcon}
                                                    >
                                                        <MenuItem disabled value="" style={{ display: 'none', color: "#E5E5E5" }}>
                                                            Select Provider
                                                        </MenuItem>
                                                        {this.providerOptions.map((item) => (
                                                            <MenuItem className='select-menu-item' value={item.value}>{item.label}</MenuItem>
                                                        ))}
                                                    </Select>

                                            </FormControl>
                                            {
                                                this.handleErrorMessage('provider')
                                            }
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6}>
                                            <Typography className={`${this.errorTextClass('deliveryProduct', this.isValueOrPlaceholder(this.state.shippingMethod.deliveryProduct,"Select Delivery Product"))}`}>Delivery Product<span className='required'>*</span></Typography>
                                            <Box style={{display:"flex" , flexDirection:"row" , alignItems:"center"}}>
                                                <FormControl id="select-form-control" style={{ width: "90%" }}>

                                                    <Select
                                                        inputProps={{
                                                            'aria-label': 'custom select',
                                                        }}
                                                        label="Select"
                                                        displayEmpty
                                                        disableUnderline
                                                        className={`width-100 ${!this.state.shippingMethod.deliveryProduct && 'select-default-color'} ${this.errorFocusClass('deliveryProduct', this.state.shippingMethod.deliveryProduct)}`}
                                                        data-test-id="deliveryProduct"
                                                        renderValue={() => this.state.shippingMethod.deliveryProduct || "Select Delivery Product"}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    marginTop: 55,
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                    width: "fit-content"
                                                                },
                                                            },
                                                        }}
                                                        value={this.state.shippingMethod.deliveryProduct}
                                                        onChange={(e: any) => this.handleValueChange(e, "deliveryProduct")}
                                                        IconComponent={ExpandMoreIcon}
                                                    >
                                                        <MenuItem disabled value="" style={{ display: 'none', color: "#E5E5E5" }}>
                                                            Select Delivery Product
                                                        </MenuItem>
                                                        {this.productOptions.map((item) => (
                                                            <MenuItem className='select-menu-item' value={item.value}>{item.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <img src={external_link} style={{paddingLeft: "10px" }} />
                                            </Box>
                                            {
                                                this.handleErrorMessage('deliveryProduct')
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} justifyContent="space-between" >
                                        <Grid item sm={6} md={6} lg={6}>
                                            <Typography className={`${this.errorTextClass('company', this.isValueOrPlaceholder(this.state.shippingMethod.company, "Select Company"))}`}>Company<span className='required'>*</span></Typography>
                                            <Box style={{display:"flex" , flexDirection:"row", alignItems:"center"}}>
                                                <FormControl id="select-form-control" style={{width:"90%"}}>
                                                <Select
                                                        inputProps={{
                                                            'aria-label': 'custom select',
                                                        }}
                                                        label="Select"
                                                        displayEmpty
                                                        disableUnderline
                                                        className={`width-100 ${!this.state.shippingMethod.company && 'select-default-color'} ${this.errorFocusClass('company', this.state.shippingMethod.company)}`}
                                                        data-test-id="company"
                                                        renderValue={() => this.state.shippingMethod.company || "Select Company"}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    marginTop: 55,
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                    width: "fit-content"
                                                                },
                                                            },
                                                        }}
                                                        value={this.state.shippingMethod.company}
                                                        onChange={(e: any) => this.handleValueChange(e, "company")}
                                                        IconComponent={ExpandMoreIcon}
                                                    >
                                                        <MenuItem disabled value="" style={{ display: 'none', color: "#E5E5E5" }}>
                                                            Select Company
                                                        </MenuItem>
                                                        {this.companyOptions.map((item) => (
                                                            <MenuItem className='select-menu-item' value={item.value}>{item.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <img src={external_link} style={{paddingLeft: "10px" }} />
                                            </Box>
                                            {
                                                this.handleErrorMessage('company')
                                            }
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6}>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={4} justifyContent="space-between">
                                        <Grid item sm={6} md={6} lg={6} >
                                            <Typography className={`${this.errorTextClass('marginOnRate', this.state.shippingMethod.marginOnRate)}`}>Margin on rate<span className='required'>*</span></Typography>
                                            <TextField
                                                data-test-id="marginOnRate"
                                                name="marginOnRate"
                                                type="number"
                                                style={{width:"90%"}}
                                                placeholder="Enter Margin on Rate"
                                                value={this.state.shippingMethod.marginOnRate}
                                                className={`form-marginonrate ${this.errorFocusClass('marginOnRate', this.state.shippingMethod.marginOnRate)}`}
                                                onChange={(e: any) => this.handleValueChange(e, "marginOnRate")}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end" style={{ color: "black" }}><Typography>%</Typography></InputAdornment>,
                                                }}
                                            />
                                            {
                                                this.handleErrorMessage('marginOnRate')
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} justifyContent="space-between">
                                        <Grid item sm={6} md={6} lg={6} >
                                            <Box display="flex" flexDirection="column" >
                                                <Box display="flex" flexDirection="row" alignItems="center">
                                                    <Typography>Free if Ordered amount is above</Typography>
                                                    <Checkbox
                                                        disableRipple
                                                        disableTouchRipple
                                                        disableFocusRipple
                                                        data-test-id="isFree"
                                                        name="free"
                                                        checked={this.state.shippingMethod.isFree}
                                                        onChange={this.handleIsFreeCheckbox}
                                                        checkedIcon={<img src={checkboxChecked} />}
                                                        icon={<img src={checkboxUnchecked} />}
                                                    />
                                                </Box>
                                                {
                                                    this.handleErrorMessage('isFree')
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={4} justifyContent="space-between">
                                        <Grid item sm={6} md={6} lg={6}>
                                            <Typography className={`${this.errorTextClass('amount', this.state.shippingMethod.amount)}`}>Amount<span className='required'>*</span></Typography>
                                            <TextField
                                                data-test-id="amount"
                                                name="amount"
                                                type="number"
                                                style={{ width: "90%" }}
                                                placeholder="Enter Amount"
                                                value={this.state.shippingMethod.amount}
                                                className={`form-input ${this.errorFocusClass('amount', this.state.shippingMethod.amount)}`}
                                                onChange={(e: any) => this.handleValueChange(e, "amount")}
                                            />
                                            {
                                                this.handleErrorMessage('amount')
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>



                                <Box className={`tab-parent-div`} >
                                    <div>
                                        <Tabs value={this.state.shippingMethod.value} onChange={this.handleTabChange} data-test-id="tabs">
                                            <Tab label={configJSON.pricing} />
                                            <Tab label={configJSON.destination_availability} />
                                            <Tab label={configJSON.description} />
                                        </Tabs>
                                        <this.TabPanel value={this.state.shippingMethod.value} index={0}>
                                            <Box className="p-15">
                                                <Grid container spacing={4} justifyContent="space-between">
                                                    <Grid item sm={6} md={6} lg={6}>
                                                        <Typography className={`${this.errorTextClass('fixedPrice', this.state.shippingMethod.pricing.fixedPrice)}`}>Fixed Price<span className='required'>*</span></Typography>
                                                        <TextField
                                                            data-test-id="pricing"
                                                            name="pricing"
                                                            type="number"
                                                            placeholder="Enter Pricing"
                                                            fullWidth
                                                            value={this.state.shippingMethod.pricing.fixedPrice}
                                                            className={`form-input ${this.errorFocusClass('fixedPrice', this.state.shippingMethod.pricing.fixedPrice)}`}
                                                            onChange={(e: any) => this.handlePricingValueChange(e, "fixedPrice")}
                                                        />
                                                        {
                                                            this.handleErrorMessage('fixedPrice')
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </this.TabPanel>
                                        <this.TabPanel value={this.state.shippingMethod.value} index={1}>
                                            <Box className="p-15">
                                                <Typography className="mb-10">Filling this form allows you to filter delivery carriers according to the delivery address of your customer.</Typography>
                                                <Grid container spacing={4} justifyContent="space-between" >
                                                    <Grid item sm={6} md={6} lg={6}>

                                                        <Box className={`main-color tags-multi-select`}>
                                                            <Typography className={`${this.errorTextClass('countries', this.state.location.selectedCountries.length)}`} style={webStyle.pageHeading as React.CSSProperties}>
                                                                Countries
                                                                <span style={{ color: "red" }}>*</span>
                                                            </Typography>
                                                            <Autocomplete
                                                                data-test-id={"countries"}
                                                                style={{ width: "100%" }}
                                                                id="tags-outlined"
                                                                multiple
                                                                options={Country.getAllCountries()}
                                                                value={this.state.location.selectedCountries}
                                                                renderOption={(option:any) => (
                                                                    <Typography style={{ 
                                                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif" 
                                                                    }}>{option.name}</Typography>
                                                                )}
                                                                getOptionLabel={
                                                                    (option: any) => option.name
                                                                }
                                                                filterSelectedOptions
                                                                disableClearable
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) ", marginTop: 2, }}>{children}</Paper>)}
                                                                popupIcon={
                                                                    <ExpandMoreIcon style={{color:"black"}}/>
                                                                }
                                                                renderTags={(tagValue, getTagProps) =>
                                                                    tagValue.map((option, index) => (
                                                                        <Chip
                                                                            deleteIcon={<CloseIcon style={{ color: "black", height: "17px" }} />}
                                                                            {...getTagProps({ index })}
                                                                            label={option.name}
                                                                        />
                                                                    ))
                                                                }
                                                                onChange={(event, value) => { this.handleCountryChange(value)}}
                                                                renderInput={(params) => (<TextField
                                                                    {...params}
                                                                    placeholder={this.autocompletePlaceholder(this.state.location.selectedCountries.length, "Select Countries")}
                                                                    className={`destination ${this.errorAutcompleteFocusClass('countries', this.state.location.selectedCountries)}`}
                                                                    variant="outlined" />
                                                                )}
                                                            />
                                                        </Box>
                                                            {this.handleErrorMessage('countries')
}
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={4} justifyContent="space-between" >
                                                    <Grid item sm={6} md={6} lg={6}>
                                                        <Box className={`main-color tags-multi-select`}>
                                                            <Typography className={`${this.errorTextClass('states', this.state.location.selectedStates.length)}`} style={webStyle.pageHeading as React.CSSProperties}>
                                                                States
                                                                <span style={{ color: "red" }}>*</span>
                                                            </Typography>
                                                            <Autocomplete
                                                                multiple
                                                                style={{ width: "100%" }}
                                                                disableClearable
                                                                data-test-id={"states"}
                                                                id="tags-outlined"
                                                                options={this.state.location.states}
                                                                value={this.state.location.selectedStates}
                                                                renderOption={(option: any) => (
                                                                    <Typography style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
                                                                        {option.name}
                                                                    </Typography>
                                                                )}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) ", marginTop: 2, }}>{children}</Paper>)}
                                                                popupIcon={<ExpandMoreIcon style={{ color: "black" }} />}
                                                                filterSelectedOptions
                                                                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                                                                    <Chip
                                                                        deleteIcon={<CloseIcon style={{ color: "black", height: "17px" }} />}
                                                                        {...getTagProps({ index })}
                                                                        label={option.name}
                                                                    />
                                                                ))
                                                                }
                                                                getOptionLabel={(option: any) => option.name}
                                                                onChange={(event, value) => { this.handleRegionChange(value) }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder={this.autocompletePlaceholder(this.state.location.selectedStates.length, "Select States")}
                                                                        className={`destination ${this.errorAutcompleteFocusClass('states', this.state.location.selectedStates)}`}
                                                                        variant="outlined"
                                                                    />
                                                                )}
                                                            />

                                                        </Box>
                                                            {
                                                                this.handleErrorMessage('states')
                                                            }
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={4} justifyContent="space-between" >
                                                    <Grid item sm={6} md={6} lg={6}>
                                                        <Box className={`main-color tags-multi-select`}>
                                                            <Typography className={`${this.errorTextClass('zip_from', this.state.location.selectedZipFrom.length)}`} style={webStyle.pageHeading as React.CSSProperties}>
                                                                Zip From
                                                                <span style={{ color: "red" }}>*</span>
                                                            </Typography>

                                                            <Autocomplete
                                                                options={this.state.location.selectedStates.length>0 ? this.state.location.cities : []}
                                                                value={this.state.location.selectedZipFrom}
                                                                disableClearable
                                                                multiple
                                                                id="tags-outlined"
                                                                data-test-id={"zip_from"}
                                                                renderOption={
                                                                    (option: any) => (
                                                                        <Typography style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>{option.name}</Typography>
                                                                )}
                                                                style={{ width: "100%" }}
                                                                getOptionLabel={(option: any) => option.name}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) ", marginTop: 2, }}>{children}</Paper>)}
                                                                popupIcon={<ExpandMoreIcon style={{ color: "black" }} />}
                                                                renderTags={(tagValue, getTagProps) =>
                                                                    tagValue.map((option, index) => (
                                                                        <Chip
                                                                            deleteIcon={<CloseIcon style={{ color: "black", height: "17px" }} />}
                                                                            {...getTagProps({ index })}
                                                                            label={option.name}
                                                                        />
                                                                    ))
                                                                }
                                                                filterSelectedOptions
                                                                onChange={(event, value) => { this.handleZipFromChange(value) }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} className={`destination ${this.errorAutcompleteFocusClass('zip_from', this.state.location.selectedZipFrom)}`} placeholder={this.autocompletePlaceholder(this.state.location.selectedZipFrom.length, "Select Zip From")} variant="outlined"/>
                                                                )}
                                                            />
                                                        </Box>
                                                            {
                                                            this.handleErrorMessage('zip_from')
                                                            }
                                                    </Grid>
                                                    <Grid item sm={6} md={6} lg={6}>
                                                        <Box className={`main-color tags-multi-select`}>
                                                            <Typography className={`${this.errorTextClass('zip_to', this.state.location.selectedZipTo.length)}`} style={webStyle.pageHeading as React.CSSProperties}>
                                                                Zip To
                                                                <span style={{ color: "red" }}>*</span>
                                                            </Typography>
                                                            <Autocomplete
                                                                data-test-id={"zip_to"}
                                                                multiple
                                                                id="tags-outlined"
                                                                options={this.state.location.selectedStates.length>0 ? this.state.location.cities : []}
                                                                value={this.state.location.selectedZipTo}
                                                                style={{ width: "100%" }}
                                                                disableClearable
                                                                getOptionLabel={(option: any) => option.name}
                                                                renderOption={(option: any) => (
                                                                    <Typography style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>{option.name}</Typography>
                                                                )}
                                                                popupIcon={<ExpandMoreIcon style={{ color: "black" }} />}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) ", marginTop: 2, }}>{children}</Paper>)}
                                                                renderTags={(tagValue, getTagProps) =>
                                                                    tagValue.map((option, index) => (
                                                                        <Chip
                                                                            deleteIcon={<CloseIcon style={{ color: "black", height: "17px" }} />}
                                                                            {...getTagProps({ index })} label={option.name}
                                                                        />
                                                                    ))
                                                                }
                                                                filterSelectedOptions
                                                                onChange={(event, value) => { this.handleZipToChange(value) }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params}
                                                                        variant="outlined" 
                                                                        placeholder={this.autocompletePlaceholder(this.state.location.selectedZipTo.length, "Select Zip To")} className={`destination ${this.errorAutcompleteFocusClass('zip_to', this.state.location.selectedZipTo)}`}
                                                                    />
                                                                )}
                                                            />

                                                        </Box>
                                                            {
                                                            this.handleErrorMessage('zip_to')
                                                            }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </this.TabPanel>
                                        <this.TabPanel value={this.state.shippingMethod.value} index={2}>
                                            <Box className="p-15">
                                                <Grid container spacing={4} justifyContent="space-between">
                                                    <Grid item sm={12} md={12} lg={12}>
                                                        <Typography>Description</Typography>
                                                        <TextField
                                                            data-test-id="descriptionText"
                                                            className="width-100"
                                                            name="descriptionText"
                                                            variant="outlined"
                                                            placeholder="Description displayed on the ecommerce and on online quotations."
                                                            multiline
                                                            rows={3}
                                                            rowsMax={10}
                                                            value={this.state.shippingMethod.description.descriptionText}
                                                            onChange={(e: any) => this.handleDescriptionValueChange(e, "descriptionText")}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </this.TabPanel>
                                    </div>
                                </Box>
                            </Grid>
                        </Box>
                        <Box sx={webStyle.actionBtnStyle}>
                            <Button variant="contained" style={webStyle.discardBtn} data-test-id="discardBtn" onClick={this.handleDiscard}>Discard</Button>
                            <Button
                                type="submit"
                                data-test-id="saveBtn"
                                style={webStyle.saveBtn}
                                onClick={this.handleSave}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    gridsideBar: {
        display: "flex",
        minHheight: "100%",
        transition: "all 0.3s",
    },
    gridMainLayout: {
        display: "flex",
    },
    gridcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },
    actionBtnStyle: {
        display: "flex",
        gap: 8,
        float: "right",
        marginBlock: "40px",
    },
    saveBtn: {
        backgroundColor: "rgba(9, 65, 115, 0.77)",
        color: "#ffffff"
    },
    discardBtn:{
        backgroundColor:"white"
    },
    divider:{
        marginTop:"20px",
        marginBottom:"20px",
        backgroundColor:"#E3E3E3"
    },
    pageHeading: {
        textWrap: "nowrap !important"
    },
    destination:{
        padding: "4px"
    }
};
// Customizable Area End
