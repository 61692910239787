// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfbranchsalesreports4 from "../../blocks/cfbranchsalesreports4/src/Cfbranchsalesreports4";
import Cfjournaltransactions4 from "../../blocks/cfjournaltransactions4/src/Cfjournaltransactions4";
import Cfposscreen4 from "../../blocks/cfposscreen4/src/Cfposscreen4";
import Cfmarketui from "../../blocks/cfmarketui/src/Cfmarketui";
import Digitalsignatures from "../../blocks/digitalsignatures/src/Digitalsignatures";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import {HomePageScreen} from "../../blocks/advancedsearch/src/HomeScreen.web";
import Cfbranchpurchasereports2 from "../../blocks/cfbranchpurchasereports2/src/Cfbranchpurchasereports2";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import Quickbooksintegration from "../../blocks/quickbooksintegration/src/Quickbooksintegration";
import Inventorymanagement3 from "../../blocks/inventorymanagement3/src/Inventorymanagement3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Itemavailability from "../../blocks/itemavailability/src/Itemavailability";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Storelocator2 from "../../blocks/storelocator2/src/Storelocator2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Itemquickviewimages from "../../blocks/itemquickviewimages/src/Itemquickviewimages";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Barcodesscanner from "../../blocks/barcodesscanner/src/Barcodesscanner";
import Audittrail2 from "../../blocks/audittrail2/src/Audittrail2";
import Cfcategorymovementstransferreports4 from "../../blocks/cfcategorymovementstransferreports4/src/Cfcategorymovementstransferreports4";
import Cfcostcenteraccountsbudget3 from "../../blocks/cfcostcenteraccountsbudget3/src/Cfcostcenteraccountsbudget3";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Cfpurchases from "../../blocks/cfpurchases/src/Cfpurchases";
import Cfcostcenteraccountstatement4 from "../../blocks/cfcostcenteraccountstatement4/src/Cfcostcenteraccountstatement4";
import Inventorytrendanalysis from "../../blocks/inventorytrendanalysis/src/Inventorytrendanalysis";
import Cfstoresanditemsreports4 from "../../blocks/cfstoresanditemsreports4/src/Cfstoresanditemsreports4";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfpurchasesreports4 from "../../blocks/cfpurchasesreports4/src/Cfpurchasesreports4";
import Cfpurchasesitemsreport3 from "../../blocks/cfpurchasesitemsreport3/src/Cfpurchasesitemsreport3";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import ProductCreation from "../../blocks/itemavailability/src/ProductCreation.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Itemgrouper from "../../blocks/itemgrouper/src/Itemgrouper";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Settings2 from "../../blocks/settings2/src/Settings2.web";
import AddCompany from "../../blocks/settings2/src/AddCompany.web";
import BankViewMode from "../../blocks/cfbankcashrecitevoucher4/src/BankViewMode.web";
import AddBank from "../../blocks/cfbankcashrecitevoucher4/src/AddBank.web";
import SettingsAddUser from "../../blocks/settings2/src/SettingsAddUser.web";
import {SettingsUserListViewStyles} from "../../blocks/settings2/src/SettingsUserListView.web";
import {SettingsAccessRightsListViewStyles} from "../../blocks/settings2/src/SettingsAccessRightsListView.web";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Cfsupplerpurchasereports2 from "../../blocks/cfsupplerpurchasereports2/src/Cfsupplerpurchasereports2";
import Cfcategorysalesreports3 from "../../blocks/cfcategorysalesreports3/src/Cfcategorysalesreports3";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import Downloadoptions from "../../blocks/downloadoptions/src/Downloadoptions";
import Cfcustomersalesreports3 from "../../blocks/cfcustomersalesreports3/src/Cfcustomersalesreports3";
import { CfcustomersalesGridviewStyles } from "../../blocks/cfcustomersalesreports3/src/Cfcustomersaleskanbanview";
import { CfcustomersaleslistviewStyles } from "../../blocks/cfcustomersalesreports3/src/Cfcustomersaleslistview";
import Cfcustomersalescreatecustomer from "../../blocks/cfcustomersalesreports3/src/Cfcustomersalescreatecustomer";
import Cfcustomersalesconfiguration from "../../blocks/cfcustomersalesreports3/src/Cfcustomersalesconfiguration";
import Sorting from "../../blocks/sorting/src/Sorting";
import Cfcategorypurchasesreport4 from "../../blocks/cfcategorypurchasesreport4/src/Cfcategorypurchasesreport4";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Organisationhierarchy from "../../blocks/organisationhierarchy/src/Organisationhierarchy";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Universallinks from "../../blocks/universallinks/src/Universallinks";
import Ordercreation2 from "../../blocks/ordercreation2/src/Ordercreation2.web";
import OrdercreationView from "../../blocks/ordercreation2/src/OrdercreationView.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Cfaccountingsetting5 from "../../blocks/cfaccountingsetting5/src/Cfaccountingsetting5";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Cfitemsalesreports3 from "../../blocks/cfitemsalesreports3/src/Cfitemsalesreports3";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Cfbankcashrecitevoucher4 from "../../blocks/cfbankcashrecitevoucher4/src/Cfbankcashrecitevoucher4";
import Barcodesettings from "../../blocks/barcodesettings/src/Barcodesettings";
import Cfagentsalesreport3 from "../../blocks/cfagentsalesreport3/src/Cfagentsalesreport3";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import {EmailAccountRegistrationWeb} from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import {EmailAccountLoginStyles} from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import Offlinework from "../../blocks/offlinework/src/Offlinework";
import Loyaltysystem from "../../blocks/loyaltysystem/src/Loyaltysystem";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import { SalesconfigrationUOMcategoriesStyles } from "../../blocks/filteritems/src/SalesconfigrationUOMcategories.web"
import { SalesconfigrationCreateUOMcategoriesStyles } from "../../blocks/filteritems/src/SalesconfigrationCreateUoMcategories.web"
import { SalesconfigrationShippingmethodStyles } from "../../blocks/filteritems/src/SalesconfigurationShippingmethod.web"
import { SalesconfigrationCreateshippingmethodStyles } from "../../blocks/filteritems/src/SalesconfigurationCreateshippingmethod.web"
import Expensetracking from "../../blocks/expensetracking/src/Expensetracking";
import Salesreporting from "../../blocks/salesreporting/src/Salesreporting";
import Cfaccountingreports2 from "../../blocks/cfaccountingreports2/src/Cfaccountingreports2";
import Cfagentpurchasesreport4 from "../../blocks/cfagentpurchasesreport4/src/Cfagentpurchasesreport4";
import Languageoptions from "../../blocks/languageoptions/src/Languageoptions";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import CfCustomerSettingTabs from "../../blocks/settings2/src/cfConfigurationSettings/CfCustomerSettingTabs.web";
import AddPosProductCategory from "../../blocks/categoriessubcategories/src/AddPosProductCategory.web";
import {PosProductCategoriesViewStyles} from "../../blocks/categoriessubcategories/src/PosProductCategoriesView.web";
import {BanksListViewStyles} from "../../blocks/cfbankcashrecitevoucher4/src/BanksListView.web";
import SalesconfigurationCreateShippingmethod from "../../blocks/settings2/src/ShippingMethodCreation.web";
import {UomCategorieslistviewStyles} from "../../blocks/settings2/src/uomCategories/UomCategorieslistview.web";
import AddUomCategories from "../../blocks/categoriessubcategories/src/AddUomCategories.web"
import Orderlisting from "../../blocks/ordercreation2/src/Orderlisting.web";
import {CompaniesListViewStyles} from "../../blocks/settings2/src/CompaniesListView.web";
import BankAccountCreation from "../../blocks/cfbankcashrecitevoucher4/src/BankAccountCreation.web";
import BankAccountListing from "../../blocks/cfbankcashrecitevoucher4/src/BankAccountListing.web";
import BankAccountCreationView from "../../blocks/cfbankcashrecitevoucher4/src/BankAccountCreationView.web";
import PosCreation from "../../blocks/cfposscreen4/src/PosCreation";
import {PosOrderListingStyles} from "../../blocks/cfposscreen4/src/PosOrderListing.web";
import {CfposscreenStyles} from "../../blocks/cfposscreen4/src/Cfposscreen4.web";

const routeMap = {
  Cfbranchsalesreports4: {
    component: Cfbranchsalesreports4,
    path: '/Cfbranchsalesreports4'
  },
  Cfjournaltransactions4: {
    component: Cfjournaltransactions4,
    path: '/Cfjournaltransactions4'
  },
  Cfposscreen4: {
    component: CfposscreenStyles,
    path: '/Cfposscreen4'
  },
  PosCreation: {
    component: PosCreation,
    path: '/PosCreation'
  },
  PosOrderListingStyles: {
    component: PosOrderListingStyles,
    path: '/PosOrderListing'
  },
  Cfmarketui: {
    component: Cfmarketui,
    path: '/Cfmarketui'
  },
  Digitalsignatures: {
    component: Digitalsignatures,
    path: '/Digitalsignatures'
  },
  OrderManagement: {
    component: OrderManagement,
    path: '/OrderManagement'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  Cfbranchpurchasereports2: {
    component: Cfbranchpurchasereports2,
    path: '/Cfbranchpurchasereports2'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  Customform3: {
    component: Customform3,
    path: '/Customform3'
  },
  Quickbooksintegration: {
    component: Quickbooksintegration,
    path: '/Quickbooksintegration'
  },
  Inventorymanagement3: {
    component: Inventorymanagement3,
    path: '/Inventorymanagement3'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: '/Contentmanagement3'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Itemavailability: {
    component: Itemavailability,
    path: '/Itemavailability'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Storelocator2: {
    component: Storelocator2,
    path: '/Storelocator2'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Itemquickviewimages: {
    component: Itemquickviewimages,
    path: '/Itemquickviewimages'
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: '/Servicespecificsettingsadmin2'
  },
  Barcodesscanner: {
    component: Barcodesscanner,
    path: '/Barcodesscanner'
  },
  Audittrail2: {
    component: Audittrail2,
    path: '/Audittrail2'
  },
  Cfcategorymovementstransferreports4: {
    component: Cfcategorymovementstransferreports4,
    path: '/Cfcategorymovementstransferreports4'
  },
  Cfcostcenteraccountsbudget3: {
    component: Cfcostcenteraccountsbudget3,
    path: '/Cfcostcenteraccountsbudget3'
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: '/Paymentadmin2'
  },
  Cfpurchases: {
    component: Cfpurchases,
    path: '/Cfpurchases'
  },
  Cfcostcenteraccountstatement4: {
    component: Cfcostcenteraccountstatement4,
    path: '/Cfcostcenteraccountstatement4'
  },
  Inventorytrendanalysis: {
    component: Inventorytrendanalysis,
    path: '/Inventorytrendanalysis'
  },
  Cfstoresanditemsreports4: {
    component: Cfstoresanditemsreports4,
    path: '/Cfstoresanditemsreports4'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Cfpurchasesreports4: {
    component: Cfpurchasesreports4,
    path: '/Cfpurchasesreports4'
  },
  Cfpurchasesitemsreport3: {
    component: Cfpurchasesitemsreport3,
    path: '/Cfpurchasesitemsreport3'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  ProductCreation: {
    component: ProductCreation,
    path: '/ProductCreation'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  Itemgrouper: {
    component: Itemgrouper,
    path: '/Itemgrouper'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  SettingsAddUser: {
    component: SettingsAddUser,
    path: '/SettingsAddUser'
  },
  AddCompany: {
    component: AddCompany,
    path: '/AddCompany'
  },
  BankViewMode: {
    component: BankViewMode,
    path: '/BankViewMode/:id'
  },
  AddBanks: {
    component: AddBank,
    path: '/AddBank/:id',
    exact:true
  },
  AddBank: {
    component: AddBank,
    path: '/AddBank'
  },
  BankAccountCreation: {
    component: BankAccountCreation,
    path: '/BankAccountCreation',
    exact: true
  },
  BankAccountCreation1: {
    component: BankAccountCreation,
    path: '/BankAccountCreation/:id',
    exact: true
  },
  BankAccountCreationView: {
    component: BankAccountCreationView,
    path: "/BankAccountCreationView/:id",
    exact: true
  },
  
  SettingsUserListView: {
    component: SettingsUserListViewStyles,
    path: '/SettingsUserListView'
  },
  CompaniesListView: {
    component: CompaniesListViewStyles,
    path: '/CompaniesListView'
  },
  SettingsAccessRightsListView: {
    component: SettingsAccessRightsListViewStyles,
    path: '/SettingsAccessRightsListView'
  },
  AddPosProductCategory: {
    component: AddPosProductCategory,
    path: "/AddPosProductCategory"
  },
  PosProductCategoriesView: {
    component: PosProductCategoriesViewStyles,
    path: '/PosProductCategoriesView'
  },
  BanksListView: {
    component: BanksListViewStyles,
    path: '/BanksListView'
  },
  BankAccountListing: {
    component: BankAccountListing,
    path: '/BankAccountListing'
  },
  AddUomCategories: {
    component: AddUomCategories,
    path: "/AddUomCategories"
  },
  UomCategorieslistview: {
    component: UomCategorieslistviewStyles,
    path: '/UomCategorieslistview'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions'
  },
  Cfsupplerpurchasereports2: {
    component: Cfsupplerpurchasereports2,
    path: '/Cfsupplerpurchasereports2'
  },
  Cfcategorysalesreports3: {
    component: Cfcategorysalesreports3,
    path: '/Cfcategorysalesreports3'
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: '/DocumentOpener'
  },
  Downloadoptions: {
    component: Downloadoptions,
    path: '/Downloadoptions'
  },
  Cfcustomersalesreports3: {
    component: Cfcustomersalesreports3,
    path: '/Cfcustomersalesreports3'
  },
  Cfcustomersaleskanbanview: {
    component: CfcustomersalesGridviewStyles,
    path: '/Cfcustomersaleskanbanview'
  },
  Cfcustomersaleslistview: {
    component: CfcustomersaleslistviewStyles,
    path: '/Cfcustomersaleslistview'
  },
  Cfcustomersalescreatecustomer: {
    component: Cfcustomersalescreatecustomer,
    path: '/Cfcustomersalescreatecustomer'
  },
  Cfcustomersalesconfiguration: {
    component: Cfcustomersalesconfiguration,
    path: "/Cfcustomersalesreports3"
  },
  Cfcustomersaleskanbanview: {
    component: CfcustomersalesGridviewStyles,
    path: "/Cfcustomersaleskanbanview"
  },
  Cfcustomersaleslistview: {
    component: CfcustomersaleslistviewStyles,
    path: "/Cfcustomersaleslistview"
  },
  Cfcustomersalescreatecustomer: {
    component: Cfcustomersalescreatecustomer,
    path: "/Cfcustomersalescreatecustomer"
  },
  Cfcustomersalesconfiguration: {
    component: Cfcustomersalesconfiguration,
    path: "/Cfcustomersalesconfiguration"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  Cfcategorypurchasesreport4: {
    component: Cfcategorypurchasesreport4,
    path: "/Cfcategorypurchasesreport4"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Organisationhierarchy: {
    component: Organisationhierarchy,
    path: "/Organisationhierarchy"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Universallinks: {
    component: Universallinks,
    path: "/Universallinks"
  },
  Orderlisting: {
    component: Orderlisting,
    path: "/SalesOrder"
  },
  Ordercreation2: {
    component: Ordercreation2,
    path: "/Ordercreation2",
    exact: true
  },
  OrdercreationView: {
    component: OrdercreationView,
    path: "/OrdercreationView/:id",
    exact: true
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  Cfaccountingsetting5: {
    component: Cfaccountingsetting5,
    path: "/Cfaccountingsetting5"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  Cfitemsalesreports3: {
    component: Cfitemsalesreports3,
    path: "/Cfitemsalesreports3"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  Cfbankcashrecitevoucher4: {
    component: Cfbankcashrecitevoucher4,
    path: "/Cfbankcashrecitevoucher4"
  },
  Barcodesettings: {
    component: Barcodesettings,
    path: "/Barcodesettings"
  },
  Cfagentsalesreport3: {
    component: Cfagentsalesreport3,
    path: "/Cfagentsalesreport3"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Offlinework: {
    component: Offlinework,
    path: "/Offlinework"
  },
  Loyaltysystem: {
    component: Loyaltysystem,
    path: "/Loyaltysystem"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Expensetracking: {
    component: Expensetracking,
    path: "/Expensetracking"
  },
  Salesreporting: {
    component: Salesreporting,
    path: "/Salesreporting"
  },
  Cfaccountingreports2: {
    component: Cfaccountingreports2,
    path: "/Cfaccountingreports2"
  },
  Cfagentpurchasesreport4: {
    component: Cfagentpurchasesreport4,
    path: "/Cfagentpurchasesreport4"
  },
  Languageoptions: {
    component: Languageoptions,
    path: "/Languageoptions"
  },
  // ReviewApprovalAdmin:{
  //  component:ReviewApprovalAdmin,
  // path:"/ReviewApprovalAdmin"},
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: "/ReviewApprovalBasicUser"
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  Cfcategorypurchasesreport4: {
    component: Cfcategorypurchasesreport4,
    path: '/Cfcategorypurchasesreport4'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Organisationhierarchy: {
    component: Organisationhierarchy,
    path: '/Organisationhierarchy'
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  // Universallinks: {
  //   component: Universallinks,
  //   path: '/Universallinks'
  // },
  Ordercreation1: {
    component: Ordercreation2,
    path: "/Ordercreation2/:id"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  StripePayments: {
    component: StripePayments,
    path: '/StripePayments'
  },
  Cfaccountingsetting5: {
    component: Cfaccountingsetting5,
    path: '/Cfaccountingsetting5'
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData'
  },
  Cfitemsalesreports3: {
    component: Cfitemsalesreports3,
    path: '/Cfitemsalesreports3'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  Cfbankcashrecitevoucher4: {
    component: Cfbankcashrecitevoucher4,
    path: '/Cfbankcashrecitevoucher4'
  },
  Barcodesettings: {
    component: Barcodesettings,
    path: '/Barcodesettings'
  },
  Cfagentsalesreport3: {
    component: Cfagentsalesreport3,
    path: '/Cfagentsalesreport3'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3'
  },
  EmailAccountRegistrationWeb: {
    component: EmailAccountRegistrationWeb,
    path: '/EmailAccountRegistrationWeb'
  },
  EmailAccountLoginStyles: {
    component: EmailAccountLoginStyles,
    path: "/EmailAccountLoginStyles"
  },
  HomePageScreen: {
    component: HomePageScreen,
    path: "/HomePageScreen"
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Offlinework: {
    component: Offlinework,
    path: '/Offlinework'
  },
  Loyaltysystem: {
    component: Loyaltysystem,
    path: '/Loyaltysystem'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Expensetracking: {
    component: Expensetracking,
    path: '/Expensetracking'
  },
  Salesreporting: {
    component: Salesreporting,
    path: '/Salesreporting'
  },
  Cfaccountingreports2: {
    component: Cfaccountingreports2,
    path: '/Cfaccountingreports2'
  },
  Cfagentpurchasesreport4: {
    component: Cfagentpurchasesreport4,
    path: '/Cfagentpurchasesreport4'
  },
  Languageoptions: {
    component: Languageoptions,
    path: '/Languageoptions'
  },
  // ReviewApprovalAdmin:{
  //  component:ReviewApprovalAdmin,
  // path:"/ReviewApprovalAdmin"},
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: '/ReviewApprovalBasicUser'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  Cfcategorypurchasesreport4: {
    component: Cfcategorypurchasesreport4,
    path: '/Cfcategorypurchasesreport4'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Organisationhierarchy: {
    component: Organisationhierarchy,
    path: '/Organisationhierarchy'
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Universallinks: {
    component: Universallinks,
    path: '/Universallinks'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  StripePayments: {
    component: StripePayments,
    path: '/StripePayments'
  },
  Cfaccountingsetting5: {
    component: Cfaccountingsetting5,
    path: '/Cfaccountingsetting5'
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData'
  },
  Cfitemsalesreports3: {
    component: Cfitemsalesreports3,
    path: '/Cfitemsalesreports3'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  Cfbankcashrecitevoucher4: {
    component: Cfbankcashrecitevoucher4,
    path: '/Cfbankcashrecitevoucher4'
  },
  Barcodesettings: {
    component: Barcodesettings,
    path: '/Barcodesettings'
  },
  Cfagentsalesreport3: {
    component: Cfagentsalesreport3,
    path: '/Cfagentsalesreport3'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Offlinework: {
    component: Offlinework,
    path: '/Offlinework'
  },
  Loyaltysystem: {
    component: Loyaltysystem,
    path: '/Loyaltysystem'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Expensetracking: {
    component: Expensetracking,
    path: '/Expensetracking'
  },
  Salesreporting: {
    component: Salesreporting,
    path: '/Salesreporting'
  },
  Cfaccountingreports2: {
    component: Cfaccountingreports2,
    path: '/Cfaccountingreports2'
  },
  Cfagentpurchasesreport4: {
    component: Cfagentpurchasesreport4,
    path: '/Cfagentpurchasesreport4'
  },
  Languageoptions: {
    component: Languageoptions,
    path: '/Languageoptions'
  },
  SalesconfigrationUOMcategories: {
    component: SalesconfigrationUOMcategoriesStyles,
    path: '/salesconfigrationUOMcategories'
  },
  SalesconfigrationCreateUOMcategories: {
    component: SalesconfigrationCreateUOMcategoriesStyles,
    path: '/salesconfigrationCreateUOMcategories'
  },
  SalesconfigrationCreateUOMcategories: {
    component: SalesconfigrationCreateUOMcategoriesStyles,
    path: "/salesconfigrationCreateUOMcategories"
  },

  SalesconfigurationShippingmethod: {
    component: Settings2,
    path: "/SalesconfigurationShippingmethod"
  },
  SalesconfigurationCreateshippingmethod: {
    component: SalesconfigurationCreateShippingmethod,
    path: "/SalesconfigurationCreateshippingmethod",
    exact: true
  },
  SalesconfigurationCreateshippingmethod1: {
    component: SalesconfigurationCreateShippingmethod,
    path: "/SalesconfigurationCreateshippingmethod/:id",
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: '/ReviewApprovalBasicUser'
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  CfCustomerSettingTabs: {
    component: CfCustomerSettingTabs,
    path: '/CfCustomerSettingTabs'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View >
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
