import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    withStyles,
    Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { accounting, contacts, humanResource, inventory, logo, pointofsale, purchase, sales, schedule, settings, sign } from "./assets"
import AdvancedSearchController, {
  configJSON,
  Props
} from "./AdvancedSearchController";
import { HomeHeader } from '../../../components/src/HomeHeader.web';
// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});



export default class HomeScreenBlock extends AdvancedSearchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area End

        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes } = this.props;
        return (
            // Required for all blocks
            <ThemeProvider theme={theme}>
                < Grid container className={classes.homeContainer}>
                    <Grid container>
                        <Grid item lg={4} md={4} sm={12}>
                            <img src={logo} alt="defaultImg" />
                        </Grid>
                        <Grid item lg={8} md={8} sm={12}>
                            <HomeHeader pageTitle={configJSON.pageTitle} />
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} className={classes.homeItem}>
                        <Box className={classes.homeBox}>
                            <Box className={classes.firstRow} >
                                <Box className={classes.contentBox} data-testid="navigatesales" onClick={() => { this.navigatetoSales() }}>
                                    <Box className={classes.contentImg}>
                                        <img className={classes.imgStyle} src={sales} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentText} >{configJSON.sales}</Typography>
                                </Box>
                                <Box className={classes.contentBox}>
                                    <Box className={classes.contentImg} data-testid="navigatepointofsale" onClick={() => { this.navigatetoPointofsale() }}>
                                        <img className={classes.imgStyle} src={pointofsale} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentText}>{configJSON.pointofsale}</Typography>
                                </Box>
                                <Box className={classes.contentBox}>
                                    <Box className={classes.contentImg} data-testid="navigatepurchase" onClick={() => { this.navigatetoPurchase() }}>
                                        <img className={classes.imgStyle} src={purchase} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentText}>{configJSON.purchase}</Typography>
                                </Box>
                                <Box className={classes.contentBox}>
                                    <Box className={classes.contentImg} data-testid="navigateinventory" onClick={() => { this.navigatetoInventory() }}>
                                        <img className={classes.imgStyle} src={inventory} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentText}>{configJSON.inventory}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.secondRow} >
                                <Box className={classes.contentBox}>
                                    <Box className={classes.contentImg} data-testid="navigateaccounting" onClick={() => { this.navigatetoAccounting() }}>
                                        <img className={classes.imgStyle} src={accounting} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentText}>{configJSON.accounting}</Typography>
                                </Box>
                                <Box className={classes.contentBox}>
                                    <Box className={classes.contentImg} data-testid="navigatecontacts" onClick={() => { this.navigatetoContacts() }}>
                                        <img className={classes.imgStyle} src={contacts} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentText}>{configJSON.contacts}</Typography>
                                </Box>
                                <Box className={classes.contentBox}>
                                    <Box className={classes.contentImg} data-testid="navigatehumanresources" onClick={() => { this.navigatetoHumanresource() }}>
                                        <img className={classes.imgStyle} src={humanResource} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentTexthuman}>{configJSON.humanresource}</Typography>
                                </Box>
                                <Box className={classes.contentBox}  data-testid="navigatesignin" onClick={() => { this.navigatetoSign() }}>
                                    <Box className={classes.contentImg}>
                                        <img className={classes.imgStyle} src={sign} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentText} >{configJSON.signin}</Typography>
                                </Box>
                                <Box className={classes.contentBox} data-testid="navigatecalender" onClick={() => { this.navigatetoCalender() }}>
                                    <Box className={classes.contentImg}>
                                        <img className={classes.imgStyle} src={schedule} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentText}>{configJSON.calender}</Typography>
                                </Box>
                                <Box className={classes.contentBox} data-testid="navigatesetting" onClick={() => { this.navigatetoSetting() }}>
                                    <Box className={classes.contentImg}>
                                        <img className={classes.imgStyle} src={settings} alt="defaultImg" />
                                    </Box>
                                    <Typography className={classes.contentText}>{configJSON.settings}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}

const homeScreenstyles: {} = () => ({
    homeContainer: {
        width: "100%",
        height: "100%",
        backgroundColor: "#006395",
        [theme.breakpoints.down("sm")]: {
            height: "max-content",
        }
    },
    homeItem: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    homeBox: {
        display: "block"
    },
    firstRow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        }
    },
    secondRow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        }
    },
    contentBox: {
        width: "100%",
        height: "100%",
        textAlign: "center",
        margin: "5%",
        marginBottom: "5%"
    },
    contentImg: {
        height: "100%",
        backgroundColor: "#fff",
        width: "130px",
        border: "1px solid #fff",
        borderRadius: "3px",
        padding: "10%"
    },
    contentText: {
        fontSize: "13px",
        color: "#fff",
        marginTop: "5%",
        fontWeight: "600px",
        fontFamily: "Inter",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    contentTexthuman: {
        fontSize: "12px",
        color: "#fff",
        marginTop: "5%",
        fontWeight: "600px",
        fontFamily: "Inter",
        letterSpacing: "normal",
    },
    imgStyle: {
        height: "100px",
        width: "100px",
    }
});

export const HomePageScreen = withStyles(homeScreenstyles)(HomeScreenBlock)
// Customizable Area End
