// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Chip, ClickAwayListener, FormControl, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import SettingsAccessRightsListViewController, { Props } from './SettingsAccessRightsListViewController.web'

import '../assets/style.css'
export const configJSON = require("./config");
import { add, close, filter, filtericon, gridleftarrow, groupby, search, settings, star } from './assets';
import { AppHeader } from '../../../components/src/AppHeader.web';
import Sidebar from '../../../components/src/Sidebar.web';
import { groupWhite } from '../../../components/src/assets';
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class SettingsAccessRightsListView extends SettingsAccessRightsListViewController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        let searchPlaceholder = this.searchPlaceholder() as string;
        const { open, showFilter, showGroup, showFav, showSettings, showCurrentSearch, showAddToDashboard } = this.state;
        const { classes }: any = this.props;

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.accessrightlistviewMainLayout}>
                <Box className={classes.accessrightlistviewsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.accessrightlistviewcontent}>
                    {" "}
                    <AppHeader pageTitle="Settings" />
                    <Box style={{backgroundColor:"#ffffff"}}>
                        <Box>
                            <Box className={classes.accessrightlistviewBlock}>
                            <Grid container spacing={0} >
                                <Grid item sm={6} className='left-center-align'>
                                    <Typography className={classes.accessrightlistviewHeading}>{configJSON.access_rights}</Typography>
                                </Grid>
                                <Grid item sm={6} className={classes.accessrightlistviewSearchbox}>
                                    <TextField
                                        className='search-box-width'
                                        id='user-list-search-input'
                                        variant="outlined"
                                        placeholder={searchPlaceholder}
                                        data-testid="listSearchinput"
                                        value={this.state.searchKeyword}
                                        onChange={(e:any) => this.setState({...this.state,searchKeyword:e.target.value})}
                                    />
                                    
                                        
                                    <img src={search} className={`${classes.accessrightlistviewImg} ${(this.searchIconDisplay()) && 'display-none'}`} />
                                    
                                    <Box className={`${!this.state.activeGroup && 'display-none'}`} style={{position:"absolute",left:"15px",top:"12px"}}>
                                        <Box style={{position:"relative"}}>
                                        <Chip style={{backgroundColor:"#006395",color:"#ffffff",padding:"0 25px"}} label={this.state.activeGroup} />
                                        <span className='handleGroupIcon' ><img src={groupWhite} /></span>
                                        <span data-testid="handleRemoves1" className='handleRemoves' onClick={()=>this.handleActiveGroup('')} >×</span>
                                        </Box>
                                        
                                    </Box>
                                    
                                    <Box className={`${this.filterUI()}`}>
                                    {
                                        this.state.activeFilterArr.map((value:any)=>{
                                        const flabel = this.handleFilterLabel(value);
                                        return  <Box key={value} className='mr-5'>
                                                    <Box style={{position:"relative"}}>
                                                    <Chip style={{backgroundColor:"#006395",color:"#ffffff",padding:"0 25px"}} label={flabel} />
                                                    <span className='handleFilterIcon' ><img src={filtericon} /></span>
                                                    <span data-testid="handleRemoves2" className='handleRemoves' onClick={()=>this.removeActiveFilter(value)} >×</span>
                                                    </Box>
                                                    
                                                </Box>
                                        })
                                    }
                                    </Box>
                                    
                                </Grid>
                            </Grid>
                            </Box>
                            <Box className={classes.accessrightlistviewFilterbox} >
                            <Grid container spacing={0} >
                                <Grid item sm={6} className='left-center-align'>
                                    <Box className={classes.accessrightlistviewColumnbox}>
                                        <Button className='filters-left' data-testid="list-Filters" onClick={this.handleFilter}
                                        ><img src={filter} className={classes.accessrightlistviewFilterImg} />{configJSON.Filters}</Button>
                                        
                                        {
                                            showFilter &&
                                            <ClickAwayListener
                                                onClickAway={this.handleFilter}
                                                data-test-id="clickFilter"
                                            >
                                            <Box className="filterBox">
                                            <List>
                                                <ListItem button className={`font-14 listItem ${this.checkSelectedListItem(0)}`} data-testid="filter-global" 
                                                onClick={()=>this.handleActiveFilter(0)}>
                                                    <ListItemText primary={configJSON.global} />
                                                </ListItem>
                                                <ListItem button className={`font-14 listItem ${this.checkSelectedListItem(1)}`} data-testid="filter-full-access" 
                                                onClick={()=>this.handleActiveFilter(1)}
                                                >
                                                    <ListItemText primary={configJSON.full_access} />
                                                </ListItem>
                                                <ListItem button className={`font-14 listItem ${this.checkSelectedListItem(2)}`} data-testid="filter-read-access" 
                                                onClick={()=>this.handleActiveFilter(2)}>
                                                    <ListItemText primary={configJSON.read_access} />
                                                </ListItem>
                                                <ListItem button className={`font-14 listItem ${this.checkSelectedListItem(3)}`} data-testid="filter-write-access" 
                                                onClick={()=>this.handleActiveFilter(3)}>
                                                    <ListItemText primary={configJSON.write_access} />
                                                </ListItem>
                                                <ListItem button className={`font-14 ${this.checkSelectedListItem(4)}`} data-testid="filter-archived" 
                                                onClick={()=>this.handleActiveFilter(4)}>
                                                    <ListItemText primary={configJSON.archived} />
                                                </ListItem>
                                            </List>
                                            </Box>
                                            </ClickAwayListener>
                                            
                                        }
                                        
                                    </Box>
                                    <Box className={classes.accessrightlistviewColumnbox}>
                                        <Button className='filters-left' data-testid="list-Groupby"
                                            onClick={this.handleGroup}
                                        ><img src={groupby} className={classes.accessrightlistviewFilterImg} />{configJSON.Groupby}</Button>
                                        
                                        {
                                            showGroup &&
                                            <ClickAwayListener
                                                onClickAway={this.handleGroup}
                                                data-test-id="clickGroup"
                                            >
                                            <Box className="filterBox">
                                            <List>
                                                <ListItem className={`font-14 group-item-padding listItem ${this.checkGroupSelectedListItem('Group')}`} button data-testid="list-group" onClick={() => { this.handleActiveGroup('Group'); }}>
                                                    <ListItemText  primary={configJSON.group} />
                                                </ListItem>
                                                <ListItem className={`font-14 group-item-padding ${this.checkGroupSelectedListItem('Model')}`} button data-testid="list-model" onClick={() => { this.handleActiveGroup('Model'); }}>
                                                    <ListItemText  primary={configJSON.model} />
                                                </ListItem>
                                            </List>
                                            </Box>
                                            </ClickAwayListener>
                                        }
                                        
                                    </Box>
                                    <Box className={classes.accessrightlistviewColumnbox}>
                                        <Button className='filters-left' data-testid="list-Favorites" onClick={this.handleFavorites} ><img src={star} className={classes.accessrightlistviewFilterImg} />{configJSON.Favorites}</Button>
                                        
                                        {
                                            showFav &&
                                            <ClickAwayListener
                                                onClickAway={this.handleFavorites}
                                                data-test-id="clickFavorites"
                                            >
                                            <Box className="filterBox">
                                            <List >
                                                <ListItem button data-testid="list-currentSearch" className='font-14 listItem favorites-list' onClick={this.handleCurrentSearch}>
                                                    <ListItemText primary="Save Current Search" />
                                                    <img src={gridleftarrow} />
                                                </ListItem>
                                                <ListItem button className='font-14 listItem favorites-list' data-testid="list-importRecords">
                                                    <ListItemText primary="Import Records" />
                                                </ListItem>
                                                <ListItem button className='font-14 favorites-list'>
                                                    <ListItemText primary="Add to my Dashboard" data-testid="list-addDashboard" onClick={this.handleAddToDashboard} />
                                                    <img src={gridleftarrow} />
                                                </ListItem>
                                            </List>
                                            </Box>
                                            </ClickAwayListener>
                                        }
                                        
                                        
                                        {
                                            showCurrentSearch &&
                                            <ClickAwayListener
                                                onClickAway={this.handleCurrentSearch}
                                                data-test-id="clickHandleFavorites"
                                            >
                                            <Box className="filterBoxCurrentSearch">
                                            <List className={classes.gridviewlist} >
                                                <ListItem button className='font-14 listItem'>
                                                    <ListItemText primary={configJSON.access_rights} />
                                                </ListItem>
                                                <ListItem button className={`font-14 ${classes.gridviewButton}`} style={{paddingBottom:0}}>
                                                    <ListItemIcon className={classes.gridviewIcon} >
                                                        <Checkbox className={classes.gridviewCheckbox} defaultChecked />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Use by default" />
                                                </ListItem>
                                                <ListItem button className={`font-14 ${classes.gridViewlistItem}`} style={{paddingTop:0}}>
                                                    <ListItemIcon className={classes.gridviewIcon}>
                                                        <Checkbox className={classes.gridviewCheckbox} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Share with all users" />
                                                </ListItem>
                                                <ListItem button className={`font-14 ${classes.gridviewButtonlist} end-center-align`} >
                                                    <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                </ListItem>
                                            </List>
                                            </Box>
                                            </ClickAwayListener>
                                            
                                        }
                                        
                                        {
                                            showAddToDashboard &&
                                            <ClickAwayListener
                                                onClickAway={this.handleAddToDashboard}
                                                data-test-id="clickHandleFavorites"
                                            >
                                            <Box className="filterBoxAddToDashboard">
                                            <List className={`font-14 ${classes.gridviewlist}`}>
                                                <ListItem button style={{paddingBottom:0}} >
                                                    <TextField id="users-input" value={configJSON.access_rights}/>
                                                    
                                                </ListItem>
                                                <ListItem button className={`font-14 ${classes.gridviewButtonlist} end-center-align`} style={{paddingTop:0}}  >
                                                <Button className='saveBtn'>{configJSON.buttonSave}</Button>  
                                                </ListItem>
                                            </List>
                                            </Box>
                                            </ClickAwayListener>
                                            
                                        }
                                    </Box>
                                </Grid>
                                <Grid item sm={6} className={classes.accessrightlistviewCreatebox} >
                                    <Box className={classes.accessrightlistviewColumnbox}>
                                        <Button className='filters-left' data-testid="list-settings" onClick={this.handleAccessRightSettings}><img src={settings} /></Button>
                                        
                                        {
                                            showSettings &&
                                            <ClickAwayListener
                                                onClickAway={this.handleAccessRightSettings}
                                                data-test-id="clickSettings"
                                            >
                                            <Box className="settingsBox">
                                            <List>
                                                <ListItem button className='font-14 listItem'>
                                                    <ListItemText primary="Export" />
                                                </ListItem>
                                                <ListItem button className='font-14 listItem'>
                                                    <ListItemText primary="Archive" />
                                                </ListItem>
                                                <ListItem button className='font-14 listItem'>
                                                    <ListItemText primary="Unarchive" />
                                                </ListItem>
                                                <ListItem button className='font-14'>
                                                    <ListItemText primary="Delete" />
                                                </ListItem>
                                            </List>
                                            </Box>
                                            </ClickAwayListener>
                                            
                                        }
                                        
                                    </Box>
                                    
                                    <Button className='filters-right' data-testid="Createcustomer" onClick={this.handleOpen}>{configJSON.CreateAccessRights}<img src={add} /></Button>
                                </Grid>
                            </Grid>
                            
                                <Box className={`${this.loaderBox()}`}>
                                {
                                this.loaderBoxContent()
                                }
                                </Box>
                                
                            
                            <Box className={`${this.listView()}`}>
                            <>
                            <Box className={classes.accessrightlistviewColumnbox} >
                                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell><Checkbox 
                                        checked={this.state.globalCheck} 
                                        onChange={()=>this.handleGlobalCheckAR()} 
                                        className={classes.listviewCheck} 
                                        data-test-id="globalCheckboxAR"
                                        /></TableCell>
                                        <TableCell className={classes.accessrightlistviewTableheading}>NAME</TableCell>
                                        <TableCell className={classes.accessrightlistviewTableheading}>MODEL</TableCell>
                                        <TableCell className={classes.accessrightlistviewTableheading}>GROUP</TableCell>
                                        <TableCell className={classes.accessrightlistviewTableheading}>READ ACCESS</TableCell>
                                        <TableCell className={classes.accessrightlistviewTableheading}>WRITE ACCESS</TableCell>
                                        <TableCell className={classes.accessrightlistviewTableheading}>CREATE ACCESS</TableCell>
                                        <TableCell className={classes.accessrightlistviewTableheading}>DELETE ACCESS</TableCell>
                                        
                                    </TableRow>
                                    </TableHead>
                                    {
                                        this.state.activeGroup && this.state.groupbyResponse.map((item:any,index:number)=>{
                                            return <>
                                            {item.permissions.data.map((user:any,index1:number) =>{
                                            return <>
                                            
                                            <TableBody>
                                            {
                                            this.handleGroupIndexZero(item,index1,index) 
                                            }
                                            { this.state.active.includes(index) &&
                                            this.expandTableRowAR(user,classes)
                                            }
                                            
                                            </TableBody>
                                            
                                            </>
                                            })}
                                            </>
                                        })
                                    }
                                    {this.defaultViewData() && 
                                    this.state.showCustomerdata?.map((user:any) => {
                                        return (
                                            <>
                                            
                                            <TableBody>
                                            {
                                                this.expandTableRowAR(user,classes)
                                            }
                                            </TableBody>
                                            
                                            </>  
                                            )
                                    })} 
                                </Table>
                            </TableContainer>
                            </Box>
                            <Box className={`${classes.accessrightlistviewItems} ${this.state.activeGroup && 'display-none'}`}>
                                <Grid container spacing={0} >
                                <Grid item sm={8} className={classes.accessrightlistviewItemsgrid}>
                                    <Typography className={classes.accessrightlistviewItemsText}>{configJSON.itemsPage}</Typography>
                                    <FormControl id="select-form-control">

                                    <Select
                                        IconComponent={this.expandMore}
                                        data-testid="select_page"
                                        id="item-select"
                                        inputProps={{
                                            'aria-label': 'custom select',
                                        }}
                                        value={this.state.per_page}
                                        onChange={(e:any)=>this.handlePaginationAR(e.target.value)}
                                        label="Select"
                                        disableUnderline
                                        className='bold-text'
                                    >
                                        <MenuItem className='select-page-option' value={9}>09</MenuItem>
                                        <MenuItem className='select-page-option' value={27}>27</MenuItem>
                                        <MenuItem className='select-page-option' value={54}>54</MenuItem>
                                        <MenuItem className='select-page-option' value={99}>99</MenuItem>
                                    </Select>
                                    
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4} className='end-center-align'>
                                    <Typography className={classes.accessrightlistviewText} >
                                        {(this.state.page - 1) * this.state.per_page + 1}
                                        &nbsp;-&nbsp;
                                        {(this.state.page - 1) * this.state.per_page +
                                            this.state.showCustomerdata.length}
                                            &nbsp;of &nbsp;
                                        {this.state.totalCounts}
                                    </Typography>
                                    <Button data-testid="listviewBackwardicon" disabled={this.state.page==1} onClick={()=>this.loadMore(0,'')} className='tb-footer-btn'><img src={this.movebackwardFunc()} /></Button>
                                    <Button data-testid="listviewBackArrow" disabled={this.state.page==1} onClick={()=>this.state.page>1 && this.loadMore(-1,'')} className='tb-footer-btn'><img src={this.backarrowFunc()} /></Button>
                                    <Button data-testid="listviewForwardArrow" disabled={this.isLastPage()} onClick={()=>this.loadMore(+1,'')} className='tb-footer-btn'><img src={this.forwardarrowFunc()} /></Button>
                                    <Button data-testid="listviewForwardIcon" disabled={this.isLastPage()} onClick={()=>this.loadMore(this.state.totalPage-this.state.page,'')} id='next-end'><img src={this.moveforwardFunc()} /></Button>
                                </Grid>
                                </Grid>
                            </Box>
                            </>
                                
                            <Modal
                                open={open}
                                onClose={this.handleClose}
                                aria-labelledby="modal-title"
                                aria-describedby="modal-description"
                            >
                                <Box className="modalBox-access-right">
                                <Box className="relative">
                                <Typography variant="h6" id="access-right-modal-title" gutterBottom>
                                    {configJSON.create_access_rights}
                                </Typography>
                                <img onClick={this.handleClose} className='modal-close' src={close} />

                                </Box>
                                <Typography variant="body1" id="modal-description" gutterBottom>
                                
                                    <Grid container spacing={1} >
                                        <Grid item sm={12} >
                                        <Box>
                                        <Box ><Typography>Name</Typography></Box>
                                        <Box>
                                        <TextField className="form-input" 
                                        data-testid="nameInput"
                                        value={this.state.name}
                                        onChange={(e:any) => this.setState({...this.state,name:e.target.value})}
                                        name="name" type="text" />
                                        </Box>
                                        </Box>
                                        </Grid>
                                        <Grid item sm={12} >
                                        <Box>
                                        <Box ><Typography>Model</Typography></Box>
                                        <Box>
                                        <FormControl id="select-form-control" className="width-99">
                                    
                                            <Select
                                                IconComponent={this.expandMore}
                                                data-testid="modelInput"
                                                inputProps={{
                                                    'aria-label': 'custom select',
                                                }}
                                                value={this.state.model}
                                                onChange={(e:any) => this.setState({...this.state,model:e.target.value})}
                                                label="Select"
                                                disableUnderline
                                                className='modal-access-right-select'
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                        marginTop:52,
                                                        },
                                                    },
                                                }}
                                                
                                            >
                                                <MenuItem value="X">X</MenuItem>
                                                <MenuItem value="Y">Y</MenuItem>
                                                <MenuItem value="Z">Z</MenuItem>
                                                
                                            </Select>
                                            </FormControl>
                                        </Box>
                                        </Box>
                                        </Grid>
                                        <Grid item sm={12} >
                                        <Box>
                                        <Box ><Typography>Group</Typography></Box>
                                        <Box>
                                        <FormControl id="select-form-control" className="width-99">
                                    
                                            <Select
                                                IconComponent={this.expandMore}
                                                data-testid="groupInput"
                                                inputProps={{
                                                    'aria-label': 'custom select',
                                                }}
                                                value={this.state.group}
                                                onChange={(e:any) => this.setState({...this.state,group:e.target.value})}
                                                label="Select"
                                                disableUnderline
                                                className='modal-access-right-select'
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                        marginTop:52,
                                                        },
                                                    },
                                                }}
                                                
                                            >
                                                <MenuItem value="X">X</MenuItem>
                                                <MenuItem value="Y">Y</MenuItem>
                                                <MenuItem value="Z">Z</MenuItem>
                                                
                                            </Select>
                                            </FormControl>
                                        </Box>
                                        </Box>
                                        </Grid>
                                        <Grid item sm={12} >
                                        <Box>
                                        <Box ><Typography>Permissions</Typography></Box>
                                        <Box className='contact-form-input flex'>
                                        <Grid container spacing={0} >
                                        <Grid item sm={3} className='left-center-align'>
                                        <Box className='left-center-align permission-box pl-0'>
                                            <Checkbox data-testid="readCheckbox" checked={this.state.read_access} onChange={()=>this.setState({read_access:!this.state.read_access})} className={classes.listviewCheck} /> Read Access
                                            </Box>
                                        </Grid>
                                        <Grid item sm={3} className='left-center-align'>
                                        <Box className='left-center-align permission-box pl-0'>
                                            <Checkbox data-testid="writeCheckbox" checked={this.state.write_access} onChange={()=>this.setState({write_access:!this.state.write_access})} className={classes.listviewCheck} /> Write Access
                                            </Box>
                                        </Grid>
                                        <Grid item sm={3} className='left-center-align'>
                                        <Box className='left-center-align permission-box pl-0'>
                                            <Checkbox data-testid="createCheckbox" checked={this.state.create_access} onChange={()=>this.setState({create_access:!this.state.create_access})} className={classes.listviewCheck} /> Create Access
                                            </Box>
                                        </Grid>
                                        <Grid item sm={3} className='end-center-align'>
                                        <Box className='right-center-align permission-box pl-0 delete-access'>
                                            <Checkbox data-testid="deleteCheckbox" checked={this.state.delete_access} onChange={()=>this.setState({delete_access:!this.state.delete_access})} className={classes.listviewCheck} /> Delete Access
                                            </Box>
                                        </Grid>
                                        </Grid>
                                            
                                        </Box>
                                        </Box>
                                        </Grid>
                                    
                                    </Grid>

                                </Typography>
                                
                                <Box className="modal-cancel"  >
                                    
                                    <Button onClick={this.handleClose} variant="outlined" className='mr-15'>Discard</Button>
                                    
                                    <Button variant="contained" onClick={this.handleCreateAccessRight} className='create-btn' style={{float:"right"}}>Save</Button>
                                    
                                    <span className={`${this.popupAlertFunc()}`} style={{paddingRight:"15px",paddingTop:"8px",float:"right"}}>{this.state.popupAlert?configJSON.access_right_created_msg:this.state.errorMsg}</span>
                                    
                                    
                                </Box>
                                </Box>
                            </Modal>
                            </Box>
                                
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const accessrightlistwebStyle: any = (theme: any) => ({
    accessrightlistviewMainLayout: {
        display: "flex",
    },
    accessrightlistviewsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    accessrightlistviewcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    accessrightlistviewBlock: {
        padding: "15px",
        border: "1px solid #e3e3e3"
    },
    accessrightlistviewHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    accessrightlistviewImg: {
        position: "absolute",
        left: "15px",
        top: "19px",
        height: "20px"
    },
    accessrightlistviewFilterbox: {
        border: "1px solid #e3e3e3"
    },
    accessrightlistviewFilterImg: {
        paddingRight: "8px"
    },
    accessrightlistviewColumnbox: {
        position: "relative"
    },
    accessrightlistviewColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    accessrightlistviewCheck: {

    },
    accessrightlistviewTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    accessrightlistviewTabletext: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    accessrightlistviewItems: {
        padding: "15px"
    },
    accessrightlistviewCreatebox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px"
    },
    accessrightlistviewSearchbox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative"
    },
    accessrightlistviewItemsgrid: {
        display: "flex !important",
        justifyContent: "left",
    },
    accessrightlistviewItemsText: {
        paddingRight: "10px",
        marginTop: "18px",
        color:"#47464a"
    },
    accessrightlistviewText: {
        marginRight: "50px"
    },
    accessrightlistgridviewbox: {
        padding: "15px"
    },
    accessrightlistgridviewFilterbox: {
        border: "1px solid #e3e3e3"
    },
    accessrightlistgridviewlistimg: {
        padding: "10px 10px 10px 0"
    },
    accessrightlistgridListimg: {
        margin: "-5px 5px 0 0",
        float: "right"
    },
    accessrightlistgridListimg2: {
        margin: "15px -20px 0 0",
        float: "right"
    },
    accessrightlistgridListtext: {
        fontSize: "20px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
    },
    accessrightlistgridListsubtext: {
        fontSize: "16px",
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
})
export const SettingsAccessRightsListViewStyles = withStyles(accessrightlistwebStyle)(SettingsAccessRightsListView)
  // Customizable Area End
