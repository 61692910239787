import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ExpandMore } from '@material-ui/icons';
import StorageProvider from "../../../framework/src/StorageProvider";
import { defaultImg } from '../../cfcustomersalesreports3/src/assets';
import { Box, MenuItem, TextField, Typography } from '@material-ui/core';
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    category:string;
    parent_category:string;
    image:any;
    token:string|null;
    formError:boolean;
    uomCategory:string;
    measure_units:any[];
    uomDDL:any[];
    categoryId:string|null;
    categoryDetail:any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AddPosProductCategoryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    addCategoryApiCallId: any = "";
    addUomCategoryApiCallId!: string;
    apigetUomDDLlistCallId!: string;
    apigetCategoryCallId!: string;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
          ];
          
        this.state = {
            // Customizable Area Start
            categoryId:null,
            uomDDL:[],
            category:'',
            parent_category:'',
            image:null,
            token:'',
            formError:false,
            uomCategory:'',
            measure_units:[{uom:"",uom_type:"",ratio:"",rounding_precision:"",active:true,archive:true}],
            categoryDetail:null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        
        // Customizable Area End
    }

    // Customizable Area Start
    getPcToken = async() => {
      const categoryId = window.location.href.split('Categories/')[1]
      const getapiToken =  await StorageProvider.get("TOKEN");
      this.setState({token: getapiToken,categoryId})
      await this.getUomDDL()
      await this.getCategoryDetail()
        
    }

    async componentDidMount(){
      this.getPcToken()
    }

    getCategoryDetail = async () => {
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token":this.state.token
      };
  
      this.apigetCategoryCallId = requestMessage.messageId;
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_productquickview/uom_categories/${this.state.categoryId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
  
      
    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        runEngine.debugLog("API Message Recived", message);
        
        if (responseJson?.errors) {
          let msg = configJSON.something_went_wrong;
          if (responseJson.errors[0]) {
            msg = responseJson.errors[0];
          }
          if (msg !== "Record not found") {
            this.showAlert(configJSON.errorTitle, msg);
          }
        } else if (apiRequestCallId === this.addCategoryApiCallId && responseJson.info?.data?.id) {
          this.addCategoryResponse();
        } else if (apiRequestCallId === this.addUomCategoryApiCallId && responseJson.info?.data?.id) {
          this.addUomCategoryResponse(responseJson);
        } else if (apiRequestCallId === this.apigetUomDDLlistCallId) {
          this.updateUomDDL(responseJson);
        } 
        if (apiRequestCallId === this.apigetCategoryCallId) {
          this.setState({ ...this.state, categoryDetail: responseJson.data?.data?.attributes });
        }
      }
      // Customizable Area End
    }

    // Customizable Area Start
    addCategoryResponse = () => {
      this.showAlert(
        configJSON.success,
        configJSON.category_created_msg
      );
      this.discardProductCategory();
    }

    addUomCategoryResponse = (responseJson:any) => {
      this.showAlert(
        configJSON.success,
        responseJson.message
      );
      this.discardUomCategory()
    }

    imageRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    expandMore = () => {
        return <ExpandMore/>;
    }

    uomOption = () => {
      return this.state.uomDDL.map((ddl:any)=>{
                return <MenuItem value={ddl?.attributes?.uom}>{ddl?.attributes?.uom}</MenuItem>
              })
    }
    
    updateUomDDL = (response:any) => {
       const data1:any = [];
       response.data.forEach((res:any)=>data1.push(res))
       this.setState({...this.state,uomDDL:data1})
    }


    imageUI = () => {
      return <>
      <Box className={`${(!this.state.image) && 'imageBox'}`}>
      <img src={this.state.image ? URL.createObjectURL(this.state.image) : defaultImg} data-test-id="imageRef" onClick={()=>{this.imageRef.current?.click();}} height={this.state.image?100:50} width={this.state.image?100:50} />
      <input data-test-id="imageFile" accept=".jpg, .jpeg, .png" type='file' className="display-none" ref={this.imageRef} name="image" onChange={(e:any)=>this.setState({...this.state,image: e?.target?.files[0]})} />
      </Box>
      
      </>
    }

    getUomDDL = async() => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token":this.state.token
      };
      
      this.apigetUomDDLlistCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_productquickview/unit_of_measures`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleCategoryError = (value:number) => {
      if(value==1 && (!this.state.category.trim() && this.state.formError)){
        return 'error-text-pos'
      }else if(value==2 && (!this.state.category.trim() && this.state.formError)){
       return 'error-focus'
       }else{
         return ''
       }
   }

    handleParentCategoryError = (value:number) => {
       if(value==1 && (!this.state.parent_category.trim() && this.state.formError)){
         return 'error-text-pos'
       }else if(value==2 && (!this.state.parent_category.trim() && this.state.formError)){
        return 'error-focus'
        }else{
          return ''
        }
    }

    hideCategoryError = () => {
      if((!this.state.category.trim() && this.state.formError)){
        return ''
      }else{
        return 'display-none'
      }
    }

    hideParentCategoryError = () => {
      if((!this.state.parent_category.trim() && this.state.formError)){
        return ''
      }else{
        return 'display-none'
      }
    }

    discardProductCategory = () => {
      this.setState({...this.state,category:'',parent_category:'',image:null,formError:false})
    }

    checkValidation = () => {
        if((!this.state.category.trim()) || (!this.state.parent_category.trim()) || (!this.state.image)){
           this.setState({...this.state,formError:true});
           return false;
        }
        return true;
    }

    addPosProductCategory = () => {
      if(!this.checkValidation()){
        return false;
      }
      this.savePosProductCategory();
    }

    savePosProductCategory = () => {
        
        let formdata:any = new FormData();
        formdata.append("product_category_image", this.state.image);
        formdata.append("category_name", this.state.category.trim());
        formdata.append("parent_category", this.state.parent_category.trim());
        const header = {
            token: this.state.token,
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
    
          this.addCategoryApiCallId = requestMessage.messageId;
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_point_of_sale/pos_product_categories'
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'POST'
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
    }

    handleUomType = (newArr:any,val:any) => {
      if(newArr.filter((i:any)=>i.uom_type == configJSON.uomOption1).length==1 && newArr.length>1 && val==configJSON.uomOption1){
        this.showAlert("Error", "You can select this type only one time");
        return false;
      }
      return true;
    }

    handleMeasureUnitsDetails= (e:any,index:number,field:string) => {
      const val = e.target?.value;
      let newArr = JSON.parse(JSON.stringify(this.state.measure_units)); 
      const editArr:any = newArr.filter((i:any,indx:number)=>indx == index)
      //console.log(editArr)
      if(!this.handleUomType(newArr,val)){
        return;
      }
      if(editArr?.length>0){
          editArr[0][field]=val;
          if(val == configJSON.uomOption1){
            editArr[0]['ratio']=1.00000;
            editArr[0]['rounding_precision']=0.01000;
          }else if(editArr[0]['ratio']==1.00000 && editArr[0]['rounding_precision']==0.01000){
            
              editArr[0]['ratio']="";
              editArr[0]['rounding_precision']="";
            
          }
      }
      this.setState({...this.state,measure_units:newArr})
    }
  
  removeColumn = (index:any) => {
    if(this.state.measure_units.length>1){
      this.setState({...this.state,measure_units:this.state.measure_units.filter((i:any,indx:number)=>indx != index)})
    }else{
      this.showAlert("Error", "UoM category weight must have atleast one reference unit of measure.");
    }
    
  }
  
  addAnotherColumn = () => {
    this.setState({
      ...this.state,
      measure_units:this.state.measure_units.concat({
        uom:"",
        uom_type:"",
        ratio:"",
        rounding_precision:"",
        active:true,
        archive:true
      })
    })
  }

  getRatioText = (ratio:any) => {
    return <Typography>{ratio}</Typography>
  }

  getInputRatio = (ratio:number,index:number,type:string,classes:any) => {
    return <TextField data-test-id="uomCategoryInput" 
    className={`form-input width-100 ${type=='ratio' ? classes.ratioStyle : classes.roundingStyle}`} 
    value={ratio}
    onChange={(e:any) => this.handleMeasureUnitsDetails(e,index,type)} 
    name="category" type="text" />
  }

  getRatioColumn = (uom_type:string,ratio:any,index:number,classes:any,p1:string,p2:string) => {
    let flabel:any = '';
     switch(uom_type){
      case configJSON.uomOption1 :  flabel = this.getRatioText(p1); break;
      case '' :  flabel = this.getRatioText(''); break; 
      case 'Smaller than the reference Unit of Measure for this category' :  
      case 'Bigger than the reference Unit of Measure for this category' :  
      flabel = this.getInputRatio(ratio,index,p2,classes);
      break;   
          default :
          break;
     }
     return flabel;
  }

  

    hideUomCategoryError = () => {
      if((!this.state.uomCategory.trim() && this.state.formError)){
        return ''
      }else{
        return 'display-none'
      }
    }
  
    handleUomCategoryError = (value:number) => {
        if(value==1 && (!this.state.uomCategory.trim() && this.state.formError)){
          return 'error-text-pos'
        }else if(value==2 && (!this.state.uomCategory.trim() && this.state.formError)){
         return 'error-focus'
         }else{
           return ''
         }
     }
  
    discardUomCategory = () => {
      this.setState({...this.state,uomCategory:'',measure_units:[{uom:"",uom_type:"",ratio:"",rounding_precision:"",active:true,archive:true}],formError:false})
    }

    hasEmptyValue = () => {
      return this.state.measure_units.some((obj) => Object.values(obj).some((value) => value === ''));
    }
  
    checkUomValidation = () => {
      if(!this.state.uomCategory.trim()){
        this.setState({...this.state,formError:true});
        return false;
      }
      else if(!this.state.measure_units.filter((i)=>i.uom_type == configJSON.uomOption1).length){
        this.showAlert("Error", "UoM category weight must have atleast one reference unit of measure.");
        return false;
      }else if(this.hasEmptyValue()){
        this.showAlert("Error", "Please fill all fields.");
        return false;
      }
      return true;
    }
  
    addUomCategories = () => {
      if(!this.checkUomValidation()){
         return false;
      }
      let formdata:any = new FormData();
      formdata.append("data[category]", this.state.uomCategory);
      formdata.append("data[unspsc_category]", null);
      formdata.append("data[archive]", false);
      this.state.measure_units.length>0 && this.state.measure_units.forEach((item:any,index:number)=>{
        Object.keys(item).forEach((key) => {
          formdata.append(`data[unit_of_measures_attributes][${index}][${key}]`, item[key]);
        });
      })

      const header = {
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.addUomCategoryApiCallId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'bx_block_productquickview/uom_categories'
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'POST'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End
}