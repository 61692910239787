import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  ClickAwayListener,
  Popper,
  MenuList,
  Card,
  MenuItem,
  CardContent,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Select,
  Menu,
  MenuProps,
  TableBody,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { AppHeader } from "../../../components/src/AppHeader.web";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
const Images = require("./assets");
import Sidebar from "../../../components/src/Sidebar.web";
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";


const ButtonComponent = styled("button")({
  color: "black",
  backgroundColor: "#F3F3F3",
  padding: "8px 15px",
  borderRadius: "4px",
  fontSize: "12px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  border: "none",
  height: "100%",
  marginX: "10px",
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#2980b9",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const tableHeading = [
  "Delivery Method",
  "Provider",
  "Company",
  "Countries",
];

const StyledMenu = ((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    PaperProps={{
      style: {
        width: 350,
      },
    }}
    {...props}
  />
));

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";


export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  expandableTable = (data: any) => {
    return (
      <TableBody>
        {data?.map((row: any, indice: number) => (
          <React.Fragment key={row.key}>
            <TableRow style={{ backgroundColor: '#E5E5E5' }}>
              <TableCell padding="checkbox">
                <IconButton data-test-id="iconButton" onClick={() => this.toggleIsExpanded(indice)}>
                  {this.state.isExpanded[indice] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {row.key} ({row.data && this.handleSingleDigit(row.data.length)})
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            {this.state.isExpanded[indice] &&
              row.data?.map((tablerow: any, index: number) => (
                <TableRow
                  key={`${row.key} ${index}`}
                  style={webStyle.tableRow}
                >
                  <TableCell>
                    <Box sx={webStyle.tableBox}>
                      <Checkbox
                        data-test-id="rowCheckbox"
                        tabIndex={-1}
                        edge="start"
                        // value={tablerow.checked}
                        checked={tablerow.checked}
                        onChange={() => { this.handleCheckBoxSelect(tablerow, index); }}
                        className="individual"
                        size="medium"
                      />
                      <img
                        src={Images.swap}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    {tablerow.shipping_method}
                  </TableCell>
                  <TableCell>
                    {tablerow.provider}
                  </TableCell>
                  <TableCell>
                    {tablerow.company}
                  </TableCell>
                  <TableCell className="chipCell">
                    {
                      tablerow.destinations_availabilities?.countries?.map((country: any) => (
                        <Chip key={index} label={country} style={webStyle.muiChipRoot} />
                      )
                      )
                    }
                  </TableCell>
                </TableRow>
              )
              )}
          </React.Fragment>
        ))}
      </TableBody>
    );
  };


  isCurrentPageOne() {
    if (!this.state.isGroupby) {
      return this.state.currentPage === 1;
    }
    return true;
  }

  isCurrentPageLast() {
    if (!this.state.responseData.length) {
      return true;
    }
    if (!this.state.isGroupby) {
      return this.state.currentPage === this.state.totalPage;
    }
    return true;
  }

  renderListView = (responseData: any) => {
    return (
      <TableBody>
        {this.state.isLoading
          ?
          <TableRow>
            <TableCell colSpan={10}>
              <Box style={webStyle.loader}><CircularProgress /></Box>
            </TableCell>
          </TableRow>
          :
          responseData.map((tablerow: any, index: number) => (
            <TableRow key={index} style={webStyle.tableRow} data-test-id="data-row" onClick={() => this.handleRowClick(tablerow.id)}>
              <TableCell>
                <Box sx={webStyle.tableBox}>
                  <Checkbox
                    className="individual"
                    tabIndex={-1}
                    checked={tablerow.checked}
                    edge="start"
                    size="medium"
                    data-test-id="rowCheckboxlist"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleCheckBoxSelect(tablerow, index);
                    }}
                  />
                  <img
                    src={Images.swap}
                  />
                </Box>
              </TableCell>
              <TableCell>
                {tablerow.shipping_method}
              </TableCell>
              <TableCell>{tablerow.provider}</TableCell>
              <TableCell>
                {tablerow.company}
              </TableCell>
              <TableCell className="chipCell">
                {
                  tablerow.destinations_availabilities?.countries?.map((country: any) => (
                    <Chip key={index} label={country} style={webStyle.muiChipRoot} />
                    )
                  )
                }
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    )
  }

  renderGroupedTable = (responseData: any) => {
    if (this.state.isGroupby) {
      return this.expandableTable(responseData);
    } else {
      return this.renderListView(responseData);
    }
  };

  renderListAndGroupbyTable = (responseData: any) => {
    return (
      this.state.isLoading ? (
        <TableRow>
          <TableCell colSpan={10}>
            <Box style={webStyle.loader}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        this.renderGroupedTable(responseData)
      )
    );
  };


  renderPagination = () => {
    return (
      <Box sx={webStyle.paginationContainer}>
        <Box sx={webStyle.perPageItem}>
          <Typography>Items per page : &nbsp;</Typography>
          <Select
            variant="outlined"
            IconComponent={KeyboardArrowDownSharpIcon}
            value={this.state.rowsPerPage}
            style={webStyle.navinationDropdown as React.CSSProperties}
            onChange={this.handleChangeRowsPerPage}
            data-test-id="dropdown"
          >
            <MenuItem value={9}>
              09
            </MenuItem>
            <MenuItem value={27}>
              27
            </MenuItem>
            <MenuItem value={54}>
              54
            </MenuItem>
            <MenuItem value={99}>
              99
            </MenuItem>
          </Select>
        </Box>
        <Box style={webStyle.paginationText}>
          <Box style={this.state.isLoading ? webStyle.paginationCountBoxHidden as React.CSSProperties : webStyle.paginationCountBoxShow as React.CSSProperties}>
            <Typography style={webStyle.rowText as React.CSSProperties}>
              {
                (this.state.currentPage - 1) * this.state.rowsPerPage + 1
              }
              &nbsp;- &nbsp;
              {
                (this.state.currentPage - 1) * this.state.rowsPerPage + this.state.responseData.length
              }
            </Typography>
            <Typography> &nbsp; of &nbsp; {this.state.totalCounts} </Typography>
          </Box>
          <Button
            onClick={() => this.handleChangePage(0)}
            data-test-id="pageNavigation"
            style={webStyle.paginationButton}
            disabled={this.isCurrentPageOne()}
          >
            <img
              src={
                this.isCurrentPageOne()
                  ? Images.moveBackwardArrowDisable
                  : Images.moveBackwardArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageOne()}
            data-test-id="pageNavigation"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(this.state.currentPage - 2)}
          >
            <img
              src={
                this.isCurrentPageOne()
                  ? Images.backArrowDisable
                  : Images.backArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageLast()}
            data-test-id="pageNavigation"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(this.state.currentPage)}
          >
            <img
              src={
                this.isCurrentPageLast()
                  ? Images.forwardArrowDisable
                  : Images.forwardArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageLast()}
            data-test-id="pageNavigation"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(this.state.totalPage - 1)}
          >
            <img
              src={
                this.isCurrentPageLast()
                  ? Images.moveForwardArrowDisable
                  : Images.moveForwardArrowEnable
              }
            />
          </Button>
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.ShippingLayout}>
          <Box sx={webStyle.sideBar}>
            <Sidebar />
            <Box sx={webStyle.bodyContent}>
              <AppHeader pageTitle="Configuration" />
              <Card style={webStyle.mainContainer}>
                <style>
                  {`
                    .MuiContainer-root-50 {
                      padding-right: 0 !important;
                    }

                    .chipCell {
                      & .MuiChip-root {
                        background-color: #fff !important;
                        color: #006395 !important;
                        border: 1px solid lightgrey;
                        margin-right: 5px;
                      }
                    }
                  `}
                </style>
                <CardContent style={webStyle.listCard}>
                  <Box style={webStyle.listHeading}>
                    <Typography
                      variant="h6"
                      style={webStyle.topbarTitle as React.CSSProperties}
                    >
                      Shipping Method
                    </Typography>
                    <Box>
                      <AdvancedSearch
                        data-test-id="advanceSearch"
                        navigation={undefined}
                        id={""}
                        classes={{}}
                        queryItems={this.state.queryItems}
                        onChangeSearchField={this.onChangeSearchField}
                        handleRemoveTag={this.handleRemoveTag}
                        querySearch={this.state.query}
                      />
                    </Box>
                  </Box>
                  <Box sx={webStyle.listMainContainer}>
                    <Box sx={webStyle.tableOption}>
                      <ClickAwayListener
                        data-test-id="clickAway"
                        onClickAway={this.handleFilterClickAway}
                      >
                        <div>
                          <Button
                            style={webStyle.buttonComponent}
                            data-test-id="popperClick"
                            onClick={this.handleClickFilter}
                          >
                            <img src={Images.filter} /> &nbsp; Filters
                          </Button>
                          <Popper
                            style={webStyle.popper}
                            anchorEl={this.state.anchorElFilter}
                            open={Boolean(this.state.anchorElFilter)}
                            placement="bottom-start"
                            transition
                          >
                            <MenuList style={{ padding: 0 }}>
                              {this.filterOptions.map((item) => (
                                <div style={webStyle.popperBlock} >
                                  <MenuItem
                                    data-test-id="MenuPopper"
                                    key={item.name}
                                    data-value={item.name}
                                    style={webStyle.popperItem}
                                    onClick={() => { this.handleFilterSelect(item); }}
                                  >
                                    <Typography style={this.state.queryItems.some((element) => element.tag === item.name) ? webStyle.selectedQueryItem : webStyle.unselectedQueryItem}>
                                      {item.name}
                                    </Typography>
                                  </MenuItem>
                                </div>
                              ))}
                            </MenuList>
                          </Popper>
                        </div>
                      </ClickAwayListener>
                      <ClickAwayListener
                        data-test-id="clickAway"
                        onClickAway={this.handleGroupClickAway}
                      >
                        <div>
                          <Button
                            data-test-id="popperClick"
                            style={webStyle.buttonComponent}
                            onClick={(e)=>{
                              this.handleClickGroupBy(e)
                            }}
                          >
                            <img src={Images.multipleFile} />
                            &nbsp; Group by
                          </Button>
                          <Popper
                            transition
                            anchorEl={this.state.anchorElGroupBy}
                            open={Boolean(this.state.anchorElGroupBy)}
                            placement="bottom-start"
                            style={webStyle.popper}
                          >

                            <MenuList style={{ padding: 0 }}>
                              {this.groupByOptions.map((item) => (
                                <div style={webStyle.popperBlock}>
                                  <MenuItem
                                    key={item.name}
                                    data-test-id="MenuPopper-group"
                                    style={webStyle.popperItem}
                                    data-value={item.name}
                                    onClick={() => { this.handleGroupBySelect(item); }}
                                  >
                                    <Typography style={this.state.queryItems.some((element) => element.tag === item.name) ? webStyle.selectedQueryItem : webStyle.unselectedQueryItem}>
                                      {item.name}
                                    </Typography>
                                    </MenuItem>
                                </div>
                              ))}
                            </MenuList>
                          </Popper>
                        </div>
                      </ClickAwayListener>

                      <div>
                        <Button style={webStyle.buttonComponent}>
                          <img src={Images.favourite} />
                          &nbsp; Favorites
                        </Button>
                      </div>
                    </Box>

                    <Box sx={webStyle.tableOption}>
                      {this.state.isAllChecked ||
                        Object.keys(this.state.selectedItems).length ? (
                        <>
                          <ClickAwayListener onClickAway={this.handleSettingsClickAway} data-test-id="clickAway">
                            <div>
                              <Button
                                data-test-id="popperClick"
                                style={webStyle.buttonComponentSettings}
                                onClick={this.handleClickSetting}
                              >
                                <img src={Images.settings} />
                              </Button>
                              <Popper
                                open={Boolean(this.state.anchorEl)}
                                anchorEl={this.state.anchorEl}
                                transition
                                style={webStyle.settingsPopper}
                                placement="bottom-end"
                              >

                                <MenuList style={{ padding: 0 }}>
                                  {this.SettingOptions.map((item) => (
                                    <div style={{ borderBottom: "1px solid #F3F3F3" }}>
                                      <MenuItem
                                        data-test-id="closedMenu-1"
                                        key={item.name}
                                        data-value={item.name}
                                        style={webStyle.popperItem}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    </div>
                                  ))}
                                </MenuList>
                              </Popper>
                            </div>
                          </ClickAwayListener>
                        </>
                      ) : null}
                      <ButtonComponent color="primary" style={webStyle.activeField}
                        onClick={
                          () => this.props.navigation.navigate("SalesconfigurationCreateshippingmethod")
                        }
                      >
                        <Typography
                          style={{ marginRight: "5px" }}
                        >
                          Create Shipping Method
                        </Typography>
                        <img src={Images.add} />
                      </ButtonComponent>
                    </Box>
                  </Box>
                  <Box sx={{ overflow: "auto" }}>
                    <TableContainer > {this.state.responseData.length ?
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                size="medium"
                                tabIndex={-1}
                                checked={this.state.isAllChecked}
                                data-test-id="allCheckBox"
                                edge="start"
                                disabled={this.state.responseData.length <= 0}
                                onChange={() => this.handleAllChecked()}
                              />
                            </TableCell>
                            {tableHeading.map((item, index) => (
                              <TableCell key={index} style={webStyle.tableHeading as React.CSSProperties}>
                                <b style={{ whiteSpace: "nowrap" }}>{item}</b>
                              </TableCell>
                            ))}
                            <TableCell
                              onClick={
                                (event) => this.toggleColumn(event.currentTarget)
                              }
                              data-test-id="toggleColumn"
                            >
                              <img
                                src={Images.more}
                              />
                            </TableCell>

                            <StyledMenu
                              onClose={() => this.toggleColumn(null)}
                              open={Boolean(this.state.anchorElement)}
                              anchorEl={this.state.anchorElement}
                              data-test-id="closedMenu-2"
                            >
                            </StyledMenu>
                          </TableRow>
                        </TableHead>
                        {this.renderListAndGroupbyTable(this.state.responseData)}
                      </Table>
                      : <Box style={webStyle.emptyData}>
                        <Typography>No Data Found</Typography>
                      </Box>
                    }

                    </TableContainer>
                    {!this.state.isGroupby && this.renderPagination()}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  ShippingLayout: {
    width: "100%",
    display: "flex",
  },
  sideBar: {
    transition: "all 0.3s",
    minHeight: "100%",
    width: "100%",
    display: "flex",
  },
  bodyContent: {
    flexGrow: 1,
    padding: "0 20px",
    width: "calc( 100% - 300px)",
    overflow: "auto",
    backgroundColor: "#f3f3f3",
  },
  tableBox: {
    display: "flex",
    alignItems: "center",
  },
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    margin: "5px 0",
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    padding: "15px",
    justifyContent: "space-between",
    height: "50px",
  },
  perPageItem: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  navigationArrows: {
    justifyContent: "space-between",
    alignItems: "center",
    width: "200px",
    display: "flex",
  },
  paginationNumber: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  cursorPointor: {
    cursor: "pointer" as string,
  },
  activeField: {
    color: "#fff",
    backgroundColor: "#006395",
  },
  tableOption: {
    margin: "5px 0",
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    gap: "10px",
    alignItems: "center",
  },
  listMainContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    padding: "15px",
    margin: "5px 0",
    height: "50px",
    gap: "10px",
  },
  canbanMainContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
    display: "flex",
    height: "50px",
    margin: "5px 0",
    gap: "10px",
  },
  paginationButton: {
    height: "100%",
  },
  mainContainer: {
    padding: 0,
    border: "1px solid #d9d9d9",
    minHeight: "750px",
    boxShadow: "none",
    marginBottom: "20px",
  },
  productDescription: {
    textOverflow: "ellipsis",
    width: "150px",
    overflow: "hidden",
    fontSize: "12px",
    textWrap: "nowrap",
  },
  disabledNaviginationButton: {
    fill: "lightgrey",
    color: "lightgrey",
  },
  enabledNaviginationButton: {
    fill: "grey",
    color: "grey",
  },
  listHeading: {
    borderBottom: "1px solid #E3E3E3",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "15px",
    display: "flex",
  },
  searchBar: {
    "& .MuiOutlinedInput-root": {
      "& input": {
        border: "none !important",
      },
      height: "100%",
      "& .MuiOutlinedInput-input": {
        border: "none !important",
      },
      width: "500px",
    },
    border: "none !important",
    minWidth: "250px",
  },
  popperBlock: {
    borderBottom: "2px solid #F3F3F3",
  },
  topbarTitle: {
    fontWeight: "bold",
    color: "#006395",
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
    marginTop: "4px",
    border: "1px solid #ccc",
    borderRadius: 4,
    backgroundColor: "#ffffff",
    padding: 0,
    width: "max-content",
  },
  buttonComponentSettings: {
    color: "black",
    backgroundColor: "#e1e1e1",
    padding: "8px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    border: "none",
    height: "100%",
  },
  settingsPopper: {
    backgroundColor: "#ffffff",
    border: "1px solid #ccc",
    marginTop: "4px",
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    padding: 0,
    borderRadius: 4,
  },
  popperItem: {
    borderBottom: "1px solid #F3F3F3 !important",
    fontSize: "small",
  },
  buttonComponent: {
    borderRadius: "4px",
    backgroundColor: "#e1e1e1",
    color: "black",
    display: "flex",
    fontSize: "12px",
    alignItems: "center",
    cursor: "pointer",
    height: "100%",
    border: "none",
    padding: "8px 15px",
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: "50vh",
    width: "100%",
  },
  rowText: {
    fontWeight: "bold",
  },
  navinationDropdown: {
    fontWeight: "bold",
    padding: "0px 5px",
    outline: "1px solid #f3f3f3",
    borderRadius: "4px",
    height: "40px",
  },
  tableRow: {
    borderBottom: "1px solid #E5E5E5",
    cursor: "pointer"
  },
  listCard: {
    padding: 0
  },
  paginationText: {
    alignItems: "center",
    display: "flex",
    height: "40px",
  },
  paginationCountBoxShow: {
    flexDirection: "row",
    display: "flex",
  },
  paginationCountBoxHidden: {
    visibility: "hidden",
    display: "flex",
    flexDirection: "row"
  },
  emptyData: {
    justifyContent: 'center',
    height: '50vh',
    alignItems: 'center',
    display: 'flex',
  },
  tableHeading: {
    textTransform: "uppercase"
  },
  muiChipRoot : {
    backgroundColor: '#fff !important',
    color:' #006395!important',
    border: '1px solid lightgrey',
    marginRight: "5px"
  },
  selectedQueryItem:{
    color: "#2A6395", 
    fontSize: "12px", 
    fontWeight: 600 
  },
  unselectedQueryItem:{
    fontSize: "12px",
    fontWeight: 600 
  }
};
// Customizable Area End
