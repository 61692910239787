// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import SalesconfigrationUOMcategoriesController, { Props } from './SalesconfigrationUOMcategoriesController.web'
import { configJSON } from './FilteroptionsController';
import { add, backArrow, dashboard, downarrow, filter, forwardArrow, groupby, list, more, moveBackwardArrow, moveForwardArrow, search, settings, star, swap } from './assets';
import { AppHeader } from '../../../components/src/AppHeader.web';
import Sidebar from '../../../components/src/Sidebar.web';
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export const DropdownCmp = () => {
    return <><img style={{ width: "100%" }} src={downarrow} /></>
}

export default class SalesconfigrationUOMcategories extends SalesconfigrationUOMcategoriesController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { showFilter, showGroup, showFav, showSettings, showColumn, showCurrentSearch, showAddToDashboard } = this.state;
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.categoryMainLayout}>
                <Box className={classes.categorysideBar}>
                    <Sidebar />
                </Box>
                <Box className={classes.categorycontent}>
                    <AppHeader pageTitle={configJSON.pageTitle} />
                    <Box className={classes.categoryMainbox} >
                        <Box>
                            <Box className={classes.categoryBlock}>
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className={classes.categorySearchgrid}>
                                        <Typography className={classes.categoryHeading}>{configJSON.mainHeading}</Typography>
                                    </Grid>
                                    <Grid item sm={6} className={classes.categorySearchbox}>
                                        <TextField
                                            className={classes.searchbox}
                                            id='search-input'
                                            variant="outlined"
                                            placeholder='Search here....'
                                            data-testid="listSearchinput"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.getsearchUoMcategorieslistApi(e)}
                                        />
                                        <img src={search} className={classes.categoryImg} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.categoryFilterbox} >
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className={classes.categorySearchgrid}>
                                        <Box className={classes.categoryColumnbox}>
                                            <Button className={classes.categoryFilters} data-testid="category-Filters" onClick={this.saleshandleFilter}
                                            ><img src={filter} className={classes.categoryFilterImg} />{configJSON.Filters}</Button>
                                            {
                                                showFilter &&
                                                <Box className={classes.filterBoxcategory} >
                                                    <List>
                                                        <ListItem button data-testid="archive-list" onClick={() => { this.getfilterUoMcategorieslistApi("filter_archive", true) }}
                                                        >
                                                            <ListItemText primary="archive" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                        <Box className={classes.categoryColumnbox}>
                                            <Button className={classes.categoryFilters} data-testid="category-Groupby"
                                                onClick={this.saleshandleGroup}
                                            ><img src={groupby} className={classes.categoryFilterImg} />{configJSON.Groupby}</Button>
                                            {
                                                showGroup &&
                                                <Box className={classes.filterBoxcategory}>
                                                    <List>
                                                        <ListItem button data-testid="list-salesPerson"
                                                        >
                                                            <ListItemText primary="Sales Person" />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                        <Box className={classes.categoryColumnbox}>
                                            <Button className={classes.categoryFilters} data-testid="category-Favorites" onClick={this.saleshandleFavorites} ><img src={star} className={classes.categoryFilterImg} />{configJSON.Favorites}</Button>
                                            {
                                                showFav &&
                                                <Box className={classes.filterBoxcategory}>
                                                    <List >
                                                        <ListItem button data-testid="category-currentSearch" onClick={this.saleshandleCurrentSearch}>
                                                            <ListItemText primary="Save Current Search" />
                                                            &gt;
                                                        </ListItem>
                                                        <ListItem button data-testid="list-importRecords">
                                                            <ListItemText primary="Import Records" />
                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary="Add to my Dashboard" data-testid="category-addDashboard" onClick={this.saleshandleAddToDashboard} />
                                                            &gt;
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                            {
                                                showCurrentSearch &&
                                                <Box className={classes.filterBoxCurrentSearch}>
                                                    <List className={classes.gridviewlist} >
                                                        <ListItem button className='listItem'>
                                                            <ListItemText primary="List of Customers" />
                                                        </ListItem>
                                                        <ListItem button className={classes.salesButton} >
                                                            <ListItemIcon className={classes.salesIcon} >
                                                                <Checkbox className={classes.salesCheckbox} defaultChecked />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Use by default" />
                                                        </ListItem>
                                                        <ListItem button className={classes.gridViewlistItem}>
                                                            <ListItemIcon className={classes.salesIcon}>
                                                                <Checkbox className={classes.salesCheckbox} />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Share with all users" />
                                                        </ListItem>
                                                        <ListItem button className={classes.salesButtonlist} >
                                                            <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                            {
                                                showAddToDashboard &&
                                                <Box className={classes.filterBoxAddToDashboard}>
                                                    <List className={classes.gridviewlist}>
                                                        <ListItem button className='listItem'>
                                                            <ListItemText primary="List of Customers" />
                                                        </ListItem>
                                                        <ListItem button className={classes.salesButtonlist}   >
                                                            <Button className='saveBtn'>{configJSON.buttonSave}</Button>
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} className={classes.categoryCreatebox} >
                                        <Box className={classes.categoryColumnbox}>
                                            <Button className={classes.categoryFilters} data-testid="category-settings" onClick={this.saleshandleSettings}><img src={settings} /></Button>
                                            {
                                                showSettings &&
                                                <Box className={classes.filterBoxcategory}>
                                                    <List>
                                                        <ListItem button >
                                                            <ListItemText primary="Export" />
                                                        </ListItem>
                                                        <ListItem button >
                                                            <ListItemText primary="Delete" data-testid="delete-category" onClick={() => { this.deleteUoMcategoriesApi() }} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            }
                                        </Box>
                                        <Button className={classes.filtersLeftcategory}><img src={list} /></Button>
                                        <Button className={classes.categoryFilters} data-testid="navigatetoGridview" onClick={this.salesnavigatetoGridview}><img src={dashboard} /></Button>
                                        <Button className={classes.filtersrightcategory} data-testid="createUoMcategories" onClick={this.salesnavigatetoCreateCustomer}>{configJSON.Createcustomer}<img src={add} /></Button>
                                    </Grid>
                                </Grid>
                                <Box className={classes.categoryColumnbox} >
                                    <Box onClick={this.saleshandleColumn} className={classes.categoryColumn} data-testid="categoryColumn" >
                                        <img src={more} />
                                    </Box>
                                    {
                                        showColumn &&
                                        <Box className={classes.filterBoxcategory}>
                                            <List className={classes.categoryAddcustomerbox}>
                                                {
                                                    this.state.columns?.length > 0 &&
                                                    this.state.columns.map((text, index) => (
                                                        <ListItem key={index} button >
                                                            <ListItemIcon className={classes.categoryIcon} >
                                                                <Checkbox className={classes.categoryCheckbox} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={text} />
                                                        </ListItem>
                                                    ))
                                                }
                                                <ListItem button className={classes.categoryButton}  >
                                                    <Box>
                                                        {configJSON.addField}
                                                    </Box>
                                                </ListItem>
                                            </List>
                                        </Box>
                                    }
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><Checkbox className={classes.categoryCheck} />
                                                        <img src={swap} />
                                                    </TableCell>
                                                    <TableCell className={classes.categoryTableheading}>{configJSON.Unit}</TableCell>
                                                    <TableCell className={classes.categoryTableNameHeading}>{configJSON.name}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.getlistcategorydata?.map((data: {
                                                    id: number; attributes: { category: string, unit_of_measure: [] }
                                                }) => {
                                                    return (
                                                        <TableRow key={data.id}>
                                                            <TableCell><Checkbox className={classes.categoryCheck} data-testid="select-checkbox" onChange={() => { this.handleCheckbox(data.id) }} />
                                                                <img src={swap} />
                                                            </TableCell>
                                                            <TableCell className={classes.categoryTabletext}>{data.attributes.category}</TableCell>
                                                            <TableCell className={classes.categoryTabletext}>
                                                                <Box className={classes.categoryUnitnamebox}>
                                                                    {data.attributes.unit_of_measure?.map((value: { uom: string }) => {
                                                                        return (
                                                                            <>
                                                                                <Typography className={classes.categoryUnitname}>{value.uom}</Typography>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid className={classes.categoryPaginationContainer}>
                                    <Box className={classes.categorypaginationBox}>
                        <Box className={classes.categoryitemPerPage}>
                          <span>{configJSON.itemsPage}&nbsp;</span>
                          <Select
                            onChange={this.handleChangerowsPerpage}
                            value={this.state.rowsPerpage}
                          >
                            <MenuItem value={10}>{configJSON.salespageFive}</MenuItem>
                            <MenuItem value={30}>{configJSON.salespageSix}</MenuItem>
                            <MenuItem value={60}>{configJSON.salespageSeven}</MenuItem>
                            <MenuItem value={90}>{configJSON.salespageEight}</MenuItem>
                          </Select>
                        </Box>
                        <Box>
                          <span>
                            {this.state.currentPagenumber * this.state.rowsPerpage +
                              1}
                            &nbsp; -&nbsp;
                            {this.state.currentPagenumber * this.state.rowsPerpage +
                              this.state.currentPagenumber}
                            &nbsp;of &nbsp;{configJSON.salespageNine}
                          </span>
                          <Button
                            disabled={this.state.currentPagenumber === 0}
                            data-testid="categorymoveBackwardarrow"
                            onClick={() =>
                              this.handleChangePageNumber(this.state.currentPagenumber - 2)
                            }
                          >
                            <img
                              src={moveBackwardArrow}
                            />
                          </Button>
                          <Button
                            disabled={this.state.currentPagenumber === 0}
                            className={classes.paginationButton}
                            data-testid="categorybackArrow"
                            onClick={() =>
                              this.handleChangePageNumber(this.state.currentPagenumber - 2)
                            }
                          >
                            <img
                              src={backArrow}
                            />
                          </Button>
                          <Button
                            disabled={
                              this.state.currentPagenumber === this.state.totalPage
                            }
                            data-testid="categoryforwardArrow"
                            onClick={() =>
                              this.handleChangePageNumber(this.state.currentPagenumber)
                            }
                          >
                            <img
                              src={forwardArrow}
                            />
                          </Button>
                          <Button
                            disabled={
                              this.state.currentPagenumber === this.state.totalPage
                            }
                            data-testid="categorymoveForwardarrow"
                            className={classes.paginationButton}
                            onClick={() =>
                              this.handleChangePageNumber(this.state.currentPagenumber)
                            }
                          >
                            <img
                              src={moveForwardArrow}
                            />
                          </Button>
                        </Box>
                      </Box>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const webStyle: any = (theme: any) => ({
    categoryMainbox: {
        marginTop: "3%"
    },
    filterBoxCurrentSearch: {
        position: "absolute",
        zIndex: "2",
        background: "#fff",
        left: "262px",
        top: " 53px",
        border: "1px solid #e3e3e3",
    },
    categoryPaginationContainer:{
        marginTop:"2%",
        marginBottom:"2%"
    },
    categoryPagination:{
       position:"relative",
       "&.MuiTablePagination-toolbar":{
        "&..MuiTablePagination-spacer":{
            "&.MuiTablePagination-caption":{
                position:"absolute !important"
            }
        }
       }
    },
    categorySearchgrid: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
    },
    searchbox: {
        maxWidth: "100%",
        width: "100%",
    },
    categoryUnitname: {
        backgroundColor: "#f3f3f3",
        color: "#3d3d3d",
        fontSize: "16px ",
        height: "100%",
        width: "max-content",
        margin: "1%",
        padding: "1%",
        paddingLeft: "4%",
        paddingRight: "4%",
        borderRadius: "15px"
    },
    categoryUnitnameActive: {
        backgroundColor: "#185d85",
        color: "white",
        fontSize: "16px ",
        height: "100%",
        width: "max-content",
        margin: "1%",
        padding: "1%",
        paddingLeft: "4%",
        paddingRight: "4%",
        borderRadius: "15px"
    },
    categoryUnitnamebox: {
        display: "flex",
        alignItems: "center",
    },
    filterBoxAddToDashboard: {
        position: "absolute",
        zIndex: "2",
        background: "#fff",
        left: "262px",
        top: "159px",
        border: "1px solid #e3e3e3",
    },
    categoryTableNameHeading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStyle: "normal",
        width: "650px",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    categoryFilters: {
        backgroundColor: "#f3f3f3",
        color: "#3d3d3d",
        margin: "15px 10px ",
        textTransform: "capitalize",
        fontSize: "16px ",
        fontWeight: "500 ",
        "&:hover": {
            backgroundColor: "#f3f3f3",
            color: "#3d3d3d",
            margin: "15px 10px ",
            textTransform: "capitalize",
            fontSize: "16px ",
            fontWeight: "500 ",
        }
    },
    filterBoxcategory: {
        position: "absolute",
        zIndex: "2",
        background: "#fff",
        left: "10px",
        top: "53px",
        border: "1px solid #e3e3e3",
    },
    filtersLeftcategory: {
        backgroundColor: "#006395",
        color: "#ffffff",
        margin: "15px 10px",
        "&:hover": {
            backgroundColor: "#006395",
            color: "#ffffff",
            margin: "15px 10px",
        }
    },
    filtersrightcategory: {
        backgroundColor: "#006395",
        color: " #ffffff ",
        margin: "15px 10px",
        fontSize: "16px",
        fontWeight: "500",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#006395",
            color: " #ffffff ",
            margin: "15px 10px",
            fontSize: "16px",
            fontWeight: "500",
            textTransform: "capitalize",
        }
    },
    categoryMainLayout: {
        display: "flex",
    },
    categorysideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    categorycontent: {
        flexGrow: 9,
        padding: "0 20px",
    },
    categoryBlock: {
        padding: "15px",
        border: "1px solid #e3e3e3",
    },
    categoryFilterblock: {
        position: "relative"
    },
    categoryHeading: {
        color: "#185d85",
        fontSize: "30px",
        fontWeight: "bold",
        fontFamily: "Inter",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    categoryImg: {
        position: "absolute",
        left: "15px",
        top: "17px",
        height: "20px"
    },
    categoryFilterbox: {
        border: "1px solid #e3e3e3"
    },
    categoryFilterImg: {
        paddingRight: "8px"
    },
    categoryColumnbox: {
        position: "relative"
    },
    categoryColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    categoryAddcustomerbox: {
        width: "250px"
    },
    categoryButton: {
        justifyContent: "end"
    },
    categoryIcon: {
        minWidth: "35px"
    },
    categoryCheckbox: {
        paddingLeft: 0
    },
    categoryCheck: {
        color: "#185d85 !important"
    },
    categoryTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    categoryTabletext: {
        fontSize: "16px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    categoryTableavatar: {
        borderRadius: "50%",
        paddingRight: "10px"
    },
    categoryItems: {
        padding: "15px"
    },
    categoryCreatebox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px"
    },
    categorySearchbox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative"
    },
    categoryItemsgrid: {
        display: "flex !important",
        justifyContent: "left",
    },
    categoryItemsText: {
        paddingRight: "10px",
        fontSize: "20px",
        marginTop: "11px"
    },
    categoryText: {
        marginRight: "50px"
    },
    salesButton: {
        paddingBottom: 0
    },
    salesIcon: {
        minWidth: "35px"
    },
    salesCheckbox: {
        paddingLeft: 0
    },
    salesButtonlist: {
        justifyContent: "end"
    },
    categorypaginationBox: {
        display: "flex",
        alignItems: "center",
        /* width: "100%", */
        padding: "15px 5px",
        justifyContent: "space-between",
        marginRight: "52px",
        height: "50px",
      },
      categoryitemPerPage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      paginationArrows: {
        width: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      paginationNumber: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
})
export const SalesconfigrationUOMcategoriesStyles = withStyles(webStyle)(SalesconfigrationUOMcategories)
  // Customizable Area End
