export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const dummy = require("../assets/dummy.jpg");

export const backArrowEnable = require("../assets/backArrowEnable.svg");
export const backArrowDisable = require("../assets/backArrowDisable.svg");

export const forwardArrowEnable = require("../assets/forwardArrowEnable.svg");
export const forwardArrowDisable = require("../assets/forwardArrowDisable.svg");

export const moveBackwardArrowEnable = require("../assets/moveBackwardEnable.svg");
export const moveBackwardArrowDisable = require("../assets/moveBackwardDisable.svg");

export const moveForwardArrowEnable = require("../assets/moveForwardEnable.svg");
export const moveForwardArrowDisable = require("../assets/moveForwardDisable.svg");

export const favourite = require("../assets/favourite.svg");
export const filter = require("../assets/filter.svg");
export const multipleFile = require("../assets/multiple_file.svg");
export const add = require("../assets/add.svg");
export const more = require("../assets/more.svg");
export const listView = require("../assets/listview.svg");
export const canban = require("../assets/dashboard.svg");
export const checkboxChecked = require("../assets/checkboxChecked.svg");
export const checkboxUnchecked = require("../assets/checkboxUnchecked.svg");
export const downArrow = require("../assets/downArrow.svg");
export const settings = require("../assets/settings.svg");
export const settingsGrey = require("../assets/settingsGrey.svg");
export const activity = require("../assets/activity.svg");
export const quantityOnHand = require("../assets/quantity_on_hand.svg");
export const quantityPurchased = require("../assets/quantity_purchased.svg");
export const quantitySold = require("../assets/quantity_sold.svg");
export const addMedia = require("../assets/add_media.svg");
export const externalLink = require("../assets/external_link.svg");
export const alert = require("../assets/alert.svg");
export const edit = require("../assets/edit.svg");
export const add_green = require("../assets/add.svg");
export const deleteBtn = require("../assets/delete.svg");
