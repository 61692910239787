import React from "react";
import {
  Box,
  Button,
  Checkbox,
  withStyles,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import CfCustomerSettingTabsController, {
  Props,
} from "./CfCustomerSettingTabsController.web";
import { rightArrow } from "../assets";
import { validationerror } from "../../../cfcustomersalesreports3/src/assets";
export const configJSON = require("../config");

const styles = (theme: any) => ({
  pricingroot: {
    padding: "0 8px",
  },
  pricingcontainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "120px",
  },
  pricingtextContainer: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderLeft: "1px solid #ddd",

    paddingLeft: "20px",
    height: "100%",
  },
  pricingtextHolder: {
    display: "flex",
    flexDirection: "column",
  },
  pricingcheckbox: {
    "& .MuiSvgIcon-root": {
      
    },
    "& .Mui-checked": {
      backgroundColor: "transparent !important",
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  pricingcheckboxHolder: {
    alignSelf: "flex-start",
    height: "100%",
    display: "flex",
  },
  pricingmainHeading: {
    fontSize: "15px",
    margin: "5px 0",
    fontWeight: "bold",
  },
  pricingmainParagraph: {
    fontSize: "14px",
    marginBottom: "12px",
    color: "#000"
  },
  pricingmainParagraphSelected: {
    fontSize: "14px",
    marginBottom: "12px",
    color: "#2A6396",
    fontWeight: "bold"
  },
  radioBtn: {
    "& .Mui-checked": {
      backgroundColor: "transparent !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#000",
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    ".MuiIconButton-colorSecondary .Mui-checked:hover": {
      backgroundColor: "transparent !important",
    }
  },
  pricingradioBtnSelected: {
    "& .Mui-checked": {
      backgroundColor: "transparent !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#2A6396",
    },
    "&:hover": {
      color: "transparent",
      backgroundColor: "transparent !important",
    },
    ".MuiIconButton-colorSecondary .Mui-checked:hover": {
      backgroundColor: "transparent !important",
    }
  },
  pricingbtnBox: {
    display: "flex",
    float: "right",
    marginBlock: "30px",
    gap: "20px",
  },
  pricingsaveButton: {
    background: "#2A6396",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#2A6396",
    },
  },
  pricingdiscardButton: {
    background: "white",
    textTransform: "capitalize",
  },
  pricingshippingBtn: {
    "& .MuiButton-label": {
      "& .MuiButton-startIcon": {
        paddingTop: "1px",
        marginRight: "2px"
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    ".MuiButton-root:hover":{
      backgroundColor: "transparent",
    }
  },
  pointereventsnone:{
    pointerEvents: "none"
  }

});

export class CfCustomerSalesPricing extends CfCustomerSettingTabsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Box className={classes.pricingroot}>
          <Grid className='pt-20' container spacing={0} >
          <Grid item sm={7}>
          <Box>
                    <Typography className='pricelist-input-text'>{configJSON.pricelist_name}<span className='required'>*</span></Typography>
                    <TextField data-test-id="pricelistInput" 
                    style={{width:"85%"}}
                    className="form-input input-field" 
                    value={this.state.userSetting?.pricelist_name}
                    onChange={(e:any) => this.handlePricelistInput(e)} 
                    name="pname" type="text" />
                    <Box className={`flex relative ${!this.state.pricelistNameError && 'display-none'}`} >
                        <img className='error-text-img' src={validationerror} />
                    <Typography className='error-text'>Please enter pricelist name.</Typography>
                    </Box>
                </Box>
          <div className={classes.pricingcontainer}>
            <div className={classes.pricingcheckboxHolder}>
              <Checkbox
                data-test-id="price-list"
                className={classes.pricingcheckbox}
                checked={this.state.userSetting.price_list}
                onChange={(event) => this.handlePricelistCheckboxCheck(event)}
              />
            </div>
            <div className={classes.pricingtextContainer}>
              <div className={`box ${!this.state.userSetting.price_list && classes.pointereventsnone}`}>
                <div className={classes.pricingtextHolder}>
                  <p className={classes.pricingmainHeading}>{configJSON.pricelists}</p>
                  <p className={classes.pricingmainParagraph}>
                    {" "}
                    {configJSON.pricelists_text}{" "}
                  </p>
                  <RadioGroup data-test-id="radio-pricing"
                    value={this.state.userSetting.price_list_setting}
                    onChange={(event) => this.handlePricelistRadioButton(event)}
                  >
                    <FormControlLabel
                      value="a"
                      control={
                        <Radio
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          size="small"
                          className={this.state.userSetting.price_list_setting === "a" ? classes.pricingradioBtnSelected : classes.pricingradioBtn}
                          
                        />
                      }
                      label={
                        <p className={this.state.userSetting.price_list_setting === "a" ? classes.pricingmainParagraphSelected : classes.pricingmainParagraph}>
                          {configJSON.multiple_prices_per_product}
                        </p>
                      }
                    />
                    <FormControlLabel
                      value="b"
                      control={
                        <Radio size="small"
                          className={this.state.userSetting.price_list_setting === "b" ? classes.pricingradioBtnSelected : classes.pricingradioBtn}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                        />
                      }
                      label={
                        <p className={this.state.userSetting.price_list_setting === "b" ? classes.pricingmainParagraphSelected : classes.pricingmainParagraph}>
                          {configJSON.advanced_price_rules}
                        </p>
                      }
                    />
                  </RadioGroup>
                  <div className={classes.pricingboxContainer}>
                <Button
                  className={classes.pricingshippingBtn}
                  variant="text"
                  color="primary"
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  startIcon={
                    <img src={rightArrow} className='mr-10' />
                  }
                >
                  {configJSON.pricelist}
                </Button>
              </div>
                </div>
              </div>
            </div>
          </div>
          </Grid>
          <Grid item sm={5}>
          <div className={classes.pricingcontainer}>
                
            <div className={classes.pricingcheckboxHolder}>
              <Checkbox
                data-test-id="pricing-coupon"
                className={classes.pricingcheckbox}
                checked={this.state.userSetting?.coupons_and_promotions}
                onChange={(event) => this.handleCouponsCheckboxCheck(event)}
              />
            </div>
            <div className={classes.pricingtextContainer}>
              <div className="box">
                <div className={classes.pricingtextHolder}>
                  <p className={classes.pricingmainHeading}>{configJSON.coupons_and_promotions}</p>
                  <p className={classes.pricingmainParagraph}>
                    {" "}
                    {configJSON.manage_programs}{" "}
                  </p>
                  <div className={classes.pricingboxContainer}>
                  <Button
                    className={classes.pricingshippingBtn}
                    variant="text"
                    color="primary"
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    startIcon={
                      <img src={rightArrow} className='mr-10' />
                    }
                  >
                    {configJSON.promotion_programs}
                  </Button>
                </div>
                  <div className={classes.pricingboxContainer}>
                  <Button
                    className={classes.pricingshippingBtn}
                    variant="text"
                    color="primary"
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    startIcon={
                      <img src={rightArrow} className='mr-10' />
                    }
                  >
                    {configJSON.coupons_programs}
                  </Button>
                </div>
                
                </div>
              </div>
            </div>
          </div>
          </Grid>
          </Grid>
          
        </Box>
        <Box className={classes.pricingbtnBox}>
          <Button variant="outlined"
            data-test-id="discard-pricing"
            className={classes.pricingdiscardButton}
            onClick={() => this.handlePricingDiscard()}>
            Discard
          </Button>
          <Button
            data-test-id="SettingPricingSubmit"
            onClick={() => {
              this.state.userSetting?.pricelist_name ? this.handleSetting(): this.handlePricelistInput('');
            }}
            variant="contained"
            className={classes.pricingsaveButton}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  }
}

//@ts-ignore
export default withStyles(styles)(CfCustomerSalesPricing);
