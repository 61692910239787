import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Switch,
  ClickAwayListener,
  Popper,
  MenuList
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
const { cross,check ,settings} = require("./assets");
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2A6395",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import BankAccountCreationController, {
  Props,
  configJSON,
} from "./BankAccountCreationController";

export default class BankAccountCreation extends BankAccountCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={webStyle.gridMainLayout}>
          <Box style={webStyle.gridsideBar}>
            <Sidebar />
          </Box>
          <Box className="gridcontent" style={webStyle.gridcontent}>
            <AppHeader pageTitle={configJSON.contacts} />
            <Box className="parent-div" >
            <style>
                    {`
                      .MuiContainer-root-50 {
                        padding-right: 0 !important;
                      }
                      .MuiSwitch-root {
                        width: 42px !important;
                        height: 26px !important;
                        padding: 0 !important;
                        margin: 8px !important;
                      }

                      .MuiSwitch-track {
                        border-radius: 13px !important;
                        background-color: #DCDCDC !important;
                        opacity: 1 !important;
                        transition: background-color 300ms ease-in-out, border 300ms ease-in-out !important;
                      }

                      .MuiSwitch-thumb {
                        width: 21px !important;
                        height: 21px !important;
                      }

                      .MuiSwitch-switchBase {
                        padding: 1px !important;
                        color : #DCDCDC !important;
                        background-color: #ffffff !important;
                        top: 2.5px !important;
                        left: 3px !important;
                      }
                      
                      .MuiSwitch-switchBase.Mui-checked {
                        transform: translateX(16px) !important;
                        color: #2A6395 !important;
                      }

                      .Mui-checked {
                        background-color: #ffffff !important;
                      }
                      
                      .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
                        background-color: #2A6395 !important;
                        opacity: 1 !important;
                        border: none !important;
                      }
                      
                      .MuiSwitch-switchBase:focus-visible .MuiSwitch-thumb {
                        color: #2A6395 !important;
                        border: 6px solid #ffffff !important;
                      }
                    `}
                  </style>
              <Grid container spacing={0} style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                <Grid item sm={3} lg={12} md={12}>
                  <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} style={{ paddingRight:"25px" }} >
                    <Typography className="customer bold-text" >{configJSON.bankAccountHeading} / <span style={{ color: "grey" }}>New</span></Typography>
                    <Box sx={webStyle.activityIconWrapper}>
                      <ClickAwayListener
                        data-test-id="clickAway"
                        onClickAway={this.handleSettingsClickAway}
                      >
                        <div>
                          <Button
                            onClick={this.handleClickSetting}
                            data-test-id="popperClick"
                            style={
                              webStyle.buttonComponentSettings
                            }
                          ><img src={settings} /></Button>
                          <Popper
                            anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)}
                            transition
                            placement="bottom-end"
                            style={webStyle.settingsPopper}
                          >

                            <MenuList style={{ padding: 0 }}>
                              {this.SettingOptions.map((item) => (
                                <div style={{ borderBottom: "1px solid #F3F3F3" }}>
                                  <MenuItem
                                    style={webStyle.popperItem}
                                    key={item.name}
                                    data-value={item.name}
                                    data-test-id="closedMenu-1"
                                  >{item.name}</MenuItem>
                                </div>
                              ))}
                            </MenuList>
                          </Popper>
                        </div>
                      </ClickAwayListener>
                    </Box>
                  </Box>
                </Grid>
                <Divider style={webStyle.divider} />
                <Box>
                {
                  this.mainErrorBox()
                }
                </Box>
                <Box style={{padding: "0px !important", width: "100%" }}>
                  <Box className="form-div" style={{width: "95%"}}>
                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item sm={6} md={6} lg={6} >
                      <Typography className={`${this.errorTextClass('account_number', this.state.account_number)}`}>Account Number<span className='required'>*</span></Typography>
                        <TextField
                          data-test-id="account_number"
                          name="account_number"
                          type="text"
                          style={{ width: "95%",  borderRadius: "5px" }}
                          value={this.state.account_number}
                          onChange={(event: any) => this.handleValueChange(event, "account_number")}
                          placeholder="Enter Account Number"
                          className={`${this.errorFocusClass('account_number', this.state.account_number)}`}
                        />
                      {
                        this.handleErrorMessage('account_number')
                      }
                    </Grid>
                    <Grid item sm={6} md={6} lg={6} >
                    <Typography className={`${this.errorTextClass('account_holder', this.state.account_holder)}`}>Account Holder<span className='required'>*</span></Typography>
                      <FormControl id="select-form-control" style={{ width: "95%" }}>
                        <Select
                          inputProps={{
                            'aria-label': 'custom select',
                          }}
                          label="Select"
                          displayEmpty
                          disableUnderline
                          renderValue={() => this.state.account_holder || "Select Account Holder"}
                          data-test-id="account_holder"
                          className={`width-100 ${!this.state.account_holder && 'select-default-color'} ${this.errorFocusClass('account_holder', this.state.account_holder)}`}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: 55,
                              },
                            },
                          }}
                          value={this.state.account_holder}
                          onChange={(event: any) => this.handleValueChange(event, "account_holder")}
                          IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                        >
                          <MenuItem disabled value="" style={{ display: 'none', color: "#B5B5B5" , fontWeight:400}}>
                            Select Account Holder
                          </MenuItem>
                          {this.AHolderOptions.map((item) => (
                            <MenuItem className='select-menu-item' value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        this.handleErrorMessage('account_holder')
                      }
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography className={`${this.errorTextClass('bank', this.state.bank)}`}>Bank<span className='required'>*</span></Typography>
                      <FormControl id="select-form-control" style={{ width: "95%" }}>
                        <Select
                          inputProps={{
                            'aria-label': 'custom select',
                          }}
                          label="Select"
                          displayEmpty
                          disableUnderline
                          renderValue={() => this.state.bank || "Select Bank"}
                          data-test-id="bank"
                          className={`width-100 ${!this.state.bank && 'select-default-color'} ${this.errorFocusClass('bank', this.state.bank)}`}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: 55,
                              },
                            },
                          }}
                          value={this.state.bank}
                          onChange={(event: any) => this.handleValueChange(event, "bank")}
                          IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                        >
                          <MenuItem disabled value="" style={{ display: 'none', color: "#B5B5B5" ,fontWeight:400}}>
                            Select Bank
                          </MenuItem>
                          {this.state.bankNameList.map((item) => (
                            <MenuItem className='select-menu-item' value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        this.handleErrorMessage('bank')
                      }
                    </Grid>
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography>Company</Typography>
                      <TextField
                        data-test-id="company"
                        name="company"
                        type="text"
                        disabled={true}
                        style={{ width: "95%", borderRadius:"5px",  backgroundColor: this.state.bank ? "" : "#F3F3F3"}}
                        value={this.state.bank}
                        onChange={(event: any) => this.handleValueChange(event, "company")}
                        placeholder=""
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} justifyContent="space-between" >
                  <Grid item sm={6} md={6} lg={6} >
                      <Typography className={`${this.errorTextClass('clabe', this.state.clabe)}`}>Clabe<span className='required'>*</span></Typography>
                        <TextField
                          data-test-id="clabe"
                          name="clabe"
                          type="text"
                          style={{ width: "95%",  borderRadius: "5px" }}
                          value={this.state.clabe}
                          onChange={(event: any) => this.handleValueChange(event, "clabe")}
                          placeholder="Enter Clabe"
                          className={`${this.errorFocusClass('clabe', this.state.clabe)}`}
                        />
                      {
                        this.handleErrorMessage('clabe')
                      }
                    </Grid>
                    <Grid item sm={6} md={6} lg={6} >
                    <Typography className={`${this.errorTextClass('currency', this.state.currency)}`}>Currency<span className='required'>*</span></Typography>
                      <FormControl id="select-form-control" style={{ width: "95%" }}>
                        <Select
                          inputProps={{
                            'aria-label': 'custom select',
                          }}
                          label="Select"
                          displayEmpty
                          disableUnderline
                          renderValue={() => this.state.currency || "Select Currency"}
                          data-test-id="currency"
                          className={`width-100 ${!this.state.currency && 'select-default-color'} ${this.errorFocusClass('currency', this.state.currency)}`}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: 55,
                              },
                            },
                          }}
                          value={this.state.currency}
                          onChange={(event: any) => this.handleValueChange(event, "currency")}
                          IconComponent={() => <ExpandMoreIcon style={{ color: 'black' }} />}
                        >
                          <MenuItem disabled value="" style={{ display: 'none', color: "#B5B5B5" ,fontWeight:400 }}>
                            Select Currency
                          </MenuItem>
                          {this.currencyOptions.map((item) => (
                            <MenuItem className='select-menu-item' value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        this.handleErrorMessage('currency')
                      }
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item sm={6} md={6} lg={6}>
                    <Typography className={`${this.errorTextClass('aba_routing', this.state.aba_routing)}`}>ABA/Routing<span className='required'>*</span></Typography>
                        <TextField
                          data-test-id="aba_routing"
                          name="aba_routing"
                          type="text"
                          style={{ width: "95%",  borderRadius: "5px" }}
                          value={this.state.aba_routing}
                          onChange={(event: any) => this.handleValueChange(event, "aba_routing")}
                          placeholder="Enter ABA/Routing"
                          className={`${this.errorFocusClass('aba_routing', this.state.aba_routing)}`}
                        />
                      {
                        this.handleErrorMessage('aba_routing')
                      }
                    </Grid>
                    <Grid item sm={6} md={6} lg={6}>
                      <Typography className={`${this.errorTextClass('account_holder_name', this.state.account_holder_name)}`}>Account Holder Name<span className='required'>*</span></Typography>
                      <TextField
                          data-test-id="account_holder_name"
                          name="account_holder_name"
                          type="text"
                          style={{ width: "95%",  borderRadius: "5px" }}
                          value={this.state.account_holder_name}
                          onChange={(event: any) => this.handleValueChange(event, "account_holder_name")}
                          placeholder="Enter Account Holder Name"
                          className={`${this.errorFocusClass('account_holder_name', this.state.account_holder_name)}`}
                        />
                      {
                        this.handleErrorMessage('account_holder_name')
                      }
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item sm={6} md={6} lg={6}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Typography>Send Money</Typography>
                          <Switch
                            data-test-id="toggleSwitch"
                            icon={<img alt="cross" src={cross} width={'100%'} height={'100%'} />}
                            checkedIcon={<img alt="check" src={check} width={'100%'} height={'100%'} />}
                            checked={this.state.send_money}
                            onChange={() => {
                              this.handleSwitchToggle();
                            }}
                            color="primary"
                            name={`switch`}
                          />
                        </Box>
                    </Grid>
                  </Grid>
                  </Box>
                </Box>
              </Grid>
            </Box>
            <Box sx={webStyle.actionBtnStyle}>
              <Button variant="contained" style={webStyle.discardBtn} data-test-id="discardBtn" onClick={this.handleDiscard}>Discard</Button>
              <Button
                type="submit"
                data-test-id="saveBtn"
                style={webStyle.saveBtn}
                onClick={this.handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputStyle: {
    flexDirection: "column",
    height: "100px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  mainWrapper: {
    paddingBottom: "30px",
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    background: "#fff",
  },
  gridMainLayout: {
    display: "flex",
  },
  buttonStyle: {
    height: "45px",
    width: "100%",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    marginTop: "40px",
  },
  gridsideBar: {
    transition: "all 0.3s",
    display: "flex",
    minHheight: "100%",
  },
  gridcontent: {
    width: "77%",
    flexGrow: 9,
    padding: "0 20px",
  },
  actionBtnStyle: {
    gap: 8,
    marginBlock: "40px",
    display: "flex",
    float: "right",
  },
  saveBtn: {
    backgroundColor: "rgba(9, 65, 115, 0.77)",
    color: "#ffffff"
  },
  discardBtn: {
    backgroundColor: "white"
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#E3E3E3"
  },
  buttonComponentSettings: {
    height: "100%",
    padding: "8px 8px",
    fontSize: "12px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#e1e1e1",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "black",
  },
  availabilityPopper: {
    border: "1px solid #ccc",
    borderRadius: 4,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    padding: 0,
    marginTop: "4px",
    backgroundColor: "#ffffff",
  },
  popperItem: {
    borderBottom: "1px solid #F3F3F3 !important",
    fontSize: "small",
  },
  popperItemBlue:{
    background:"#2A6395",
    borderBottom: "1px solid #F3F3F3 !important",
    fontSize: "18px",
  },
  productCardBox: {
    display: "flex",
    gap: 40
  },
  activityIconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  graphpopper: {
    margin: "10",
    minWidth: "30px",
    height: "30px",
  },
  boldFont:{
    fontSize: "22px",
    fontWeight: 500,
    marginLeft:"5%",
  },
  settingsPopper: {
    backgroundColor: "#ffffff",
    marginTop: "4px",
    padding: 0,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    border: "1px solid #ccc",
    borderRadius: 4,
  },
};
// Customizable Area End
