import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

type Token = string | null;
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";


export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  settingTabs: number;
  token: Token;
  userId : number;
  pricelist_setting:string;
  pricelistNameError:boolean;
  userSetting: any;
  value: number;
  edit: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CfCustomerSettingTabsController extends BlockComponent<Props, S, SS> {
  putSettingsData: string = "";
  getSettingsData: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      settingTabs: 0,
      token: "",
      userId: 0,
      value: 0,
      edit: false,
      pricelist_setting:"a",
      pricelistNameError:false,
      userSetting: {
        unit_of_measure: false,
        pricelist_name: '',
        price_list: false,
        price_list_setting: '',
        coupons_and_promotions: false,
        delivery_methods: false,
        invoice_policy: false,
        invoice_policy_setting: '',
      }
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  async componentDidMount() {
    const token = await getStorageData("TOKEN");
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginStyles")
    }
    else{
      this.setState({
        token: token,
      });
      this.getUserSettingApi()
    }
  }

  handlerTabs = (value: number) => {
    this.setState({
      settingTabs: value,
    });
  };
  apiResponse = (responseJson: any) => {
    this.setState({
      ...this.state,
      edit: true,
      pricelist_setting:responseJson.data.attributes.price_list_setting,
      userSetting: {
        unit_of_measure: responseJson.data.attributes.unit_of_measure,
        pricelist_name: responseJson.data.attributes.pricelist_name,
        price_list: responseJson.data.attributes.price_list,
        price_list_setting: responseJson.data.attributes.price_list_setting,
        coupons_and_promotions: responseJson.data.attributes.coupons_and_promotions,
        delivery_methods: responseJson.data.attributes.delivery_methods,
        invoice_policy: responseJson.data.attributes.invoice_policy,
        invoice_policy_setting: responseJson.data.attributes.invoice_policy_setting,
      }
    })
  }

  handleInvoiceRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      userSetting: {
        ...this.state.userSetting,
        invoice_policy_setting: event.target.value,
      }
    });
  };
  handlePricelistRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      userSetting: {
        ...this.state.userSetting,
        price_list_setting: event.target.value,
      }
    });
  };
  handleUnitOfMeasureCheckboxCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      userSetting: {
        ...this.state.userSetting,
        unit_of_measure: event.target.checked,
      }
    });
  };
  handlePricelistCheckboxCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      userSetting: {
        ...this.state.userSetting,
        price_list: event.target.checked,
        price_list_setting:this.handlePricelistSetting(event)
      }
    });
  };
  handlePricelistSetting = (event: React.ChangeEvent<HTMLInputElement>) =>{
    return (!event.target.checked)?"":this.state.pricelist_setting?this.state.pricelist_setting:"a"
  }
  handleCouponsCheckboxCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      userSetting: {
        ...this.state.userSetting,
        coupons_and_promotions: event.target.checked,
      }
    });
  };
  
  handlePricelistInput = (e:any) => {
    if(e){
      this.setState({...this.state,pricelistNameError:false,userSetting:{...this.state.userSetting,pricelist_name:e.target.value}});
    }else{
      this.setState({...this.state,pricelistNameError:true})
    }
    
  }

  handleProductCatalogDiscard = () => {
    this.state.edit ? this.getUserSettingApi() : this.setState({...this.state,userSetting:{unit_of_measure: false}});
  };

  handlePricingDiscard = () => {
    this.state.edit ? this.getUserSettingApi() : this.setState({...this.state,userSetting:{pricelist_name: '',price_list: false,price_list_setting: '',coupons_and_promotions: false}});
  };

  handleDeliveryCheckboxCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      userSetting: {
        delivery_methods: event.target.checked,
      }
    });
  };

  handleInvoiceCheckboxCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { userSetting } = this.state;

    const updatedUserSetting = {
      ...userSetting,
      invoice_policy: event.target.checked,
    };

    if (updatedUserSetting.invoice_policy) {
      updatedUserSetting.invoice_policy_setting = "order";
    }
    this.setState({ userSetting: updatedUserSetting });

    if (updatedUserSetting.invoice_policy===false) {
      updatedUserSetting.invoice_policy_setting = "";
    }
    this.setState({ userSetting: updatedUserSetting });
  };

  
  updateSettingResponse = () => {
    this.showAlert(
      configJSON.success,
      configJSON.setting_updated_msg
    );
  }

  
  handleSettingInvoiceDiscard = () => {
    this.getUserSettingApi();
  };


  handleSettingShippingDiscard = () => {
    this.getUserSettingApi()
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    runEngine.debugLog("API Message Recived", message);
    
    if (responseJson?.data?.id) {
      this.setUserSettingId(responseJson?.data?.id);
    }else if (responseJson?.data && responseJson?.data[0]?.id) {
      this.setUserSettingId(responseJson?.data[0]?.id);
      this.setState({ ...this.state, edit: true });
    }

    if ((apiRequestCallId === this.putSettingsData) && responseJson?.data[0]?.id) {
      this.updateSettingResponse()
    }
    if ((apiRequestCallId === this.getSettingsData) && responseJson?.data?.id) {
      this.apiResponse(responseJson);
      console.log(responseJson,"responseJson")
    }
  }

  

  handleSetting = () => {
    let formdata = new FormData();
    Object.keys(this.state.userSetting).forEach(key=>{
      if(this.state.userSetting[key]!==undefined){
        formdata.append(`[data][${key}]`, this.state.userSetting[key])
      }
    })

    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const user_setting_id = typeof window !== 'undefined' ? getStorageData('user_setting_id') : null;
    this.putSettingsData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.edit ? `bx_block_settings/settings/${user_setting_id}` : 'bx_block_settings/settings/'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.edit ? 'PUT' : 'POST'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  setUserSettingId = (id: any) => {
    typeof window !== 'undefined' && setStorageData('user_setting_id', id);
  }

  getUserSettingApi = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    this.getSettingsData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/settings`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
