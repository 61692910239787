Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.sales = 'SALES'
exports.pointofsale = 'POINT OF SALE'
exports.purchase = 'PURCHASE'
exports.inventory = 'INVENTORY'
exports.accounting = 'ACCOUNTING'
exports.contacts = 'CONTACTS'
exports.humanresource = 'HUMAN RESOURCE'
exports.signin = 'SIGNIN'
exports.calender = 'CALENDER'
exports.settings = 'SETTINGS'
// Customizable Area End
